import { PageHeader, Divider, Row, Col, Card, Descriptions } from "antd";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import LayoutContent from "components/LayoutContent/LayoutContent";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useMemo } from "react";
import { getLessonCompletedAction } from "store/actions/student.action";
import { useEffect } from "react";
import { RootState } from "store/reducer";
import { StudentState } from "store/reducers/student.reducer";
import moment from "moment";
import queryString from "query-string";

export default function LessonsCompleted() {
  // React router
  const { goBack } = useHistory();
  const { search } = useLocation();
  const { params } = useRouteMatch<{ studentId: string }>();
  const query: any = useMemo(() => queryString.parse(search), [search]);

  // React redux
  const dispatch = useDispatch();
  const { lessonCompletedData } = useSelector<RootState, StudentState>(state => state.studentState);

  const fetchData = useCallback(() => {
    try {
      if (params.studentId) {
        dispatch(getLessonCompletedAction(params.studentId, {class_id: query.classId}));
      }
    } catch (error) {
      //
    }
  }, [dispatch, params.studentId, query.classId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <PageHeader title="Lessons completed" onBack={goBack} />
      <LayoutContent>
        {lessonCompletedData.results && lessonCompletedData.results.map((theme) => (
          <div className="mb-10" key={theme.id}>
            <h2 className="text-lg">{theme.theme}</h2>
            <Divider />
            <Row gutter={48}>
              {theme.lessons.map((lesson) => (
                <Col key={lesson.id} className="mb-3" xs={24} md={12} lg={8}>
                  <Card
                    title={lesson.title}
                    type="inner"
                    actions={[
                      <span className="pointer-default">
                        Completed {moment(new Date(lesson.completed_at + 'z')).format("DD/MM/YYYY")}
                      </span>,
                    ]}
                    className="ant-card-actions--text"
                  >
                    <Descriptions colon={false} column={2}>
                      <Descriptions.Item label="📖">
                        {lesson.total_books} BOOKS
                      </Descriptions.Item>
                      <Descriptions.Item label="▶️">
                        {lesson.total_videos} VIDEOS
                      </Descriptions.Item>
                      <Descriptions.Item label="🎵">
                        {lesson.total_songs} SONGS
                      </Descriptions.Item>
                      <Descriptions.Item label="🎨">
                        {lesson.total_activities} ACTIVITIES
                      </Descriptions.Item>
                    </Descriptions>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        ))}
      </LayoutContent>
    </div>
  );
}
