import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router";
import { RootState } from "store/reducer";
import { AuthState } from "store/reducers/auth.reducer";
import queryString from "query-string";
import { authRoutes } from "constants/routes";

export default function AuthLayout() {
  // React router
  const { search } = useLocation();
  const { replace } = useHistory();

  // React redux
  const { user } = useSelector<RootState, AuthState>(
    (state) => state.authState
  );

  // Variables
  const query: any = useMemo(() => queryString.parse(search), [search]);

  useEffect(() => {
    if (user) {
      if (query.continue) {
        replace(query.continue);
      } else {
        replace("/dashboard");
      }
    }
  }, [user, query, replace]);

  return (
    <div className="min-h-screen min-w-screen bg-secondary flex items-center justify-center">
      <Switch>
        {authRoutes.map((route) => (
          <Route
            path={route.href}
            key={route.href}
            exact
            component={route.component}
          />
        ))}

        <Redirect to="/auth/login" />
      </Switch>
    </div>
  );
}
