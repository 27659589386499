export const PASSWORD_RULE = "Password must contain at least 8 characters long, at least one number, one lowercase, one uppercase letter and one special character";


export const ERROR_MESSAGES = {
  // COMMON
  INVALID_EMAIL: "Invalid email address",
  INVALID_PASSWORD: PASSWORD_RULE,
  INVALID_PHONE_NUMBER: "Invalid phone number",

  // LOGIN
  LOGIN_EMAIL_REQUIRED: "Email is required",
  LOGIN_PASSWORD_REQUIRED: "Password is required",


  // FORGOT PASSWORD
  FORGOT_EMAIL_REQUIRED: "Email is required",
  FORGOT_PASSWORD_REQUIRED: "Password is required",
  FORGOT_INVALID_PASSWORD: PASSWORD_RULE,
  FORGOT_CONFIRM_PASSWORD_REQUIRED: "Confirm password is required",
  FORGOT_PASSWORD_NOT_MATCH: "Passwords do not match",
  FORGOT_CODE_REQUIRED: "Verification code is required",

  // SIGN UP
  SIGN_UP_NAME_REQUIRED: "Full name is required",
  SIGN_UP_PASSWORD_REQUIRED: "Password is required",
  SIGN_UP_INVALID_PASSWORD: PASSWORD_RULE,
  SIGN_UP_CITY_REQUIRED: "City is required",
  SIGN_UP_COUNTRY_REQUIRED: "Country is required",
  SIGN_UP_CONFIRM_PASSWORD_REQUIRED: "Confirm password is required",
  SIGN_UP_PASSWORD_NOT_MATCH: "Passwords do not match",
  SIGN_UP_NOT_AGREE: "You must agree to the terms & conditions to continue",
  SIGN_UP_CODE_REQUIRED: "Verification code is required",

  // CHANGE PASSWORD
  CHANGE_PASSWORD_OLD_PASSWORD_REQUIRED: "Old password is required",
  CHANGE_PASSWORD_PASSWORD_REQUIRED: "New password is required",
  CHANGE_PASSWORD_PASSWORD_INVALID: PASSWORD_RULE,
  CHANGE_PASSWORD_CONFIRM_REQUIRED: "Confirm password is required",
  CHANGE_PASSWORD_NOT_MATCH: "Passwords do not match",

  // PROFILE
  PROFILE_FRONT_REQUIRED: "Front picture is required",
  PROFILE_BACK_REQUIRED: "Back picture is required",
  PROFILE_AVATAR_REQUIRED: "Profile picture is required",
  PROFILE_NAME_REQUIRED: "Name is required",
  PROFILE_FIRST_NAME_REQUIRED: "First name is required",
  PROFILE_LAST_NAME_REQUIRED: "Last name is required",
  PROFILE_PHONE_REQUIRED: "Phone number is required",
  PROFILE_DOB_REQUIRED: "Date of birth is required",
  PROFILE_NATIONALITY_REQUIRED: "Nationality is required",
  PROFILE_BIO_REQUIRED: "Bio is required",
  PROFILE_ADDRESS_REQUIRED: "Address is required",
  PROFILE_CITY_REQUIRED: "City is required",
  PROFILE_STATE_REQUIRED: "State is required",
  PROFILE_POSTCODE_REQUIRED: "Postcode is required",
  PROFILE_COUNTRY_REQUIRED: "Country is required",
  PROFILE_DIALECT_REQUIRED: "Spoken dialect is required",
  PROFILE_VIDEO_REQUIRED: "Video intro is required",
  PROFILE_LANGUAGES_REQUIRED: "You must have at least one spoken language",
  PROFILE_LANGUAGE_REQUIRED: "Language is required",
  PROFILE_LEVEL_RQUIRED: "Level is required",
  VERIFY_PHOTO_WARNING: `Acceptable identity documents vary by country, however, a passport scan for identity verification is always acceptable and is preferred`,
  CLASS_TYPES_REQUIRED: `Class types is required`,
  // ADD CLASS
  ADD_CLASS_AGE_RANGE_REQUIRED: "Age range is required",
  ADD_CLASS_CLASS_DURATION_REQUIRED: "Class duration is required",
  ADD_CLASS_CLASS_TYPE_REQUIRED: "Class type is required",
  ADD_CLASS_CLASS_SUBJECT_REQUIRED: "Class subject is required",
  ADD_CLASS_DAY_OF_WEEK_REQUIRED: "Day of week is required",
  ADD_CLASS_TIME_REQUIRED: "Time is required",
  ADD_CLASS_DATE_REQUIRED: "Start date is required",
  ADD_CLASS_MAX_STUDENT_REQUIRED: "Max amount of students is required",

  // RESCHEDULE
  RESCHEDULE_NEW_DATE_REQUIRED: "New date is required",

  // UPDATE PAYMENT
  PAYMENT_NAME_REQUIRED: "Account name is required",
  PAYMENT_ACCOUNT_NUMBER_REQUIRED: "Account number is required",
  PAYMENT_BSB_REQUIRED: "BSB is required",

  // UPDATE SUBSCRIPTION
  SUBSCRIPTION_FEE_REQUIRED: "Fee is required",
  SUBSCRIPTION_FEE_INVALID: "Fee is out of ranges"
}


export const POPUP_CONTENT = {
  RESET_PASSWORD: {
    title: "Successfully",
    content: "Your password has been reset, you can login now"
  },
  LOG_OUT: {
    title: "Sign out",
    content: "Are you sure you want to log out?"
  },
  // LESSON
  START_LESSON: {
    title: "Start lesson",
    content: "Are you sure you want to start this lesson?"
  },
  PAUSE_LESSON: {
    title: "Pause lesson",
    content: "Are you sure you want to pause this lesson?"
  },
}

// console.clear();
// Object.keys(POPUP_CONTENT).forEach((key) => {
//   console.log(key);
//   console.log((POPUP_CONTENT as any)[key].title);
//   console.log((POPUP_CONTENT as any)[key].content);
//   console.log("--------");
// })
