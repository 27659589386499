import axios, { AxiosResponse } from "axios";
import { TEACHER_URL } from "constants/urls";
import { stringifyParams } from "helpers/params";
import { Action } from "types/action";
import { EpisodeResourceData, WhatToWatch } from "types/episode";

//  ============================================================
//  GET EPISODE
//  ============================================================
export const GET_EPISODES_PENDING = "GET_EPISODES_PENDING";
export const GET_EPISODES_SUCCESS = "GET_EPISODES_SUCCESS";
export const GET_EPISODES_FAILURE = "GET_EPISODES_FAILURE";
function getEpisodesPending(): Action {
  return {
      type: GET_EPISODES_PENDING
   }
}

function getEpisodesSuccess(episodeResourceData: EpisodeResourceData): Action {
  return {
      type: GET_EPISODES_SUCCESS,
      payload: episodeResourceData
   }
}

function getEpisodesFailure(error: any): Action {
  return {
      type: GET_EPISODES_FAILURE,
      payload: error
   }
}

export function getEpisodesAction(query?: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(getEpisodesPending());
      const response: AxiosResponse<EpisodeResourceData> = await axios.get(`${TEACHER_URL}/episodes/${stringifyParams(query)}`);
      dispatch(getEpisodesSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getEpisodesFailure(error));
      return Promise.reject(error)
    }
  }
}

//  ============================================================
//  GET EPISODE
//  ============================================================
export const GET_WHAT_TO_WATCH_PENDING = "GET_WHAT_TO_WATCH_PENDING";
export const GET_WHAT_TO_WATCH_SUCCESS = "GET_WHAT_TO_WATCH_SUCCESS";
export const GET_WHAT_TO_WATCH_FAILURE = "GET_WHAT_TO_WATCH_FAILURE";
function getWhatToWatchPending(): Action {
  return {
      type: GET_WHAT_TO_WATCH_PENDING
   }
}

function getWhatToWatchSuccess(whatToWatch: WhatToWatch): Action {
  return {
      type: GET_WHAT_TO_WATCH_SUCCESS,
      payload: whatToWatch
   }
}

function getWhatToWatchFailure(error: any): Action {
  return {
      type: GET_WHAT_TO_WATCH_FAILURE,
      payload: error
   }
}

export function getWhatToWatchAction(query?: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(getWhatToWatchPending());
      const response: AxiosResponse<WhatToWatch> = await axios.get(`${TEACHER_URL}/episodes/what-to-watch/${stringifyParams(query)}`);
      dispatch(getWhatToWatchSuccess({results: response.data.results, filter: query.since}));
      return Promise.resolve();
    } catch (error) {
      dispatch(getWhatToWatchFailure(error));
      return Promise.reject(error)
    }
  }
}