import { Select } from "antd";
type DialectSelectProps = {
  value?: string;
  onChange?: (value: string) => void;
};

export default function DialectSelect(props: DialectSelectProps) {
  const dialect = [
    {id: "0be14139-2f5f-40ce-a7ae-02edb0de515a", title: "Sudanese", languageCode: "en"},
    {id: "5c20c4df-6a7f-40f8-bd73-44cd7bb1d848", title: "Moroccan", languageCode: "en"},
    {id: "8a7bc56b-3582-47a7-8d34-91407d7529d7", title: "Jordanian", languageCode: "en"},
    {id: "cf2a7034-72e5-421c-9d7c-06505f0b87de", title: "Algerian", languageCode: "en"},
    {id: "e39d5339-a50e-4d53-b432-9b03c38095e1", title: "Tunisian", languageCode: "en"},
    {id: "ee309db4-c7f8-42ad-82e9-cb95a8f1a09b", title: "Egyptian", languageCode: "en"}
  ]

  return (
    <Select {...props} >
      {dialect.map((dialect) => (
        <Select.Option key={dialect.id} value={dialect.title}>
          {dialect.title}
        </Select.Option>
      ))}
    </Select>
  );
}
