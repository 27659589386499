import axios, { AxiosResponse } from "axios";
import { TEACHER_URL } from "constants/urls";
import { Action } from "types/action";
import { DialectData } from "types/dialect";

//  ============================================================
//  GET DIALECTS
//  ============================================================
export const GET_DIALECTS_PENDING = "GET_DIALECTS_PENDING";
export const GET_DIALECTS_SUCCESS = "GET_DIALECTS_SUCCESS";
export const GET_DIALECTS_FAILURE = "GET_DIALECTS_FAILURE";
function getDialectsPending(): Action {
  return {
      type: GET_DIALECTS_PENDING
   }
}

function getDialectsSuccess(dialectData: DialectData): Action {
  return {
      type: GET_DIALECTS_SUCCESS,
      payload: dialectData
   }
}

function getDialectsFailure(error: any): Action {
  return {
      type: GET_DIALECTS_FAILURE,
      payload: error
   }
}

export function getDialectsAction() {
  return async (dispatch: Function) => {
    try {
      dispatch(getDialectsPending());
      const response: AxiosResponse<DialectData> = await axios.get(`${TEACHER_URL}/profile/dialects/`);
      console.log(response);
      dispatch(getDialectsSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getDialectsFailure(error));
      return Promise.reject(error)
    }
  }
}