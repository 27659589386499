import { Card, List, Avatar } from "antd";
import { getYearOld } from "helpers/date";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "store/reducer";
import { ClassLessonState } from "store/reducers/class-lesson.reducer";

export default function ClassLessonAttendees() {

  const { attendeesLesson } = useSelector<RootState, ClassLessonState>(state => state.classLessonState)
  return (
    <Card title="LESSON ATTENDEES" className="lesson-attendees">
      <List
        split={false}
        dataSource={attendeesLesson.results || []}
        renderItem={(attendee) => (
          <List.Item actions={[<Link to={`/my-students/${attendee.student.id}`} key="profile">View profile</Link>]}>
            <Avatar src={attendee.student.user.image?.image_s3_url}>{attendee.student.user.first_name}</Avatar>
            <div className="flex-1 px-2">{attendee.student.user.first_name} {attendee.student.user.last_name}</div>
            <div className="px-2">{getYearOld(attendee.student.user.date_of_birth)} year-old</div>
          </List.Item>
        )}
      ></List>
    </Card>
  );
}
