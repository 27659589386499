import { PageHeader, Row, Col, Spin, Space } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import LessonOverview from "./ClassLessonOverview";
import LessonReady from "./ClassLessonReady";
import LessonOutline from "./ClassLessonOutline";
import LessonAttendees from "./ClassLessonAttendees";
import LessonResources from "./ClassLessonResources";
import LessonSummary from "./ClassLessonSummary";
import queryString from "query-string";
import { Fragment, useState } from "react";
import { useMemo } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getClassLessonAction,
  getClassLessonAttendeesAction,
  markAsReparedAction,
} from "store/actions/class-lesson.action";
import { useEffect } from "react";
import { RootState } from "store/reducer";
import { ClassLessonState } from "store/reducers/class-lesson.reducer";
import { useRef } from "react";
import Countdown from "react-countdown";

import "./ClassLessonDetails.scss";
import ClassLessonAttendance from "./ClassLessonAttendence";
import Clock from "components/Clock/Clock";
import { LessonStatus } from "types/lesson";

export default function ClassLessonDetails() {
  // State
  const [lessonStatus, setLessonStatus] = useState<LessonStatus>(
    LessonStatus.PAUSED
  );

  // React router
  const { search } = useLocation();
  const { goBack } = useHistory();
  const { params } = useRouteMatch<{ classId: string }>();

  // React redux
  const dispatch = useDispatch();
  const { classLesson, getClassLessonLoading } = useSelector<
    RootState,
    ClassLessonState
  >((state) => state.classLessonState);

  // Variables
  const clockRef = useRef<Countdown>(null);
  const query: any = useMemo(() => queryString.parse(search), [search]);

  const [isLessonStarted, setisLessonStarted] = useState<boolean>(false);
  const [isLessonEnded, setisLessonEnded] = useState<boolean>(false);

  const fetchData = useCallback(() => {
    try {
      if (params.classId && query.lessonId) {
        Promise.all([
          dispatch(getClassLessonAction(query.lessonId)),
          dispatch(getClassLessonAttendeesAction(query.lessonId, {no_pagination: true}))
        ])
      }
    } catch (error) {}
  }, [dispatch, params.classId, query.lessonId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (classLesson) {
      if (classLesson.status === LessonStatus.PAUSED) {
        setLessonStatus(LessonStatus.PAUSED);
        setisLessonStarted(false)
      } else {
        setLessonStatus(LessonStatus.STARTED);
      }
      setisLessonStarted(classLesson.ended_at === null && classLesson.status !== LessonStatus.PAUSED)
      setisLessonEnded(classLesson.ended_at !== null)
    }
  }, [classLesson]);

  function onLessonStart() {
    // setIsPlaying(true);
    setisLessonStarted(true)
    setLessonStatus(LessonStatus.STARTED);
  }

  function onLessonPause() {
    // setIsPlaying(false);
    setisLessonStarted(false)
    setLessonStatus(LessonStatus.PAUSED);
  }

  function onLessonResume() {
    setLessonStatus(LessonStatus.STARTED);
  }

  function onLessonRestart() {
    setLessonStatus(LessonStatus.PAUSED);
    setTimeout(() => {
      setLessonStatus(LessonStatus.STARTED);
    }, 0);
  }

  function onLessonEnd() {
    setisLessonEnded(true);
    setLessonStatus(LessonStatus.ENDED);
  }

  // function timerRenderer({ minutes, seconds, completed }: any) {
  //   const minStr = minutes.toString().padStart(2, "0");
  //   const secStr = seconds.toString().padStart(2, "0");
  //   if (completed) {
  //     return <strong>Timed out!</strong>;
  //   } else {
  //     return (
  //       <span>
  //         <strong>
  //           {minStr}:{secStr}
  //         </strong>
  //       </span>
  //     );
  //   }
  // }

  useEffect(() => {
    if (isLessonStarted && !isLessonEnded && clockRef.current) {
      clockRef.current.start();
    }
  }, [isLessonStarted, isLessonEnded]);

  useEffect(() => {
    if (
      classLesson.status === LessonStatus.PAUSED &&
      clockRef.current
    ) {
      clockRef.current.start();
    }
  }, [classLesson]);

  function handleMarkAsPrepared(e: any) {
    const {checked} = e.target;
    const body = {is_prepared: checked}
    dispatch(markAsReparedAction(query.lessonId, body)); 
  }

  return (
    <div className="class-lesson-details">
      <Spin spinning={getClassLessonLoading}>
        <PageHeader
          title="Lesson details"
          onBack={goBack}
          extra={[
            isLessonStarted && !isLessonEnded && classLesson.status && (
              <Space key="clock" className="text-xl">
                <Clock lessonStatus={lessonStatus} />
              </Space>
            ),
            !isLessonStarted && !isLessonEnded && classLesson && (
              <Fragment key="prepared">
                <span key="prepared-text">
                  Have you prepared for you next lesson?
                </span>
                <Checkbox
                  key="prepared-checkbox"
                  defaultChecked={classLesson.is_prepared}
                  onChange={handleMarkAsPrepared}
                >
                  Mark as prepared
                </Checkbox>
              </Fragment>
            ),
          ]}
        />

        <div className="px-6">
          <div className="mb-6">
            <LessonOverview
              isLessonStarted={isLessonStarted}
              isLessonEnded={isLessonEnded}
              onLessonStart={onLessonStart}
              onLessonPause={onLessonPause}
              onLessonResume={onLessonResume}
              onLessonRestart={onLessonRestart}
              onLessonEnd={onLessonEnd}
              classId={params.classId}
              lessonId={query.lessonId}
            />
          </div>

          <Row gutter={30}>
            <Col flex="0 0 400px">
              {classLesson.started_at && (
                <div className="mb-6">
                  <ClassLessonAttendance
                    classId={params.classId}
                    lessonId={query.lessonId}
                  />
                </div>
              )}

              <div className="mb-6">
                <LessonReady
                  classId={params.classId}
                  lessonId={query.lessonId}
                  isLessonStarted={isLessonStarted}
                  isLessonEnded={isLessonEnded}
                />
              </div>

              <div className="mb-6">
                <LessonOutline
                  classId={params.classId}
                  lessonId={query.lessonId}
                  isLessonStarted={isLessonStarted}
                  isLessonEnded={isLessonEnded}
                />
              </div>

              {!classLesson.started_at && (
                <div className="mb-6">
                  <LessonAttendees />
                </div>
              )}
            </Col>
            <Col flex="1">
              {isLessonEnded ? (
                <LessonSummary
                  classId={params.classId}
                  lessonId={query.lessonId}
                />
              ) : (
                <LessonResources
                  classId={params.classId}
                  lessonId={query.lessonId}
                />
              )}
            </Col>
          </Row>
        </div>
      </Spin>
    </div>
  );
}
