import moment from "moment";
import { Popover } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";

export function CustomEventWrapper(props: any) {
  const { event, type, children } = props;
  const {
    resource: { lesson },
  } = event;
  const timezone = moment.tz.guess()
  const start = moment(new Date(lesson.scheduled_at + 'z')).tz(timezone)
  const end = moment(start).add(lesson.lesson_class.duration, "minutes");

  const content =
    type === "date" ? (
      <div>
        <div>
        <strong>
            {lesson.ended_at ? 'Lesson Ended' : lesson.status === 'Started' ? 'Started' : ''}
          </strong>
        </div>
        <div>
          {lesson.ended_at && <CheckCircleFilled />}{" "}
          <strong>
            {lesson.lesson_class.class_type.title} ({lesson.lesson_class.age_range.title})
          </strong>
        </div>
        <div>
          {start.format("hh:mm a")} - {end.format("hh:mm a")}
        </div>
        {!lesson.ended_at && (
          <div>{lesson.is_prepared ? "Prepared" : "Not prepared"}</div>
        )}
      </div>
    ) : (
      <div>
        <strong>{event.title}</strong>
      </div>
    );

  return (
    <Popover content={content}>
      {children}
    </Popover>
  );
}
