import ForgotPasswordPage from "pages/auth/ForgotPasswordPage";
import InvitationPage from "pages/auth/InvitationPage";
import LoginPage from "pages/auth/LoginPage";
import SignUpPage from "pages/auth/SignUpPage";
import CurriculumnPage from "pages/curriculum/CurriculumPage";
import FinancialsPage from "pages/financials/FinancialsPage";
import MessagesPage from "pages/messages/MessagesPage";
import MyClassesPage from "pages/my-classes/MyClassesPage";
import MyStudentsPage from "pages/my-students/MyStudentsPage";
import BooksPage from "pages/resources/books/BooksPage";
import EpisodesPage from "pages/resources/episodes/EpisodesPage";
import MusicPage from "pages/resources/music/MusicPage";
import ToPrintPagePage from "pages/resources/to-print/ToPrintPage";
import DashboardPage from "../pages/dashboard/DashboardPage";
// import HelpPage from "../pages/help/HelpPage";

type AuthRouteItem = {
  href: string,
  component: () => JSX.Element
}

type RouteItem = {
  href: string;
  text: string;
  component?: () => JSX.Element;
  icon?: React.ComponentType;
  children?: Array<RouteItem>;
};

export const authRoutes: Array<AuthRouteItem> = [
  {
    href: "/auth/login",
    component: LoginPage
  },
  {
    href: "/auth/forgot-password",
    component: ForgotPasswordPage
  },
  {
    href: "/auth/invitation",
    component: InvitationPage
  },
  {
    href: "/auth/sign-up",
    component: SignUpPage
  }
]

export const routes: Array<RouteItem> = [
  {
    href: "/dashboard",
    text: "Dashboard",
    component: DashboardPage,
  },
  {
    href: "/my-classes",
    text: "My classes",
    component: MyClassesPage
  },
  {
    href: "/curriculum",
    text: "Curriculum",
    component: CurriculumnPage
  },
  {
    href: "/my-students",
    text: "My students",
    component: MyStudentsPage
  },
  {
    href: "/financials",
    text: "Financials",
    component: FinancialsPage
  },
  {
    href: "/messages",
    text: "Messages",
    component: MessagesPage
  },
  {
    href: "/resources",
    text: "Resources",
    children: [
      {
        href: "/music",
        text: "Music",
        component: MusicPage
      },
      {
        href: "/books",
        text: "Books",
        component: BooksPage
      },
      {
        href: "/episodes",
        text: "Episodes",
        component: EpisodesPage
      },
      {
        href: "/to-print",
        text: "To print",
        component: ToPrintPagePage
      }
    ]
  },
  // {
  //   href: "/help",
  //   text: "Help",
  //   component: HelpPage
  // }
];
