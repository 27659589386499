export enum Environment {
  Local,
  Deploy
}

export enum Staging {
  Dev = "dev",
  Stage = "stage",
  Prod = "prod"
}

export const environment = {
  env: Environment.Local,
  staging: Staging.Dev,
  prod: Staging.Dev
}