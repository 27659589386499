import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { ClassState } from "store/reducers/class.reducer";
import { Class } from "types/class";
import useTableAndQueryChange from "hooks/useTableAndQueryChange";
import usePagination from "hooks/usePagination";
import { timezone } from "helpers/time";

export default function CompletedClassesTab() {

  // React redux
  const { currentClassData, getCurrentClassesLoading } = useSelector<RootState, ClassState>(
    (state) => state.classState
  );

  // set variable

  const { onTableChange } = useTableAndQueryChange();
  const { page, limit } = usePagination();

  const columns: ColumnsType<Class> = [
    {
      title: "#",
      key: "#",
      render: (_, __, index) => index + 1,
    },
    {
      title: "Start date",
      key: "start_datetime",
      dataIndex: "start_datetime",
      render: (start_datetime: string) => moment(start_datetime + 'z').tz(timezone).format("DD/MM/YYYY"),
    },
    {
      title: "Subject",
      key: "class_subject",
      dataIndex: ["class_subject", "title"],
    },
    {
      title: "Class type",
      key: "class_type",
      dataIndex: ["class_type", "title"],
    },
    {
      title: "Day of the week",
      key: "days_of_week",
      dataIndex: "days_of_week",
    },
    {
      title: "Start time",
      key: "time",
      dataIndex: "start_datetime",
      render: (start_datetime: string) => moment(start_datetime+'z').tz(timezone, true).format("HH:mm"),
    },
    {
      title: "Duration",
      key: "duration",
      dataIndex: "duration",
      render: (duration: string) => duration + " mins",
    },
    {
      title: "Age group",
      key: "age_range",
      dataIndex: ["age_range", "title"],
    },
    {
      title: "Max. students",
      key: "max_students",
      dataIndex: "max_students",
    }
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={currentClassData.results}
        loading={getCurrentClassesLoading}
        rowKey="id"
        onChange={onTableChange}
        pagination={{
          showSizeChanger: true,
          total: currentClassData.count,
          current: page,
          pageSize: limit,
          hideOnSinglePage: true
        }}
      />
    </div>
  );
}
