export function validateConfirmPassword(password: string, confirm?: string) {
  if (!confirm || confirm === password) {
    return Promise.resolve();
  }

  return Promise.reject();
}

export function validateStripeForm(value: any, errorMessage: string): Promise<any> {
  if (!value || (value && value.empty)) {
    return Promise.reject(errorMessage);
  } else if (value.error && value.error.message) {
    return Promise.reject(value.error.message);
  } else if (!value.complete) {
    return Promise.reject("Please input all required form");
  }

  return Promise.resolve();
}