import axios, { AxiosResponse } from "axios";
import { CLASS_DURATION_URL } from "constants/urls";
import {Action} from "types/action";
import { ClassDuration } from "types/class-duration";

//  ============================================================
//  GET CLASS DURATION BY AGE RANGE
//  ============================================================
export const GET_AGE_RANGE_CLASS_DURATION_PENDING = "GET_AGE_RANGE_CLASS_DURATION_PENDING";
export const GET_AGE_RANGE_CLASS_DURATION_SUCCESS = "GET_AGE_RANGE_CLASS_DURATION_SUCCESS";
export const GET_AGE_RANGE_CLASS_DURATION_FAILURE = "GET_AGE_RANGE_CLASS_DURATION_FAILURE";
function getAgeRangeClassDurationPending(): Action {
  return {
      type: GET_AGE_RANGE_CLASS_DURATION_PENDING
   }
}

function getAgeRangeClassDurationSuccess(classDuration: ClassDuration): Action {
  return {
      type: GET_AGE_RANGE_CLASS_DURATION_SUCCESS,
      payload: classDuration
   }
}

function getAgeRangeClassDurationFailure(error: any): Action {
  return {
      type: GET_AGE_RANGE_CLASS_DURATION_FAILURE,
      payload: error
   }
}

export function getAgeRangeClassDurationAction(ageRangeReferenceId: string) {
  return async (dispatch: Function) => {
    try {
      dispatch(getAgeRangeClassDurationPending());
      const response: AxiosResponse<ClassDuration> = await axios.get(CLASS_DURATION_URL + "/by-age-range/" + ageRangeReferenceId)
      dispatch(getAgeRangeClassDurationSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getAgeRangeClassDurationFailure(error));
      return Promise.reject(error)
    }
  }
}