import { Card, Row, Col } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { LessonState } from "store/reducers/lesson.reducer";

export default function ClassOverview() {

  const { lesson } = useSelector<RootState, LessonState>(state => state.lessonState);

  return (
    <Card>
      <Row gutter={48} align="middle">
        <Col xs={24} lg={6}>
          <img className="w-full" height="200" src={lesson?.badge?.image_s3_url} alt="lesson-img" />
        </Col>
        <Col xs={24} lg={18}>
          <Row className="mb-3">
            <Col xs={24} md={12} lg={6}>
              <p>
                <strong>Class subject</strong>
              </p>
              <p>{lesson?.class_subs?.map(ag => ag.title).join(" • ")}</p>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <p>
                <strong>Theme</strong>
              </p>
              <p>{lesson.theme?.title}</p>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <p>
                <strong>Lesson</strong>
              </p>
              <p>{lesson.title}</p>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <p>
                <strong>Age group</strong>
              </p>
              <p>{lesson?.age_group?.map(ag => ag.title).join(" • ")}</p>
            </Col>
          </Row>
          <p>
            <strong>Skills developed</strong>
          </p>
          <p>{lesson.skill_develop?.map(skill => skill).join(" • ")}</p>
        </Col>
      </Row>
    </Card>
  );
}
