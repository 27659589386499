import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./store/store";
import { setupInterceptor } from "helpers/interceptor";
import Amplify from "aws-amplify";
import { COGNITO_CONFIG } from "configs/cognito";
import {
  ReactReduxFirebaseProvider,
} from 'react-redux-firebase'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import "./antd.less";
import "./index.scss";
import "./tailwind.css";
import { FIREBASE_CONFIG } from "configs/firebase";
import {registerServiceWorker} from './serviceWorker'

setupInterceptor();
registerServiceWorker()
Amplify.configure({
  userPoolId: COGNITO_CONFIG.userPoolId,
  userPoolWebClientId: COGNITO_CONFIG.userPoolWebClientId,
});

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users'
  // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}

// Initialize firebase instance
firebase.initializeApp(FIREBASE_CONFIG)

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch
  // createFirestoreInstance // <- needed if using firestore
}

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <Router>
        <App />
      </Router>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
