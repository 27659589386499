import axios, { AxiosResponse } from "axios";
import { RESOURCE_URL, TEACHER_URL } from "constants/urls";
import { stringifyParams } from "helpers/params";
import { Action } from "types/action";
import { BookDetails, BookResourceData, FeaturedBookData, WhatToRead } from "types/book";

//  ============================================================
//  GET BOOKS
//  ============================================================
export const GET_BOOKS_PENDING = "GET_BOOKS_PENDING";
export const GET_BOOKS_SUCCESS = "GET_BOOKS_SUCCESS";
export const GET_BOOKS_FAILURE = "GET_BOOKS_FAILURE";
function getBooksPending(): Action {
  return {
      type: GET_BOOKS_PENDING
   }
}

function getBooksSuccess(bookData: BookResourceData): Action {
  return {
      type: GET_BOOKS_SUCCESS,
      payload: bookData
   }
}

function getBooksFailure(error: any): Action {
  return {
      type: GET_BOOKS_FAILURE,
      payload: error
   }
}

export function getBooksAction(query: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(getBooksPending());
      const response: AxiosResponse<BookResourceData> = await axios.get(`${TEACHER_URL}/books/${stringifyParams(query)}`);
      dispatch(getBooksSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getBooksFailure(error));
      return Promise.reject(error)
    }
  }
}

//  ============================================================
//  GET WHAT TO READ
//  ============================================================
export const GET_WHAT_TO_READ_PENDING = "GET_WHAT_TO_READ_PENDING";
export const GET_WHAT_TO_READ_SUCCESS = "GET_WHAT_TO_READ_SUCCESS";
export const GET_WHAT_TO_READ_FAILURE = "GET_WHAT_TO_READ_FAILURE";
function getWhatToReadPending(): Action {
  return {
      type: GET_WHAT_TO_READ_PENDING
   }
}

function getWhatToReadSuccess(whatToRead: WhatToRead): Action {
  return {
      type: GET_WHAT_TO_READ_SUCCESS,
      payload: whatToRead
   }
}

function getWhatToReadFailure(error: any): Action {
  return {
      type: GET_WHAT_TO_READ_FAILURE,
      payload: error
   }
}

export function getWhatToReadAction(query: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(getWhatToReadPending());
      const response: AxiosResponse<WhatToRead> = await axios.get(`${TEACHER_URL}/books/what-to-read/${stringifyParams(query)}`);
      dispatch(getWhatToReadSuccess({results: response.data.results, filter: query.since}));
      return Promise.resolve();
    } catch (error) {
      dispatch(getWhatToReadFailure(error));
      return Promise.reject(error)
    }
  }
}

//  ============================================================
//  GET FEATURED BOOKS
//  ============================================================
export const GET_FEATURED_BOOKS_PENDING = "GET_FEATURED_BOOKS_PENDING";
export const GET_FEATURED_BOOKS_SUCCESS = "GET_FEATURED_BOOKS_SUCCESS";
export const GET_FEATURED_BOOKS_FAILURE = "GET_FEATURED_BOOKS_FAILURE";
function getFeaturedBooksPending(): Action {
  return {
      type: GET_FEATURED_BOOKS_PENDING
   }
}

function getFeaturedBooksSuccess(featuredBooks: FeaturedBookData): Action {
  return {
      type: GET_FEATURED_BOOKS_SUCCESS,
      payload: featuredBooks
   }
}

function getFeaturedBooksFailure(error: any): Action {
  return {
      type: GET_FEATURED_BOOKS_FAILURE,
      payload: error
   }
}

export function getFeaturedBooksAction() {
  return async (dispatch: Function) => {
    try {
      dispatch(getFeaturedBooksPending());
      const response: AxiosResponse<FeaturedBookData> = await axios.get(`${TEACHER_URL}/featured-books`);
      dispatch(getFeaturedBooksSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getFeaturedBooksFailure(error));
      return Promise.reject(error)
    }
  }
}

//  ============================================================
//  GET BOOK DETAILS
//  ============================================================
export const GET_BOOK_DETAILS_PENDING = "GET_BOOK_DETAILS_PENDING";
export const GET_BOOK_DETAILS_SUCCESS = "GET_BOOK_DETAILS_SUCCESS";
export const GET_BOOK_DETAILS_FAILURE = "GET_BOOK_DETAILS_FAILURE";
function getBookDetailsPending(): Action {
  return {
      type: GET_BOOK_DETAILS_PENDING
   }
}

function getBookDetailsSuccess(book: BookDetails): Action {
  return {
      type: GET_BOOK_DETAILS_SUCCESS,
      payload: book
   }
}

function getBookDetailsFailure(error: any): Action {
  return {
      type: GET_BOOK_DETAILS_FAILURE,
      payload: error
   }
}

export function getBookDetailsAction(bookId: string) {
  return async (dispatch: Function) => {
    try {
      dispatch(getBookDetailsPending());
      const response: AxiosResponse<BookDetails> = await axios.get(`${RESOURCE_URL}/book-detail/${bookId}`);
      dispatch(getBookDetailsSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getBookDetailsFailure(error));
      return Promise.reject(error)
    }
  }
}