import { Card } from "antd";
import { Book } from "types/book";
import { Link } from "react-router-dom";
import "./BookThumbnail.scss";

type BookThumbnailProps = {
  book: Book;
};

export default function BookThumbnail({ book }: BookThumbnailProps) {
  return (
    <div className="book-thumbnail">
      <Card
        size="small"
        cover={
          <div className="book-thumbnail__cover">
            <img
              className="book-thumbnail__cover-image"
              src={book.thumbnail.image_s3_url}
              alt={book.title}
            />
          </div>
        }
        actions={[
          <Link key="read" to={`/resources/books/${book.id}/?link=${book.file}`}>
            Read
          </Link>,
        ]}
      >
        <Card.Meta
          title={book.title}
          description={book.reading_times + " minutes read"}
        />
      </Card>
    </div>
  );
}
