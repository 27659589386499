//  ============================================================
//  GET DASHBOARD

import axios, { AxiosResponse } from "axios";
import { TEACHER_URL } from "constants/urls";
import { Action } from "types/action";
import { Dashboard, NextWeekLesson } from "types/dashboard";

//  ============================================================
export const GET_DASHBOARD_PENDING = "GET_DASHBOARD_PENDING";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_FAILURE = "GET_DASHBOARD_FAILURE";
function getDashboardPending(): Action {
  return {
      type: GET_DASHBOARD_PENDING
   }
}

function getDashboardSuccess(dashboard: Dashboard): Action {
  return {
      type: GET_DASHBOARD_SUCCESS,
      payload: dashboard
   }
}

function getDashboardFailure(error: any): Action {
  return {
      type: GET_DASHBOARD_FAILURE,
      payload: error
   }
}

export function getDashboardAction() {
  return async (dispatch: Function) => {
    try {
      dispatch(getDashboardPending());
      const response: AxiosResponse<Dashboard> = await axios.get(TEACHER_URL + "/reports/");
      dispatch(getDashboardSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getDashboardFailure(error));
      return Promise.reject(error)
    }
  }
}

//  ============================================================
//  GET NEXT WEEK LESSONS
//  ============================================================
export const GET_NEXT_WEEK_PENDING = "GET_NEXT_WEEK_PENDING";
export const GET_NEXT_WEEK_SUCCESS = "GET_NEXT_WEEK_SUCCESS";
export const GET_NEXT_WEEK_FAILURE = "GET_NEXT_WEEK_FAILURE";
function getNextWeekPending(): Action {
  return {
      type: GET_NEXT_WEEK_PENDING
   }
}

function getNextWeekSuccess(data: NextWeekLesson): Action {
  return {
      type: GET_NEXT_WEEK_SUCCESS,
      payload: data
   }
}

function getNextWeekFailure(error: any): Action {
  return {
      type: GET_NEXT_WEEK_FAILURE,
      payload: error
   }
}

export function getNextWeekAction() {
  return async (dispatch: Function) => {
    try {
      dispatch(getNextWeekPending());
      const response: AxiosResponse<NextWeekLesson> = await axios.get(TEACHER_URL + "/find-nextweek-lessons");
      dispatch(getNextWeekSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getNextWeekFailure(error));
      return Promise.reject(error)
    }
  }
}