import { GET_RATING_SUCCESS, GET_REVIEWS_FAILURE, GET_REVIEWS_PENDING, GET_REVIEWS_SUCCESS } from "store/actions/review.action"
import { Action } from "types/action"
import { Rating, ReviewData } from "types/review"

export type ReviewState = {
  // Data
  reviewData: ReviewData;
  totalReview: Rating;
  // Loading
  getReviewsLoading: boolean;
  // Error
  getReviewsError: any;
}

const initialState: ReviewState = {
  // Data
  reviewData: {count: 0, results: []},
  totalReview: {id: '', rating: 0},
  // Loading
  getReviewsLoading: false,
  // Error
  getReviewsError: null
}

export default function reviewReducer(state = initialState, action: Action): ReviewState {
  switch (action.type) {
    //  ============================================================
    //  GET REVIEWS
    //  ============================================================
    case GET_REVIEWS_PENDING:
      return {
        ...state,
        getReviewsLoading: true
      }
    case GET_REVIEWS_SUCCESS:
      return {
        ...state,
        getReviewsLoading: false,
        reviewData: action.payload
      }
    case GET_REVIEWS_FAILURE:
      return {
        ...state,
        getReviewsLoading: false,
        getReviewsError: action.payload
      }
      case GET_RATING_SUCCESS:
      return {
        ...state,
        getReviewsLoading: false,
        totalReview: action.payload
      }
    default:
      return { ...state }
  }
}