import { useHistory, useLocation } from "react-router-dom";
import AuthContainer from "./AuthContainer";
import queryString from "query-string";
import { useMemo } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { AuthState } from "store/reducers/auth.reducer";
import { verifyInvitationAction } from "store/actions/auth.action";
import { Spin, Result } from "antd";

export default function InvitationPage() {
  // React router
  const { search } = useLocation();
  const { push } = useHistory();

  // React redux
  const dispatch = useDispatch();
  const { verifyResult, verifyLoading, verifyError } = useSelector<
    RootState,
    AuthState
  >((state) => state.authState);

  // Variables
  const query: any = useMemo(() => queryString.parse(search), [search]);

  useEffect(() => {
    if (query.email && query.code) {
      dispatch(verifyInvitationAction(query.email, query.code));
    }
  }, [query, dispatch]);

  useEffect(() => {
    if (verifyResult && verifyResult.is_match && query) {
      push(`/auth/sign-up?first_name=${query.first_name}&last_name=${query.last_name}`);
    }
  }, [verifyResult, push, query]);

  return (
    <AuthContainer title="Invitation">
      {verifyLoading && (
        <div className="py-6 flex items-center justify-center">
          <Spin spinning />
        </div>
      )}

      {verifyResult && !verifyResult.is_match && (
        <Result
          status="error"
          title="Error"
          subTitle="The invitation information is incorrect, please contact the administrator for information"
        />
      )}

      {verifyError && (
        <Result
          title="Error"
          subTitle={verifyError.response?.data?.message || "Unexpected error"}
        />
      )}
    </AuthContainer>
  );
}
