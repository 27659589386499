import { Card, Checkbox } from "antd";
import { Lesson } from "types/lesson";
import "./LessonSelect.scss";

type LessonSelectProps = {
  lesson: Lesson;
};

export default function LessonSelect(props: LessonSelectProps) {
  const { lesson } = props;

  return (
    <div className="lesson-select">
      <Card cover={<img src={lesson.badge.image_s3_url} alt="" />}>
        <Checkbox defaultChecked={true} value={lesson.id}>{lesson.title}</Checkbox>
      </Card>
    </div>
  );
}
