import { Link } from "react-router-dom";
import { BookDetails } from "types/book";
import "./FeaturedBook.scss";

type FeaturedBookProps = {
  book: BookDetails;
};

export default function FeaturedBook(props: FeaturedBookProps) {
  return (
    <div className="featured-book">
      <img
        src={props.book.rollingBanner}
        alt=""
        className="featured-book__banner"
      />

      <div className="featured-book__text">
        <div className="featured-book__title">{props.book.title}</div>
      </div>

      <div className="featured-book__read">
        <Link className="featured-book__link" to={`/resources/books/${props.book.referenceId}`}>Read</Link>
      </div>
    </div>
  );
}
