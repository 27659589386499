import { GET_AGE_RANGE_CLASS_DURATION_FAILURE, GET_AGE_RANGE_CLASS_DURATION_PENDING, GET_AGE_RANGE_CLASS_DURATION_SUCCESS } from "store/actions/class-duration.action"
import { Action } from "types/action"
import { ClassDuration } from "types/class-duration"

export type ClassDurationState = {
  // Data
  classDurationByAgeRange: ClassDuration | null,
  // Loading
  getClassDurationByAgeRangeLoading: boolean;
  // Error
  getClassDurationByAgeRangeError: any;
}

const initialState: ClassDurationState = {
  // Data
  classDurationByAgeRange: null,
  // Loading
  getClassDurationByAgeRangeLoading: false,
  // Error
  getClassDurationByAgeRangeError: null
}

export default function classDurationReducer(state = initialState, action: Action): ClassDurationState {
  switch (action.type) {
    //  ============================================================
    //  GET CLASS DURATION BY AGE RANGE
    //  ============================================================
    case GET_AGE_RANGE_CLASS_DURATION_PENDING:
      return {
        ...state,
        getClassDurationByAgeRangeLoading: true
      }
    case GET_AGE_RANGE_CLASS_DURATION_SUCCESS:
      return {
        ...state,
        getClassDurationByAgeRangeLoading: false,
        classDurationByAgeRange: action.payload
      }
    case GET_AGE_RANGE_CLASS_DURATION_FAILURE:
      return {
        ...state,
        getClassDurationByAgeRangeLoading: false,
        getClassDurationByAgeRangeError: action.payload
      }
    default:
      return { ...state }
  }
}