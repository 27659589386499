import { Col, Row, Spin } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { ChatState } from "store/reducers/chat.reducer";
import ChatDetails from "./ChatDetails";
import ChatList from "./ChatList";
import "./MessagesPage.scss";

export default function MessagesPage() {
  const { socketConnected } = useSelector<RootState, ChatState>(
    (state) => state.chatState
  );

  return (
    <Spin spinning={!socketConnected}>
      <div className="messages-page__wrapper">
        <Row gutter={24} className="max-h-full h-full">
          <Col flex="0 0 400px" className="max-h-full h-full">
            <ChatList />
          </Col>

          <Col flex="1" className="max-h-full h-full">
            <ChatDetails />
          </Col>
        </Row>
      </div>
    </Spin>
  );
}
