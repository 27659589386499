import { Select } from "antd";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAgeRangesAction } from "store/actions/age-range.action";
import { RootState } from "store/reducer";
import { AgeRangeState } from "store/reducers/age-range.reducer";

type AgeRangeSelectProps = {
  value?: string;
  onChange?: (value: string) => void;
};

export default function AgeRangeSelect(props: AgeRangeSelectProps) {
  // React redux
  const dispatch = useDispatch();
  const { ageRangeData, getAgeRangesLoading } = useSelector<
    RootState,
    AgeRangeState
  >((state) => state.ageRangeState);

  const fetchData = useCallback(() => {
    try {
      dispatch(getAgeRangesAction({is_available: true, no_pagination: true}));
    } catch (error) {
      //
    }
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Select {...props} loading={getAgeRangesLoading} disabled={getAgeRangesLoading}>
      {ageRangeData.results.map((ageRange) => (
        <Select.Option key={ageRange.id} value={ageRange.id}>
          {ageRange.title}
        </Select.Option>
      ))}
    </Select>
  );
}
