import { Row, Col, Statistic, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import TransactionFilter from "components/TransactionFilter/TransactionFilter";
import { timezone } from "helpers/time";
import usePagination from "hooks/usePagination";
import useTableAndQueryChange from "hooks/useTableAndQueryChange";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { ClassState } from "store/reducers/class.reducer";
import { TransactionState } from "store/reducers/transaction.reducer";
import { UpcomingTransaction } from "types/transaction";

type UpcomingTransactionsProps = {
  currentClass: string;
};

export default function UpcomingTransactions(props: UpcomingTransactionsProps) {
  // React redux
  const { currentClassData, getCurrentClassesLoading } = useSelector<RootState, ClassState>(
    (state) => state.classState
  );
  const { upcomingTransactionData, totalEarning, getUpcomingTransactionsLoading } =
    useSelector<RootState, TransactionState>((state) => state.transactionState);

  const { page, limit } = usePagination();
  const { onTableChange } = useTableAndQueryChange();

  const columns: ColumnsType<UpcomingTransaction> = [
    {
      title: "Transaction date",
      key: "date",
      dataIndex: "created_at",
      render: (created_at: string) => moment(new Date(created_at + 'z')).tz(timezone).format("DD/MM/YYYY")
    },
    {
      title: "Parent name",
      key: "parent",
      dataIndex: ["parent_first_name"],
      render: (parent_first_name: string, past) => `${parent_first_name} ${past.parent_last_name}`,
    },
    {
      title: "Amount",
      key: "amount",
      dataIndex: "amount",
      render: (amount: string) => "$" + amount
    },
  ];

  return (
    <div>
      <Row gutter={48}>
        <Col flex="1">
          <div className="mb-6">
            <TransactionFilter
              classData={currentClassData}
              currentClass={props.currentClass}
              loading={getCurrentClassesLoading}
            />
          </div>

          <Table
            rowKey="id"
            columns={columns}
            dataSource={upcomingTransactionData.results}
            loading={getUpcomingTransactionsLoading}
            onChange={onTableChange}
            pagination={{
              current: page,
              pageSize: limit,
              total: upcomingTransactionData.totalRow,
              showSizeChanger: true,
              hideOnSinglePage: true
            }}
          />
        </Col>
        <Col flex="0 0 300px">
          <Statistic
            title="Upcoming earnings in this class"
            value={totalEarning.total_earning}
            prefix="$"
          />
        </Col>
      </Row>
    </div>
  );
}
