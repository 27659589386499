import {
  ADD_CLASS_FAILURE,
  ADD_CLASS_PENDING,
  ADD_CLASS_SUCCESS,
  EDIT_CLASS_FAILURE,
  EDIT_CLASS_PENDING,
  EDIT_CLASS_SUCCESS,
  END_CLASS_FAILURE,
  END_CLASS_PENDING,
  END_CLASS_SUCCESS,
  GET_ALL_CLASSES_FAILURE,
  GET_ALL_CLASSES_PENDING,
  GET_ALL_CLASSES_SUCCESS,
  GET_COMPLETED_CLASSES_FAILURE,
  GET_COMPLETED_CLASSES_PENDING,
  GET_COMPLETED_CLASSES_SUCCESS,
  GET_CURRENT_CLASSES_FAILURE,
  GET_CURRENT_CLASSES_PENDING,
  GET_CURRENT_CLASSES_SUCCESS,
  GET_DETAIL_CLASS_FAILURE,
  GET_DETAIL_CLASS_PENDING,
  GET_DETAIL_CLASS_SUCCESS,
} from "store/actions/class.action";
import { Action } from "types/action";
import { ClassData, ClassDetails } from "types/class";

export type ClassState = {
  // Data
  classData: ClassData;
  currentClassData: ClassData;
  completedClassData: ClassData;
  classDetailData: ClassDetails
  // Loading
  getAllClassesLoading: boolean;
  getCurrentClassesLoading: boolean;
  getCompletedClassesLoading: boolean;
  endClassLoading: boolean;
  addClassLoading: boolean;
  getDetailClassLoading: boolean;
  // Error
  getAllClassesError: any;
  getCurrentClassesError: any;
  getCompletedClassesError: any;
  endClassError: any;
  addClassError: any;
};

const initialState: ClassState = {
  // Data
  classData: {count: 0, results: []},
  currentClassData: { count: 0, results: [] },
  completedClassData: {count: 0, results: []},
  classDetailData: {},
  // Loading
  getAllClassesLoading: false,
  getCurrentClassesLoading: false,
  getCompletedClassesLoading: false,
  endClassLoading: false,
  addClassLoading: false,
  getDetailClassLoading: false,
  // Error
  getAllClassesError: null,
  getCurrentClassesError: null,
  getCompletedClassesError: null,
  endClassError: null,
  addClassError: null,
};

export default function classReducer(
  state = initialState,
  action: Action
): ClassState {
  switch (action.type) {
    //  ============================================================
    //  GET ALL CLASSES
    //  ============================================================
    case GET_ALL_CLASSES_PENDING:
      return {
        ...state,
        getAllClassesLoading: true
      }
    case GET_ALL_CLASSES_SUCCESS:
      return {
        ...state,
        getAllClassesLoading: false,
        classData: action.payload
      }
    case GET_ALL_CLASSES_FAILURE:
      return {
        ...state,
        getAllClassesLoading: false,
        getAllClassesError: action.payload
      }
    //  ============================================================
    //  GET CURRENT CLASSES
    //  ============================================================
    case GET_CURRENT_CLASSES_PENDING:
      return {
        ...state,
        getCurrentClassesLoading: true,
      };
    case GET_CURRENT_CLASSES_SUCCESS:
      return {
        ...state,
        getCurrentClassesLoading: false,
        currentClassData: action.payload,
      };
    case GET_CURRENT_CLASSES_FAILURE:
      return {
        ...state,
        getCurrentClassesLoading: false,
        getCurrentClassesError: action.payload,
      };
    //  ============================================================
    //  GET COMPLETED CLASSES
    //  ============================================================
    case GET_COMPLETED_CLASSES_PENDING:
      return {
        ...state,
        getCompletedClassesLoading: true
      }
    case GET_COMPLETED_CLASSES_SUCCESS:
      return {
        ...state,
        getCompletedClassesLoading: false,
        completedClassData: action.payload
      }
    case GET_COMPLETED_CLASSES_FAILURE:
      return {
        ...state,
        getCompletedClassesLoading: false,
        getCompletedClassesError: action.payload
      }
    //  ============================================================
    //  GET DETAILS CLASS
    //  ============================================================
    case GET_DETAIL_CLASS_PENDING:
    return {
      ...state,
      getDetailClassLoading: true
    }
  case GET_DETAIL_CLASS_SUCCESS:
    return {
      ...state,
      getDetailClassLoading: false,
      classDetailData: action.payload
    }
  case GET_DETAIL_CLASS_FAILURE:
    return {
      ...state,
      getDetailClassLoading: false,
      getCompletedClassesError: action.payload
    }
    //  ============================================================
    //  END CLASS
    //  ============================================================
    case END_CLASS_PENDING:
      return {
        ...state,
        endClassLoading: true,
      };
    case END_CLASS_SUCCESS:
      return {
        ...state,
        endClassLoading: false,
      };
    case END_CLASS_FAILURE:
      return {
        ...state,
        endClassLoading: false,
        endClassError: action.payload,
      };
    //  ============================================================
    //  ADD CLASS
    //  ============================================================
    case ADD_CLASS_PENDING:
      return {
        ...state,
        addClassLoading: true,
      };
    case ADD_CLASS_SUCCESS:
      return {
        ...state,
        addClassLoading: false,
      };
    case ADD_CLASS_FAILURE:
      return {
        ...state,
        addClassLoading: false,
        addClassError: action.payload,
      };
     //  ============================================================
    //  EDIT CLASS
    //  ============================================================
    case EDIT_CLASS_PENDING:
      return {
        ...state,
        addClassLoading: true,
      };
    case EDIT_CLASS_SUCCESS:
      return {
        ...state,
        addClassLoading: false,
      };
    case EDIT_CLASS_FAILURE:
      return {
        ...state,
        addClassLoading: false,
        addClassError: action.payload,
      };
    default:
      return { ...state };
  }
}
