import { AWS_REGION } from "configs/cognito";
import { environment, Staging } from "configs/env";

let domain = "adamwamishmish.com";
let fullDomain = `https://${domain}`;
let apiDomain = `https://dev-api-v2.${domain}`;
let websocket = "wss://g9mdg6f4pg.execute-api.us-east-2.amazonaws.com/dev"

if (environment.staging === Staging.Stage) {
  apiDomain = `https://api-stage-v2.${domain}`;
  websocket = "wss://hgu0rg1z1d.execute-api.us-east-2.amazonaws.com/stage";
} else if (environment.prod === Staging.Prod) {
  websocket = "wss://o6y71xtc7h.execute-api.us-east-2.amazonaws.com/prod";
}

let apiPrefix = `${apiDomain}/api/v1_0`;

export const WEB_SOCKET = websocket;

export const DOMAIN = domain;
export const FULL_DOMAIN = fullDomain;
export const API_DOMAIN = apiDomain;
export const API_PREFIX = apiPrefix;
export const REFRESH_URL = `https://cognito-idp.${AWS_REGION}.amazonaws.com/`;
export const TEACHER_URL = apiPrefix + "/teacher";
export const AUTH_URL = API_PREFIX + "/auth";
export const CLASS_URL = TEACHER_URL + "/classes";
export const INVITATION_URL = apiPrefix + "/auth/invitation-verify/";
export const UPLOAD_URL = apiDomain + "/api/upload/";
export const AGE_RANGE_URL = TEACHER_URL + "/settings/age-ranges/";
export const CLASS_DURATION_URL = TEACHER_URL + "/class-duration";
export const DIALECT_URL = apiPrefix + "/arabic-dialect";
export const SUBSCRIPTION_URL = TEACHER_URL + "/class-fees/";
export const CLASS_LESSON_URL = TEACHER_URL + "/class-lesson";
export const RESOURCE_URL = TEACHER_URL + "/resources";
export const LESSON_URL = TEACHER_URL + "/lessons/";
export const LESSON_OUTLINE_URL = TEACHER_URL + "/lesson-outline";
export const CLASS_SUBJECT_URL = TEACHER_URL + "/settings/class-subjects/";
export const SUMMARY_URL = TEACHER_URL + "/lesson-summary";
export const STUDENT_URL = TEACHER_URL + "/students";
export const TRANSACTION_URL = TEACHER_URL + "/transaction";
export const THEME_URL = TEACHER_URL + "/themes/";
export const REVIEW_URL = TEACHER_URL + "/reviews/";
export const CHAT_URL = API_DOMAIN + "/api/chat";