import { GET_THEMES_FAILURE, GET_THEMES_PENDING, GET_THEMES_SUCCESS } from "store/actions/theme.action"
import { Action } from "types/action"
import { ThemeData } from "types/theme"

export type ThemeState = {
  // Data
  themeData: ThemeData;
  // Loading
  getThemesLoading: boolean;
  // Error
  getThemesError: any;
}

const initialState: ThemeState = {
  // Data
  themeData: { results: [] },
  // Loading
  getThemesLoading: false,
  // Error
  getThemesError: null
}

export default function themeReducer(state = initialState, action: Action): ThemeState {
  switch (action.type) {
    //  ============================================================
    //  GET THEMES
    //  ============================================================
    case GET_THEMES_PENDING:
      return {
        ...state,
        getThemesLoading: true
      }
    case GET_THEMES_SUCCESS:
      return {
        ...state,
        getThemesLoading: false,
        themeData: action.payload
      }
    case GET_THEMES_FAILURE:
      return {
        ...state,
        getThemesLoading: false,
        getThemesError: action.payload
      }
    default:
      return { ...state }
  }
}