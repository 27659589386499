import { createStore, compose, applyMiddleware } from 'redux';
import rootReducer from './reducer';
import thunk from 'redux-thunk';
import { socketMiddleware } from './middlewares/socket-middleware';

const middlewares: any[] = [socketMiddleware, thunk];

const composeEnhancers =
  typeof window === 'object' && ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ?
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ :
    compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));

export default store;