import {
  CHANGE_LESSON_STATUS_FAILURE,
  CHANGE_LESSON_STATUS_PENDING,
  CHANGE_LESSON_STATUS_SUCCESS,
  GET_CLASS_LESSON_ATTENDEES_FAILURE,
  GET_CLASS_LESSON_ATTENDEES_PENDING,
  GET_CLASS_LESSON_ATTENDEES_SUCCESS,
  GET_CLASS_LESSON_FAILURE,
  GET_CLASS_LESSON_PENDING,
  GET_CLASS_LESSON_SUCCESS,
  GET_SUMMARY_FAILURE,
  GET_SUMMARY_PENDING,
  GET_SUMMARY_SUCCESS,
  RESCHEDULE_LESSON_FAILURE,
  RESCHEDULE_LESSON_PENDING,
  RESCHEDULE_LESSON_SUCCESS,
  SEND_SUMMARY_FAILURE,
  SEND_SUMMARY_PENDING,
  SEND_SUMMARY_SUCCESS,
} from "store/actions/class-lesson.action";
import { Action } from "types/action";
import { ClassLesson, ListAttendees, ListSummary } from "types/class-lesson";

export type ClassLessonState = {
  // Data
  classLesson: ClassLesson;
  attendeesLesson: ListAttendees;
  lessonStatus: string;
  summaryData: ListSummary
  // Loading
  getClassLessonLoading: boolean;
  getClassAttendingLessonLoading: boolean;
  changeLessonStatusLoading: boolean;
  reScheduleLessonLoading: boolean;
  summaryLoading: boolean;
  sendSummaryLoading: boolean;
  downloadFlashCardsLoading: boolean;
  // Error
  getClassLessonError: any;
  changeLessonStatusError: any;
  reScheduleLessonError: any;
  sendSummaryError: any;
};

const initialState: ClassLessonState = {
  // Data
  classLesson: {
    is_prepared: false,
  },
  attendeesLesson: { results: [] },
  summaryData: { results: [] },
  lessonStatus: '',
  // Loading
  getClassLessonLoading: false,
  getClassAttendingLessonLoading: false,
  changeLessonStatusLoading: false,
  reScheduleLessonLoading: false,
  summaryLoading: false,
  sendSummaryLoading: false,
  downloadFlashCardsLoading: false,
  // Error
  getClassLessonError: null,
  changeLessonStatusError: null,
  reScheduleLessonError: null,
  sendSummaryError: null
};

export default function classLessonReducer(
  state = initialState,
  action: Action
): ClassLessonState {
  switch (action.type) {
    //  ============================================================
    //  GET CLASS LESSON
    //  ============================================================
    case GET_CLASS_LESSON_PENDING:
      return {
        ...state,
        getClassLessonLoading: true,
        classLesson: {},
      };
    case GET_CLASS_LESSON_SUCCESS:
      return {
        ...state,
        getClassLessonLoading: false,
        classLesson: action.payload,
      };
    case GET_CLASS_LESSON_FAILURE:
      return {
        ...state,
        getClassLessonLoading: false,
        getClassLessonError: action.payload,
      };
    //  ============================================================
    //  CHANGE LESSON STATUS
    //  ============================================================
    case CHANGE_LESSON_STATUS_PENDING:
      return {
        ...state,
        changeLessonStatusLoading: true,
      };
    case CHANGE_LESSON_STATUS_SUCCESS:
      return {
        ...state,
        changeLessonStatusLoading: false,
        lessonStatus: action.payload,
      }
    case CHANGE_LESSON_STATUS_FAILURE:
      return {
        ...state,
        changeLessonStatusLoading: false,
        changeLessonStatusError: action.payload,
      };
    //  ============================================================
    //  RESCHEDULE LESSON
    //  ============================================================
    case RESCHEDULE_LESSON_PENDING:
      return {
        ...state,
        reScheduleLessonLoading: true,
      };
    case RESCHEDULE_LESSON_SUCCESS:
      return {
        ...state,
        reScheduleLessonLoading: false,
        classLesson: action.payload
      };
    case RESCHEDULE_LESSON_FAILURE:
      return {
        ...state,
        reScheduleLessonLoading: false,
        reScheduleLessonError: action.payload,
      };
    //  ============================================================
    //  GET ATTENDEE LESSON
    //  ============================================================
    case GET_CLASS_LESSON_ATTENDEES_PENDING:
      return {
        ...state,
        getClassAttendingLessonLoading: true,
      };
    case GET_CLASS_LESSON_ATTENDEES_SUCCESS:
      return {
        ...state,
        getClassAttendingLessonLoading: false,
        attendeesLesson: action.payload
      };
    case GET_CLASS_LESSON_ATTENDEES_FAILURE:
      return {
        ...state,
        getClassAttendingLessonLoading: false,
      };

    //  ============================================================
    //  GET SUMMARY
    //  ============================================================
    case GET_SUMMARY_PENDING:
      return {
        ...state,
        summaryLoading: true
      }
    case GET_SUMMARY_SUCCESS:
      return {
        ...state,
        sendSummaryLoading: false,
        summaryData: action.payload
      }
    case GET_SUMMARY_FAILURE:
      return {
        ...state,
        sendSummaryLoading: false,
      }
    //  ============================================================
    //  SEND SUMMARY
    //  ============================================================
    case SEND_SUMMARY_PENDING:
      return {
        ...state,
        sendSummaryLoading: true
      }
    case SEND_SUMMARY_SUCCESS:
      return {
        ...state,
        sendSummaryLoading: false,
      }
    case SEND_SUMMARY_FAILURE:
      return {
        ...state,
        sendSummaryLoading: false,
        sendSummaryError: action.payload
      }

    default:
      return { ...state };
  }
}
