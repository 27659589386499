import VideoPlayer from "components/VideoPlayer/VideoPlayer";
import galleryImage from "assets/images/gallery-image.png";
import videoPlaceholder from "assets/images/video-placeholder.png";
import "./GalleryCard.scss";
import moment from "moment";
import { timezone } from "helpers/time";

type GalleryCardProps = {
  fileName: string;
  fileType: string;
  createdAt: string;
  file: any;
  className: string
};

export default function GalleryCard({
  fileType,
  createdAt,
  file,
}: GalleryCardProps) {
  return (
    <div className="gallery-card">
      <div className="gallery-card__media-wrapper">
        {fileType === "video" ? (
          <VideoPlayer
            thumbnail={videoPlaceholder}
            source={{
              type: "video",
              sources: [
                {
                  src: file,
                },
              ],
            }}
          />
        ) : (
          <div className="gallery-card__image-wrapper">
            <img className="gallery-card__image object-contain" src={file || galleryImage} alt="" />
          </div>
        )}
      </div>
      {/* <div className="gallery-card__name">{fileName}</div>
      <div className="gallery-card__class-name">{className}</div> */}
      <div className="gallery-card__date text-center">
        {moment(new Date(createdAt + 'z')).tz(timezone).format("DD/MM/YYYY hh:mm a")}
      </div>
    </div>
  );
}
