import { GET_AGE_RANGES_FAILURE, GET_AGE_RANGES_PENDING, GET_AGE_RANGES_SUCCESS, GET_CLASS_SIZE_FAILURE, GET_CLASS_SIZE_PENDING, GET_CLASS_SIZE_SUCCESS } from "store/actions/age-range.action"
import { Action } from "types/action"
import { AgeRangeData, ClassSize } from "types/age-range"

export type AgeRangeState = {
  // Data
  ageRangeData: AgeRangeData,
  classSize: ClassSize | null,
  // Loading
  getAgeRangesLoading: boolean;
  getClassSizeLoading: boolean;
  // Error
  getAgeRangesError: any;
  getClassSizeError: any;
}

const initialState: AgeRangeState = {
  // Data
  ageRangeData: { totalRow: 0, results: []},
  classSize: null,
  // Loading
  getAgeRangesLoading: false,
  getClassSizeLoading: false,
  // Error
  getAgeRangesError: null,
  getClassSizeError: null
}

export default function ageRangeReducer(state = initialState, action: Action): AgeRangeState {
  switch (action.type) {
    //  ============================================================
    //  GET AGE RANGES
    //  ============================================================
    case GET_AGE_RANGES_PENDING:
      return {
        ...state,
        getAgeRangesLoading: true,
        classSize: null
      }
    case GET_AGE_RANGES_SUCCESS:
      return {
        ...state,
        getAgeRangesLoading: false,
        ageRangeData: action.payload
      }
    case GET_AGE_RANGES_FAILURE:
      return {
        ...state,
        getAgeRangesLoading: false,
        getAgeRangesError: action.payload
      }
    //  ============================================================
    //  GET CLASS SIZE
    //  ============================================================
    case GET_CLASS_SIZE_PENDING:
      return {
        ...state,
        getClassSizeLoading: true
      }
    case GET_CLASS_SIZE_SUCCESS:
      return {
        ...state,
        getClassSizeLoading: false,
        classSize: action.payload
      }
    case GET_CLASS_SIZE_FAILURE:
      return {
        ...state,
        getClassSizeLoading: false,
        getClassSizeError: action.payload
      }
    default:
      return { ...state }
  }
}