import {
  PageHeader,
  Collapse,
  Space,
  Row,
  Col,
  Button,
  Form,
  Checkbox,
  Empty,
  List,
} from "antd";
import LayoutContent from "components/LayoutContent/LayoutContent";
import { PrinterOutlined, DownloadOutlined } from "@ant-design/icons";

import songLibthumbnail from "assets/images/song-thumbnail.png";

import PrintCard from "components/PrintCard/PrintCard";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { downloadFlashCardsAction, getCraftsAction, getFlashCardsAction } from "store/actions/printable.action";
import { RootState } from "store/reducer";
import { PrintableState } from "store/reducers/printable.reducer";
import Print from "print-js";
import { ThemeState } from "store/reducers/theme.reducer";
import { LessonState } from "store/reducers/lesson.reducer";
import { getThemesAction } from "store/actions/theme.action";
import { getLessonsAction } from "store/actions/lesson.action";

export default function ToPrintPagePage() {
  // React redux
  const dispatch = useDispatch();
  const { craftData, flashCardData } = useSelector<RootState, PrintableState>(
    (state) => state.printableState
  );
  const { themeData } = useSelector<RootState, ThemeState>((state) => state.themeState)
  const { downloadFlashCardsLoading } = useSelector<RootState, PrintableState>((state) => state.printableState)
  const { lessonData, getLessonsLoading } = useSelector<RootState, LessonState>((state) => state.lessonState)
  const [submitType, setSubmitType] = useState<string>('print')

  const fetchData = useCallback(() => {
    try {
      Promise.all([
        dispatch(getThemesAction({ show_total_printable_items: true }),
        )]);
    } catch (error) {
      //
    }
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function printResources(values: any) {
    if (values.resources && values.resources.length > 0) {
      if (submitType === 'print') {
        Print({
          printable: values.resources,
          type: "image",
          imageStyle: "max-width: 100%"
        })
      } else {
        const array = flashCardData.results.map((item) => item.flashcards.filter(x => values.resources.includes(x.thumbnail?.image_s3_url)).map((card) => card.id));
        const crafts = craftData.results.filter(x => values.resources.includes(x.thumbnail?.image_s3_url)).map((item) => item.id);;
        var flashcards = array.flat(1)
        dispatch(downloadFlashCardsAction({ flashcards, crafts }))

      }
    }
  }


  // get lession by theme
  function themesActive(e: string | any) {
    dispatch(getLessonsAction({ no_pagination: true, theme: e }))
  }

  // get episodes by lessions
  function lessonsActive(e: string | any) {
    dispatch(getCraftsAction({ no_pagination: true, lesson_id: e }))
    dispatch(getFlashCardsAction({ no_pagination: true, lesson_id: e }))
  }

  return (
    <div>
      <PageHeader title="To Print" />

      <LayoutContent loading={getLessonsLoading}>
        <Form onFinish={printResources}>
          <Form.Item noStyle name="resources">
            <Checkbox.Group className="w-full">
              <div className="print-page">
                <div className="flex items-center justify-between mb-3">
                  <h2 className="text-xl">Printables library</h2>
                  <Space>
                    <Button icon={<PrinterOutlined />} htmlType="submit" onClick={() => setSubmitType('print')}>Print</Button>
                    <Button icon={<DownloadOutlined />} loading={downloadFlashCardsLoading} htmlType="submit" onClick={() => setSubmitType('download')}>Download</Button>
                  </Space>
                </div>

                <Collapse expandIconPosition="right" className="print-collapse" accordion onChange={themesActive}>
                  {themeData.results.map((theme) => (
                    <Collapse.Panel
                      header={
                        <Space>
                          <img src={theme.cover_image?.image_s3_url || songLibthumbnail} alt="" />
                          <div>
                            <p>
                              <strong>{theme.title}</strong>
                            </p>
                            <p className="flex items-center">🎨 {" "} {theme.total_crafts} ARTS & CRAFTS 🎴 {theme.total_flashcards} FLASH CARDS</p>
                          </div>
                        </Space>
                      }
                      key={theme.id}
                    >
                      <Collapse expandIconPosition="right" ghost accordion onChange={lessonsActive}>
                        {
                          lessonData.results.length === 0 && (
                            <Collapse.Panel
                              key={theme.id + 'lesson'}
                              showArrow={false}
                              header={
                                <Empty description="No lessons found" />
                              }
                            >
                            </Collapse.Panel>
                          )
                        }
                        {lessonData.results.length > 0 && lessonData.results.map((lesson) => (
                          <Collapse.Panel
                            key={lesson.id}
                            header={<strong>{lesson.title}</strong>}
                          >
                            <Row gutter={24}>
                              {craftData.results.map((craft) => (
                                <Col
                                  key={craft.id}
                                  className="mb-3"
                                  xs={24}
                                  sm={12}
                                  md={8}
                                  lg={6}
                                  xl={4}
                                  xxl={3}
                                >
                                  <PrintCard
                                    value={craft.id}
                                    name={craft.title}
                                    image={craft.thumbnail?.image_s3_url || songLibthumbnail}
                                  />
                                </Col>
                              ))}
                            </Row>
                            <Row gutter={24}>
                              {flashCardData.results.map((card) => (
                                <Col
                                  key={card.id}
                                  className="mb-3"
                                  xs={24}
                                  sm={12}
                                  md={8}
                                  lg={6}
                                  xl={24}
                                  xxl={24}
                                >
                                  <List.Item
                                    key={card.id}>
                                    <Row gutter={24}>
                                      <Col
                                        className="mb-3"
                                        key={card.id}>
                                        <p>
                                          <strong>{card.title}</strong>
                                        </p>
                                        <div className="w-full flex flex-wrap">
                                          {card.flashcards.map((item) => (
                                            <PrintCard
                                              key={item.id}
                                              name={item.title}
                                              checked={true}
                                              value={item.id}
                                              image={item.thumbnail?.image_s3_url}
                                            />
                                          ))}
                                        </div>
                                      </Col>
                                    </Row>
                                  </List.Item>
                                </Col>
                              ))}
                            </Row>
                          </Collapse.Panel>
                        ))}
                      </Collapse>
                    </Collapse.Panel>
                  ))}
                </Collapse>
              </div>
            </Checkbox.Group>
          </Form.Item>
        </Form>
      </LayoutContent>
    </div>
  );
}
