import { firebaseReducer } from "react-redux-firebase";
import { combineReducers } from "redux";
import ageRangeReducer, { AgeRangeState } from "./reducers/age-range.reducer";
import authReducer, { AuthState } from "./reducers/auth.reducer";
import bookReducer, { BookState } from "./reducers/book.reducer";
import chatReducer, { ChatState } from "./reducers/chat.reducer";
import classDurationReducer, {
  ClassDurationState,
} from "./reducers/class-duration.reducer";
import classLessonReducer, { ClassLessonState } from "./reducers/class-lesson.reducer";
import classSubjectReducer, { ClassSubjectState } from "./reducers/class-subject.reducer";
import classReducer, { ClassState } from "./reducers/class.reducer";
import dashboardReducer, { DashboardState } from "./reducers/dashboard.reducer";
import dialectReducer, { DialectState } from "./reducers/dialect.reducer";
import episodeReducer, { EpisodeState } from "./reducers/episode.reducer";
import lessonOutlineReducer, { LessonOutlineState } from "./reducers/lesson-outline.reducer";
import lessonReducer, { LessonState } from "./reducers/lesson.reducer";
import musicReducer, { MusicState } from "./reducers/music.reducer";
import notifyReducer, { NotifyState } from "./reducers/notification.reducer";
import printableReducer, { PrintableState } from "./reducers/printable.reducer";
import reviewReducer, { ReviewState } from "./reducers/review.reducer";
import studentReducer, { StudentState } from "./reducers/student.reducer";
import subscriptionReducer, { SubscriptionState } from "./reducers/subscription.reducer";
import themeReducer, { ThemeState } from "./reducers/theme.reducer";
import transactionReducer, { TransactionState } from "./reducers/transaction.reducer";

export type RootState = {
  ageRangeState: AgeRangeState;
  authState: AuthState;
  bookState: BookState;
  chatState: ChatState;
  classDurationState: ClassDurationState;
  classLessonState: ClassLessonState;
  classSubjectState: ClassSubjectState;
  classState: ClassState;
  dashboardState: DashboardState;
  dialectState: DialectState;
  episodeState: EpisodeState;
  lessonOutlineState: LessonOutlineState;
  lessonState: LessonState;
  musicState: MusicState;
  printableState: PrintableState;
  reviewState: ReviewState;
  studentState: StudentState;
  subscriptionState: SubscriptionState;
  themeState: ThemeState;
  transactionState: TransactionState;
  notifyStage: NotifyState
};

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  ageRangeState: ageRangeReducer,
  authState: authReducer,
  bookState: bookReducer,
  chatState: chatReducer,
  classDurationState: classDurationReducer,
  classLessonState: classLessonReducer,
  classSubjectState: classSubjectReducer,
  classState: classReducer,
  dashboardState: dashboardReducer,
  dialectState: dialectReducer,
  episodeState: episodeReducer,
  lessonOutlineState: lessonOutlineReducer,
  lessonState: lessonReducer,
  musicState: musicReducer,
  printableState: printableReducer,
  reviewState: reviewReducer,
  studentState: studentReducer,
  subscriptionState: subscriptionReducer,
  themeState: themeReducer,
  transactionState: transactionReducer,
  notifyStage: notifyReducer
});

export default rootReducer;
