import {
  GET_PAST_TRANSACTIONS_FAILURE,
  GET_PAST_TRANSACTIONS_PENDING,
  GET_PAST_TRANSACTIONS_SUCCESS,
  GET_TOTAL_TRANSACTIONS_FAILURE,
  GET_TOTAL_TRANSACTIONS_PENDING,
  GET_TOTAL_TRANSACTIONS_SUCCESS,
  GET_UPCOMING_TRANSACTIONS_FAILURE,
  GET_UPCOMING_TRANSACTIONS_PENDING,
  GET_UPCOMING_TRANSACTIONS_SUCCESS,
} from "store/actions/transaction.action";
import { Action } from "types/action";
import { PastTransactionData, TotalEarning, UpcomingTransactionData } from "types/transaction";

export type TransactionState = {
  // Data
  upcomingTransactionData: UpcomingTransactionData;
  pastTransactionData: PastTransactionData;
  totalEarning: TotalEarning;
  // Loading
  getUpcomingTransactionsLoading: boolean;
  getPastTransactionsLoading: boolean;
  // Error
  getUpcomingTransactionsError: any;
  getPastTransactionsError: any;
};

const initialState: TransactionState = {
  // Data
  upcomingTransactionData: {
    results: [],
    totalRow: 0,
  },
  pastTransactionData: {
    results: [],
    totalRow: 0,
  },
  totalEarning: {total_earning: 0},
  // Loading
  getUpcomingTransactionsLoading: false,
  getPastTransactionsLoading: false,
  // Error
  getUpcomingTransactionsError: null,
  getPastTransactionsError: null,
};

export default function transactionReducer(
  state = initialState,
  action: Action
): TransactionState {
  switch (action.type) {
    //  ============================================================
    //  GET UPCOMING TRANSACTION
    //  ============================================================
    case GET_UPCOMING_TRANSACTIONS_PENDING:
      return {
        ...state,
        getUpcomingTransactionsLoading: true,
      };
    case GET_UPCOMING_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        getUpcomingTransactionsLoading: false,
        upcomingTransactionData: action.payload,
      };
    case GET_UPCOMING_TRANSACTIONS_FAILURE:
      return {
        ...state,
        getUpcomingTransactionsLoading: false,
        getUpcomingTransactionsError: action.payload,
      };
    //  ============================================================
    //  GET PAST TRANSACTION
    //  ============================================================
    case GET_PAST_TRANSACTIONS_PENDING:
      return {
        ...state,
        getPastTransactionsLoading: true,
      };
    case GET_PAST_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        getPastTransactionsLoading: false,
        pastTransactionData: action.payload,
      };
    case GET_PAST_TRANSACTIONS_FAILURE:
      return {
        ...state,
        getPastTransactionsLoading: false,
        getPastTransactionsError: action.payload,
      };
     //  ============================================================
    //  GET TOTAL TRANSACTION
    //  ============================================================
    case GET_TOTAL_TRANSACTIONS_PENDING:
      return {
        ...state,
        getPastTransactionsLoading: true,
      };
    case GET_TOTAL_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        getPastTransactionsLoading: false,
        totalEarning: action.payload,
      };
    case GET_TOTAL_TRANSACTIONS_FAILURE:
      return {
        ...state,
        getPastTransactionsLoading: false,
      };
    default:
      return { ...state };
  }
}
