import { Table, Space, Button, Modal } from "antd";
import { ColumnsType } from "antd/lib/table";
import { timezone } from "helpers/time";
import usePagination from "hooks/usePagination";
import useTableAndQueryChange from "hooks/useTableAndQueryChange";
import moment from "moment-timezone";
import { MouseEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { endClassAction } from "store/actions/class.action";
import { RootState } from "store/reducer";
import { ClassState } from "store/reducers/class.reducer";
import { Class } from "types/class";

type CurrentClassesTabProps = {
  fetchData: () => void
}

export default function CurrentClassesTab(props: CurrentClassesTabProps) {

  const { fetchData } = props;

  // React redux
  const dispatch = useDispatch();
  const { currentClassData, getCurrentClassesLoading } = useSelector<RootState, ClassState>(
    (state) => state.classState
  );
  // set variable
  const { onTableChange } = useTableAndQueryChange();
  const { page, limit } = usePagination();

  const columns: ColumnsType<Class> = [
    {
      title: "#",
      key: "#",
      render: (_, __, index) => index + 1,
    },
    {
      title: "Start date",
      key: "start_datetime",
      dataIndex: "start_datetime",
      render: (start_datetime: string) => moment(new Date(start_datetime + 'z')).tz(timezone).format("DD/MM/YYYY"),
    },
    {
      title: "Subject",
      key: "class_subject",
      dataIndex: ["class_subject", "title"],
    },
    {
      title: "Class type",
      key: "class_type",
      dataIndex: ["class_type", "title"],
    },
    {
      title: "Day of the week",
      key: "days_of_week",
      dataIndex: "days_of_week",
    },
    {
      title: "Start time",
      key: "time",
      dataIndex: "start_datetime",
      render: (start_datetime: string) => moment(new Date(start_datetime + 'z')).tz(timezone, true).format("HH:mm"),
    },
    {
      title: "Duration",
      key: "duration",
      dataIndex: "duration",
      render: (duration: string) => duration + " mins",
    },
    {
      title: "Age group",
      key: "age_range",
      dataIndex: ["age_range", "title"],
    },
    {
      title: "Max. students",
      key: "max_students",
      dataIndex: "max_students",
    },
    {
      key: "actions",
      width: 100,
      align: "center",
      render: (classObj: Class) => (
        <Space>
          <Link key="add-class" to={`/my-classes/details/${classObj.id}`}>
            <Button>View Detail</Button>
          </Link>
          <Button type="primary" onClick={(event) => onEndClassClick(event, classObj)}>
            End class
          </Button>
        </Space>
      ),
    },
  ];

  function onEndClassClick(event: MouseEvent, classObj: Class) {
    event.stopPropagation();

    Modal.confirm({
      title: "End class",
      content: (
        <div>
          <p>
            This class will end when all students have finished their
            subscription. No more new students will be able to join this class.
          </p>
          <p>
            Class end date: <strong>{moment().format("DD/MM/YYYY")}</strong>
          </p>
        </div>
      ),
      onOk: async () => {
        try {
          const body = {ended_at: moment().utc().format()}
          await dispatch(endClassAction(classObj.id, body));
          fetchData();
        } catch (error) {
          //
        }
      }
    });
  }

  return (
    <div>
      <Table
        columns={columns}
        dataSource={currentClassData.results}
        loading={getCurrentClassesLoading}
        onChange={onTableChange}
        rowKey="id"
        pagination={{
          showSizeChanger: true,
          total: currentClassData.count,
          current: page,
          pageSize: limit,
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
}
