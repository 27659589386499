import queryString from "query-string";
import { useMemo } from "react";
import { useLocation } from "react-router";

export type PaginationParams = {
  pageKey: string,
  limitKey: string
}

export default function usePagination(options: PaginationParams = { pageKey: "page", limitKey: "limit" }) {
  const { search } = useLocation();

  const query: any = useMemo(() => queryString.parse(search), [search]);

  const page: number = Number(query[options.pageKey]) || 1;
  const limit: number = Number(query[options.limitKey]) || 10;

  return { page, limit }
}