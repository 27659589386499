import { PageHeader, Collapse, Space, Row, Col, Divider, Empty } from "antd";
import LayoutContent from "components/LayoutContent/LayoutContent";
import HorizontalLessonThumbnail from "components/HorizontalLessonThumbnail/HorizontalLessonThumbnail";
import bookThumbnail from "assets/images/book-thumbnail.png";
import VideoPlayer from "components/VideoPlayer/VideoPlayer";

import "./EpisodesPage.scss";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import {
  getEpisodesAction, getWhatToWatchAction,
} from "store/actions/episode.action";
import { RootState } from "store/reducer";
import { EpisodeState } from "store/reducers/episode.reducer";
import { LessonState } from "store/reducers/lesson.reducer";
import { ThemeState } from "store/reducers/theme.reducer";
import { getThemesAction } from "store/actions/theme.action";
import { getLessonsAction } from "store/actions/lesson.action";

export default function EpisodesPage() {
  // React redux
  const dispatch = useDispatch();
  const {
    episodeData,
    whatToWatchLastWeek,
    whatToWatchNextWeek,
    whatToWatchThisWeek,
    getEpisodesLoading,
    getWhatToWatchLoading,
  } = useSelector<RootState, EpisodeState>((state) => state.episodeState);
  const {themeData} = useSelector<RootState, ThemeState>((state) => state.themeState)
  const {lessonData, getLessonsLoading} = useSelector<RootState, LessonState>((state) => state.lessonState)

  const fetchData = useCallback(() => {
    try {
      Promise.all([
        dispatch(getThemesAction({ show_total_episodes: true })),
        dispatch(getWhatToWatchAction({since: 'last_week', page_size: 5})),
        dispatch(getWhatToWatchAction({since: 'next_week', page_size: 5})),
        dispatch(getWhatToWatchAction({since: 'this_week', page_size: 5})),
      ]);
    } catch (error) {
      //
    }
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

   // get lession by theme
   function themesActive(e: string | any) {
    dispatch(getLessonsAction({no_pagination: true, theme: e}))
  }

  // get episodes by lessions
  function lessonsActive(e: string | any) {
    dispatch(getEpisodesAction({no_pagination: true, lesson_id: e}))
  }


  return (
    <div>
      <PageHeader title="Episodes" />

      <LayoutContent loading={getEpisodesLoading || getWhatToWatchLoading || getLessonsLoading}>
        <div className="episodes-page">
          <h2 className="text-xl mb-2">Episodes library</h2>

          <Collapse expandIconPosition="right" className="book-collapse" accordion onChange={themesActive}>
            {themeData.results.map((theme) => (
              <Collapse.Panel
                key={theme.id}
                header={
                  <Space>
                    <img src={theme.cover_image?.image_s3_url || bookThumbnail} alt="" />
                    <span>
                      <strong>
                        {theme.title}
                      </strong>
                      <p className="flex items-center"><svg xmlns="http://www.w3.org/2000/svg" width="20.068" height="15.599" viewBox="0 0 20.068 15.599">
  <g id="Group_10507" data-name="Group 10507" transform="translate(-119.28 -711.061)">
    <rect id="Rectangle_2179" data-name="Rectangle 2179" width="9.503" height="9.503" rx="4.751" transform="translate(124.52 714.371)" fill="#f3fafa"/>
    <path id="Path_11586" data-name="Path 11586" d="M26.569,50.345c0-.9-.012-1.807,0-2.711a5.264,5.264,0,0,1,4.839-4.967c3.386-.059,6.774-.047,10.16,0a5.3,5.3,0,0,1,4.676,3.754,7.323,7.323,0,0,1,.372,2.121c.037,1.443-.008,2.891-.063,4.334a5.638,5.638,0,0,1-4.211,5.145,4.34,4.34,0,0,1-.937.116c-2.6.04-5.191.077-7.786.093-.789,0-1.58-.034-2.367-.1a5.389,5.389,0,0,1-4.683-5C26.557,52.2,26.568,51.271,26.569,50.345Zm7.788.123h-.031c0,.721,0,1.441,0,2.161.007,1.12.326,1.326,1.3.815,1.453-.767,2.9-1.549,4.345-2.327a2.587,2.587,0,0,0,.4-.248.614.614,0,0,0-.017-1.068,2.486,2.486,0,0,0-.409-.235C38.47,48.84,37,48.109,35.521,47.391c-.783-.381-1.134-.168-1.159.714C34.339,48.892,34.357,49.68,34.357,50.469Z" transform="translate(92.716 668.434)" fill="#89c9d0"/>
  </g>
</svg> 
 <span className="mx-1">{theme.total_episodes}</span> Episodes</p>

                    </span>
                  </Space>
                }
              >
                <Collapse expandIconPosition="right" ghost accordion onChange={lessonsActive}>
                  {
                    lessonData.results.length === 0 && (
                      <Collapse.Panel
                      key={theme.id + 'lesson'}
                      showArrow={false}
                      header={
                        <Empty description="No episodes found" />
                      }
                    >
                    </Collapse.Panel>
                    )
                  }
                  {lessonData.results.length > 0 && lessonData.results.map((lesson) => (
                    <Collapse.Panel
                      key={lesson.id}
                      header={
                        <strong>
                          {lesson.title}
                        </strong>
                      }
                    >
                      <Row gutter={24}>
                        {episodeData.results.map((episode) => (
                          <Col key={episode.id} xs={24} lg={12} xl={8} xxl={6}>
                            <VideoPlayer
                              showName
                              thumbnail={episode.thumbnail.image_s3_url}
                              source={{
                                type: "video",
                                sources: [{ src: episode.file }],
                              }}
                            />
                          </Col>
                        ))}
                      </Row>
                    </Collapse.Panel>
                  ))}
                </Collapse>
              </Collapse.Panel>
            ))}
          </Collapse>

          <Divider />

          <h2 className="text-xl">What to watch to this week</h2>
          <Row gutter={48}>
            <Col xs={24} xl={24}>
              <div className="p-4">
                <p className="text-lg mb-3">This week's lesson</p>
                {whatToWatchThisWeek.results.length > 0 ? whatToWatchThisWeek.results.map((episodeWeek) => (
                  <div key={episodeWeek.id}>
                    <div className="mb-6">
                      <HorizontalLessonThumbnail
                        lesson={episodeWeek.lesson}
                        schedule={episodeWeek.scheduled_at}
                      />
                    </div>

                    <Row gutter={24}>
                      {episodeWeek.lesson.episodes.map((ep) => (
                        <Col
                          className="mb-3"
                          key={ep.id}
                          xs={8}
                          lg={8}
                          xxl={4}
                        >
                          <VideoPlayer
                            showName
                            thumbnail={ep.thumbnail?.image_s3_url}
                            name={ep.title}
                            source={{
                              type: "video",
                              sources: [
                                {
                                  src: ep.youtubeLink,
                                },
                              ],
                            }}
                          />
                        </Col>
                      ))}
                    </Row>
                  </div>
                )): <Empty description="No lesson found" />}
                </div>
            </Col>
            </Row>

          <Row gutter={48}>
            <Col xs={24} xl={12}>
              <div className="p-4">
                <p className="text-lg mb-3">Last week's lesson</p>

                {whatToWatchLastWeek.results.length > 0 ? whatToWatchLastWeek.results.map((episodeWeek) => (
                  <div key={episodeWeek.id}>
                    <div className="mb-6">
                      <HorizontalLessonThumbnail
                        lesson={episodeWeek.lesson}
                        schedule={episodeWeek.scheduled_at}
                      />
                    </div>

                    <Row gutter={24}>
                      {episodeWeek.lesson.episodes.map((ep) => (
                        <Col
                          className="mb-3"
                          key={ep.id}
                          xs={24}
                          lg={12}
                          xxl={8}
                        >
                          <VideoPlayer
                            showName
                            thumbnail={ep.thumbnail?.image_s3_url}
                            name={ep.title}
                            source={{
                              type: "video",
                              sources: [
                                {
                                  src: ep.youtubeLink,
                                },
                              ],
                            }}
                          />
                        </Col>
                      ))}
                    </Row>
                  </div>
                )): <Empty description="No lesson found" />}
                </div>
            </Col>

            <Col xs={24} xl={12}>
              <div className="p-4">
                <p className="text-lg mb-3">Next week's lesson</p>

                {whatToWatchNextWeek.results.length > 0 ? whatToWatchNextWeek.results.map((episodeWeek) => (
                  <div key={episodeWeek.id}>
                    <div className="mb-6">
                      <HorizontalLessonThumbnail
                        lesson={episodeWeek.lesson}
                        schedule={episodeWeek.scheduled_at}
                      />
                    </div>

                    <Row gutter={24}>
                      {episodeWeek.lesson.episodes.map((ep) => (
                        <Col
                          className="mb-3"
                          key={ep.id}
                          xs={24}
                          lg={12}
                          xxl={8}
                        >
                          <VideoPlayer
                            thumbnail={ep.thumbnail?.image_s3_url}
                            showName
                            name={ep.title}
                            source={{
                              type: "video",
                              sources: [
                                {
                                  src: ep.youtubeLink,
                                },
                              ],
                            }}
                          />
                        </Col>
                      ))}
                    </Row>
                  </div>
                )) : <Empty description="No lesson found" />}
              </div>
            </Col>
          </Row>
        </div>
      </LayoutContent>
    </div>
  );
}
