import { PageHeader, Button, Space } from "antd";
import { useEffect, useMemo } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { useState } from "react";
import LayoutContent from "components/LayoutContent/LayoutContent";
import Spinner from "components/Spinner/Spinner";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import queryString from "query-string";

export default function BookDetails() {
  // State
  const [totalPages, setTotalPages] = useState<number>(1);
  const [page, setPage] = useState<number>(1);

  // query param
  const { search } = useLocation();
  const query: any = useMemo(() => queryString.parse(search), [search]);

  // React router
  const { params } = useRouteMatch<{ bookId: string }>();
  const { goBack } = useHistory();

  const loading = (
    <div className="py-3 flex justify-center">
      <Spinner />
    </div>
  );

  useEffect(() => {
    if (params.bookId) {
      console.log(query);
    }
  }, [query, params.bookId]);

  function onLoadSuccess({ numPages }: any) {
    setTotalPages(numPages);
  }

  function onLoadError(error: Error) {
    console.log(error);
  }

  function handleNext() {
    setPage((prev) => prev + 1);
  }

  function handlePrevious() {
    setPage((prev) => prev - 1);
  }

  return (
    <div>
      <PageHeader title="Book details" onBack={goBack} />

      {query.link && (
        <LayoutContent>
          <div className="py-3 flex justify-center">
            <Space align="center">
              <Button
                icon={<ArrowLeftOutlined />}
                type="text"
                onClick={handlePrevious}
                disabled={page === 1}
              />
              <span>Page {page}</span>
              <span>/</span>
              <span>{totalPages}</span>
              <Button
                icon={<ArrowRightOutlined />}
                type="text"
                onClick={handleNext}
                disabled={page === totalPages}
              />
            </Space>
          </div>
          <Document
            file={query.link}
            onLoadSuccess={onLoadSuccess}
            onLoadError={onLoadError}
            loading={loading}
            className="book-detais__documents flex justify-center"
          >
            <Page loading={loading} pageNumber={page} className="book-details__page" />
          </Document>
        </LayoutContent>
      )}
    </div>
  );
}
