export enum ChatRoute {
  CHAT = "chat",
}

export enum ChatAction {
  CONVERSATION_CREATE = "conversation-create",
  GROUP_CONVERSATION_CREATE = "group-conversation-create",
  CONVERSATION_LIST = "conversation-list",
  GROUP_CONVERSATION_LIST_BY_TEACHER = "group-conversation-list-by-teacher",
  CONVERSATION_LIST_BY_TEACHER = "conversation-list-by-teacher",
  CONVERSATION_DETAILS = "conversation-details",
  CONVERSATION_DETAILS_UPDATE = "conversation-details-update",
  CONVERSATION_JOIN = "conversation-join",
  MESSAGE_LIST = "message-list",
  MESSAGE_SEND = "message-send",
  MESSAGE_UPDATE = "message-update",
  MESSAGE_REMOVE = "message-remove",
}

export enum ConversationType {
  SINGLE = "one-to-one",
  GROUP = "group",
}

export type SingleConversation = {
  chatId: string;
  chatKey: string;
  createdAt: string;
  repliedAt: string;
  teacherId: string;
  type: ConversationType;
  userId: string;
  user: any;
  lastMessage?: {
    chatId: string;
    chatKey: string;
    createdAt: string;
    format: string;
    message: string;
    metadata: any;
    senderId: string;
  };
};

export type GroupConversation = {
  chatId: string;
  chatKey: string;
  createdAt: string;
  repliedAt: string;
  teacherId: string;
  type: ConversationType;
  userId: string;
  lastMessage?: {
    chatId: string;
    chatKey: string;
    createdAt: string;
    format: string;
    message: string;
    metadata: any;
    senderId: string;
  };
};

export type SingleConversationData = {
  Count: number;
  Items: Array<SingleConversation>;
};

export type GroupConversationData = {
  Count: number;
  Items: Array<GroupConversation>;
};

export type Message = {
  chatId: string;
  chatKey: string;
  createdAt: string;
  format: string;
  message: string;
  metadata: any;
  senderId: string;
};

export type MessageData = {
  Count: number;
  Items: Array<Message>;
  ScannedCount: number;
};

export type ChatUserProfile = {
  id: string;
  name: string;
  avatar: string;
  
};

export type ChatClassInfo = {
    days_of_week: string;
    id: string;
    duration: number;
    start_datetime: string;
    end_datetime: string;
};
