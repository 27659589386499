import { GET_SUBSCRIPTION_FEES_FAILURE, GET_SUBSCRIPTION_FEES_PENDING, GET_SUBSCRIPTION_FEES_SUCCESS, UPDATE_SUBSCRIPTION_FEES_FAILURE, UPDATE_SUBSCRIPTION_FEES_PENDING, UPDATE_SUBSCRIPTION_FEES_SUCCESS } from "store/actions/subscription.action"
import { Action } from "types/action"
import { SubscriptionFee } from "types/subscription"

export type SubscriptionState = {
  // Data
  subscriptionFees: Array<SubscriptionFee>,
  // Loading
  getSubscriptionFeesLoading: boolean;
  updateSubscriptionFeesLoading: boolean;
  // Error
  getSubscriptionFeesError: any;
  updateSubscriptionFeesError: any;
}

const initialState: SubscriptionState = {
  // Data
  subscriptionFees: [],
  // Loading
  getSubscriptionFeesLoading: false,
  updateSubscriptionFeesLoading: false,
  // Error
  getSubscriptionFeesError: null,
  updateSubscriptionFeesError: null
}

export default function subscriptionReducer(state = initialState, action: Action): SubscriptionState {
  switch (action.type) {
    //  ============================================================
    //  GET SUBSCRIPTION FEES
    //  ============================================================
    case GET_SUBSCRIPTION_FEES_PENDING:
      return {
        ...state,
        getSubscriptionFeesLoading: true
      }
    case GET_SUBSCRIPTION_FEES_SUCCESS:
      return {
        ...state,
        getSubscriptionFeesLoading: false,
        subscriptionFees: action.payload
      }
    case GET_SUBSCRIPTION_FEES_FAILURE:
      return {
        ...state,
        getSubscriptionFeesLoading: false,
        getSubscriptionFeesError: action.payload
      }
    //  ============================================================
    //  UPDATE SUBSCRIPTION FEES
    //  ============================================================
    case UPDATE_SUBSCRIPTION_FEES_PENDING:
      return {
        ...state,
        updateSubscriptionFeesLoading: true
      }
    case UPDATE_SUBSCRIPTION_FEES_SUCCESS:
      return {
        ...state,
        updateSubscriptionFeesLoading: false,
      }
    case UPDATE_SUBSCRIPTION_FEES_FAILURE:
      return {
        ...state,
        updateSubscriptionFeesLoading: false,
        updateSubscriptionFeesError: action.payload
      }
    default:
      return { ...state }
  }
}