import { Profile } from "types/profile"

export function checkCompletedProfileProgress(user: Profile) {
    const array = Object.values(user.profile_completed_steps)
    const itemComplete = array.filter(x => x === true).length
    return (itemComplete * 100)/array.length
}

export function checkInCompleteProfileProgress(user: Profile) {
    const array = Object.values(user.profile_completed_steps)
    const itemComplete = array.filter(x => x === false).length
    return itemComplete
}