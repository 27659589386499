import { Card, Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { toggleRepareListAction } from "store/actions/class-lesson.action";
import { RootState } from "store/reducer";
import { ClassLessonState } from "store/reducers/class-lesson.reducer";

type ClassLessonReadyProps = {
  classId: string,
  lessonId: string,
  isLessonStarted: boolean;
  isLessonEnded: boolean;
}

export default function ClassLessonReady(props: ClassLessonReadyProps) {

  // React redux
  const dispatch = useDispatch();
  const { classLesson } = useSelector<RootState, ClassLessonState>(state => state.classLessonState);

  function handleRepareChange(e: any, field: string) {
    const { checked } = e.target;
    if (classLesson.prepared_checklist) {
      let body = { prepared_checklist: classLesson.prepared_checklist }
      switch (field) {
        case 'review_flashcards':
          body.prepared_checklist.review_flashcards = checked
          break;
        case 'print_all_resources':
          body.prepared_checklist.print_all_resources = checked
          break;
        case 'review_lesson_outlines':
          body.prepared_checklist.review_lesson_outlines = checked
          break;
        case 'source_buy_make_wow_object':
          body.prepared_checklist.source_buy_make_wow_object = checked
          break;

        default:
          body.prepared_checklist.resources_to_source_yourself = checked
          break;
      }
    dispatch(toggleRepareListAction(props.lessonId, body));
    }
  }

  return (
    <Card title="ARE YOU READY FOR YOUR LESSON?">

      <p>
        <Checkbox disabled={props.isLessonEnded} onChange={(e) => handleRepareChange(e, 'review_flashcards')} defaultChecked={classLesson.prepared_checklist?.review_flashcards}>Review flash cards</Checkbox>
      </p>
      <p>
        <Checkbox disabled={props.isLessonEnded} onChange={(e) => handleRepareChange(e, 'print_all_resources')} defaultChecked={classLesson.prepared_checklist?.print_all_resources}>Print all resources</Checkbox>
      </p>
      <p>
        <Checkbox disabled={props.isLessonEnded} onChange={(e) => handleRepareChange(e, 'review_lesson_outlines')} defaultChecked={classLesson.prepared_checklist?.review_lesson_outlines}>Review lesson outlines</Checkbox>
      </p>
      <p>
        <Checkbox disabled={props.isLessonEnded} onChange={(e) => handleRepareChange(e, 'source_buy_make_wow_object')} defaultChecked={classLesson.prepared_checklist?.source_buy_make_wow_object}>Source buy make wow object</Checkbox>
      </p>
      <p>
        <Checkbox disabled={props.isLessonEnded} onChange={(e) => handleRepareChange(e, 'resources_to_source_yourself')} defaultChecked={classLesson.prepared_checklist?.resources_to_source_yourself}>Resource to source yourself</Checkbox>
      </p>
    </Card>
  );
}
