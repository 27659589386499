import axios, { AxiosResponse } from "axios";
import { CLASS_URL, LESSON_URL } from "constants/urls";
import { stringifyParams } from "helpers/params";
import { Action } from "types/action";
import { LessonCalendarData, LessonData, LessonDetails } from "types/lesson";


//  ============================================================
//  GET LESSON CALENDAR
//  ============================================================
export const GET_LESSON_CALENDAR_PENDING = "GET_LESSON_CALENDAR_PENDING";
export const GET_LESSON_CALENDAR_SUCCESS = "GET_LESSON_CALENDAR_SUCCESS";
export const GET_LESSON_CALENDAR_FAILURE = "GET_LESSON_CALENDAR_FAILURE";
function getLessonCalendarPending(): Action {
  return {
      type: GET_LESSON_CALENDAR_PENDING
   }
}

function getLessonCalendarSuccess(LessonCalendar: LessonCalendarData): Action {
  return {
      type: GET_LESSON_CALENDAR_SUCCESS,
      payload: LessonCalendar
   }
}

function getLessonCalendarFailure(error: any): Action {
  return {
      type: GET_LESSON_CALENDAR_FAILURE,
      payload: error
   }
}

export function getLessonCalendarAction(query?: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(getLessonCalendarPending());
      const response: AxiosResponse<LessonCalendarData> = await axios.get(`${CLASS_URL}/lessons/${stringifyParams(query)}`);
      dispatch(getLessonCalendarSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getLessonCalendarFailure(error));
      return Promise.reject(error)
    }
  }
}

//  ============================================================
//  GET LESSONS
//  ============================================================
export const GET_LESSONS_PENDING = "GET_LESSONS_PENDING";
export const GET_LESSONS_SUCCESS = "GET_LESSONS_SUCCESS";
export const GET_LESSONS_FAILURE = "GET_LESSONS_FAILURE";
function getLessonsPending(): Action {
  return {
      type: GET_LESSONS_PENDING
   }
}

function getLessonsSuccess(lessonData: LessonData): Action {
  return {
      type: GET_LESSONS_SUCCESS,
      payload: lessonData
   }
}

function getLessonsFailure(error: any): Action {
  return {
      type: GET_LESSONS_FAILURE,
      payload: error
   }
}

export function getLessonsAction(query?: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(getLessonsPending());
      const response: AxiosResponse<LessonData> = await axios.get(LESSON_URL + stringifyParams(query));
      dispatch(getLessonsSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getLessonsFailure(error));
      return Promise.reject(error)
    }
  }
}

//  ============================================================
//  GET LESSON DETAILS
//  ============================================================
export const GET_LESSON_DETAILS_PENDING = "GET_LESSON_DETAILS_PENDING";
export const GET_LESSON_DETAILS_SUCCESS = "GET_LESSON_DETAILS_SUCCESS";
export const GET_LESSON_DETAILS_FAILURE = "GET_LESSON_DETAILS_FAILURE";
function getLessonDetailsPending(): Action {
  return {
      type: GET_LESSON_DETAILS_PENDING
   }
}

function getLessonDetailsSuccess(lessonDetails: LessonDetails): Action {
  return {
      type: GET_LESSON_DETAILS_SUCCESS,
      payload: lessonDetails
   }
}

function getLessonDetailsFailure(error: any): Action {
  return {
      type: GET_LESSON_DETAILS_FAILURE,
      payload: error
   }
}

export function getLessonDetailsAction(lessonReferenceId: string) {
  return async (dispatch: Function) => {
    try {
      dispatch(getLessonDetailsPending());
      const response: AxiosResponse<LessonDetails> = await axios.get(LESSON_URL + `${lessonReferenceId}/full/`);
      dispatch(getLessonDetailsSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getLessonDetailsFailure(error));
      return Promise.reject(error)
    }
  }
}