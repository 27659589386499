import { environment, Staging } from "./env";

let region = "ap-southeast-2";

type CognitoConfig = {
  userPoolId: string;
  userPoolWebClientId: string;
};

let cognitoConfig: CognitoConfig = {
  userPoolId: `${region}_Dv1W4uX2t`,
  userPoolWebClientId: "1m5cv680h0shdhd5hf0fforgid",
};

if (environment.staging === Staging.Stage) {
  region = "us-east-2";
  cognitoConfig = {
    userPoolId: `${region}_0gB4CHoKz`,
    userPoolWebClientId: "2h7m0ae610nk88k6rdm79h74pk",
  };
} else if (environment.prod === Staging.Prod) {
  region = "us-east-2";
  cognitoConfig = {
    userPoolId: `${region}_vRX61fVFh`,
    userPoolWebClientId: "airibnbosuu70tnd8p1rpr72v"
  }
}

export const AWS_REGION = region;
export const COGNITO_CONFIG = cognitoConfig;
