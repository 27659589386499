import { GET_LESSON_OUTLINE_FAILURE, GET_LESSON_OUTLINE_PENDING, GET_LESSON_OUTLINE_SUCCESS } from "store/actions/lesson-outline.action"
import { Action } from "types/action"
import { LessonOutline } from "types/lesson-outline"

export type LessonOutlineState = {
  // Data
  lessonOutline: LessonOutline,
  // Loading
  getLessonOutlineLoading: boolean;
  // Error
  getLessonOutlineError: any
}

const initialState: LessonOutlineState = {
  // Data
  lessonOutline: {results: []},
  // Loading
  getLessonOutlineLoading: false,
  // Error
  getLessonOutlineError: null
}

export default function lessonOutlineReducer(state = initialState, action: Action): LessonOutlineState {
  switch (action.type) {
    //  ============================================================
    //  GET LESSON OUTLINE
    //  ============================================================
    case GET_LESSON_OUTLINE_PENDING:
      return {
        ...state,
        getLessonOutlineLoading: true
      }
    case GET_LESSON_OUTLINE_SUCCESS:
      return {
        ...state,
        getLessonOutlineLoading: false,
        lessonOutline: action.payload
      }
    case GET_LESSON_OUTLINE_FAILURE:
      return {
        ...state,
        getLessonOutlineLoading: false,
        getLessonOutlineError: action.payload
      }
    default:
      return { ...state }
  }
}