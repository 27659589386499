import { Empty } from "antd";
import GalleryCard from "components/GalleryCard/GalleryCard";
import { timezone } from "helpers/time";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { StudentState } from "store/reducers/student.reducer";

export default function MediaGallery() {
  const { galleryData } = useSelector<RootState, StudentState>((state) => state.studentState);
  console.log(galleryData);
  return (
    <div>
      {galleryData && galleryData.results.length > 0 ? (
        galleryData.results.map((media) => (
          <GalleryCard
            className={
              moment(new Date(media.class_lesson.start_datetime + 'z')).tz(timezone).format("dddd\\s, ")
            }
            key={media.id}
            fileName=''
            fileType={media.type}
            createdAt={media.created_at}
            file={media.type === 'image' ? media.file?.image_s3_url : media.file}
          />
        ))
      ) : (
        <Empty />
      )}
    </div>
  );
}
