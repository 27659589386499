import {
  GET_LESSONS_FAILURE,
  GET_LESSONS_PENDING,
  GET_LESSONS_SUCCESS,
  GET_LESSON_CALENDAR_FAILURE,
  GET_LESSON_CALENDAR_PENDING,
  GET_LESSON_CALENDAR_SUCCESS,
  GET_LESSON_DETAILS_FAILURE,
  GET_LESSON_DETAILS_PENDING,
  GET_LESSON_DETAILS_SUCCESS,
} from "store/actions/lesson.action";
import { Action } from "types/action";
import { LessonCalendarData, LessonData, LessonDetails } from "types/lesson";

export type LessonState = {
  // Data
  lessonCalendar: LessonCalendarData;
  lessonData: LessonData;
  lesson: LessonDetails;
  // Loading
  getLessonCalendarLoading: boolean;
  getLessonsLoading: boolean;
  getLessonDetailsLoading: boolean;
  // Error
  getLessonCalendarError: any;
  getLessonsError: any;
  getLessonDetailsError: any;
};

const initialState: LessonState = {
  // Data
  lessonCalendar: { totalRow: 0, results: [] },
  lessonData: { count: 0, results: [] },
  lesson: { lesson: null, class_subs: [], lesson_outlines: [], flashcards: [], musics: [], books: [], resources: [], crafts: [], episodes: []},
  // Loading
  getLessonCalendarLoading: false,
  getLessonsLoading: false,
  getLessonDetailsLoading: false,
  // Error
  getLessonCalendarError: null,
  getLessonsError: null,
  getLessonDetailsError: null,
};

export default function lessonReducer(
  state = initialState,
  action: Action
): LessonState {
  switch (action.type) {
    //  ============================================================
    //  GET LESSON CALENDAR
    //  ============================================================
    case GET_LESSON_CALENDAR_PENDING:
      return {
        ...state,
        getLessonCalendarLoading: true,
      };
    case GET_LESSON_CALENDAR_SUCCESS:
      return {
        ...state,
        getLessonCalendarLoading: false,
        lessonCalendar: action.payload,
      };
    case GET_LESSON_CALENDAR_FAILURE:
      return {
        ...state,
        getLessonCalendarLoading: false,
        getLessonCalendarError: action.payload,
      };
    //  ============================================================
    //  GET LESSONS
    //  ============================================================
    case GET_LESSONS_PENDING:
      return {
        ...state,
        getLessonsLoading: true,
      };
    case GET_LESSONS_SUCCESS:
      return {
        ...state,
        getLessonsLoading: false,
        lessonData: action.payload,
      };
    case GET_LESSONS_FAILURE:
      return {
        ...state,
        getLessonsLoading: false,
        getLessonsError: action.payload,
      };
    //  ============================================================
    //  GET LESSON DETAILS
    //  ============================================================
    case GET_LESSON_DETAILS_PENDING:
      return {
        ...state,
        getLessonDetailsLoading: true
      }
    case GET_LESSON_DETAILS_SUCCESS:
      return {
        ...state,
        getLessonDetailsLoading: false,
        lesson: action.payload
      }
    case GET_LESSON_DETAILS_FAILURE:
      return {
        ...state,
        getLessonDetailsLoading: false,
        getLessonDetailsError: action.payload
      }
    default:
      return { ...state };
  }
}
