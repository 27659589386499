import { Collapse, PageHeader } from "antd";
import { useHistory, useRouteMatch } from "react-router-dom";
import LayoutContent from "components/LayoutContent/LayoutContent";
// import BookCard from "components/BookCard/BookCard";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { getBookReadAction } from "store/actions/student.action";
import { useEffect } from "react";
import { RootState } from "store/reducer";
import { StudentState } from "store/reducers/student.reducer";

export default function BooksRead() {
  // React router
  const { goBack } = useHistory();
  const { params } = useRouteMatch<{ studentId: string }>();
  const { bookReadData } = useSelector<RootState, StudentState>(
    (state) => state.studentState
  );

  // React redux
  const dispatch = useDispatch();

  const fetchData = useCallback(() => {
    try {
      if (params.studentId) {
        dispatch(getBookReadAction(params.studentId));
      }
    } catch (error) {
      //
    }
  }, [dispatch, params.studentId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <PageHeader title="Books read" onBack={goBack} />

      <LayoutContent>
        <Collapse expandIconPosition="right" defaultActiveKey={0}>
          {bookReadData.results.map((theme) => (
            <Collapse.Panel
              key={theme.id}
              header={<strong>{theme.title}</strong>}
            >
              {/* {theme.books.map((book) => (
                <BookCard
                  key={book.id}
                  image={book.thumbnail}
                  name={book.title}
                />
              ))} */}
            </Collapse.Panel>
          ))}
        </Collapse>
      </LayoutContent>
    </div>
  );
}
