import { Avatar, Input, List, Space, Tabs } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { ChatState } from "store/reducers/chat.reducer";
// import { FormOutlined } from "@ant-design/icons";
import Timeago from "react-timeago";
import {
  ChatAction,
  ChatRoute,
  ConversationType,
} from "types/conversation";
import {
  setClassInfoListAction,
  setSelectedConversationAction,
  setUserProfileListAction,
  socketSendMessageAction,
} from "store/actions/chat.action";
import axios, { AxiosResponse } from "axios";
import { CHAT_URL } from "constants/urls";
import { useState } from "react";
import moment from "moment";
import { timezone } from "helpers/time";

export enum ChatListTabKey {
  ONE_TO_ONE = "one-to-one",
  GROUP = "group",
}

export default function ChatList() {
  // State
  const [loading, setLoading] = useState<boolean>(false);

  // React router
  const { push } = useHistory();
  const { pathname, search } = useLocation();

  // React redux
  const dispatch = useDispatch();
  const {
    socketConnected,
    singleConversationData,
    groupConversationData,
    getSingleConversationsLoading,
    getGroupConversationsLoading,
    userProfileList,
    classInfoList,
  } = useSelector<RootState, ChatState>((state) => state.chatState);
  // Variables
  const query: any = useMemo(() => queryString.parse(search), [search]);
  // Fetch conversation list on init
  const fetchData = useCallback(() => {
    if (socketConnected) {
      if (query.tab === ChatListTabKey.GROUP) {
        dispatch(
          socketSendMessageAction({
            route: ChatRoute.CHAT,
            action: ChatAction.GROUP_CONVERSATION_LIST_BY_TEACHER,
            data: {
              options: {
                sorted: false,
              },
            },
          })
        );
      } else {
        dispatch(
          socketSendMessageAction({
            route: ChatRoute.CHAT,
            action: ChatAction.CONVERSATION_LIST_BY_TEACHER,
            data: {
              options: {
                sorted: false,
              },
            },
          })
        );
      }
    }
  }, [dispatch, socketConnected, query.tab]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Select the first conversation by default
  useEffect(() => {
    if (query.chatId) {
      const info = {
        chatId: query.chatId as string,
        type: (query.tab as ConversationType) || ConversationType.SINGLE,
      };

      if (
        (info.type === ConversationType.SINGLE &&
          singleConversationData.Items.length > 0) ||
        (info.type === ConversationType.GROUP &&
          groupConversationData.Items.length > 0)
      ) {
        dispatch(
          setSelectedConversationAction({
            chatId: query.chatId as string,
            type: (query.tab as ConversationType) || ConversationType.SINGLE,
          })
        );
      }
    } else {
      const type = (query.tab as ConversationType) || ConversationType.SINGLE;
      if (
        type === ConversationType.SINGLE &&
        singleConversationData.Items.length > 0
      ) {
        const queryObj = {
          tab: type,
          chatId: singleConversationData.Items[0].chatId,
        };
        push(pathname + "?" + queryString.stringify(queryObj));
      } else if (
        type === ConversationType.GROUP &&
        groupConversationData.Items.length > 0
      ) {
        const queryObj = {
          tab: type,
          chatId: groupConversationData.Items[0].chatId,
        };
        push(pathname + "?" + queryString.stringify(queryObj));
      }
    }
  }, [
    query.chatId,
    query.tab,
    dispatch,
    singleConversationData,
    groupConversationData,
    push,
    pathname,
  ]);

  // Get user profile from ID
  const getUserProfileList = useCallback(
    async (ids: Array<string>) => {
      try {
        setLoading(true);
        const userListResponse: Array<AxiosResponse<any>> = await axios.post(CHAT_URL + '/profile/', {users_id: ids})
        dispatch(setUserProfileListAction(userListResponse.map((res) => res.data.results)));
      } catch (error) {
        //
      } finally {
        setLoading(false);
      }
    },
    [dispatch]
  );

  // Get class info from ID
  const getClassInfoList = useCallback(
    async (ids: Array<string>) => {
      try {
        setLoading(true);
        const classInfoListResponse: Array<AxiosResponse<any>> =
          await Promise.all(
            ids.map((classId) =>
              axios.post(CHAT_URL+'/class/', {class_id: classId})
            )
          );
        dispatch(setClassInfoListAction(classInfoListResponse.map((res) => res.data.results || {})));
      } catch (error) {
        //
      } finally {
        setLoading(false);
      }
    },
    [dispatch]
  );

  // Fetch user profiles
  useEffect(() => {
    const type = query.tab || ConversationType.SINGLE;
    if (
      type === ConversationType.SINGLE &&
      singleConversationData.Items.length > 0
    ) {
      const profileToGet = singleConversationData.Items.map(
        (cvs) => cvs.userId
      );
      getUserProfileList(profileToGet);
    } else if (
      type === ConversationType.GROUP &&
      groupConversationData.Items.length > 0
    ) {
      const profileToGet = groupConversationData.Items.map((cvs) => cvs.userId);
      const classToGet = groupConversationData.Items.map((cvs) => cvs.chatId);
      Promise.all([
        getUserProfileList(profileToGet),
        getClassInfoList(classToGet),
      ]);
    }
  }, [
    query.tab,
    singleConversationData.Items,
    groupConversationData.Items,
    getUserProfileList,
    getClassInfoList,
  ]);

  function handleChatClick(chatId: string) {
    push(
      pathname +
        "?" +
        queryString.stringify({
          ...query,
          chatId,
        })
    );
  }

  function handleTabChange(activeKey: string) {
    push(pathname + "?tab=" + activeKey);
  }

  return (
    <div className="messages-page__chat-list flex flex-col max-h-full">
      <div>
        <div className="flex items-center justify-between">
          <h1 className="m-0">Recent chats</h1>
          <Space>
            <div className="ml-3 flex justify-end flex-1">
              <Input.Search style={{ width: 200 }} />
            </div>
            {/* <Button type="primary" icon={<FormOutlined />} /> */}
          </Space>
        </div>
      </div>

      <Tabs
        className="flex-1"
        onChange={handleTabChange}
        activeKey={query.tab || ChatListTabKey.ONE_TO_ONE}
      >
        <Tabs.TabPane tab="One to one" key={ChatListTabKey.ONE_TO_ONE}>
          <List
            loading={getSingleConversationsLoading || loading}
            dataSource={singleConversationData.Items}
            rowKey="id"
            size="small"
            renderItem={(chat) => (
              <List.Item
                onClick={() => handleChatClick(chat.chatId)}
                className={`cursor-pointer hover:bg-red-100 transition-all px-3${
                  query.chatId === chat.chatId ? " bg-red-100" : ""
                }`}
                extra={
                  <small>
                    <Timeago date={chat.repliedAt} />
                  </small>
                }
              >
                <List.Item.Meta
                  className="items-center"
                  avatar={
                    <Avatar src={userProfileList[chat.userId]?.avatar}>
                      {userProfileList[chat.userId]
                        ? userProfileList[chat.userId].name[0]
                        : ""}
                    </Avatar>
                  }
                  title={userProfileList[chat.userId]?.name}
                  description={chat.lastMessage && chat.lastMessage.message}
                />
              </List.Item>
            )}
          />
        </Tabs.TabPane>

        <Tabs.TabPane tab="Group" key={ChatListTabKey.GROUP}>
          <List
            loading={getGroupConversationsLoading || loading}
            dataSource={groupConversationData.Items}
            rowKey="id"
            size="small"
            renderItem={(chat) => (
              <List.Item
                onClick={() => handleChatClick(chat.chatId)}
                className={`cursor-pointer hover:bg-red-100 transition-all px-3${
                  query.chatId === chat.chatId ? " bg-red-100" : ""
                }`}
                extra={
                  <small>
                    <Timeago date={chat.repliedAt} />
                  </small>
                }
              >
                <List.Item.Meta
                  title={
                    classInfoList[chat.chatId]?.days_of_week +
                    ", " +
                    moment(new Date(classInfoList[chat.chatId]?.start_datetime + 'z')).tz(timezone).format('HH:mm DD-MM-YYYY')
                  }
                  description={chat.lastMessage && chat.lastMessage.message}
                />
              </List.Item>
            )}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
