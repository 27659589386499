import {
  Button,
  PageHeader,
  Row,
  Col,
  Pagination,
  Spin,
  Empty,
} from "antd";
import LayoutContent from "components/LayoutContent/LayoutContent";
import { Link, useHistory, useLocation } from "react-router-dom";
import LessonThumbnail from "components/LessonThumbnail/LessonThumbnail";
import queryString from "query-string";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { getLessonsAction } from "store/actions/lesson.action";
import { useEffect } from "react";
import { RootState } from "store/reducer";
import { LessonState } from "store/reducers/lesson.reducer";
import usePagination from "hooks/usePagination";
import ClassFilter from "components/ClassFilter/ClassFilter";

export default function CurriculumnPage() {
  // React router
  const { pathname, search } = useLocation();
  const { push } = useHistory();

  // React redux
  const dispatch = useDispatch();
  const { lessonData, getLessonsLoading } = useSelector<RootState, LessonState>(
    (state) => state.lessonState
  );

  // Custom hooks
  const { page, limit } = usePagination();

  // Variables
  const query: any = useMemo(() => queryString.parse(search), [search]);

  const fetchData = useCallback(() => {
    try {
      Promise.all([
        dispatch(getLessonsAction(query)),
      ]);
    } catch (error) {
      //
    }
  }, [dispatch, query]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function onPaginationChange(page: number, pageSize?: number) {
    push(pathname + "?" + queryString.stringify({ page, page_size: pageSize }));
  }

  return (
    <div>
      <PageHeader
        title="Curriculum"
        extra={
          <Link key="add-new" to="/my-classes/add-new">
            <Button type="primary">Add new class</Button>
          </Link>
        }
      />

      <LayoutContent>

        <ClassFilter />

        <Spin spinning={getLessonsLoading}>
          <Row gutter={12}>
            {lessonData.results.map((lesson) => (
              <Col
                key={lesson.id}
                xs={24}
                sm={12}
                md={8}
                lg={6}
                xxl={4}
                className="mb-4"
              >
                <LessonThumbnail lesson={lesson} />
              </Col>
            ))}
          
          </Row>
          {
            lessonData.results.length === 0 && ( <Empty description="No lessons found" />)
          }
        </Spin>

        <div className="flex justify-end">
          <Pagination
            hideOnSinglePage
            showSizeChanger={true}
            total={lessonData.count}
            current={page}
            pageSize={limit}
            onChange={onPaginationChange}
          />
        </div>
      </LayoutContent>
    </div>
  );
}
