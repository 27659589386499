import axios, { AxiosResponse } from "axios";
import { REVIEW_URL } from "constants/urls";
import { stringifyParams } from "helpers/params";
import { Action } from "types/action";
import { Rating, ReviewData } from "types/review";

//  ============================================================
//  GET REVIEWS
//  ============================================================
export const GET_REVIEWS_PENDING = "GET_REVIEWS_PENDING";
export const GET_REVIEWS_SUCCESS = "GET_REVIEWS_SUCCESS";
export const GET_REVIEWS_FAILURE = "GET_REVIEWS_FAILURE";
function getReviewsPending(): Action {
  return {
      type: GET_REVIEWS_PENDING
   }
}

function getReviewsSuccess(data: ReviewData): Action {
  return {
      type: GET_REVIEWS_SUCCESS,
      payload: data
   }
}

function getReviewsFailure(error: any): Action {
  return {
      type: GET_REVIEWS_FAILURE,
      payload: error
   }
}

export function getReviewsAction(query?: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(getReviewsPending());
      const response: AxiosResponse<ReviewData> = await axios.get(REVIEW_URL + stringifyParams(query));
      dispatch(getReviewsSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getReviewsFailure(error));
      return Promise.reject(error)
    }
  }
}

//  ============================================================
//  GET TOTAL RATING
//  ============================================================
export const GET_RATING_SUCCESS = "GET_RATING_SUCCESS";

function getRatingSuccess(data: Rating): Action {
  return {
      type: GET_RATING_SUCCESS,
      payload: data
   }
}

export function getRatingAction() {
  return async (dispatch: Function) => {
    try {
      dispatch(getReviewsPending());
      const response: AxiosResponse<Rating> = await axios.get(REVIEW_URL + 'overall-rating/');
      dispatch(getRatingSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error)
    }
  }
}