import {
  Card,
  Row,
  Col,
  Divider,
  List,
  Button,
  Space,
  Form,
  Checkbox,
} from "antd";
// import songImage from "assets/images/song-image.png";
import VideoPlayer from "components/VideoPlayer/VideoPlayer";
import AudioPlayer from "components/AudioPlayer/AudioPlayer";
import { PrinterOutlined, DownloadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { LessonState } from "store/reducers/lesson.reducer";
import Print from "print-js";
import { useForm } from "antd/lib/form/Form";
import bookIcon from "assets/images/book-icon.png";
import songIcon from "assets/images/song-icon.png";
import videoIcon from "assets/images/video-icon.png";
import craftIcon from "assets/images/craft-icon.png";
import flashcardIcon from "assets/images/flashcard-icon.png";
import videoPlaceholder from "assets/images/video-placeholder.png";
import { useState } from "react";
import PrintCard from "components/PrintCard/PrintCard";
import { downloadFlashCardsAction } from "store/actions/printable.action";
import { PrintableState } from "store/reducers/printable.reducer";
export default function ClassResources() {
  // store
  const dispatch = useDispatch();
  const { downloadFlashCardsLoading } = useSelector<RootState, PrintableState>((state) => state.printableState)
  const { lesson } = useSelector<RootState, LessonState>(
    (state) => state.lessonState
  );


  const [flashCardForm] = useForm();
  const [submitType, setSubmitType] = useState<string>('print')

  function handlePrintCraft(imageUrl: string) {
    Print({
      printable: imageUrl,
      type: "image",
      imageStyle: "max-width:100%",
    });
  }

  function printResources(values: any) {
    if (values.flashcards && values.flashcards.length > 0) {
      if (submitType === 'print') {
        Print({
          printable: values.flashcards,
          type: "image",
          imageStyle: "max-width: 100%"
        })
      } else {
        const array = lesson.flashcards.map((item) => item.flashcards.filter(x => values.flashcards.includes(x.thumbnail?.image_s3_url)).map((card) => card.id));
        var flashcards = array.flat(1)
        dispatch(downloadFlashCardsAction({ flashcards, crafts: [] }))
      }
   
    }
  }
  
  return (
    <Card title="RESOURCES">
      <p>
        <strong>Source yourself:</strong>
      </p>
      <p className="font-serif mb-6">
        <i>
          These are resources you may have already around your house, or may
          need to purchase prior to the lesson.
        </i>
      </p>

      <Row>
        {lesson.resources.map((resource, index) => (
          <Col xs={24} lg={12} xxl={8} key={index}>
            <p>
              <strong>{resource.title}</strong>
            </p>
            <span>{resource.resource_type?.en.title}</span>
            {resource.resources.map((item) => (
              <p key={item.id} className="my-5">
                <img src={item.thumbnail?.image_s3_url} width="30" height="30" style={{height: '30px'}} alt="" className="mr-1"/>
                <span>{item.title}</span>
              </p>
            ))}
          </Col>
        ))}
      </Row>

      <Divider />

      <p>
        <strong>Adam & MishMish Resources</strong>
      </p>
      <p className="font-serif mb-6">
        <i>
          These are resources you have at your disposal as part of the Adam &
          MishMish curriculum.
        </i>
      </p>

      <List
        header={
          <h3 className="flex items-center">
            <img src={bookIcon} alt="book" width="20" className="block mr-2" />
            <strong>BOOKS</strong>
          </h3>
        }
        dataSource={lesson.books || []}
        renderItem={(book) => (
          <List.Item
            actions={[
              <a href={book.file} target="_blank" rel="noopener noreferrer">
                Read
              </a>,
            ]}
          >
            <img src={book.thumbnail.image_s3_url} alt={book.title} width="100" />
            <div className="flex-1 px-3">{book.title}</div>
          </List.Item>
        )}
      />

      <List
        header={
          <h3 className="flex items-center">
            <img src={songIcon} alt="song" width="20" className="block mr-2" />
            <strong>MUSIC</strong>
          </h3>
        }
        dataSource={lesson.musics}
        renderItem={(music) => (
          <List.Item>
            <AudioPlayer src={music.file} name={music.title} />
          </List.Item>
        )}
      />

      <Divider />

      <h3 className="flex items-center">
        <img src={videoIcon} alt="video" className="block mr-2" width="20" />
        <strong>VIDEOS</strong>
      </h3>
      <Divider />

      <Row gutter={24}>
        {lesson.episodes.map((episode) => (
          <Col key={episode.id} className="mb-3" xs={24} lg={12} xl={8} xxl={6}>
            <VideoPlayer
              thumbnail={episode.thumbnail.image_s3_url || videoPlaceholder}
              source={{
                type: "video",
                sources: [
                  {
                    src: episode.file,
                  },
                ],
              }}
            />
          </Col>
        ))}
      </Row>

      <Divider />
        <List
          header={
            <h3 className="flex items-center">
              <img
                src={craftIcon}
                alt="craft"
                width="20"
                className="block mr-2"
              />
              <strong>ARTS & CRAFTS</strong>
            </h3>
          }
          dataSource={lesson.crafts}
          renderItem={(craft) => (
            <List.Item
              actions={[
                <Button
                  key="print"
                  icon={<PrinterOutlined />}
                  onClick={() => handlePrintCraft(craft.thumbnail.image_s3_url)}
                >
                  Print
                </Button>,
                <Button icon={<DownloadOutlined />} onClick={() => dispatch(downloadFlashCardsAction({flashcards: [], crafts: [craft.id]}))} loading={downloadFlashCardsLoading}>Download</Button>
              ]}
            >
              <img src={craft.thumbnail.image_s3_url} alt="" width="100" className="mr-2" />
              <div className="flex-1 px-3">{craft.title}</div>
            </List.Item>
          )}
        />
      <Divider />

      <Form form={flashCardForm} onFinish={printResources}>
        <Form.Item name="flashcards" valuePropName="checked" noStyle>
          <Checkbox.Group className="w-full">
            <List
              header={
                <div className="flex justify-between items-center">
                  <div className="flex items-center">
                    <img
                      src={flashcardIcon}
                      alt="flashcard"
                      width="20"
                      className="block mr-2"
                    />
                    <h3>FLASH CARD</h3>
                  </div>

                  <Space>
                    <Button
                      icon={<PrinterOutlined />}
                      htmlType="submit" 
                      onClick={() => setSubmitType('print')}
                    >
                      Print
                    </Button>
                    <Button icon={<DownloadOutlined />} htmlType="submit" loading={downloadFlashCardsLoading} onClick={() => setSubmitType('download')}>Download</Button>
                  </Space>
                </div>
              }
              dataSource={lesson.flashcards}
              split={false}
              renderItem={(card) => (
                <List.Item
                  key={card.id}>
                  <Row gutter={24}>
                    <Col
                      className="mb-3"
                      key={card.id}>
                      <p>
                        <strong>{card.title}</strong>
                      </p>
                      <div className="w-full flex flex-wrap">
                        {card.flashcards.map((item) => (
                          <PrintCard
                            key={item.id}
                            name={item.title}
                            checked={true}
                            value={item.id}
                            image={item.thumbnail?.image_s3_url}
                          />
                        ))}
                      </div>
                    </Col>
                  </Row>
                </List.Item>
              )}
            />
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Card>
  );
}
