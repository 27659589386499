import Icon from "@ant-design/icons";
import PlayIcon from "components/icons/PlayIcon";
import PauseIcon from "components/icons/PauseIcon";
import WaveSurfer from "wavesurfer.js";

import "./AudioPlayer.scss";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";

type AudioPlayerProps = {
  name: string;
  src?: string;
};

export default function AudioPlayer(props: AudioPlayerProps) {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const waveformRef = useRef<HTMLDivElement>(null);
  const wavesurfer = useRef<WaveSurfer>();

  useEffect(() => {
    if (waveformRef.current) {
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        height: 30,
        barWidth: 2,
        barRadius: 2,
        waveColor: "#fef7ed",
        progressColor: "#f1b24d",
        cursorColor: "#f1b24d",
      });

      wavesurfer.current.on("finish", onFinish);
    }

    return () => {
      if (wavesurfer.current) {
        wavesurfer.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (wavesurfer.current) {
      wavesurfer.current.load(
        props.src ||
          "https://file-examples-com.github.io/uploads/2017/11/file_example_OOG_1MG.ogg"
      );
    }
  }, [wavesurfer, props.src]);

  function onPlayClick() {
    if (wavesurfer.current) {
      setIsPlaying(true);
      wavesurfer.current.play();
    }
  }

  function onPauseClick() {
    if (wavesurfer.current) {
      setIsPlaying(false);
      wavesurfer.current.pause();
    }
  }

  function onFinish() {
    setIsPlaying(false);
    if (wavesurfer.current) {
      wavesurfer.current.stop();
    }
  }

  return (
    <div className="audio-player">
      <div className="audio-player__play-pause">
        {isPlaying ? (
          <Icon component={() => <PauseIcon />} onClick={onPauseClick} />
        ) : (
          <Icon component={() => <PlayIcon />} onClick={onPlayClick} />
        )}
      </div>
      <div className="audio-player__name">{props.name}</div>
      <div className="audio-player__waveform" ref={waveformRef}>
        <div ref={waveformRef}></div>
      </div>
    </div>
  );
}
