import axios, { AxiosResponse } from "axios";
import { API_PREFIX, TEACHER_URL } from "constants/urls";
import { stringifyParams } from "helpers/params";
import { Action } from "types/action";
import { ListNotifications, TotalNotice } from "types/notification";

//  ============================================================
//  GET LIST NOTIFICATION
//  ============================================================
export const GET_NOTIFY_PENDING = "GET_NOTIFY_PENDING";
export const GET_NOTIFY_SUCCESS = "GET_NOTIFY_SUCCESS";
export const GET_NOTIFY_FAILURE = "GET_NOTIFY_FAILURE";


function getNotifyPending(): Action {
  return {
      type: GET_NOTIFY_PENDING
   }
}

function getNotifySuccess(notify: ListNotifications): Action {
  return {
      type: GET_NOTIFY_SUCCESS,
      payload: notify
   }
}

function getNotifyFailure(error: any): Action {
  return {
      type: GET_NOTIFY_FAILURE,
      payload: error
   }
}

export function getNotificationsAction(query?: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(getNotifyPending());
      const response: AxiosResponse<ListNotifications> = await axios.get(`${TEACHER_URL}/notifications/${stringifyParams(query)}`);
      dispatch(getNotifySuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getNotifyFailure(error));
      return Promise.reject(error)
    }
  }
}


//  ============================================================
//  GET TOTAL NOTIFICATIONs UNREAD
//  ============================================================
export const GET_NOTIFY_UNREAD_PENDING = "GET_NOTIFY_UNREAD_PENDING";
export const GET_NOTIFY_UNREAD_SUCCESS = "GET_NOTIFY_UNREAD_SUCCESS";
export const GET_NOTIFY_UNREAD_FAILURE = "GET_NOTIFY_UNREAD_FAILURE";


function getNotifyUnReadPending(): Action {
  return {
      type: GET_NOTIFY_UNREAD_PENDING
   }
}

function getNotifyUnReadSuccess(notify: TotalNotice): Action {
  return {
      type: GET_NOTIFY_UNREAD_SUCCESS,
      payload: notify
   }
}

function getNotifyUnReadFailure(error: any): Action {
  return {
      type: GET_NOTIFY_UNREAD_FAILURE,
      payload: error
   }
}

export function getNotificationsUnreadAction() {
  return async (dispatch: Function) => {
    try {
      dispatch(getNotifyUnReadPending());
      const response: AxiosResponse<TotalNotice> = await axios.get(`${TEACHER_URL}/notifications/total-notice/`);
      dispatch(getNotifyUnReadSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getNotifyUnReadFailure(error));
      return Promise.reject(error)
    }
  }
}



//  ============================================================
//  Make READ ALL NOTIFY
//  ============================================================
export const MAKE_READ_ALL_PENDING = "MAKE_READ_ALL_PENDING";
export const MAKE_READ_ALL_SUCCESS = "MAKE_READ_ALL_SUCCESS";
export const MAKE_READ_ALL_FAILURE = "MAKE_READ_ALL_FAILURE";


function makeReadAllPending(): Action {
  return {
      type: MAKE_READ_ALL_PENDING
   }
}

function makeReadAllSuccess(notify: string): Action {
  return {
      type: MAKE_READ_ALL_SUCCESS,
      payload: notify
   }
}

function makeReadAllFailure(error: any): Action {
  return {
      type: MAKE_READ_ALL_FAILURE,
      payload: error
   }
}

export function makeReadAllNoticeAction() {
  return async (dispatch: Function) => {
    try {
      dispatch(makeReadAllPending());
      await axios.put(`${TEACHER_URL}/notifications/read-all-notice/`);
      dispatch(makeReadAllSuccess('success'));
      return Promise.resolve();
    } catch (error) {
      dispatch(makeReadAllFailure(error));
      return Promise.reject(error)
    }
  }
}

//  ============================================================
//  UPDATE FCM TOKEN
//  ============================================================
export const UPDATE_FCM_TOKEN_PENDING = "UPDATE_FCM_TOKEN_PENDING";
export const UPDATE_FCM_TOKEN_SUCCESS = "UPDATE_FCM_TOKEN_SUCCESS";
export const UPDATE_FCM_TOKEN_FAILURE = "UPDATE_FCM_TOKEN_FAILURE";


function getUpdateFcmTokenPending(): Action {
  return {
      type: UPDATE_FCM_TOKEN_PENDING
   }
}

function getUpdateFcmTokenSuccess(): Action {
  return {
      type: UPDATE_FCM_TOKEN_SUCCESS,
   }
}

function getUpdateFcmTokenFailure(error: any): Action {
  return {
      type: UPDATE_FCM_TOKEN_FAILURE,
      payload: error
   }
}

export function getUpdateFcmTokenAction(body: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(getUpdateFcmTokenPending());
      await axios.put(`${API_PREFIX}/auth/fcm-registration-id/web/`, body);
      dispatch(getUpdateFcmTokenSuccess());
      return Promise.resolve();
    } catch (error) {
      dispatch(getUpdateFcmTokenFailure(error));
      return Promise.reject(error)
    }
  }
}