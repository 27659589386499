import PhoneNumberInput, { PhoneInputProps } from "react-phone-number-input";
import "react-phone-number-input/style.css";

type CustomPhoneInputProps = {
  value?: string;
  onChange?: (value: string) => void;
};

export default function PhoneInput({
  onChange,
  value,
  ...props
}: CustomPhoneInputProps & Omit<PhoneInputProps, "onChange" | "value">) {

  function onInputChange(value: string) {
    if (onChange) {
      onChange(value);
    }
  }

  return (
    <PhoneNumberInput
      {...props}
      value={value || ""}
      onChange={onInputChange}
      defaultCountry="AU"
      numberInputProps={{
        className: "ant-input",
      }}
    />
  );
}
