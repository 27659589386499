import { Alert, Button, Col, Form, Input, message, Row } from "antd";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { AuthState } from "store/reducers/auth.reducer";
import { CreateTokenBankAccountData } from "@stripe/stripe-js";
import { useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import {
  createPaymentAction,
  getPaymentAction,
  getProfileProgressAction,
  uploadStripeDocumentAction,
} from "store/actions/auth.action";
import { ERROR_MESSAGES } from "constants/content";
import { useCallback } from "react";
import { checkCompletedProfileProgress } from "helpers/profileProgress";

type PaymentTabProps = {
  form?: FormInstance;
  onUpdateSuccess?: () => void;
};

type Countries = {
  alpha2Code: string;
  currency: string;
  currencies: Array<{
    code: string;
  }>
}

export default function PaymentTab({ form, onUpdateSuccess }: PaymentTabProps) {
  // State
  const [loading, setLoading] = useState<boolean>(false);
  const [front, setFront] = useState<File | null>();
  const [back, setBack] = useState<File | null>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [countries, setCountries] = useState<Array<Countries>>([])
  // React redux
  const dispatch = useDispatch();
  const { user, paymentMethod } = useSelector<RootState, AuthState>(
    (state) => state.authState
  );
  // Stripe
  const stripe = useStripe();

  const [paymentForm] = useForm();

  const fetchData = useCallback(() => {
    try {
      fetch(`https://restcountries.eu/rest/v2/`)
      .then((response) => {
        if (!response.ok) {
          return [];
        }
        return response.json() as Promise<
          Array<Countries>
        >;
      })
      .then((result) => {
        setCountries(result);
      });

      dispatch(getPaymentAction());
    } catch (error) {}
  }, [dispatch]);

  // get countries and payment details
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // set default fullname
  useEffect(() => {
    const formInstance = form || paymentForm;
    if (user) {
      const values = {
        name: `${user.user.first_name} ${user.user.last_name}`,
      };
      formInstance.setFieldsValue(values);
    }
  }, [user, paymentForm, form]);

  // check file change (front-back photo)
  function backPhotoChange(e: any, type: string) {
    const {files} = e.target
    if (type === 'back') {
      setBack(files[0])
    } else {
      setFront(files[0])
    }
  }

  async function handleSubmit(values: any) {
    try {
      if (!stripe) {
        return;
      }
      if (paymentMethod && paymentMethod.connect_status !== 'unverified') {
        if (onUpdateSuccess) {
          onUpdateSuccess();
        }
      } else {
          setLoading(true);
          const country = countries.find(x => x.alpha2Code === user?.nationality)
          const { token, error } = await stripe.createToken("bank_account", {
            country: user?.nationality,
            currency: country?.currencies[0].code,
            account_holder_type: "individual",
            account_holder_name: values.name,
            account_number: values.accountNumber,
            routing_number: values.bsb,
          } as CreateTokenBankAccountData);
          if (token) {
            setErrorMessage('')
            await dispatch(createPaymentAction({ bank_token_id: token.id }));
            const fb = new FormData()
            if (front && back) {
              fb.append('document_front_id', front, front.name )
              fb.append('document_back_id', back, back.name )
            }
            await dispatch(uploadStripeDocumentAction(fb))
            if (user && checkCompletedProfileProgress(user) < 100) {
              await dispatch(getProfileProgressAction());
            }
            if (onUpdateSuccess) {
              dispatch(getPaymentAction());
              onUpdateSuccess();
              setTimeout(() => {
                setLoading(false);
              }, 2000);
            } else {
              message.success("Payment details updated");
              setLoading(false);
              dispatch(getPaymentAction());
            }
          } else if (error) {
            setErrorMessage(error.message?.toString() || '')
          }
      }
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <div>
      <Form
        onFinish={handleSubmit}
        form={form || paymentForm}
        layout="vertical"
      >
        {paymentMethod && paymentMethod.connect_status !== 'unverified' &&
          <Form.Item>
            <Alert
              type="info"
              message="Current payment details:"
              description={
                <div>
                  <div>**** **** **** {paymentMethod.bank.last4}</div>
                  <div>Account name: {paymentMethod.bank.account_holder_name}</div>
                  <div>Routing number: {paymentMethod.bank.routing_number}</div>
                </div>
              }
            />
          </Form.Item>
        }
        {
          paymentMethod && paymentMethod.connect_status === 'unverified' &&
           <>
              {
                errorMessage && (<Alert type="warning" message={errorMessage} />)
              }
              <Form.Item
                label="Account name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: ERROR_MESSAGES.PAYMENT_NAME_REQUIRED,
                  },
                ]}
              >
                <Input />
              </Form.Item>

            <Row gutter={24}>
            <Col span={12}>
            <Form.Item
                label="BSB"
                name="bsb"
                rules={[
                  {
                    required: true,
                    message: ERROR_MESSAGES.PAYMENT_BSB_REQUIRED,
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
              </Col>
            <Col span={12}>
              <Form.Item
                label="Account number"
                name="accountNumber"
                rules={[
                  {
                    required: true,
                    message: ERROR_MESSAGES.PAYMENT_ACCOUNT_NUMBER_REQUIRED,
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Alert type="warning" message={ERROR_MESSAGES.VERIFY_PHOTO_WARNING} />
            <Col span={12}>
            <Form.Item
                label="Front photo"
                name="document_front_id"
                rules={[
                  {
                    required: true,
                    message: ERROR_MESSAGES.PROFILE_FRONT_REQUIRED,
                  },
                ]}
              >
                <Input type="file" accept="image/*"  onChange={(e) => backPhotoChange(e, 'front')} />
              </Form.Item>
            </Col>
            <Col span={12}>
            <Form.Item
                label="Back photo"
                name="document_back_id"
                rules={[
                  {
                    required: true,
                    message: ERROR_MESSAGES.PROFILE_BACK_REQUIRED,
                  },
                ]}
              >
                <Input type="file" accept="image/*" onChange={(e) => backPhotoChange(e, 'back')} />
              </Form.Item>
            </Col>
          </Row>
           </>
        }
       
        {!form && (
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Update
            </Button>
          </Form.Item>
        )}
      </Form>
    </div>
  );
}
