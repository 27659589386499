import { Card, List } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { LessonState } from "store/reducers/lesson.reducer";

export default function ClassOutline() {
  const { lesson } = useSelector<RootState, LessonState>(
    (state) => state.lessonState
  );

  return (
    <Card title="CLASS OUTLINE" className="class-outline">
      {lesson.lesson_outlines.length > 0 && lesson.lesson_outlines.map((outline) => (
        <List
          key={outline.id}
          header={
            <div className="text-lg flex items-center">
              <strong>{outline.title}</strong>
            </div>
          }
          split={false}
        >
          {outline.activities.map((item) => (
            <List.Item key={item.title}>
              {/* <Checkbox> */}
              <div className="flex justify-between flex-1 items-center">
                <img src={item.thumbnail.image_s3_url} alt={item.title} width="20" className="mr-2" />
                <div className="flex-1 pr-1">{item.title}</div>
                <div>{item.time.toString().padStart(2, "0")} m</div>
              </div>
              {/* </Checkbox> */}
            </List.Item>
          ))}
        </List>
      ))}
    </Card>
  );
}
