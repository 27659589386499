import { AgeRange } from "./age-range";
import { Attachment } from "./attachment";
import { Book } from "./book";
import { Music } from "./music";
import { Craft, Flashcard } from "./printable";
import { Image } from "./profile";
import { Theme } from "./theme";

export enum LessonStatus {
  STARTED = "Started",
  PAUSED = "Paused",
  ENDED = 'Ended'
}

export type LessonCalendar = {
  id: string;
  classId: string;
  lessonId: string;
  is_prepared: boolean;
  ended_at: string | null;
  lesson_class: Lesson;
  scheduled_at: string;
  isPrepared: boolean;
  status: LessonStatus;
  schedule: string;
  endedAt: string | null;
  createdAt: string;
  updatedAt: string;
  class: {
    id: string;
    classTypeId: string;
    classDuration: number;
    startTime: string;
    ageRange: AgeRange;
    classType: {
      id: string;
      title: string;
      languageCode: string;
      referenceId: string;
      createdBy: string | null;
      updatedBy: string | null;
      createdAt: string;
      updatedAt: string;
      deletedAt: string | null;
    };
  };
};

export type LessonCalendarData = {
  totalRow: number;
  results: Array<LessonCalendar>;
};

export type Lesson = {
  age_group: Array<AgeRange>;
  age_range: AgeRange;
  id: string;
  badge: Image;
  title: { en: string };
  description: string;
  allowTrial: boolean;
  is_ended: boolean;
  duration: number;
  what_to_learn?: string;
  lesson_outlines: Array<{
    id: string;
    title: string;
    activities: Array<{
      id: string;
      title: string;
      description: string;
      time: number;
      thumbnail: Image;
    }>
  }>;
  class_type: {
    title: string
  }
  themeId: string;
  theme: {
    title: string;
  }
  start_datetime: string;
  classSubject: {
    createdAt: string;
    id: string;
    languageCode: string;
    referenceId: string;
    title: string;
    updatedAt: string;
  };
  resources: Array<Resources>;
  classSubjectId: string;
  musics: Array<Music>;
  music: Array<Music>;
  books: Array<Book>;
  crafts: Array<Craft>;
  episodes: Array<{
    createdAt: string;
    createdBy: string | null;
    deletedAt: string | null;
    episodeLesson: {
      createdAt: string | null;
      createdBy: string | null;
      episodeId: string;
      id: string;
      lessonId: string;
      updatedAt: string | null;
      updatedBy: string | null;
      viewOn: number;
    };
    id: string;
    languageCode: string;
    referenceId: string;
    rollingBanner: string;
    seasonId: string;
    thumbnail: Image;
    file: string;
    title: string;
    updatedAt: string;
    updatedBy: string | null;
    youtubeLink: string;
    youtubeVideoId: string;
  }>;
  flashcards: Array<Flashcard>;
  whatToLearn: string | null;
  languageCode: string;
  vocabularyLearned: string | null;
  referenceId: string;
  lessonOutlineId: string;
  ageRangeId: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string | null;
  updatedBy: string | null;
  themes: Theme;
  skill_develop: Array<string>;
  skillDevelops: Array<{
    createdAt: string | null;
    createdBy: string | null;
    id: string;
    languageCode: string;
    lessonId: string;
    referenceId: string;
    title: string;
    updatedAt: string | null;
    updatedBy: string | null;
  }>;
};

export type LessonData = {
  count: number;
  results: Array<Lesson>;
};

export type LessonDetails = {
  lesson: Lesson | null;
  badge?: Image;
  age_group?: AgeRange[];
  title?: string;
  theme?: {
    title: string
  }
  class_subs: Resources[];
  description?: string;
  what_to_learn?: string;
  skill_develop?: Array<string>;
  duration?: number;
  lesson_outlines: Array<{
    id: string;
    title: string;
    activities: Array<{
      id: string;
      time: number;
      thumbnail: Image;
      description: string;
      title: string;
      youtubeLink: string | null;
      youtubeVideoId: string | null;
      videoThumbnail: string | null;
      planSectionLessonId: string;
      languageCode: string;
      referenceId: string;
      attachments: Array<Attachment>;
    }>;
  }>;
  musics: Resources[];
  crafts: Resources[];
  resources: Resources[];
  episodes: Resources[];
  books: Resources[];
  flashcards: Resources[];
};

type Resources = {
  title: string;
  id: string;
  file: string;
  reading_times: number;
  thumbnail: Image;
  resource_type?: {
    en: { title: string }
  }
  resources: Array<{
    title: string;
    is_prepared: boolean;
    id: string;
    file: string;
    reading_times: number;
    thumbnail: Image;
  }>
  flashcards:  Array<{
    title: string;
    is_prepared: boolean;
    id: string;
    file: string;
    reading_times: number;
    thumbnail: Image;
  }>
}

export type Attendances = {
  studentId?: string;
  studentDetail?: any;
  isPresent?: boolean;
  isSummarySent?: boolean;
}