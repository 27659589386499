import { PageHeader, Spin } from "antd";
import LayoutContent from "components/LayoutContent/LayoutContent";
import moment from "moment-timezone";
import { useCallback, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getLessonCalendarAction } from "store/actions/lesson.action";
import queryString from "query-string";
import { useMemo } from "react";
import { RootState } from "store/reducer";
import { LessonState } from "store/reducers/lesson.reducer";
import { CustomEventWrapper } from "components/CustomEvent/CustomEvent";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { timezone } from "helpers/time";

const localizer = momentLocalizer(moment);

export default function LessonCalendar() {
  // React router
  const { search } = useLocation();
  const { replace, push } = useHistory();

  // React redux
  const dispatch = useDispatch();
  const { lessonCalendar, getLessonCalendarLoading } = useSelector<
    RootState,
    LessonState
  >((state) => state.lessonState);

  const query: any = useMemo(() => queryString.parse(search), [search]);
  const events = lessonCalendar.results.map((lesson) => {
    const {
      lesson_class: {duration },
      scheduled_at
    } = lesson;
    const startString = moment(new Date(scheduled_at + 'z')).tz(timezone)
    const end = moment(startString).add(duration, "minutes");
    return {
      start: startString.toDate(),
      end: end.toDate(),
      title: `${lesson.lesson_class.class_type.title} (${lesson.lesson_class.age_range.title})`,
      resource: {
        lesson,
      },
    };
  });

  const fetchData = useCallback(() => {
    try {
      dispatch(getLessonCalendarAction({ ...query, no_pagination: true }));
    } catch (error) {
      //
    }
  }, [dispatch, query]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <PageHeader
        title="My lesson calendar"
        onBack={() => replace("/my-classes")}
      />

      <LayoutContent>
        <Spin spinning={getLessonCalendarLoading}>
          <Calendar
            popup
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            selectable={false}
            onSelectEvent={(event) => {
              push(
                `/my-classes/${event.resource.lesson.lesson_class.id}?lessonId=${event.resource.lesson.id}`
              );
            }}
            components={{
              eventWrapper: CustomEventWrapper,
            }}
            eventPropGetter={(event) => {
              const lesson = event.resource.lesson;

              let className = "";
              if (lesson.is_prepared && !lesson.ended_at) {
                className = "prepared";
              } else if (lesson.ended_at && lesson.status === 'Started') {
                className = "ended";
              } else if (lesson.status === 'Started'){
                className = "started";
              }

              return {
                className: className,
              };
            }}
            views={{ week: true, month: true }}
            style={{ height: "70vh", minHeight: 500 }}
          />
        </Spin>
        <div className="flex mt-3">
          <div className="flex"> <div style={{background: 'red', width: '20px', height: '20px'}} className="mr-2"></div> Prepared</div>
          <div className="flex mx-4"> <div></div> Not start</div>
          <div className="flex mr-2"> <div style={{background: '#dbc529', width: '20px', height: '20px'}} className="mr-2"></div> Started</div>
          <div className="flex"> <div style={{background: '#138c38', width: '20px', height: '20px'}} className="mr-2"></div> Ended</div>
        </div>
      </LayoutContent>
    </div>
  );
}
