import {
  Avatar,
  Divider,
  Layout,
  Space,
  Spin,
  Menu,
  Dropdown,
  Button,
  Modal,
  Form,
  Input,
  Alert,
  Badge,
  Popover,
  Empty,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { signOutAction, updatePasswordAction } from "store/actions/auth.action";
import { RootState } from "store/reducer";
import { AuthState } from "store/reducers/auth.reducer";
import { ERROR_MESSAGES } from "constants/content";
import { PASSWORD_PATTERN } from "constants/patterns";
import { validateConfirmPassword } from "helpers/validation";
import { BellOutlined } from '@ant-design/icons';
import { NotifyState } from "store/reducers/notification.reducer";
import axios from "axios";
import { TEACHER_URL } from "constants/urls";
import { getNotificationsAction, getNotificationsUnreadAction, makeReadAllNoticeAction } from "store/actions/notification.action";

const { Header } = Layout;

enum MenuKey {
  PROFILE = "profile",
  UPDATE_PASSWORD = "update-password",
  REVIEWS = "reviews",
  GUIDE = "guide",
}

export default function PortalNavbar() {
  // State
  const [updatePwdVisibility, setUpdatePwdVisibility] = useState<boolean>(false);
  const { notifyData, notifyTotal } = useSelector<RootState, NotifyState>(state => state.notifyStage);

  // React router
  const { push } = useHistory();

  // React redux
  const dispatch = useDispatch();
  const {
    user,
    getProfileLoading,
    updatePasswordLoading,
    updatePasswordError,
  } = useSelector<RootState, AuthState>((state) => state.authState);

  const [updatePwdForm] = useForm();

  function handleMenuClick(event: { key: string }) {
    if (event.key === MenuKey.PROFILE) {
      push("/profile");
    } else if (event.key === MenuKey.UPDATE_PASSWORD) {
      setUpdatePwdVisibility(true);
    } else if (event.key === MenuKey.REVIEWS) {
      push("/my-reviews");
    }
  }

  async function handleUpdatePassword(values: any) {
    try {
      await dispatch(updatePasswordAction(values.oldPassword, values.newPassword));
      Modal.success({
        title: "Successfully",
        content: "Your password has been updated, you need to login again.",
        onOk: async () => {
          try {
            await dispatch(signOutAction());
          } catch (error) {
            //
          }
        },
      });
    } catch (error) {
      //
    }
  }



  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key={MenuKey.PROFILE}>Account details</Menu.Item>
      <Menu.Item key={MenuKey.UPDATE_PASSWORD}>Update password</Menu.Item>
      <Menu.Item key={MenuKey.REVIEWS}>My reviews</Menu.Item>
    </Menu>
  );

  let notifications = (
    <Menu onClick={handleNotifyClick} style={{width: '400px'}}>
      {
        notifyData.results.length > 0 ? notifyData.results.map(item => (
          <Menu.Item key={item.id} danger={!item.is_read}>{item.body}</Menu.Item>
        )) : <Empty description="No notifications found" />
      }
    </Menu>
  )
  const notifyTitle = (
    <div className="flex justify-between m-2">
      <span>Notifications</span>
      <span className="cursor-pointer" onClick={handlerMakeReadAll}>Mark all as read</span>
    </div>
  )

  function handleNotifyClick(e: any) {
    const url = `${TEACHER_URL}/notifications/${e.key}/`
    axios.put(url, {}).then(data => {
      dispatch(getNotificationsAction())
    })
  }

  function handlerMakeReadAll() {
    Promise.all([
      dispatch(makeReadAllNoticeAction()),
      dispatch(getNotificationsAction()),
      dispatch(getNotificationsUnreadAction())
    ])
  }


  return (
    <div id="navbar">
      <Header className="bg-secondary shadow-md">
        <div className="flex items-center">
          <div className="ml-auto"></div>
          <Divider type="vertical" />
          <div className="flex items-center">
            <Spin spinning={getProfileLoading}>
              <Space align="center">
                <Popover placement="bottomLeft" title={notifyTitle} content={notifications} trigger="click">
                  <Badge count={notifyTotal.total_notice} size="small" >
                    <BellOutlined />
                  </Badge>
                </Popover>

                <Dropdown overlay={menu}>
                  <Button type="text">
                    <span>{user ? user?.user.first_name : "John"}</span>
                  </Button>
                </Dropdown>
                <Avatar src={user?.user?.image?.image_s3_url}>{user ? user?.user.first_name : "J"}</Avatar>
              </Space>
            </Spin>
          </div>
        </div>
      </Header>

      <Modal
        visible={updatePwdVisibility}
        title="Update password"
        onOk={updatePwdForm.submit}
        okButtonProps={{ loading: updatePasswordLoading }}
        onCancel={() => setUpdatePwdVisibility(false)}
      >
        <Form
          layout="vertical"
          form={updatePwdForm}
          onFinish={handleUpdatePassword}
          autoComplete="off"
        >
          {updatePasswordError && (
            <Form.Item>
              <Alert type="error" showIcon message={updatePasswordError.message} />
            </Form.Item>
          )}
          <Form.Item
            label="Current password"
            name="oldPassword"
            rules={[
              {
                required: true,
                message: ERROR_MESSAGES.CHANGE_PASSWORD_OLD_PASSWORD_REQUIRED,
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="New password"
            name="newPassword"
            rules={[
              {
                required: true,
                message: ERROR_MESSAGES.CHANGE_PASSWORD_PASSWORD_REQUIRED,
              },
              {
                pattern: PASSWORD_PATTERN,
                message: ERROR_MESSAGES.INVALID_PASSWORD,
              },
            ]}
          >
            <Input.Password autoComplete="new-password" />
          </Form.Item>
          <Form.Item
            label="Confirm password"
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: ERROR_MESSAGES.CHANGE_PASSWORD_CONFIRM_REQUIRED,
              },
              ({ getFieldValue }) => ({
                validator: (_, value?: string) =>
                  validateConfirmPassword(getFieldValue("newPassword"), value),
                message: ERROR_MESSAGES.CHANGE_PASSWORD_NOT_MATCH,
              }),
            ]}
          >
            <Input.Password autoComplete="new-password" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
