import axios, { AxiosResponse } from "axios";
import { TEACHER_URL } from "constants/urls";
import { stringifyParams } from "helpers/params";
import { Action  } from "types/action";
import { LessonOutline } from "types/lesson-outline";

//  ============================================================
//  GET LESSON OUTLINE
//  ============================================================
export const GET_LESSON_OUTLINE_PENDING = "GET_LESSON_OUTLINE_PENDING";
export const GET_LESSON_OUTLINE_SUCCESS = "GET_LESSON_OUTLINE_SUCCESS";
export const GET_LESSON_OUTLINE_FAILURE = "GET_LESSON_OUTLINE_FAILURE";
function getLessonOutlinePending(): Action {
  return {
      type: GET_LESSON_OUTLINE_PENDING
   }
}

function getLessonOutlineSuccess(lessonOutline: Array<LessonOutline>): Action {
  return {
      type: GET_LESSON_OUTLINE_SUCCESS,
      payload: lessonOutline
   }
}

function getLessonOutlineFailure(error: any): Action {
  return {
      type: GET_LESSON_OUTLINE_FAILURE,
      payload: error
   }
}

export function getLessonOutlineAction(query?: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(getLessonOutlinePending());
      const response: AxiosResponse<Array<LessonOutline>> = await axios.get(TEACHER_URL + `/settings/class-types/${stringifyParams(query)}`);
      dispatch(getLessonOutlineSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getLessonOutlineFailure(error));
      return Promise.reject(error)
    }
  }
}