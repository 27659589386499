import {
  Form,
  Button,
  Space,
  Row,
  Col,
  Input,
  DatePicker,
  Divider,
  message,
  Select,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { AuthState } from "store/reducers/auth.reducer";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import moment, { Moment } from "moment";
import PlaceAutocomplete from "components/PlaceAutocomplete/PlaceAutocomplete";
import { LocationResult } from "helpers/location";
import videoplaceholder from "assets/images/video-placeholder.png";
import {
  getProfileAction,
  getProfileProgressAction,
  updateProfileAction,
} from "store/actions/auth.action";
import DialectSelect from "components/DialectSelect/DialectSelect";
import { FormInstance } from "antd";
import PhoneInput from "components/PhoneInput/PhoneInput";
import CountryAutoComplete from "components/CountryAutocomplete/CountryAutocomplete";
import FileInput from "components/FileInput/FileInput";
import { ERROR_MESSAGES } from "constants/content";
import { isValidPhoneNumber } from "react-phone-number-input";
import Plyr from "plyr-react";
import { checkCompletedProfileProgress } from "helpers/profileProgress";
import { getLessonOutlineAction } from "store/actions/lesson-outline.action";
import { LessonOutlineState } from "store/reducers/lesson-outline.reducer";

type ProfileTabProps = {
  form?: FormInstance;
  onUpdateSuccess?: () => void;
};

export default function ProfileTab(props: ProfileTabProps) {
  // React redux
  const dispatch = useDispatch();
  const { user, updateProfileLoading } = useSelector<RootState, AuthState>((state) => state.authState);
  const { lessonOutline } = useSelector<RootState, LessonOutlineState>((state) => state.lessonOutlineState);
  const [videoIntro, setVideoIntro] = useState('')
  const [profileForm] = useForm();
  console.log(lessonOutline);
  useEffect(() => {
    try {
      Promise.all([
        dispatch(getProfileAction()),
        dispatch(getLessonOutlineAction({is_parent: true}))
      ]);
    } catch (error) {
      //
    }
    
  }, [dispatch])

  useEffect(() => {
    if (user) {
      if (user.user.date_of_birth) {
        user.user.date_of_birth = moment(user.user.date_of_birth);
      }
      const form = props.form || profileForm;
      setVideoIntro(user?.intro_video || '')
      const classTypes = user.class_types.map((item) => (item.id))
      const values = {
        ...user,
        first_name: user?.user.first_name,
        last_name: user?.user.last_name,
        phone: user?.user.phone,
        date_of_birth: user?.user.date_of_birth,
        image: user?.image ? user?.image : user?.user?.image?.image_s3_url,
        address: user?.address.address,
        city: user?.address.city,
        state: user?.address.state,
        longitude: user?.address.longitude,
        latitude: user?.address.latitude,
        country: user?.address.country,
        postcode: user?.address.postcode,
        class_types: classTypes
      }
      form.setFieldsValue(values);
    }
  }, [user, profileForm, props.form]);

  function onSelectLocation(location: LocationResult) {
    const form = props.form || profileForm;
    form.setFieldsValue(location);
  }

  async function handleSubmit(values: any) {
    try {
      values.date_of_birth = (values.date_of_birth as Moment).format('YYYY-MM-DD');
      if (values.image.includes('cloudfront.net/')) {
        delete values.image
      }
      if (values.intro_video.includes('amazonaws.com/')) {
        delete values.intro_video
      }
      const body = {
        ...values,
        user: {
          ...values
        },
        address: {...values},
      }
      await dispatch(updateProfileAction(body));

      if (user && checkCompletedProfileProgress(user) < 100) {
        await dispatch(getProfileProgressAction());
      }

      if (props.onUpdateSuccess) {
        props.onUpdateSuccess();
      } else {
        dispatch(getProfileAction())
        message.success("Profile details updated");
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handlerChangeVideo(e: any) {
    if (e) {
      const videoURL = `https://s3-ap-south-1.amazonaws.com/adam-dev-media/${e}`
      setVideoIntro(videoURL)
    }
  }

  return (
    <div>
      <Form
        layout="vertical"
        form={props.form || profileForm}
        onFinish={handleSubmit}
      >
        <Row gutter={32} justify="space-between">
          <Col xs={24} xl={6}>
            <Form.Item
              label="Profile picture"
              name="image"
              rules={[
                {
                  required: true,
                  message: ERROR_MESSAGES.PROFILE_AVATAR_REQUIRED,
                },
              ]}
            >
              <FileInput accept="image/*" listType="picture-card" />
            </Form.Item>
          </Col>

          <Col xs={24} xl={6}>
            <Form.Item
              shouldUpdate={(prev, values) =>
                prev.introView !== values.intro_video
              }
              noStyle
            >
                <Form.Item>
                  <div style={{ maxWidth: 400 }}>
                    {user && videoIntro ? (
                      <Plyr
                        options={{
                          ratio: "16:9",
                          controls: [
                            "play-large",
                            "play",
                            "progress",
                            "fullscreen",
                          ],
                        }}
                        source={{
                          type: "video",
                          sources: [{ src: videoIntro }],
                        }}
                      />
                    ) : (
                      <img src={videoplaceholder} alt="" width="100%" />
                    )}
                  </div>
                </Form.Item>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={32}>
          <Col xs={24} xl={6}>
            <Form.Item
              label="First name"
              name="first_name"
              rules={[
                {
                  required: true,
                  message: ERROR_MESSAGES.PROFILE_FIRST_NAME_REQUIRED,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Last name"
              name="last_name"
              rules={[
                {
                  required: true,
                  message: ERROR_MESSAGES.PROFILE_LAST_NAME_REQUIRED,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Phone number"
              name="phone"
              rules={[
                {
                  required: true,
                  message: ERROR_MESSAGES.PROFILE_PHONE_REQUIRED,
                },
                {
                  validator: (_, value) => {
                    if (!value || isValidPhoneNumber(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(ERROR_MESSAGES.INVALID_PHONE_NUMBER);
                  },
                },
              ]}
            >
              <PhoneInput />
            </Form.Item>
            <Form.Item
              label="Date of birth (older than 18 years old)"
              name="date_of_birth"
              rules={[
                {
                  required: true,
                  message: ERROR_MESSAGES.PROFILE_DOB_REQUIRED,
                },
              ]}
            >
              <DatePicker
                className="w-full"
                format="MMMM Do, YYYY"
                disabledDate={(date) => moment().diff(date, "years") <= 18}
                defaultPickerValue={moment().subtract(19, "years")}
              />
            </Form.Item>
          </Col>
          <Col xs={24} xl={6}>
            <Form.Item
              label="Nationality"
              name="nationality"
              rules={[
                {
                  required: true,
                  message: ERROR_MESSAGES.PROFILE_NATIONALITY_REQUIRED,
                },
              ]}
            >
              <CountryAutoComplete />
            </Form.Item>
            <Form.Item
              label="Bio"
              name="bio"
              rules={[
                {
                  required: true,
                  message: ERROR_MESSAGES.PROFILE_BIO_REQUIRED,
                },
              ]}
            >
              <Input.TextArea rows={10} />
            </Form.Item>
          </Col>

          <Col xs={24} xl={6}>
            <Form.Item
              label="Address"
              name="address"
              rules={[
                {
                  required: true,
                  message: ERROR_MESSAGES.PROFILE_ADDRESS_REQUIRED,
                },
              ]}
            >
              <PlaceAutocomplete onSelect={onSelectLocation} />
            </Form.Item>

            <Form.Item
              label="City"
              name="city"
              rules={[
                {
                  required: true,
                  message: ERROR_MESSAGES.PROFILE_CITY_REQUIRED,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="State"
              name="state"
              rules={[
                {
                  required: true,
                  message: ERROR_MESSAGES.PROFILE_STATE_REQUIRED,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Postcode"
              name="postcode"
              rules={[
                {
                  required: true,
                  message: ERROR_MESSAGES.PROFILE_STATE_REQUIRED,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Country"
              name="country"
              rules={[
                {
                  required: true,
                  message: ERROR_MESSAGES.PROFILE_COUNTRY_REQUIRED,
                },
              ]}
            >
              <CountryAutoComplete />
            </Form.Item>
            <Form.Item name="longitude" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="latitude" hidden>
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} xl={6}>
            <Form.Item
              label="Video intro"
              name="intro_video"
              rules={[
                {
                  required: true,
                  message: ERROR_MESSAGES.PROFILE_VIDEO_REQUIRED,
                },
              ]}
            >
              <FileInput accept="video/mp4" onChange={handlerChangeVideo} />
            </Form.Item>
            <Form.Item
              label="Class Types"
              name="class_types"
              rules={[
                {
                  required: true,
                  message: ERROR_MESSAGES.CLASS_TYPES_REQUIRED,
                },
              ]}
            >
              <Select mode="multiple" allowClear placeholder="Please select class types">
                  {lessonOutline.results.map((type) => (
                    <Select.Option key={type.id} value={type.id}>
                      {type.title}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Spoken dialect"
              name="dialect"
              rules={[
                {
                  required: true,
                  message: ERROR_MESSAGES.PROFILE_DIALECT_REQUIRED,
                },
              ]}
            >
              <DialectSelect />
            </Form.Item>
            <Form.Item label="Spoken languages">
              <Form.List
                name="spoken_language"
                rules={[
                  {
                    validator: async (_, languages) => {
                      if (!languages || languages.length < 1) {
                        return Promise.reject(
                          new Error(ERROR_MESSAGES.PROFILE_LANGUAGES_REQUIRED)
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <Space direction="vertical">
                    {fields.map((field) => (
                      <Form.Item key={field.key} required={false}>
                        <Space>
                          <Form.Item
                            name={[field.name, "language"]}
                            noStyle
                            rules={[
                              {
                                required: true,
                                message:
                                  ERROR_MESSAGES.PROFILE_LANGUAGE_REQUIRED,
                              },
                            ]}
                          >
                            <Input placeholder="Language" autoFocus />
                          </Form.Item>
                          <Form.Item
                            name={[field.name, "level"]}
                            noStyle
                            rules={[
                              {
                                required: true,
                                message: ERROR_MESSAGES.PROFILE_LEVEL_RQUIRED,
                              },
                            ]}
                          >
                            <Select style={{ width: 120 }} allowClear>
                              <Select.Option value="Native">Native</Select.Option>
                              <Select.Option value="Fluent">Fluent</Select.Option>
                              <Select.Option value="Advanced">Advanced</Select.Option>
                            </Select>
                            {/* <Input placeholder="Level" /> */}
                          </Form.Item>

                          <MinusCircleOutlined
                            onClick={() => remove(field.name)}
                          />
                        </Space>
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        icon={<PlusOutlined />}
                        onClick={() => add()}
                      />
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </Space>
                )}
              </Form.List>
            </Form.Item>
          </Col>
        </Row>

        {!props.form && (
          <>
            <Divider />

            <Form.Item>
              <Button
                loading={updateProfileLoading}
                type="primary"
                htmlType="submit"
              >
                Update
              </Button>
            </Form.Item>
          </>
        )}
      </Form>
    </div>
  );
}
