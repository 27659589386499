import { Card, List, Avatar } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { getYearOld } from "helpers/date";
import { useDispatch, useSelector } from "react-redux";
import { toggleAttendanceAction } from "store/actions/class-lesson.action";
import { RootState } from "store/reducer";
import { ClassLessonState } from "store/reducers/class-lesson.reducer";

type ClassLessonAttendanceProps = {
  classId: string;
  lessonId: string;
};

export default function ClassLessonAttendance(
  props: ClassLessonAttendanceProps
) {
  const dispatch = useDispatch();
  const { attendeesLesson } = useSelector<RootState, ClassLessonState>(
    (state) => state.classLessonState
  );
  function toggleAttendance(studentId: string, e: any) {
    const {checked} = e.target
    const body = {is_attended: checked}
    dispatch(toggleAttendanceAction(props.lessonId, studentId, body));
  }

  return (
    <Card
      title="LESSON ATTENDANCE"
      className="lesson-attendees"
      extra="Present?"
    >
      <List
        split={false}
        dataSource={attendeesLesson.results}
        renderItem={(attendee) => (
          <List.Item
            actions={[
              <Checkbox
                key="present"
                defaultChecked={attendee.is_attended}
                onChange={(e) => toggleAttendance(attendee.student.id, e)}
              />,
            ]}
          >
            <Avatar src={attendee.student.user.image?.image_s3_url}>
              {attendee.student.user.first_name} {attendee.student.user.last_name}
            </Avatar>
            <div className="flex-1 px-2">{attendee.student.user.first_name} {attendee.student.user.last_name}</div>
            <div className="px-2">
              {getYearOld(attendee.student.user.date_of_birth)} year-old
            </div>
          </List.Item>
        )}
      ></List>
    </Card>
  );
}
