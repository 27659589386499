import { useHistory, useLocation } from "react-router-dom";

export function useGoBack() {
  const { pathname } = useLocation();
  const { replace } = useHistory();

  const lastSplashIndex = pathname.lastIndexOf("/");
  const backPath = pathname.slice(0, lastSplashIndex);

  function goBack() {
    replace(backPath);
  }

  return { goBack };
}