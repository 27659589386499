import { Alert, Button, Checkbox, Col, Form, Input, Modal, Row } from "antd";
import { ERROR_MESSAGES } from "constants/content";
import { PASSWORD_PATTERN } from "constants/patterns";
import { validateConfirmPassword } from "helpers/validation";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  completeSignUpAction,
  resentEmailCodeAction,
  signOutAction,
  signUpAction,
} from "store/actions/auth.action";
import { RootState } from "store/reducer";
import { AuthState } from "store/reducers/auth.reducer";
import AuthContainer from "./AuthContainer";
import queryString from "query-string";
import PhoneInput from "components/PhoneInput/PhoneInput";
import { isValidPhoneNumber } from "react-phone-number-input";

export default function SignUpPage() {
  // get query param
  const { search } = useLocation();
  const query: any = useMemo(() => queryString.parse(search), [search]);

  // States
  const [isCodeSent, setIsCodeSent] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('Verification code has been sent to your email address');

  // React router
  const { goBack, replace } = useHistory();

  // React redux
  const dispatch = useDispatch();
  const { verifyResult, signUpLoading, completeSignUpLoading } = useSelector<
    RootState,
    AuthState
  >((state) => state.authState);


  useEffect(() => {
    if (!verifyResult) {
      goBack();
    }
  }, [verifyResult, goBack]);

  async function handleSubmit(values: any) {
    try {
      if (verifyResult) {
        if (!isCodeSent) {
          const { first_name, last_name, date_of_birth, phone } = values;
          const language = 'en'
          await dispatch(
            signUpAction({ ...verifyResult, first_name, last_name, date_of_birth, phone, language }, values.password)
          );
          setIsCodeSent(true);
        } else {
          const { password } = values;
            await dispatch(
              completeSignUpAction(
                {
                  ...verifyResult,
                  password,
                },
                values.verifyCode
              )
            );

            Modal.success({
              title: "Successfully",
              content: "Sign up successfully, you can login now",
              onOk: async () => {
                await dispatch(signOutAction());
                replace(`/auth/login?email=${verifyResult.email}`);
              },
            });
        }
      }
    } catch (error) {
      //
    }
  }

  async function resendEmailCoder() {
    if (verifyResult) {
      await dispatch(resentEmailCodeAction(verifyResult.id))
      setMessage('New verification code has been sent to your email address, please check')
    }
  }

  return (
    <AuthContainer title="Sign up">
      <Form layout="vertical" autoComplete="off" onFinish={handleSubmit}>
        {isCodeSent && (
          <Form.Item>
            <Alert
              type="success"
              showIcon
              message={message}
            />
          </Form.Item>
        )}

        {!isCodeSent ? (
          <>
            <Form.Item label="Email">
              <Input value={verifyResult?.email} disabled />
            </Form.Item>
            <Row gutter={24}>
              <Col xs={24} md={12}>
                <Form.Item
                  label="First name"
                  name="first_name"
                  initialValue={query.first_name}
                  rules={[
                    {
                      required: true,
                      message: ERROR_MESSAGES.PROFILE_FIRST_NAME_REQUIRED,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Last name"
                  name="last_name"
                  initialValue={query.last_name}
                  rules={[
                    {
                      required: true,
                      message: ERROR_MESSAGES.PROFILE_LAST_NAME_REQUIRED,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
                  label="Phone number"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: ERROR_MESSAGES.PROFILE_PHONE_REQUIRED,
                    },
                    {
                      validator: (_, value) => {
                        if (!value || isValidPhoneNumber(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(ERROR_MESSAGES.INVALID_PHONE_NUMBER);
                      },
                    },
                  ]}
                >
                  <PhoneInput />
    
                </Form.Item>
{/*             

            <Row gutter={24}>
              <Col xs={24} md={12}>
                <Form.Item
                  label="City"
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: ERROR_MESSAGES.SIGN_UP_CITY_REQUIRED,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Country"
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: ERROR_MESSAGES.SIGN_UP_COUNTRY_REQUIRED,
                    },
                  ]}
                >
                  <CountryAutoComplete  />
                </Form.Item>
              </Col>
            </Row> */}

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: ERROR_MESSAGES.SIGN_UP_PASSWORD_REQUIRED,
                },
                {
                  pattern: PASSWORD_PATTERN,
                  message: ERROR_MESSAGES.INVALID_PASSWORD,
                },
              ]}
            >
              <Input.Password autoComplete="new-password" />
            </Form.Item>

            <Form.Item
              label="Confirm password"
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: ERROR_MESSAGES.SIGN_UP_CONFIRM_PASSWORD_REQUIRED,
                },
                ({ getFieldValue }) => ({
                  validator: (_, value: string) =>
                    validateConfirmPassword(getFieldValue("password"), value),
                  message: ERROR_MESSAGES.SIGN_UP_PASSWORD_NOT_MATCH,
                }),
              ]}
            >
              <Input.Password autoComplete="new-password" />
            </Form.Item>

            <Form.Item
              rules={[
                {
                  required: true,
                  message: ERROR_MESSAGES.SIGN_UP_NOT_AGREE,
                },
              ]}
              name="agree"
              valuePropName="checked"
            >
              <Checkbox>I agree to the terms & conditions</Checkbox>
            </Form.Item>
          </>
        ) : (
         <>
           <Form.Item
           className="mb-0"
            label="Verification code"
            name="verifyCode"
            rules={[
              {
                required: true,
                message: ERROR_MESSAGES.SIGN_UP_CODE_REQUIRED,
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <label>Dont receive the code?</label>
          <Button type="link" className="p-0 text-success" onClick={resendEmailCoder}>Resend code</Button>
         </>
        )}

        <Form.Item>
          <Button
            loading={signUpLoading || completeSignUpLoading}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </AuthContainer>
  );
}
