import {
  GET_DASHBOARD_FAILURE,
  GET_DASHBOARD_PENDING,
  GET_DASHBOARD_SUCCESS,
  GET_NEXT_WEEK_FAILURE,
  GET_NEXT_WEEK_PENDING,
  GET_NEXT_WEEK_SUCCESS,
} from "store/actions/dashboard.action";
import { Action } from "types/action";
import { Dashboard, NextWeekLesson } from "types/dashboard";

export type DashboardState = {
  // Data
  dashboardData: Dashboard | null;
  nextWeekLessons: NextWeekLesson;
  // Loading
  getDashboardLoading: boolean;
  getNextWeekLoading: boolean;
  // Error
  getDashboardError: any;
  getNextWeekError: any;
};

const initialState: DashboardState = {
  // Data
  dashboardData: null,
  nextWeekLessons: [],
  // Loading
  getDashboardLoading: false,
  getNextWeekLoading: false,
  // Error
  getDashboardError: null,
  getNextWeekError: null,
};

export default function dashboardReducer(
  state = initialState,
  action: Action
): DashboardState {
  switch (action.type) {
    //  ============================================================
    //  GET DASHBOARD
    //  ============================================================
    case GET_DASHBOARD_PENDING:
      return {
        ...state,
        getDashboardLoading: true,
      };
    case GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        getDashboardLoading: false,
        dashboardData: action.payload,
      };
    case GET_DASHBOARD_FAILURE:
      return {
        ...state,
        getDashboardLoading: false,
        getDashboardError: action.payload,
      };
    //  ============================================================
    //  GET NEXT WEEK LESSONS
    //  ============================================================
    case GET_NEXT_WEEK_PENDING:
      return {
        ...state,
        getNextWeekLoading: true,
      };
    case GET_NEXT_WEEK_SUCCESS:
      return {
        ...state,
        getNextWeekLoading: false,
        nextWeekLessons: action.payload,
      };
    case GET_NEXT_WEEK_FAILURE:
      return {
        ...state,
        getNextWeekLoading: false,
        getNextWeekError: action.payload,
      };
    default:
      return { ...state };
  }
}
