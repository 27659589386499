import { Action } from "types/action";
import Cookie from "js-cookie";
import { COOKIE_CONFIG } from "configs/cookie";
import { WEB_SOCKET } from "constants/urls";
import {
  socketConnectedAction,
  socketDisconnectedAction,
  socketMessageAction,
  WS_MESSAGE,
  WS_SEND_MESSAGE,
} from "store/actions/chat.action";

export const socketMiddleware = ({ dispatch, getState }: any) => {
  // Socket declaration must be here to run only once
  let socket: WebSocket | null = null;

  return (next: any) => (action: Action) => {
    if (!socket) {
      const token = Cookie.get(COOKIE_CONFIG.ACCESS_NAME);

      if (token) {
        socket = new WebSocket(WEB_SOCKET + "?token=" + token);
        socket.onopen = function () {
          dispatch(socketConnectedAction());
        };

        socket.onclose = function () {
          dispatch(socketDisconnectedAction());
          socket = null;
        };

        socket.onmessage = function (event) {
          dispatch(socketMessageAction(event.data));
        };
      }
    }

    if (socket && socket.readyState === 1) {
      if (action.type === WS_SEND_MESSAGE) {
        socket.send(JSON.stringify(action.payload));
      } else if (action.type === WS_MESSAGE) {
        return next({
          ...action,
          payload: JSON.parse(action.payload),
        });
      }
    }

    return next(action);
  };
};
