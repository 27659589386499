import { Card, Button, Form, Input, Divider, Upload, Space, Modal } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { ClassLessonState } from "store/reducers/class-lesson.reducer";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import { useEffect } from "react";
import { useForm } from "antd/lib/form/Form";
import { UploadRequestOption } from "rc-upload/lib/interface";
import axios, { AxiosResponse } from "axios";
import { NewUploadResponse } from "types/upload";
import { UPLOAD_URL } from "constants/urls";
import { sendSummaryAction } from "store/actions/class-lesson.action";

type ClassLessonSummaryProps = {
  classId: string;
  lessonId: string;
};

type StudentFileList = {
  [studentId: string]: {
    fileList: Array<UploadFile<any>>;
  };
};

export default function ClassLessonSummary(props: ClassLessonSummaryProps) {
  const [fileList, setFileList] = useState<StudentFileList>({});

  // React redux
  const dispatch = useDispatch();
  const { classLesson, attendeesLesson, sendSummaryLoading } = useSelector<RootState, ClassLessonState>((state) => state.classLessonState);

  const [sumaryForm] = useForm();

  useEffect(() => {
    if (attendeesLesson) {
      const initiialFileList: StudentFileList = {};
      const formValues: any = {
        summaryInfo: [],
      };

      attendeesLesson.results.forEach((student, index) => {
        initiialFileList[student.student.id] = {
          fileList: [],
        };
        formValues.summaryInfo[index] = {
          student: student.student.id,
          notes: "",
        };
      });

      sumaryForm.setFieldsValue(formValues);

      setFileList(initiialFileList);
    }
  }, [classLesson, attendeesLesson, sumaryForm]);

  function handleFileListChange(studentId: string, info: UploadChangeParam) {
    setFileList((prev) => ({
      ...prev,
      [studentId]: {
        fileList: info.fileList,
      },
    }));
  }

  async function customUploadRequest({
    file,
    onSuccess,
    onError,
    onProgress,
  }: UploadRequestOption) {
    let uploadType: "image" | "video" | "" = "";
    const uploadFile = file as File;

    if (uploadFile.type.startsWith("video/")) {
      uploadType = "video";
    } else if (uploadFile.type.startsWith("image/")) {
      uploadType = "image";
    }

    if (uploadType.length === 0) {
      Modal.error({
        title: "Error",
        content: "File is not supported",
      });
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append('type', uploadType)
      const response: AxiosResponse<NewUploadResponse> = await axios.post(`${UPLOAD_URL}`, formData,
        {
          onUploadProgress: (event: ProgressEvent) => {
            const percent = Math.floor((event.loaded / event.total) * 100);
            if (onProgress) {
              onProgress({
                ...event,
                percent,
              });
            }
          },
        }
      );

      const responseData = {
        file: response.data.result,
        name: uploadFile.name,
        file_type: uploadType,
        file_extension: uploadFile.name.slice(uploadFile.name.lastIndexOf(".")),
        thumbnail: response.data.result
      };

      if (onSuccess) {
        onSuccess(
          responseData,
          response.request
        );
      }
    } catch (error) {
      if (onError) {
        onError(error);
      }
    }
  }

  async function handleSubmit(values: any) {
    const bodyArray = (values.summaryInfo as Array<{ studentId: string, notes: string }>).filter(info => info.notes.length > 0).map(
      (info) => {
        const files = fileList[info.studentId].fileList.map(
          (res) => res.response
        );
        const classLessonId = classLesson.lesson_class?.id;

        return {
          ...info,
          files,
          classLessonId,
        };
      }
    );
    await Promise.all(
      bodyArray.map((body) => dispatch(sendSummaryAction(props.lessonId, body)))
    );

    Modal.success({
      title: "Lesson summary sent",
      content:
        "Your lesson summary, notes and images/videos was successfully sent to parents.",
    });
  }

  return (
    <>
      <Card title="Lesson summary">
        <p className="text-md">
          <strong>What we learned</strong>
        </p>
        <p>{classLesson.lesson?.what_to_learn || "No description"}</p>
        <br />

        <Form layout="vertical" onFinish={handleSubmit} form={sumaryForm}>
          <Form.List name="summaryInfo">
            {(fields) =>
              fields.length > 0 &&
              attendeesLesson.results.map((attendance, index) => (
                <div key={index}>
                  <p className="text-xl text-primary">
                    {attendance.student.user.first_name} {attendance.student.user.last_name}
                    {attendance.is_summary ? "(already sent summary)" : ""}
                  </p>
                  <Divider />

                  {!attendance.is_summary && (
                    <>
                      <p>
                        <strong>Add photos or videos</strong>
                      </p>
                      {/* <p className="font-serif">
                        <i>
                          You can send parents photos or videos of their children during
                          the lesson.
                        </i>
                      </p> */}
                      <Form.Item name="files">
                        <Upload
                          fileList={fileList[attendance.student.id].fileList}
                          customRequest={customUploadRequest}
                          onChange={(info) => handleFileListChange(attendance.student.id, info)}
                          accept="image/*,video/*"
                        >
                          <Button icon={<UploadOutlined />}>Upload files</Button>
                        </Upload>
                      </Form.Item>

                      <br />
                      <br />

                      <Form.Item
                        name={[fields[index].name, "studentId"]}
                        hidden
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        name={[fields[index].name, "notes"]}
                        rules={[
                          {
                            required: true,
                            message: "Notes is required",
                          },
                        ]}
                        label={<strong>Add any comments or notes</strong>}
                      >
                        <Input.TextArea rows={5} />
                      </Form.Item>
                    </>
                  )}
                </div>
              ))
            }
          </Form.List>

          <Divider />
          {
            attendeesLesson.results.length > 0 && (
              <Form.Item>
                <Space>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={sendSummaryLoading}
                  >
                    Send summary
                  </Button>
                </Space>
              </Form.Item>
            )
          }
        </Form>
      </Card>
    </>
  );
}
