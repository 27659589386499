type PlayIconProps = {
  size?: number
}

export default function PlayIcon({ size = 30 }: PlayIconProps) {
  return (
    <svg
      id="Group_10234"
      data-name="Group 10234"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16.656 16.656"
    >
      <circle
        id="Ellipse_75"
        data-name="Ellipse 75"
        cx="8.328"
        cy="8.328"
        r="8.328"
        fill="#f1b24d"
      />
      <path
        id="Path_13"
        data-name="Path 13"
        d="M83.917,80.547h-.038c0,.9,0,1.8,0,2.7.009,1.4.407,1.658,1.62,1.019,1.817-.959,3.624-1.937,5.434-2.91a3.236,3.236,0,0,0,.5-.31.767.767,0,0,0-.022-1.335,3.108,3.108,0,0,0-.512-.293c-1.842-.91-3.682-1.824-5.53-2.723-.979-.476-1.418-.21-1.45.892C83.9,78.575,83.917,79.561,83.917,80.547Z"
        transform="translate(-78.754 -71.75)"
        fill="#fef7ed"
      />
    </svg>
  );
}
