import { Statistic, Row, Col, Card } from "antd";
import {
  CopyOutlined,
  CarryOutOutlined,
  BookOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { StudentState } from "store/reducers/student.reducer";

export default function LearningProgress() {

  // React router
  const { pathname } = useLocation();

  // React redux
  const { studentProfile } = useSelector<RootState, StudentState>(state => state.studentState);
  return (
    <div className="p-4">
      <Row gutter={24}>
        <Col xs={24} lg={8}>
          <Card bordered={false} className="rounded shadow-lg">
            <Statistic
              title={
                <div className="flex justify-between">
                  <span>Lessons completed</span>
                  <Link to={`${pathname}/lessons-completed?classId=${studentProfile?.classes.id}`} >View all</Link>
                </div>
              }
              prefix={<CopyOutlined />}
              value={studentProfile?.lessons_completed}
            />
          </Card>
        </Col>
        <Col xs={24} lg={8}>
          <Card bordered={false} className="rounded shadow-lg">
            <Statistic
              title={
                <div className="flex justify-between">
                  <span>Vocabulary learned</span>
                  <Link to={`${pathname}/vocabulary-learned`}>View all</Link>
                </div>
              }
              prefix={<CarryOutOutlined />}
              value={studentProfile?.vocabulary_learned}
            />
          </Card>
        </Col>
        <Col xs={24} lg={8}>
          <Card bordered={false} className="rounded shadow-lg">
            <Statistic
              title={
                <div className="flex justify-between">
                  <span>Books read</span>
                  <Link to={`${pathname}/books-read`}>View all</Link>
                </div>
              }
              prefix={<BookOutlined />}
              value={studentProfile?.books_read}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}
