import axios, { AxiosResponse } from "axios";
import { TEACHER_URL } from "constants/urls";
import { stringifyParams } from "helpers/params";
import { Action } from "types/action";
import { MusicResourceData, WhatToListen } from "types/music";

//  ============================================================
//  GET MUSIC
//  ============================================================
export const GET_MUSIC_PENDING = "GET_MUSIC_PENDING";
export const GET_MUSIC_SUCCESS = "GET_MUSIC_SUCCESS";
export const GET_MUSIC_FAILURE = "GET_MUSIC_FAILURE";
function getMusicPending(): Action {
  return {
      type: GET_MUSIC_PENDING
   }
}

function getMusicSuccess(music: MusicResourceData): Action {
  return {
      type: GET_MUSIC_SUCCESS,
      payload: music
   }
}

function getMusicFailure(error: any): Action {
  return {
      type: GET_MUSIC_FAILURE,
      payload: error
   }
}

export function getMusicAction(query?: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(getMusicPending());
      const response: AxiosResponse<MusicResourceData> = await axios.get(`${TEACHER_URL}/musics/${stringifyParams(query)}`);
      dispatch(getMusicSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getMusicFailure(error));
      return Promise.reject(error)
    }
  }
}

//  ============================================================
//  GET WHAT TO LISTEN
//  ============================================================
export const GET_WHAT_LISTEN_PENDING = "GET_WHAT_LISTEN_PENDING";
export const GET_WHAT_LISTEN_SUCCESS = "GET_WHAT_LISTEN_SUCCESS";
export const GET_WHAT_LISTEN_FAILURE = "GET_WHAT_LISTEN_FAILURE";
function getWhatListenPending(): Action {
  return {
      type: GET_WHAT_LISTEN_PENDING
   }
}

function getWhatListenSuccess(whatToListen: WhatToListen): Action {
  return {
      type: GET_WHAT_LISTEN_SUCCESS,
      payload: whatToListen
   }
}

function getWhatListenFailure(error: any): Action {
  return {
      type: GET_WHAT_LISTEN_FAILURE,
      payload: error
   }
}

export function getWhatListenAction(query?: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(getWhatListenPending());
      const response: AxiosResponse<WhatToListen> = await axios.get(`${TEACHER_URL}/musics/what-to-listen/${stringifyParams(query)}`);
      dispatch(getWhatListenSuccess({results: response.data.results, filter: query.since}));
      return Promise.resolve();
    } catch (error) {
      dispatch(getWhatListenFailure(error));
      return Promise.reject(error)
    }
  }
}