import axios, { AxiosResponse } from "axios";
import { AGE_RANGE_URL, TEACHER_URL } from "constants/urls";
import { stringifyParams } from "helpers/params";
import { Action } from "types/action";
import { AgeRangeData, ClassSize } from "types/age-range";


//  ============================================================
//  GET AGE RANGES
//  ============================================================
export const GET_AGE_RANGES_PENDING = "GET_AGE_RANGES_PENDING";
export const GET_AGE_RANGES_SUCCESS = "GET_AGE_RANGES_SUCCESS";
export const GET_AGE_RANGES_FAILURE = "GET_AGE_RANGES_FAILURE";
function getAgeRangesPending(): Action {
  return {
      type: GET_AGE_RANGES_PENDING
   }
}

function getAgeRangesSuccess(ageRangeData: AgeRangeData): Action {
  return {
      type: GET_AGE_RANGES_SUCCESS,
      payload: ageRangeData
   }
}

function getAgeRangesFailure(error: any): Action {
  return {
      type: GET_AGE_RANGES_FAILURE,
      payload: error
   }
}

export function getAgeRangesAction(query?: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(getAgeRangesPending());
      const response: AxiosResponse<AgeRangeData> = await axios.get(AGE_RANGE_URL + stringifyParams(query));
      dispatch(getAgeRangesSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getAgeRangesFailure(error));
      return Promise.reject(error)
    }
  }
}

//  ============================================================
//  GET CLASS SIZE
//  ============================================================
export const GET_CLASS_SIZE_PENDING = "GET_CLASS_SIZE_PENDING";
export const GET_CLASS_SIZE_SUCCESS = "GET_CLASS_SIZE_SUCCESS";
export const GET_CLASS_SIZE_FAILURE = "GET_CLASS_SIZE_FAILURE";
function getClassSizePending(): Action {
  return {
      type: GET_CLASS_SIZE_PENDING
   }
}

function getClassSizeSuccess(classSize: ClassSize): Action {
  return {
      type: GET_CLASS_SIZE_SUCCESS,
      payload: classSize
   }
}

function getClassSizeFailure(error: any): Action {
  return {
      type: GET_CLASS_SIZE_FAILURE,
      payload: error
   }
}

export function getClassSizeAction(ageRangeReferenceId: string) {
  return async (dispatch: Function) => {
    try {
      dispatch(getClassSizePending());
      const response: AxiosResponse<ClassSize> = await axios.get(TEACHER_URL + "/class-size/by-age-range/" + ageRangeReferenceId)
      dispatch(getClassSizeSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getClassSizeFailure(error));
      return Promise.reject(error)
    }
  }
}