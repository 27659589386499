import axios, { AxiosResponse } from "axios";
import { STUDENT_URL, TEACHER_URL } from "constants/urls";
import { stringifyParams } from "helpers/params";
import { Action } from "types/action";
import { BookReadData, LessonCompletedData, ListGallery, StudentData, StudentProfile, VocabularyLearnedData } from "types/student";

//  ============================================================
//  GET STUDENTS
//  ============================================================
export const GET_STUDENTS_PENDING = "GET_STUDENTS_PENDING";
export const GET_STUDENTS_SUCCESS = "GET_STUDENTS_SUCCESS";
export const GET_STUDENTS_FAILURE = "GET_STUDENTS_FAILURE";
function getStudentsPending(): Action {
  return {
    type: GET_STUDENTS_PENDING,
  };
}

function getStudentsSuccess(studentData: { data: StudentData, status: "active" | "past" }): Action {
  return {
    type: GET_STUDENTS_SUCCESS,
    payload: studentData,
  };
}

function getStudentsFailure(error: any): Action {
  return {
    type: GET_STUDENTS_FAILURE,
    payload: error,
  };
}

export function getStudentsAction(query: any, status: "active" | "past") {
  return async (dispatch: Function) => {
    try {
      dispatch(getStudentsPending());
      const response: AxiosResponse<StudentData> = await axios.get(
        `${TEACHER_URL}/students/${stringifyParams(query)}`
      );
      dispatch(getStudentsSuccess({ data: response.data, status }));
      return Promise.resolve();
    } catch (error) {
      dispatch(getStudentsFailure(error));
      return Promise.reject(error);
    }
  };
}

//  ============================================================
//  GET STUDENT PROFILE
//  ============================================================
export const GET_STUDENT_PROFILE_PENDING = "GET_STUDENT_PROFILE_PENDING";
export const GET_STUDENT_PROFILE_SUCCESS = "GET_STUDENT_PROFILE_SUCCESS";
export const GET_STUDENT_PROFILE_FAILURE = "GET_STUDENT_PROFILE_FAILURE";
function getStudentProfilePending(): Action {
  return {
    type: GET_STUDENT_PROFILE_PENDING,
  };
}

function getStudentProfileSuccess(student: StudentProfile): Action {
  return {
    type: GET_STUDENT_PROFILE_SUCCESS,
    payload: student,
  };
}

function getStudentProfileFailure(error: any): Action {
  return {
    type: GET_STUDENT_PROFILE_FAILURE,
    payload: error,
  };
}

export function getStudentProfileAction(studentId: string) {
  return async (dispatch: Function) => {
    try {
      dispatch(getStudentProfilePending());
      const response: AxiosResponse<StudentProfile> = await axios.get(STUDENT_URL + `/${studentId}/`);
      dispatch(getStudentProfileSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getStudentProfileFailure(error));
      return Promise.reject(error);
    }
  };
}

//  ============================================================
//  ADD STUDENT MEDIA
//  ============================================================
export const ADD_STUDENT_MEDIA_PENDING = "ADD_STUDENT_MEDIA_PENDING";
export const ADD_STUDENT_MEDIA_SUCCESS = "ADD_STUDENT_MEDIA_SUCCESS";
export const ADD_STUDENT_MEDIA_FAILURE = "ADD_STUDENT_MEDIA_FAILURE";
function addStudentMediaPending(): Action {
  return {
    type: ADD_STUDENT_MEDIA_PENDING,
  };
}

function addStudentMediaSuccess(): Action {
  return {
    type: ADD_STUDENT_MEDIA_SUCCESS,
  };
}

function addStudentMediaFailure(error: any): Action {
  return {
    type: ADD_STUDENT_MEDIA_FAILURE,
    payload: error,
  };
}

export function addStudentMediaAction(studentId: string, body: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(addStudentMediaPending());
      await axios.post(TEACHER_URL + `/albums/${studentId}/gallery/`, body);
      dispatch(addStudentMediaSuccess());
      return Promise.resolve();
    } catch (error) {
      dispatch(addStudentMediaFailure(error));
      return Promise.reject(error);
    }
  };
}


//  ============================================================
//  GET list Gallery
//  ============================================================
export const GET_GALLERY_PENDING = "GET_GALLERY_PENDING";
export const GET_GALLERY_SUCCESS = "GET_GALLERY_SUCCESS";
export const GET_GALLERY_FAILURE = "GET_GALLERY_FAILURE";
function getGalleryStudentPending(): Action {
  return {
      type: GET_GALLERY_PENDING
   }
}

function getGalleryStudentSuccess(gallery: ListGallery): Action {
  return {
      type: GET_GALLERY_SUCCESS,
      payload: gallery
   }
}

function getGalleryStudentFailure(error: any): Action {
  return {
      type: GET_GALLERY_FAILURE,
      payload: error
   }
}

export function getGalleryStudentAction(studentId: string) {
  return async (dispatch: Function) => {
    try {
      dispatch(getGalleryStudentPending());
      const response: AxiosResponse<ListGallery> = await axios.get(`${TEACHER_URL}/albums/${studentId}/gallery/`);
      console.log(response);
      dispatch(getGalleryStudentSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getGalleryStudentFailure(error));
      return Promise.reject(error)
    }
  }
}

//  ============================================================
//  GET LESSON COMPLETED
//  ============================================================
export const GET_LESSON_COMPLETED_PENDING = "GET_LESSON_COMPLETED_PENDING";
export const GET_LESSON_COMPLETED_SUCCESS = "GET_LESSON_COMPLETED_SUCCESS";
export const GET_LESSON_COMPLETED_FAILURE = "GET_LESSON_COMPLETED_FAILURE";
function getLessonCompletedPending(): Action {
  return {
      type: GET_LESSON_COMPLETED_PENDING
   }
}

function getLessonCompletedSuccess(lessonCompleted: LessonCompletedData): Action {
  return {
      type: GET_LESSON_COMPLETED_SUCCESS,
      payload: lessonCompleted
   }
}

function getLessonCompletedFailure(error: any): Action {
  return {
      type: GET_LESSON_COMPLETED_FAILURE,
      payload: error
   }
}

export function getLessonCompletedAction(studentId: string, query: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(getLessonCompletedPending());
      const response: AxiosResponse<LessonCompletedData> = await axios.get(`${STUDENT_URL}/${studentId}/lesson-complete/${stringifyParams(query)}`);
      dispatch(getLessonCompletedSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getLessonCompletedFailure(error));
      return Promise.reject(error)
    }
  }
}

//  ============================================================
//  GET VOCABULARY LEARNED
//  ============================================================
export const GET_VOCABULARY_LEARNED_PENDING = "GET_VOCABULARY_LEARNED_PENDING";
export const GET_VOCABULARY_LEARNED_SUCCESS = "GET_VOCABULARY_LEARNED_SUCCESS";
export const GET_VOCABULARY_LEARNED_FAILURE = "GET_VOCABULARY_LEARNED_FAILURE";
function getVocabularyLearnedPending(): Action {
  return {
      type: GET_VOCABULARY_LEARNED_PENDING
   }
}

function getVocabularyLearnedSuccess(vocabularyLearned: VocabularyLearnedData): Action {
  return {
      type: GET_VOCABULARY_LEARNED_SUCCESS,
      payload: vocabularyLearned
   }
}

function getVocabularyLearnedFailure(error: any): Action {
  return {
      type: GET_VOCABULARY_LEARNED_FAILURE,
      payload: error
   }
}

export function getVocabularyLearnedAction(studentId: string) {
  return async (dispatch: Function) => {
    try {
      dispatch(getVocabularyLearnedPending());
      const response: AxiosResponse<VocabularyLearnedData> = await axios.get(`${STUDENT_URL}/${studentId}/vocabulary-learned/`);
      dispatch(getVocabularyLearnedSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getVocabularyLearnedFailure(error));
      return Promise.reject(error)
    }
  }
}

//  ============================================================
//  GET BOOK READ
//  ============================================================
export const GET_BOOK_READ_PENDING = "GET_BOOK_READ_PENDING";
export const GET_BOOK_READ_SUCCESS = "GET_BOOK_READ_SUCCESS";
export const GET_BOOK_READ_FAILURE = "GET_BOOK_READ_FAILURE";
function getBookReadPending(): Action {
  return {
      type: GET_BOOK_READ_PENDING
   }
}

function getBookReadSuccess(bookReadData: BookReadData): Action {
  return {
      type: GET_BOOK_READ_SUCCESS,
      payload: bookReadData
   }
}

function getBookReadFailure(error: any): Action {
  return {
      type: GET_BOOK_READ_FAILURE,
      payload: error
   }
}

export function getBookReadAction(studentId: string, query?: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(getBookReadPending());
      const response: AxiosResponse<BookReadData> = await axios.get(`${STUDENT_URL}/${studentId}/books-read/`);
      dispatch(getBookReadSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getBookReadFailure(error));
      return Promise.reject(error)
    }
  }
}