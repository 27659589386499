import axios, { AxiosResponse } from "axios";
import { THEME_URL } from "constants/urls";
import { stringifyParams } from "helpers/params";
import { Action } from "types/action";
import { ThemeData } from "types/theme";

//  ============================================================
//  GET THEMES
//  ============================================================
export const GET_THEMES_PENDING = "GET_THEMES_PENDING";
export const GET_THEMES_SUCCESS = "GET_THEMES_SUCCESS";
export const GET_THEMES_FAILURE = "GET_THEMES_FAILURE";
function getThemesPending(): Action {
  return {
      type: GET_THEMES_PENDING
   }
}

function getThemesSuccess(themeData: ThemeData): Action {
  return {
      type: GET_THEMES_SUCCESS,
      payload: themeData
   }
}

function getThemesFailure(error: any): Action {
  return {
      type: GET_THEMES_FAILURE,
      payload: error
   }
}

export function getThemesAction(query?: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(getThemesPending());
      const response: AxiosResponse<ThemeData> = await axios.get(THEME_URL + stringifyParams(query));
      dispatch(getThemesSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getThemesFailure(error));
      return Promise.reject(error)
    }
  }
}