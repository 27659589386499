import { FormInstance, Spin, message } from "antd";
import { Form, Button, Divider, Row, Col, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ERROR_MESSAGES } from "constants/content";
import { checkCompletedProfileProgress } from "helpers/profileProgress";
import { useCallback, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfileProgressAction } from "store/actions/auth.action";
import {
  getSubscriptionFeesAction,
  updateSubscriptionFeesAction,
} from "store/actions/subscription.action";
import { RootState } from "store/reducer";
import { AuthState } from "store/reducers/auth.reducer";
import { SubscriptionState } from "store/reducers/subscription.reducer";

type SubscriptionFeesProps = {
  form?: FormInstance;
  onUpdateSuccess?: () => void;
};


export default function SubscriptionFees(props: SubscriptionFeesProps) {
  // React redux
  const dispatch = useDispatch();
  const [fees, setFees] = useState([])
  const { user } = useSelector<RootState, AuthState>(
    (state) => state.authState
  );

  const currentLanguage = 'en'
  const {
    subscriptionFees,
    updateSubscriptionFeesLoading,
    getSubscriptionFeesLoading,
  } = useSelector<RootState, SubscriptionState>(
    (state) => state.subscriptionState
  );

  const [subscriptionForm] = useForm();

  const { form, onUpdateSuccess } = props;

  const fetchData = useCallback(() => {
    try {
      if (form) {
        dispatch(getSubscriptionFeesAction());
      }
    } catch (error) {
      //
    }
  }, [form, dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const formInstance = form || subscriptionForm;
    const values = formInstance.getFieldsValue()
    return () => {
      if (values.fees && values.fees.length === fees.length) {
        // formInstance.setFieldsValue({ fees: [] });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fees])

  useEffect(() => {
    if (subscriptionFees) {
      const formInstance = form || subscriptionForm;
      const values: any = [];
      subscriptionFees.map((item) => (
        item.age_ranges.map((age) => (
          age.fees.map(fee => (
            values.push({
                id: fee.id,
                fee: fee.fee,
            })
          ))
        ))
      ))
      setFees(values)
      formInstance.setFieldsValue({ fees: values });
    }
  }, [subscriptionFees, subscriptionForm, form]);

  async function handleSubmit(values: any) {
    try {
      await dispatch(updateSubscriptionFeesAction(values.fees));

      if (user && checkCompletedProfileProgress(user) < 100) {
          dispatch(getProfileProgressAction());
      }
      if (onUpdateSuccess) {
        onUpdateSuccess();
      } else {
        message.success("Subscription fees updated");
      }
    } catch (error) {
      //
    }
  }

  function checkFeeIndex(id: string) {
    const index = fees.findIndex(x => x['id'] === id)
    return index
  }

  return (
    <div style={{ maxWidth: 1024 }}>
      <Form
        layout="vertical"
        form={form || subscriptionForm}
        onFinish={handleSubmit}
      >
        {getSubscriptionFeesLoading ? (
          <div className="py-10 flex items-center justify-center">
            <Spin spinning />
          </div>
        ) : (
          <Form.List name="fees">
            {(fields) =>
              subscriptionFees.map((classType, classTypeIndex) => (
                <div key={classType[currentLanguage].id}>
                  <div className="text-md mb-2 text-primary">
                    <strong>{classType[currentLanguage].title.toUpperCase()}</strong>
                  </div>
                  <div className="pl-3">
                    <Row gutter={48} className="mb-6">
                      {classType.age_ranges.map((ageRange, ageRangeIndex) => (
                        <Col xs={24} lg={12} key={ageRange.id}>
                          <p>
                            <strong>{ageRange[currentLanguage].title}</strong>
                          </p>
                          <p className="font-serif mb-4">
                        <i>
                          You can set your fee within the range set for this age
                          group (min. {ageRange[currentLanguage].from_unit}month / max. ${ageRange[currentLanguage].to_unit}month).
                        </i>
                      </p>

                          <Row gutter={24}>
                            {ageRange.fees.map(
                              (feeItem, feeItemIndex) => (
                                <Col key={feeItem.id} xs={24} md={12}>
                                  <Form.Item
                                    label={`Fee for ${feeItem.subscription_length.interval_count} ${feeItem.subscription_length.interval} (${feeItem.subscription_length.lesson_amount} classes)`}
                                    name={[
                                      checkFeeIndex(feeItem.id),
                                      'fee'
                                    ]}
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          ERROR_MESSAGES.SUBSCRIPTION_FEE_REQUIRED,
                                      },
                                      {
                                        pattern: new RegExp("[0-9]*"),
                                        message: 'Invaild'
                                      },
                                      {
                                        validator: (_, value: string) => {
                                          const fee = parseFloat(value);
                                          if (
                                            !value ||
                                            (fee >= Number(feeItem.minimum_amount)  &&
                                              fee <= Number(feeItem.maximum_amount))
                                          ) {
                                            return Promise.resolve();
                                          }

                                          return Promise.reject(
                                            `Fee is out of ranges (min: $${feeItem.minimum_amount} - max: $${feeItem.maximum_amount})`
                                          );
                                        },
                                      },
                                    ]}
                                  >
                                    <Input type="number" prefix="$" />
                                  </Form.Item>
                                </Col>
                              )
                            )}
                          </Row>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </div>
              ))
            }
          </Form.List>
        )}

        {!form && (
          <>
            <Divider />
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={updateSubscriptionFeesLoading}
              >
                Save change
              </Button>
            </Form.Item>
          </>
        )}
      </Form>
    </div>
  );
}
