import AuthContainer from "./AuthContainer";
import { Form, Input, Button, Alert, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  forgotPasswordAction,
  resetPasswordAction,
} from "store/actions/auth.action";
import { RootState } from "store/reducer";
import { AuthState } from "store/reducers/auth.reducer";
import { useHistory } from "react-router-dom";
import { ERROR_MESSAGES } from "constants/content";
import { validateConfirmPassword } from "helpers/validation";
import { Link } from "react-router-dom";

export default function ForgotPasswordPage() {
  // React router
  const { replace } = useHistory();

  // React redux
  const dispatch = useDispatch();
  const {
    forgotPasswordError,
    forgotPasswordLoading,
    username,
    resetPasswordLoading,
  } = useSelector<RootState, AuthState>((state) => state.authState);

  async function handleSubmit(values: any) {
    try {
      if (values.email) {
        // Enter email step
        dispatch(forgotPasswordAction(values.email));
      } else {
        // Reset password step
        await dispatch(
          resetPasswordAction(username, values.code, values.password)
        );
        Modal.success({
          title: "Successfully",
          content: "Your password has been reset, you can login now.",
          onOk: () => replace("/auth/login"),
        });
      }
    } catch (error) {
      //
    }
  }

  return (
    <AuthContainer title="Forgot password">
      <Form layout="vertical" onFinish={handleSubmit} autoComplete="off">
        {forgotPasswordError && (
          <Form.Item>
            <Alert
              type="error"
              message={forgotPasswordError.message}
              showIcon
            />
          </Form.Item>
        )}

        {username.length > 0 ? (
          <>
            <Form.Item
              label="New password"
              name="password"
              rules={[
                {
                  required: true,
                  message: ERROR_MESSAGES.FORGOT_PASSWORD_REQUIRED,
                },
                {
                  min: 8,
                  message: ERROR_MESSAGES.FORGOT_INVALID_PASSWORD,
                },
              ]}
            >
              <Input.Password autoComplete="new-password" autoFocus />
            </Form.Item>

            <Form.Item
              label="Confirm password"
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: ERROR_MESSAGES.FORGOT_CONFIRM_PASSWORD_REQUIRED,
                },
                ({ getFieldValue }) => ({
                  validator: (_, value: string) =>
                    validateConfirmPassword(getFieldValue("password"), value),
                  message: ERROR_MESSAGES.FORGOT_PASSWORD_NOT_MATCH,
                }),
              ]}
            >
              <Input.Password autoComplete="new-password" />
            </Form.Item>

            <Form.Item
              label="Verification code"
              name="code"
              rules={[
                {
                  required: true,
                  message: ERROR_MESSAGES.FORGOT_CODE_REQUIRED,
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          </>
        ) : (
          <Form.Item label="Email address" name="email">
            <Input autoFocus />
          </Form.Item>
        )}

        <Form.Item>
          <Button
            loading={forgotPasswordLoading || resetPasswordLoading}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>

        <p>
          <Link to="/auth/login">Back to login</Link>
        </p>
      </Form>
    </AuthContainer>
  );
}
