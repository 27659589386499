import { Checkbox } from "antd";
import "./PrintCard.scss"

type PrintCardProps = {
  name: string;
  image: string;
  value?: string;
  checked?: boolean
  onChange?: (value: string) => void;
};

export default function PrintCard({
  name,
  image,
  value,
  checked,
  onChange,
}: PrintCardProps) {
  function handlerChange(e: any) {
    if (onChange) {
      onChange(e)
    }
  }
  
  return (
    <div className="text-center">
      <div className="rounded shadow-lg overflow-hidden m-2">
        <img width="100" height="100" src={image} alt="" />
      </div>
      <div className="py-2">
        <Checkbox value={image} onChange={handlerChange}>{name}</Checkbox>
      </div>
    </div>
  );
}
