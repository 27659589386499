import { Form, Select, Input, Row, Col } from "antd";
import moment from "moment";
import { ClassData } from "types/class";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { useMemo } from "react";
import { timezone } from "helpers/time";

type TransactionFilterProps = {
  classData: ClassData;
  currentClass: string;
  loading?: boolean;
};

export default function TransactionFilter(props: TransactionFilterProps) {

  const { search, pathname } = useLocation();
  const { push } = useHistory();
  const query: any = useMemo(() => queryString.parse(search), [search]);

  function onValuesChange(changedValues: any, values: any) {
    push(pathname + "?" + queryString.stringify({
      ...query,
      ...values
    }))
  }

  return (
    <Form layout="vertical" onValuesChange={onValuesChange}>
      <Row gutter={48} justify="space-between">
        <Col xs={24} xl={12} xxl={8}>
          <Form.Item
            label="Class"
            name="class"
            initialValue={props.currentClass}
          >
            <Select style={{ width: "100%" }} loading={props.loading} disabled={props.loading}>
              {props.classData.results.map((cls) => (
                <Select.Option key={cls.id} value={cls.id}>
                     <span> {cls.days_of_week}, </span>
                    <span>
                      {moment(cls.start_datetime+ 'z').tz(timezone).format('YYYY-MM-DD, HH:mm')}
                    </span>
                    <span>{" "}({cls.age_range.title})</span>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} xl={12} xxl={8}>
          <Form.Item label="Search">
            <Input.Search />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
