import {
  Card,
  Row,
  Col,
  Button,
  Space,
  Divider,
  Modal,
  Form,
  DatePicker,
  Input,
} from "antd";
import {
  CaretRightOutlined,
  CalendarOutlined,
  ReloadOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { ClassLessonState } from "store/reducers/class-lesson.reducer";
import moment, { Moment } from "moment";
import { ERROR_MESSAGES, POPUP_CONTENT } from "constants/content";
import {
  changeLessonStatusAction,
  reScheduleLessonAction,
} from "store/actions/class-lesson.action";
import { useForm } from "antd/lib/form/Form";
import { useMemo } from "react";
import { timezone } from "helpers/time";

type LessonOverviewProps = {
  isLessonStarted: boolean;
  isLessonEnded: boolean;
  onLessonStart: () => void;
  onLessonPause: () => void;
  onLessonResume: () => void;
  onLessonRestart: () => void;
  onLessonEnd: () => void;
  classId: string;
  lessonId: string;
};

export default function ClassLessonOverview(props: LessonOverviewProps) {
  // State
  const [pausedVisibility, setPausedVisibility] = useState<boolean>(false);
  const [rescheduleVisibility, setReschedulueVisibility] =
    useState<boolean>(false);

  // React redux
  const dispatch = useDispatch();
  const { classLesson, reScheduleLessonLoading } = useSelector<
    RootState,
    ClassLessonState
  >((state) => state.classLessonState);

  const timeString = useMemo(() => {
    if (classLesson.lesson_class) {
      const { duration } = classLesson.lesson_class;
      const start = moment(new Date(classLesson.scheduled_at + 'z')).tz(timezone);
      const end = moment(start).add(duration, "minutes");
      return start.format("dddd DD MMMM YYYY • hh:mm a") + " - " + end.format("hh:mm a");
    } else {
      return "";
    }
  }, [classLesson]);

  const [rescheduleForm] = useForm();

  function handleRescheduleClick() {
    setReschedulueVisibility(true);
  }

  function handleCloseRescheduleModal() {
    setReschedulueVisibility(false);
  }

  function handleStartLessonClick() {
    Modal.confirm({
      ...POPUP_CONTENT.START_LESSON,
      onOk: async () => {
        try {
          const time = moment().utc().format()
          await dispatch(changeLessonStatusAction(props.lessonId, "started", time));
          props.onLessonStart();
        } catch (error) {
          //
        }
      },
    });
  }

  function handleEndLessonClick() {
    Modal.confirm({
      title: "End lesson",
      content:
        "Are you sure you want to end the lesson? Once you confirm, you won’t be able to go back.",
      onOk: async () => {
        try {
          const time = moment().utc().format()
          await dispatch(changeLessonStatusAction(props.lessonId, "ended", time));
          props.onLessonEnd();
        } catch (error) {
          //
        }
      },
      okText: "End lesson",
    });
  }

  function handleResumeLessonClick() {
    setPausedVisibility(false);
    props.onLessonResume();
  }

  function handleRestartLessonClick() {
    setPausedVisibility(false);
    props.onLessonRestart();
  }

  async function handleReschedule(values: any) {
    try {
      values.scheduled_at = (values.scheduled_at as Moment).utc().format();
      await dispatch(reScheduleLessonAction(props.lessonId, values));
      setReschedulueVisibility(false);
    } catch (error) {
      //
    }
  }

  return (
    <div>
      <Card>
        <Row gutter={32} align="middle">
          <Col flex="0 0 500px">
            <img
              src={classLesson.lesson?.badge.image_s3_url}
              alt="lesson-img"
            />
          </Col>
          <Col flex="1">
            {props.isLessonEnded && (
              <h2 className="text-xl">
                <strong>LESSON ENDED</strong>
              </h2>
            )}
            {classLesson.lesson_class && (
              <p>
                <strong>
                  {timeString}
                </strong>
              </p>
            )}
            <p>{classLesson.lesson_class?.class_type?.title}</p>
            <p>
              <strong>{classLesson.lesson?.theme?.title}</strong>
            </p>
            <p>{classLesson.lesson?.age_range?.title}</p>
            <p>
              <strong>Skills developed</strong>
            </p>
            <p>
              {classLesson.lesson?.skill_develop
                .map((skill) => skill)
                .join(" • ")}
            </p>

            {!props.isLessonEnded && <Divider />}

            <Space>
              {!(props.isLessonStarted || props.isLessonEnded) && (
                <>
                  <Button
                    icon={<CaretRightOutlined />}
                    type="primary"
                    onClick={handleStartLessonClick}
                    disabled={moment().isBefore(moment(new Date(classLesson.scheduled_at + 'z')).tz(timezone))}
                  >
                    Start lesson
                  </Button>
                  <Button
                    icon={<CalendarOutlined />}
                    onClick={handleRescheduleClick}
                  >
                    Reschedule
                  </Button>
                </>
              )}
              {props.isLessonStarted && !props.isLessonEnded && (
                <>
                  <Button
                    icon={<LogoutOutlined />}
                    onClick={handleEndLessonClick}
                  >
                    End lesson
                  </Button>
                </>
              )}
            </Space>
          </Col>
        </Row>
      </Card>

      <Modal
        title="Reschedule class"
        visible={rescheduleVisibility}
        okText="Confirm new time"
        onCancel={handleCloseRescheduleModal}
        onOk={rescheduleForm.submit}
        okButtonProps={{ loading: reScheduleLessonLoading }}
      >
        <Form
          layout="vertical"
          onFinish={handleReschedule}
          form={rescheduleForm}
        >
          <Form.Item
            label="New date"
            name="scheduled_at"
            rules={[
              {
                required: true,
                message: ERROR_MESSAGES.RESCHEDULE_NEW_DATE_REQUIRED,
              },
            ]}
          >
            <DatePicker
              showTime
              format="dddd DD MMMM, YYYY hh:mm a"
              className="w-full"
            />
          </Form.Item>
          <Form.Item label="Reason for rescheduling (optional)" name="rescheduled_reason">
            <Input.TextArea rows={3} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Lesson paused"
        // visible={!!classLesson && classLesson.classLesson.status === "paused"}
        visible={pausedVisibility}
        closable={false}
        footer={[
          <Button
            key="resume"
            icon={<CaretRightOutlined />}
            onClick={handleResumeLessonClick}
          >
            Resume
          </Button>,
          <Button
            key="restart"
            icon={<ReloadOutlined />}
            onClick={handleRestartLessonClick}
          >
            Start again
          </Button>,
        ]}
      >
        <p>
          You have paused this lesson. Click resume to pick up where you left
          off, or Start again to start the class from the beginning.
        </p>
      </Modal>
    </div>
  );
}
