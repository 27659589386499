import { Space } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { LessonStatus } from "types/lesson";
import { useState } from "react";
import { useRef } from "react";

type ClockProps = {
  lessonStatus: LessonStatus
}

export default function Clock({ lessonStatus }: ClockProps) {

  const [time, setTime] = useState<number>(0);
  const interval = useRef<NodeJS.Timeout | number>();

  function renderTime(): string {
    const minutes =(Math.floor(time / 60)).toString().padStart(2, "0");
    const seconds = (time % 60).toString().padStart(2, "0");
    return minutes + ":" + seconds;
  }

  function start() {
    interval.current = setInterval(() => {
      setTime(prev => prev + 1);
    }, 1000);
  }

  function pause() {
    clearInterval(interval.current as number);
  }

  useEffect(() => {
    if (lessonStatus === LessonStatus.STARTED) {
      start();
    } else if (lessonStatus === LessonStatus.PAUSED) {
      pause();
    }
  }, [lessonStatus]);
  

  return (
  <Space className="text-xl">
    <ClockCircleOutlined />
    <span>{renderTime()}</span>
  </Space>
  )
}
