import { Button, Form, Input } from "antd";
import { SendOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { useDispatch, useSelector } from "react-redux";
import { socketSendMessageAction } from "store/actions/chat.action";
import { ChatAction, ChatRoute } from "types/conversation";
import { RootState } from "store/reducer";
import { ChatState } from "store/reducers/chat.reducer";

export default function ChatForm() {
  // React redux
  const dispatch = useDispatch();
  const { selectedConversation } = useSelector<RootState, ChatState>(
    (state) => state.chatState
  );

  const [chatForm] = useForm();

  function handleSendMessage(values: any) {
    if (selectedConversation) {
      dispatch(
        socketSendMessageAction({
          route: ChatRoute.CHAT,
          action: ChatAction.MESSAGE_SEND,
          data: {
            chatId: selectedConversation.chatId,
            chatKey: selectedConversation.chatKey,
            message: values.message,
          },
        })
      );
    }

    chatForm.resetFields();
  }

  return (
    <div className="messages-page__chat-form flex items-end">
      <Form
        layout="inline"
        className="flex-1 items-end ml-3"
        autoComplete="off"
        onFinish={handleSendMessage}
        form={chatForm}
      >
        <Form.Item style={{ flex: 1 }} name="message">
          <Input
            bordered={false}
            autoFocus
            size="large"
            autoComplete="off"
            placeholder="Type your message here..."
          />
        </Form.Item>

        <Form.Item style={{ marginRight: 0 }}>
          <Button
            size="large"
            htmlType="submit"
            type="text"
            icon={<SendOutlined />}
          />
        </Form.Item>
      </Form>
    </div>
  );
}
