import { Form, Row, Col, Select, Input } from "antd";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getAgeRangesAction } from "store/actions/age-range.action";
import { getClassSubjectsAction } from "store/actions/class-subject.action";
import { getThemesAction } from "store/actions/theme.action";
import { RootState } from "store/reducer";
import { AgeRangeState } from "store/reducers/age-range.reducer";
import { ClassSubjectState } from "store/reducers/class-subject.reducer";
import queryString from "query-string";
import { ThemeState } from "store/reducers/theme.reducer";

export default function ClassFilter() {
  // React router
  const { pathname, search } = useLocation();
  const { push } = useHistory();
  // React redux
  const dispatch = useDispatch();
  const { classSubjectData, getClassSubjectsLoading } = useSelector<RootState,ClassSubjectState>((state) => state.classSubjectState);
  const { ageRangeData, getAgeRangesLoading } = useSelector<RootState, AgeRangeState>((state) => state.ageRangeState);
  const { themeData, getThemesLoading } = useSelector<RootState, ThemeState>((state) => state.themeState);

  // Variables
  const query: any = useMemo(() => queryString.parse(search), [search]);
  const fetchData = useCallback(() => {
    try {
      Promise.all([
        dispatch(getClassSubjectsAction({ no_pagination: true })),
        dispatch(getAgeRangesAction({is_available: true, no_pagination: true })),
        dispatch(getThemesAction({ no_pagination: true })),
      ]);
    } catch (error) {
      //
    }
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function onFilterFormChange(_: any, values: any) {
    // Convert key to where[key]
    // const apiQuery = convertToWhere(values);
    push(pathname + "?" + queryString.stringify(values));
  }

  return (
    <Form
          layout="vertical"
          onValuesChange={onFilterFormChange}
          initialValues={query}
        >
          <Row gutter={24}>
            <Col xs={24} md={12} xl={6}>
              <Form.Item label="Class subject" name="class_subs" >
                <Select
                  style={{ width: "100%" }}
                  loading={getClassSubjectsLoading}
                  disabled={getClassSubjectsLoading}
                  allowClear
                >
                  {classSubjectData.results.map((subject) => (
                    <Select.Option key={subject.id} value={subject.id}>
                      {subject.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={6}>
              <Form.Item label="Theme" name="theme">
                <Select
                  style={{ width: "100%" }}
                  disabled={getThemesLoading}
                  loading={getThemesLoading}
                  allowClear
                >
                  {themeData.results.map((theme) => (
                    <Select.Option key={theme.id} value={theme.id}>
                      {theme.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={6}>
              <Form.Item label="Age range" name="age_group">
                <Select
                  style={{ width: "100%" }}
                  loading={getAgeRangesLoading}
                  disabled={getAgeRangesLoading}
                  allowClear
                >
                  {ageRangeData.results.map((ageRange) => (
                    <Select.Option
                      key={ageRange.id}
                      value={ageRange.id}
                    >
                      {ageRange.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={6}>
              <Form.Item label="Search" name="search">
                <Input.Search />
              </Form.Item>
            </Col>
          </Row>
        </Form>
  );
}
