import { Table, Button, Form, Select, Row, Col, Input } from "antd";
import { ColumnsType } from "antd/lib/table";
import { getYearOld } from "helpers/date";
import { timezone } from "helpers/time";
import moment from "moment";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "store/reducer";
import { ClassState } from "store/reducers/class.reducer";
import { StudentState } from "store/reducers/student.reducer";
import { Student } from "types/student";

type PastStudentsProps = {
  handleClassChange: (value: string) => void;
  class: string;
};

export default function PastStudents(props: PastStudentsProps) {
  const { completedClassData } = useSelector<RootState, ClassState>(
    (state) => state.classState
  );
  const { pastStudentData, getStudentsLoading } = useSelector<
    RootState,
    StudentState
  >((state) => state.studentState);

  const tableColumns: ColumnsType<Student> = [
    { title: "#", key: "#", render: (_, __, index) => index + 1 },
    {
      title: "Started class",
      key: "started",
      dataIndex: ["classes"],
      render: (classes: {start_datetime: string}) => moment(new Date(classes.start_datetime + 'z')).tz(timezone).format("DD/MM/YYYY"),
    },
    {
      title: "Ended class",
      key: "ended",
      dataIndex: ["classes"],
      render: (classes: {ended_at: string}) => moment(new Date(classes.ended_at + 'z')).tz(timezone).format("DD/MM/YYYY"),
    },
    {
      title: "Student",
      key: "student",
      dataIndex: ["user"],
      render: (user: { first_name: string; last_name: string; date_of_birth: string }) =>
      `${user.first_name} ${user.last_name} (${getYearOld(user.date_of_birth)} year-old)`,
    },
    {
      title: "Lessons completed",
      key: "completed",
      dataIndex: "lessons_completed",
    },
    {
      title: "",
      key: "actions",
      align: "center",
      width: 200,
      dataIndex: "studentId",
      render: (id: string) => (
        <Link to={`/my-students/${id}`}>
          <Button>View profile</Button>
        </Link>
      ),
    },
  ];

  function searchStudent(e: any) {
    const {value} = e.target;
    console.log(value);
  }

  return (
    <div>
      <Form layout="vertical">
        <Row justify="space-between">
          <Col>
            <Form.Item label="Class" name="class">
              <Select
                style={{ width: 320, maxWidth: "100%" }}
                onChange={props.handleClassChange}
                value={props.class}
              >
                {completedClassData.results.map((cls) => (
                  <Select.Option key={cls.id} value={cls.id}>
                    <span> {cls.days_of_week}, </span>
                    <span>
                    {moment(new Date(cls.start_datetime+ 'z')).tz(timezone).format('YYYY-MM-DD, HH:mm')}
                    </span>
                    <span> ({cls.age_range.title})</span>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Search" name="search_fields">
              <Input.Search onPressEnter={searchStudent}/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        loading={getStudentsLoading}
        columns={tableColumns}
        dataSource={pastStudentData.results}
        rowKey="id"
      />
    </div>
  );
}
