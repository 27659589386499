import { Card } from "antd";
import { Link } from "react-router-dom";
import { Lesson } from "types/lesson";
import "./LessonThumbnail.scss"

type LessonThumbnailProps = {
  lesson: Lesson
};

export default function LessonThumbnail(props: LessonThumbnailProps) {

  const { lesson } = props;

  return (
    <Link to={`/curriculum/${lesson.id}`}>
      <Card className="rounded" hoverable cover={<img src={lesson.badge.image_s3_url} alt="" />} bodyStyle={{padding: 15}}>
        <Card.Meta description={lesson.title} />
      </Card>
    </Link>
  );
}
