import { GET_MUSIC_FAILURE, GET_MUSIC_PENDING, GET_MUSIC_SUCCESS, GET_WHAT_LISTEN_FAILURE, GET_WHAT_LISTEN_PENDING, GET_WHAT_LISTEN_SUCCESS } from "store/actions/music.action"
import { Action } from "types/action"
import { MusicResourceData, WhatToListen } from "types/music"

export type MusicState = {
  // Data
  musicData: MusicResourceData;
  whatToListenLastWeek: WhatToListen;
  whatToListenNextWeek: WhatToListen;
  whatToListenThisWeek: WhatToListen;
  // Loading
  getMusicLoading: boolean;
  getWhatToListenLoading: boolean;
  // Error
  getMusicError: any;
  getWhatToListenError: any;
}

const initialState: MusicState = {
  // Data
  musicData: {totalRow: 0, results: []},
  whatToListenLastWeek: {results: [], filter: ''},
  whatToListenNextWeek: {results: [], filter: ''},
  whatToListenThisWeek: {results: [], filter: ''},
  // Loading
  getMusicLoading: false,
  getWhatToListenLoading: false,
  // Error
  getMusicError: null,
  getWhatToListenError: null
}

export default function musicReducer(state = initialState, action: Action): MusicState {
  switch (action.type) {
    //  ============================================================
    //  GET MUSIC
    //  ============================================================
    case GET_MUSIC_PENDING:
      return {
        ...state,
        getMusicLoading: true
      }
    case GET_MUSIC_SUCCESS:
      return {
        ...state,
        getMusicLoading: false,
        musicData: action.payload
      }
    case GET_MUSIC_FAILURE:
      return {
        ...state,
        getMusicLoading: false,
        getMusicError: action.payload
      }
    //  ============================================================
    //  GET WHAT TO LISTEN
    //  ============================================================
    case GET_WHAT_LISTEN_PENDING:
      return {
        ...state,
        getWhatToListenLoading: true
      }
    case GET_WHAT_LISTEN_SUCCESS:
    if (action.payload.filter === 'next_week') {
      return {
        ...state,
        getWhatToListenLoading: false,
        whatToListenNextWeek: action.payload,
      };
    } else if (action.payload.filter === 'this_week'){
      return {
        ...state,
        getWhatToListenLoading: false,
        whatToListenLastWeek: action.payload,
      };
    } else {
      return {
        ...state,
        getWhatToListenLoading: false,
        whatToListenThisWeek: action.payload,
      };
    }
    case GET_WHAT_LISTEN_FAILURE:
      return {
        ...state,
        getWhatToListenLoading: false,
        getWhatToListenError: action.payload
      }
    default:
      return { ...state }
  }
}