import { GET_NOTIFY_FAILURE, GET_NOTIFY_PENDING, GET_NOTIFY_SUCCESS, GET_NOTIFY_UNREAD_FAILURE, GET_NOTIFY_UNREAD_PENDING, GET_NOTIFY_UNREAD_SUCCESS, MAKE_READ_ALL_FAILURE, MAKE_READ_ALL_PENDING, MAKE_READ_ALL_SUCCESS, UPDATE_FCM_TOKEN_FAILURE, UPDATE_FCM_TOKEN_PENDING, UPDATE_FCM_TOKEN_SUCCESS } from "store/actions/notification.action"
import { Action } from "types/action"
import { ListNotifications, TotalNotice } from "types/notification"

export type NotifyState = {
    // Data
    notifyData: ListNotifications;
    notifyTotal: TotalNotice;
    readAll: string;
    // Loading
    getNotifyLoading: boolean;
    // Error
    getNotifyError: any;
}

const initialState: NotifyState = {
    // Data
    notifyData: { results: [] },
    notifyTotal: { total_notice: 0 },
    readAll: '',
    // Loading
    getNotifyLoading: false,
    // Error
    getNotifyError: null
}

export default function notifyReducer(state = initialState, action: Action): NotifyState {
    switch (action.type) {
        //  ============================================================
        //  GET Notify
        //  ============================================================
        case GET_NOTIFY_PENDING:
            return {
                ...state,
                getNotifyLoading: true
            }
        case GET_NOTIFY_SUCCESS:
            return {
                ...state,
                getNotifyLoading: false,
                notifyData: action.payload
            }
        case GET_NOTIFY_FAILURE:
            return {
                ...state,
                getNotifyLoading: false,
                getNotifyError: action.payload
            }
        //  ============================================================
        //  GET Notify Unread
        //  ============================================================
        case GET_NOTIFY_UNREAD_PENDING:
            return {
                ...state,
                getNotifyLoading: true
            }
        case GET_NOTIFY_UNREAD_SUCCESS:
            return {
                ...state,
                getNotifyLoading: false,
                notifyTotal: action.payload
            }
        case GET_NOTIFY_UNREAD_FAILURE:
            return {
                ...state,
                getNotifyLoading: false,
                getNotifyError: action.payload
            }

        //  ============================================================
        //  Make all notify 
        //  ============================================================
        case MAKE_READ_ALL_PENDING:
            return {
                ...state,
                getNotifyLoading: true
            }
        case MAKE_READ_ALL_SUCCESS:
            return {
                ...state,
                getNotifyLoading: false,
                readAll: action.payload
            }
        case MAKE_READ_ALL_FAILURE:
            return {
                ...state,
                getNotifyLoading: false,
            }
        //  ============================================================
        //  FCM token
        //  ============================================================
        case UPDATE_FCM_TOKEN_PENDING:
            return {
                ...state,
                getNotifyLoading: true
            }
        case UPDATE_FCM_TOKEN_SUCCESS:
            return {
                ...state,
                getNotifyLoading: false,
            }
        case UPDATE_FCM_TOKEN_FAILURE:
            return {
                ...state,
                getNotifyLoading: false,
                getNotifyError: action.payload
            }
        default:
            return { ...state }
    }
}