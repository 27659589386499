import {
  PageHeader,
  Space,
  Avatar,
  Tag,
  Divider,
  Tabs,
  Button,
  Spin,
  Modal,
  Upload,
  Form,
  Select,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import LayoutContent from "components/LayoutContent/LayoutContent";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useMemo } from "react";
import queryString from "query-string";
import LearningProgress from "./tabs/LearningProgress";
import MediaGallery from "./tabs/MediaGallery";
import { useGoBack } from "hooks/useGoBack";
import { useState } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addStudentMediaAction,
  getGalleryStudentAction,
  getStudentProfileAction,
} from "store/actions/student.action";
import { useEffect } from "react";
import { RootState } from "store/reducer";
import { StudentState } from "store/reducers/student.reducer";
import { getYearOld } from "helpers/date";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import { customUploadRequest } from "helpers/upload";
import { getAllClassesAction } from "store/actions/class.action";
import { ClassState } from "store/reducers/class.reducer";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import { timezone } from "helpers/time";

enum MyStudentsTabKey {
  LEARNING_PROGRESS = "learning-progress",
  MEDIA_GALLERY = "media-gallery",
}

export default function StudentDetails() {
  const [uploadVisibility, setUploadVisibility] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  // React router
  const { pathname, search } = useLocation();
  const { push } = useHistory();
  const { goBack } = useGoBack();
  const { params } = useRouteMatch<{ studentId: string }>();

  // React redux
  const dispatch = useDispatch();
  const { studentProfile, getStudentProfileLoading, addStudentMediaLoading } =
    useSelector<RootState, StudentState>((state) => state.studentState);
  const { classData } = useSelector<RootState, ClassState>(
    (state) => state.classState
  );

  const [mediaForm] = useForm();

  // Variables
  const query: any = useMemo(() => queryString.parse(search), [search]);

  const fetchData = useCallback(() => {
    try {
      if (params.studentId) {
        Promise.all([
          dispatch(getStudentProfileAction(params.studentId)),
          dispatch(getAllClassesAction({ no_pagination: true })),
          dispatch(getGalleryStudentAction(params.studentId))
        ])
      }
    } catch (error) {
      //
    }
  }, [dispatch, params.studentId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function handleTabChange(activeKey: string) {
    push(pathname + "?tab=" + activeKey);
  }

  function handleUploadClick() {
    setUploadVisibility(true);
  }

  function handleUploadChange(info: UploadChangeParam) {
    console.log(info);
    setFileList(info.fileList);
  }

  async function finishUpload() {
    try {
      const values = await mediaForm.validateFields();
      const body = {
        class_lesson: values.class_lesson,
        files: fileList.map((info) => info.response),
      };
      await dispatch(addStudentMediaAction(params.studentId, body));
      await dispatch(getGalleryStudentAction(params.studentId));
      setUploadVisibility(false);
      setFileList([]);
    } catch (error) {
      //
    }
  }

  return (
    <div>
      <PageHeader title="Student profile" onBack={goBack} />

      <LayoutContent>
        <Spin spinning={getStudentProfileLoading}>
          <Space align="start" size={32}>
            <Avatar src={studentProfile?.user.avatar} size={64}>
              {studentProfile ? `${studentProfile.user.first_name} ${studentProfile.user.last_name}` : ""}
            </Avatar>
            <div>
              <p className="text-lg">{studentProfile?.user.first_name} {studentProfile?.user.last_name}</p>
              <Tag color="success">Active</Tag>
            </div>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td width="150">Age:</td>
                    <td>
                      {studentProfile ? getYearOld(studentProfile.user.day_of_birth) : ""}{" "}
                      year-old
                    </td>
                  </tr>
                  {studentProfile?.arabic_level && (
                    <tr>
                      <td>Arabic Dialect:</td>
                      <td>{studentProfile.arabic_level}</td>
                    </tr>
                  )}
                  <tr>
                    <td>Arabic Level:</td>
                    <td>{studentProfile?.dialect}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Space>

          <Divider />

          <Tabs
            activeKey={query.tab || MyStudentsTabKey.LEARNING_PROGRESS}
            onChange={handleTabChange}
            tabBarExtraContent={
              <Button onClick={handleUploadClick} icon={<UploadOutlined />}>
                Upload files
              </Button>
            }
          >
            <Tabs.TabPane
              tab="Learning progress"
              key={MyStudentsTabKey.LEARNING_PROGRESS}
            >
              <LearningProgress />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab="Media gallery"
              key={MyStudentsTabKey.MEDIA_GALLERY}
            >
              <MediaGallery />
            </Tabs.TabPane>
          </Tabs>
        </Spin>
      </LayoutContent>

      <Modal
        visible={uploadVisibility}
        onCancel={() => setUploadVisibility(false)}
        title="Upload media"
        okText="Finish upload"
        onOk={finishUpload}
        okButtonProps={{ loading: addStudentMediaLoading }}
      >
        <Form form={mediaForm} layout="vertical">
          <Form.Item
            label="Class"
            name="class_lesson"
            rules={[
              {
                required: true,
                message: "Class is required",
              },
            ]}
          >
            <Select>
              {classData.results.map((cls) => (
                <Select.Option key={cls.id} value={cls.id}>
                   <span> {cls.days_of_week}, </span>
                      <span>
                        {moment(new Date(cls.start_datetime+ 'z')).tz(timezone).format('YYYY-MM-DD, HH:mm')}
                      </span>
                      <span>{" "}({cls.age_range.title})</span>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
        <Upload
          fileList={fileList}
          onChange={handleUploadChange}
          customRequest={customUploadRequest}
          accept="image/*,video/*"
        >
          <Button type="dashed" block>
            Select files
          </Button>
        </Upload>
      </Modal>
    </div>
  );
}
