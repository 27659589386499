type PauseIconProps = {
  size?: number
}

export default function PauseIcon({ size = 30 }: PauseIconProps) {
  return (
    <svg
      id="Group_10233"
      data-name="Group 10233"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16.656 16.656"
    >
      <circle
        id="Ellipse_75"
        data-name="Ellipse 75"
        cx="8.328"
        cy="8.328"
        r="8.328"
        fill="#fef7ed"
      />
      <path
        id="Icon_material-pause"
        data-name="Icon material-pause"
        d="M9,15.056h2.159V7.5H9ZM13.318,7.5v7.556h2.159V7.5Z"
        transform="translate(-3.91 -2.95)"
        fill="#f1b24d"
      />
    </svg>
  );
}
