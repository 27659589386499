import ChatContent from "./ChatContent";
import ChatForm from "./ChatForm";

export default function ChatDetails() {
  return (
    <div className="max-h-full h-full flex flex-col">
      <ChatContent />
      <ChatForm />
    </div>
  )
}