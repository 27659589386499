import queryString from "query-string";

export function stringifyParams(params: any) {
  if (!params) return "";

  const keys = Object.keys(params);
  if (keys.length === 0) {
    return "";
  }

  return (
    "?" +
    queryString.stringify(params, { skipEmptyString: true, skipNull: true })
  );
}

export function convertToWhere(object: any) {
  const newObject: any = {};
  Object.keys(object).forEach((key) => {
    newObject[`where[${key}]`] = object[key];
  });

  return newObject;
}
