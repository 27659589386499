import { useMemo } from "react";
import { useHistory, useLocation } from "react-router";
import { TablePaginationConfig } from "antd";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import queryString from "query-string";
import { PaginationParams } from "./usePagination";

export default function useTableAndQueryChange(options: PaginationParams = { pageKey: "page", limitKey: "limit" }) {

  const { pathname, search } = useLocation();
  const { push } = useHistory();

  const query = useMemo(() => queryString.parse(search), [search]);

  function onTableChange(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<any> | SorterResult<any>[]) {

    const { current, pageSize } = pagination;

    // Remove other page and page_size on report page
    Object.keys(query).forEach(key => {
      if (key.includes("page") || key.includes("page_size")) {
        delete query[key];
      }
    })

    push(pathname + "?" + queryString.stringify({
      ...query,
      [options.pageKey]: current,
      [options.limitKey]: pageSize
    }, { skipEmptyString: true, skipNull: true }));
  }

  function onSearchChange(search: string) {

    const options: queryString.StringifyOptions = {
      skipNull: true,
      skipEmptyString: true
    }

    push(pathname + "?" + queryString.stringify({ search: search }, options));
  }

  return { onTableChange, onSearchChange };
}