import { Divider, Spin } from "antd";
import Message from "components/Message/Message";
import { useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { socketSendMessageAction } from "store/actions/chat.action";
import { ChatAction, ChatRoute, ConversationType } from "types/conversation";
import { RootState } from "store/reducer";
import { ChatState } from "store/reducers/chat.reducer";
import { AuthState } from "store/reducers/auth.reducer";
import moment from "moment";
import { timezone } from "helpers/time";

export default function ChatContent() {
  // React route hooks
  const { search } = useLocation();

  // React redux
  const dispatch = useDispatch();
  const { user } = useSelector<RootState, AuthState>(
    (state) => state.authState
  );
  const {
    socketConnected,
    messageData,
    getMessageDataLoading,
    selectedConversation,
    userProfileList,
    classInfoList,
  } = useSelector<RootState, ChatState>((state) => state.chatState);

  const lastMessageRef = useRef<HTMLDivElement>(null);

  const query: any = useMemo(() => queryString.parse(search), [search]);

  useEffect(() => {
    if (query.chatId && socketConnected) {
      dispatch(
        socketSendMessageAction({
          route: ChatRoute.CHAT,
          action: ChatAction.MESSAGE_LIST,
          data: {
            chatId: query.chatId,
          },
        })
      );
    }
  }, [query.chatId, socketConnected, dispatch]);

  useEffect(() => {
    if (lastMessageRef.current && messageData.Items) {
      lastMessageRef.current.scrollIntoView();
    }
  }, [query.chatId, messageData]);

  return (
    <div className="messages-page__chat-content flex-1 mb-3 flex flex-col overflow-hidden">
      <div className="flex items-center justify-between">
        {selectedConversation && (!query.tab || query.tab === ConversationType.SINGLE) &&
          userProfileList[selectedConversation.userId] && (
            <strong>{userProfileList[selectedConversation.userId].name}</strong>
          )}
        {selectedConversation &&
          classInfoList[selectedConversation.chatId] &&
          query.tab === ConversationType.GROUP && (
            <strong>
              {classInfoList[selectedConversation.chatId].days_of_week +
                ", " +
                moment(new Date(classInfoList[selectedConversation.chatId].start_datetime + 'z')).tz(timezone).format('HH:mm DD-MM-YYYY')}
            </strong>
          )}
      </div>
      <Divider />

      <div className="flex-1 overflow-auto px-3">
        <Spin spinning={getMessageDataLoading}>
          {messageData.Items.map((msg, index) => (
            <Message
              name={
                query.tab === ConversationType.GROUP &&
                userProfileList[msg.senderId]
                  ? messageData.Items[index - 1]
                    ? messageData.Items[index - 1].senderId !== msg.senderId
                      ? userProfileList[msg.senderId].name
                      : undefined
                    : userProfileList[msg.senderId].name
                  : undefined
              }
              key={msg.chatKey}
              role={user && user.id === msg.senderId ? "teacher" : "parent"}
              content={msg.message}
            />
          ))}
        </Spin>
        <div ref={lastMessageRef}></div>
      </div>
    </div>
  );
}
