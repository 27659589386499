import axios from "axios";
import { SUBSCRIPTION_URL } from "constants/urls";
import { Action } from "types/action";
import { SubscriptionFee } from "types/subscription";

//  ============================================================
//  GET SUBSCRIPTION FEE
//  ============================================================
export const GET_SUBSCRIPTION_FEES_PENDING = "GET_SUBSCRIPTION_FEES_PENDING";
export const GET_SUBSCRIPTION_FEES_SUCCESS = "GET_SUBSCRIPTION_FEES_SUCCESS";
export const GET_SUBSCRIPTION_FEES_FAILURE = "GET_SUBSCRIPTION_FEES_FAILURE";
function getSubscriptionFeesPending(): Action {
  return {
      type: GET_SUBSCRIPTION_FEES_PENDING
   }
}

function getSubscriptionFeesSuccess(subFees: Array<SubscriptionFee>): Action {
  return {
      type: GET_SUBSCRIPTION_FEES_SUCCESS,
      payload: subFees
   }
}

function getSubscriptionFeesFailure(error: any): Action {
  return {
      type: GET_SUBSCRIPTION_FEES_FAILURE,
      payload: error
   }
}

export function getSubscriptionFeesAction() {
  return async (dispatch: Function) => {
    try {
      dispatch(getSubscriptionFeesPending());
      const response = await axios.get(SUBSCRIPTION_URL + "?no_pagination=true");
      dispatch(getSubscriptionFeesSuccess(response.data.results));
      return Promise.resolve();
    } catch (error) {
      dispatch(getSubscriptionFeesFailure(error));
      return Promise.reject(error)
    }
  }
}

//  ============================================================
//  UPDATE SUBSCRIPTION FEES
//  ============================================================
export const UPDATE_SUBSCRIPTION_FEES_PENDING = "UPDATE_SUBSCRIPTION_FEES_PENDING";
export const UPDATE_SUBSCRIPTION_FEES_SUCCESS = "UPDATE_SUBSCRIPTION_FEES_SUCCESS";
export const UPDATE_SUBSCRIPTION_FEES_FAILURE = "UPDATE_SUBSCRIPTION_FEES_FAILURE";
function updateSubscriptionFeesPending(): Action {
  return {
      type: UPDATE_SUBSCRIPTION_FEES_PENDING
   }
}

function updateSubscriptionFeesSuccess(): Action {
  return {
      type: UPDATE_SUBSCRIPTION_FEES_SUCCESS
   }
}

function updateSubscriptionFeesFailure(error: any): Action {
  return {
      type: UPDATE_SUBSCRIPTION_FEES_FAILURE,
      payload: error
   }
}

export function updateSubscriptionFeesAction(body: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(updateSubscriptionFeesPending());
      await axios.put(SUBSCRIPTION_URL, body);
      dispatch(updateSubscriptionFeesSuccess());
      return Promise.resolve();
    } catch (error) {
      dispatch(updateSubscriptionFeesFailure(error));
      return Promise.reject(error)
    }
  }
}