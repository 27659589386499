import { GET_CLASS_LESSON_ATTENDEES_FAILURE, GET_CLASS_LESSON_ATTENDEES_PENDING, GET_CLASS_LESSON_ATTENDEES_SUCCESS } from "store/actions/class-lesson.action";
import {
  ADD_STUDENT_MEDIA_FAILURE,
  ADD_STUDENT_MEDIA_PENDING,
  ADD_STUDENT_MEDIA_SUCCESS,
  GET_BOOK_READ_FAILURE,
  GET_BOOK_READ_PENDING,
  GET_BOOK_READ_SUCCESS,
  GET_GALLERY_FAILURE,
  GET_GALLERY_PENDING,
  GET_GALLERY_SUCCESS,
  GET_LESSON_COMPLETED_FAILURE,
  GET_LESSON_COMPLETED_PENDING,
  GET_LESSON_COMPLETED_SUCCESS,
  GET_STUDENTS_FAILURE,
  GET_STUDENTS_PENDING,
  GET_STUDENTS_SUCCESS,
  GET_STUDENT_PROFILE_FAILURE,
  GET_STUDENT_PROFILE_PENDING,
  GET_STUDENT_PROFILE_SUCCESS,
  GET_VOCABULARY_LEARNED_FAILURE,
  GET_VOCABULARY_LEARNED_PENDING,
  GET_VOCABULARY_LEARNED_SUCCESS,
} from "store/actions/student.action";
import { Action } from "types/action";
import { ListAttendees } from "types/class-lesson";
import { BookReadData, LessonCompletedData, ListGallery, StudentData, StudentProfile, VocabularyLearnedData } from "types/student";

export type StudentState = {
  // Data
  activeStudentData: StudentData;
  pastStudentData: StudentData;
  attendeesData: ListAttendees;
  studentProfile: StudentProfile | null;
  galleryData: ListGallery
  lessonCompletedData: LessonCompletedData;
  vocabularyLearnedData: VocabularyLearnedData;
  bookReadData: BookReadData;
  // Loading
  getStudentsLoading: boolean;
  getStudentProfileLoading: boolean;
  getGalleryLoading: boolean;
  addStudentMediaLoading: boolean;
  getLessonCompletedLoading: boolean;
  getVocabularyLearnedLoading: boolean;
  getBookReadLoading: boolean;
  // Error
  getStudentsError: any;
  getStudentProfileError: any;
  addStudentMediaError: any;
  getLessonCompletedError: any;
  getVocabularyLearnedError: any;
  getBookReadError: any;
};

const initialState: StudentState = {
  // Data
  activeStudentData: { count: 0, results: [] },
  pastStudentData: { count: 0, results: [] },
  attendeesData: { results: [] },
  studentProfile: null,
  galleryData: { results: [] },
  lessonCompletedData: { count: 0, results: [] },
  vocabularyLearnedData: { count: 0, results: [] },
  bookReadData: { count: 0, results: [] },
  // Loading
  getStudentsLoading: false,
  getStudentProfileLoading: false,
  addStudentMediaLoading: false,
  getGalleryLoading: false,
  getLessonCompletedLoading: false,
  getVocabularyLearnedLoading: false,
  getBookReadLoading: false,
  // Error
  getStudentsError: null,
  getStudentProfileError: null,
  addStudentMediaError: null,
  getLessonCompletedError: null,
  getVocabularyLearnedError: null,
  getBookReadError: null
};

export default function studentReducer(
  state = initialState,
  action: Action
): StudentState {
  switch (action.type) {
    //  ============================================================
    //  GET STUDENTS
    //  ============================================================
    case GET_STUDENTS_PENDING:
      return {
        ...state,
        getStudentsLoading: true,
      };
    case GET_STUDENTS_SUCCESS:
      if (action.payload.status === "active") {
        return {
          ...state,
          getStudentsLoading: false,
          activeStudentData: action.payload.data,
        };
      } else {
        return {
          ...state,
          getStudentsLoading: false,
          pastStudentData: action.payload.data,
        };
      }
    case GET_STUDENTS_FAILURE:
      return {
        ...state,
        getStudentsLoading: false,
        getStudentsError: action.payload,
      };
    //  ============================================================
    //  GET STUDENT PROFILE
    //  ============================================================
    case GET_STUDENT_PROFILE_PENDING:
      return {
        ...state,
        getStudentProfileLoading: true,
      };
    case GET_STUDENT_PROFILE_SUCCESS:
      return {
        ...state,
        getStudentProfileLoading: false,
        studentProfile: action.payload,
      };
    case GET_STUDENT_PROFILE_FAILURE:
      return {
        ...state,
        getStudentProfileLoading: false,
        getStudentProfileError: action.payload,
      };
    //  ============================================================
    //  GET List Attendees
    //  ============================================================
    case GET_CLASS_LESSON_ATTENDEES_PENDING:
      return {
        ...state,
        getStudentProfileLoading: true,
      };
    case GET_CLASS_LESSON_ATTENDEES_SUCCESS:
      return {
        ...state,
        getStudentProfileLoading: false,
        attendeesData: action.payload,
      };
    case GET_CLASS_LESSON_ATTENDEES_FAILURE:
      return {
        ...state,
        getStudentProfileLoading: false,
        getStudentProfileError: action.payload,
      };
    //  ============================================================
    //  ADD STUDENT MEDIA
    //  ============================================================
    case ADD_STUDENT_MEDIA_PENDING:
      return {
        ...state,
        addStudentMediaLoading: true,
      };
    case ADD_STUDENT_MEDIA_SUCCESS:
      return {
        ...state,
        addStudentMediaLoading: false,
      };
    case ADD_STUDENT_MEDIA_FAILURE:
      return {
        ...state,
        addStudentMediaLoading: false,
        addStudentMediaError: action.payload,
      };
    //  ============================================================
    //  GET STUDENT GALLERY
    //  ============================================================
    case GET_GALLERY_PENDING:
      return {
        ...state,
        getGalleryLoading: true,
      };
    case GET_GALLERY_SUCCESS:
      return {
        ...state,
        getGalleryLoading: false,
        galleryData: action.payload
      };
    case GET_GALLERY_FAILURE:
      return {
        ...state,
        getGalleryLoading: false,
        addStudentMediaError: action.payload,
      };
    //  ============================================================
    //  GET LESSON COMPLETED
    //  ============================================================
    case GET_LESSON_COMPLETED_PENDING:
      return {
        ...state,
        getLessonCompletedLoading: true
      }
    case GET_LESSON_COMPLETED_SUCCESS:
      return {
        ...state,
        getLessonCompletedLoading: false,
        lessonCompletedData: action.payload
      }
    case GET_LESSON_COMPLETED_FAILURE:
      return {
        ...state,
        getLessonCompletedLoading: false,
        getLessonCompletedError: action.payload
      }
    //  ============================================================
    //  GET VOCABULARY LEARNED
    //  ============================================================
    case GET_VOCABULARY_LEARNED_PENDING:
      return {
        ...state,
        getVocabularyLearnedLoading: true
      }
    case GET_VOCABULARY_LEARNED_SUCCESS:
      return {
        ...state,
        getVocabularyLearnedLoading: false,
        vocabularyLearnedData: action.payload
      }
    case GET_VOCABULARY_LEARNED_FAILURE:
      return {
        ...state,
        getVocabularyLearnedLoading: false,
        getVocabularyLearnedError: action.payload
      }
    //  ============================================================
    //  GET BOOK READ
    //  ============================================================
    case GET_BOOK_READ_PENDING:
      return {
        ...state,
        getBookReadLoading: true
      }
    case GET_BOOK_READ_SUCCESS:
      return {
        ...state,
        getBookReadLoading: false,
        bookReadData: action.payload
      }
    case GET_BOOK_READ_FAILURE:
      return {
        ...state,
        getBookReadLoading: false,
        getBookReadError: action.payload
      }
    default:
      return { ...state };
  }
}
