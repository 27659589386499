import { Button, PageHeader, Tabs } from "antd";
import LayoutContent from "components/LayoutContent/LayoutContent";
import queryString from "query-string";
import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CurrentClassesTab from "./tabs/CurrentClassesTab";
import CompletedClassesTab from "./tabs/CompletedClassesTab";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { getCurrentClassesAction } from "store/actions/class.action";
import { useEffect } from "react";
import { getPaymentAction } from "store/actions/auth.action";
import { AuthState } from "store/reducers/auth.reducer";
import { RootState } from "store/reducer";


enum MyClassTabKey {
  CURRENT = "current",
  COMPLETE = "completed"
}

export default function MyClassesPage() {

  // React router
  const { pathname, search } = useLocation();
  const { push } = useHistory();

  // React redux
  const dispatch = useDispatch();
  const { paymentMethod } = useSelector<RootState, AuthState>((state) => state.authState);
  // Variables
  const query: any = useMemo(() => queryString.parse(search), [search]);
 
  const fetchData = useCallback(() => {
    try {
      dispatch(getCurrentClassesAction({is_ended: query.tab === MyClassTabKey.COMPLETE, page: query.page || 1, page_size: query.limit || 10}));
      dispatch(getPaymentAction());
    } catch (error) {
      //
    }
  }, [dispatch, query]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function onTabChange(activeKey: string) {
    push(pathname + "?tab=" + activeKey);
   
  }

  return (
    <div className="my-classes">
      <PageHeader title="My classes" extra={[
        <Link key="lesson-calendar" to="/my-classes/lesson-calendar">
          <Button>View lesson calendar</Button>
        </Link>,
         paymentMethod?.connect_status !== 'unverified' && (
        <Link key="add-class" to="/my-classes/add-new" >
          <Button type="primary">Add new class</Button>
        </Link>
         )
      ]} />

      <LayoutContent>
        <Tabs activeKey={query.tab || MyClassTabKey.CURRENT} onChange={onTabChange}>
          <Tabs.TabPane tab="Current classes" key={MyClassTabKey.CURRENT}>
            <CurrentClassesTab fetchData={fetchData} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Completed classes" key={MyClassTabKey.COMPLETE}>
            <CompletedClassesTab />
          </Tabs.TabPane>
        </Tabs>
      </LayoutContent>
    </div>
  );
}
