import {
  CHANGE_AVATAR_FAILURE,
  CHANGE_AVATAR_PENDING,
  CHANGE_AVATAR_SUCCESS,
  COMPLETE_SIGN_UP_FAILURE,
  COMPLETE_SIGN_UP_PENDING,
  COMPLETE_SIGN_UP_SUCCESS,
  CREATE_PAYMENT_FAILURE,
  CREATE_PAYMENT_PENDING,
  CREATE_PAYMENT_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_PENDING,
  FORGOT_PASSWORD_SUCCESS,
  GET_PAYMENT_FAILURE,
  GET_PAYMENT_PENDING,
  GET_PAYMENT_SUCCESS,
  GET_PROFILE_FAILURE,
  GET_PROFILE_PENDING,
  GET_PROFILE_PROGRESS_FAILURE,
  GET_PROFILE_PROGRESS_PENDING,
  GET_PROFILE_PROGRESS_SUCCESS,
  GET_PROFILE_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_PENDING,
  LOGIN_SUCCESS,
  RESEND_EMAIL_CODE,
  RESEND_EMAIL_FAILURE,
  RESEND_EMAIL_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_PENDING,
  RESET_PASSWORD_SUCCESS,
  SIGN_UP_FAILURE,
  SIGN_UP_PENDING,
  SIGN_UP_SUCCESS,
  STRIPE_DOCUMENT_FAILURE,
  STRIPE_DOCUMENT_PENDING,
  STRIPE_DOCUMENT_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  UPDATE_PASSWORD_PENDING,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_PENDING,
  UPDATE_PROFILE_SUCCESS,
  VERIFY_INVITATION_FAILURE,
  VERIFY_INVITATION_PENDING,
  VERIFY_INVITATION_SUCCESS,
} from "store/actions/auth.action";
import { Action } from "types/action";
import { VerifyTeacherResponse } from "types/auth";
import { PaymentMethod, StripeDocument } from "types/payment";
import { Profile } from "types/profile";

export type AuthState = {
  // Data
  user: Profile | null;
  username: string;
  verifyResult: VerifyTeacherResponse | null;
  paymentMethod: PaymentMethod | null;
  stripeDocument: StripeDocument | null;
  // Loading
  loginLoading: boolean;
  getProfileLoading: boolean;
  forgotPasswordLoading: boolean;
  resetPasswordLoading: boolean;
  verifyLoading: boolean;
  signUpLoading: boolean;
  resentEmailCodeLoading: boolean;
  completeSignUpLoading: boolean;
  changeAvatarLoading: boolean;
  updateProfileLoading: boolean;
  getPaymentLoading: boolean;
  createPaymentLoading: boolean;
  updatePasswordLoading: boolean;
  getProfileProgressLoading: boolean;
  // Error
  loginError: any;
  getProfileError: any;
  forgotPasswordError: any;
  resetPasswordError: any;
  verifyError: any;
  signUpError: any;
  completeSignUpError: any;
  changeAvatarError: any;
  updateProfileError: any;
  getPaymentError: any;
  createPaymentError: any;
  updatePasswordError: any;
  getProfileProgressError: any;
};

const initialState: AuthState = {
  // Data
  user: null,
  username: "",
  verifyResult: null,
  paymentMethod: null,
  stripeDocument: null,
  // Loading
  loginLoading: false,
  getProfileLoading: false,
  forgotPasswordLoading: false,
  resetPasswordLoading: false,
  verifyLoading: false,
  signUpLoading: false,
  resentEmailCodeLoading: false,
  completeSignUpLoading: false,
  changeAvatarLoading: false,
  updateProfileLoading: false,
  getPaymentLoading: false,
  createPaymentLoading: false,
  updatePasswordLoading: false,
  getProfileProgressLoading: false,
  // Error
  loginError: null,
  getProfileError: null,
  forgotPasswordError: null,
  resetPasswordError: null,
  verifyError: null,
  signUpError: null,
  completeSignUpError: null,
  changeAvatarError: null,
  updateProfileError: null,
  getPaymentError: null,
  createPaymentError: null,
  updatePasswordError: null,
  getProfileProgressError: null,
};

export default function authReducer(
  state = initialState,
  action: Action
): AuthState {
  switch (action.type) {
    //  ============================================================
    //  LOGIN
    //  ============================================================
    case LOGIN_PENDING:
      return {
        ...state,
        loginLoading: true,
        loginError: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginLoading: false,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loginLoading: false,
        loginError: action.payload,
      };
    //  ============================================================
    //  GET PROFILE
    //  ============================================================
    case GET_PROFILE_PENDING:
      return {
        ...state,
        getProfileLoading: true,
      };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        getProfileLoading: false,
        user: action.payload,
      };
    case GET_PROFILE_FAILURE:
      return {
        ...state,
        getProfileLoading: false,
        getProfileError: action.payload,
      };
    //  ============================================================
    //  FORGOT PASSWORD
    //  ============================================================
    case FORGOT_PASSWORD_PENDING:
      return {
        ...state,
        forgotPasswordLoading: true,
        forgotPasswordError: null,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordLoading: false,
        username: action.payload,
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPasswordLoading: false,
        forgotPasswordError: action.payload,
      };
    //  ============================================================
    //  RESET PASSWORD
    //  ============================================================
    case RESET_PASSWORD_PENDING:
      return {
        ...state,
        resetPasswordLoading: true,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordLoading: false,
        username: "",
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordError: action.payload,
      };
    //  ============================================================
    //  VERIFY INVITATION
    //  ============================================================
    case VERIFY_INVITATION_PENDING:
      return {
        ...state,
        verifyLoading: true,
      };
    case VERIFY_INVITATION_SUCCESS:
      return {
        ...state,
        verifyLoading: false,
        verifyResult: action.payload,
      };
    case VERIFY_INVITATION_FAILURE:
      return {
        ...state,
        verifyLoading: false,
        verifyError: action.payload,
      };
    //  ============================================================
    //  SIGN UP
    //  ============================================================
    case SIGN_UP_PENDING:
      return {
        ...state,
        signUpLoading: true,
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        signUpLoading: false,
      };
    case SIGN_UP_FAILURE:
      return {
        ...state,
        signUpLoading: false,
        signUpError: action.payload,
      };
    //  ============================================================
    //  Resent email code
    //  ============================================================
    case RESEND_EMAIL_CODE:
      return {
        ...state,
        resentEmailCodeLoading: true,
      };
    case RESEND_EMAIL_SUCCESS:
      return {
        ...state,
        resentEmailCodeLoading: false,
      };
    case RESEND_EMAIL_FAILURE:
      return {
        ...state,
        resentEmailCodeLoading: false,
        signUpError: action.payload,
      };
    //  ============================================================
    //  COMPLETE SIGN UP
    //  ============================================================
    case COMPLETE_SIGN_UP_PENDING:
      return {
        ...state,
        completeSignUpLoading: true,
      };
    case COMPLETE_SIGN_UP_SUCCESS:
      return {
        ...state,
        completeSignUpLoading: false,
      };
    case COMPLETE_SIGN_UP_FAILURE:
      return {
        ...state,
        completeSignUpLoading: false,
        completeSignUpError: action.payload,
      };
    //  ============================================================
    //  CHANGE AVATAR
    //  ============================================================
    case CHANGE_AVATAR_PENDING:
      return {
        ...state,
        changeAvatarLoading: true,
      };
    case CHANGE_AVATAR_SUCCESS:
      if (state.user) {
        return {
          ...state,
          changeAvatarLoading: false,
          user: {
            ...state.user,
            avatar: action.payload,
          },
        };
      }
      return {
        ...state,
        changeAvatarLoading: false,
      };
    case CHANGE_AVATAR_FAILURE:
      return {
        ...state,
        changeAvatarLoading: false,
        changeAvatarError: action.payload,
      };
    //  ============================================================
    //  UPDATE PROFILE
    //  ============================================================
    case UPDATE_PROFILE_PENDING:
      return {
        ...state,
        updateProfileLoading: true,
      };
    case UPDATE_PROFILE_SUCCESS:
      if (state.user) {
        return {
          ...state,
          updateProfileLoading: false,

          user: {
            ...state.user,
            ...action.payload,
          },
        };
      }

      return {
        ...state,
        updateProfileLoading: false,
      };
    case UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        updateProfileLoading: false,
        updateProfileError: action.payload,
      };
    //  ============================================================
    //  GET PAYMENT
    //  ============================================================
    case GET_PAYMENT_PENDING:
      return {
        ...state,
        getPaymentLoading: true,
      };
    case GET_PAYMENT_SUCCESS:
      return {
        ...state,
        getPaymentLoading: false,
        paymentMethod: action.payload,
      };
    case GET_PAYMENT_FAILURE:
      return {
        ...state,
        getPaymentLoading: false,
        getPaymentError: action.payload,
      };
    //  ============================================================
    //  Upload stripe document
    //  ============================================================
    case STRIPE_DOCUMENT_PENDING:
    return {
      ...state,
      getPaymentLoading: true,
    };
    case STRIPE_DOCUMENT_SUCCESS:
      return {
        ...state,
        getPaymentLoading: false,
        stripeDocument: action.payload,
      };
    case STRIPE_DOCUMENT_FAILURE:
      return {
        ...state,
        getPaymentLoading: false,
        getPaymentError: action.payload,
      };
    //  ============================================================
    //  CREATE PAYMENT METHOD
    //  ============================================================
    case CREATE_PAYMENT_PENDING:
      return {
        ...state,
        createPaymentLoading: true,
      };
    case CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        createPaymentLoading: false,
      };
    case CREATE_PAYMENT_FAILURE:
      return {
        ...state,
        createPaymentLoading: false,
        createPaymentError: action.payload,
      };
    //  ============================================================
    //  UPDATE PASSWORD
    //  ============================================================
    case UPDATE_PASSWORD_PENDING:
      return {
        ...state,
        updatePasswordLoading: true,
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        updatePasswordLoading: false,
      };
    case UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        updatePasswordLoading: false,
        updatePasswordError: action.payload,
      };
    //  ============================================================
    //  GET PROFILE PROGRESS
    //  ============================================================
    case GET_PROFILE_PROGRESS_PENDING:
      return {
        ...state,
        getProfileProgressLoading: true,
      };
    case GET_PROFILE_PROGRESS_SUCCESS:
      if (state.user) {
        return {
          ...state,
          getProfileProgressLoading: false,
          user: {
            ...state.user,
            profile_completed_steps: action.payload,
          },
        };
      }

      return {
        ...state,
        getProfileProgressLoading: false,
      };
    case GET_PROFILE_PROGRESS_FAILURE:
      return {
        ...state,
        getProfileProgressLoading: false,
        getProfileProgressError: action.payload,
      };
    default:
      return { ...state };
  }
}
