import { AutoCompleteProps, Select } from "antd";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";

type CountryAutocompleteProps = {
  timeout?: number;
};

export default function CountryAutoComplete({
  timeout = 300,
  ...props
}: AutoCompleteProps & CountryAutocompleteProps) {
  const [countries, setCountries] = useState<
    Array<{ label: string; value: string }>
  >([]);

  const fetchCountry = useCallback(() => {
    try {
      fetch(`https://restcountries.eu/rest/v2/`)
        .then((response) => {
          if (!response.ok) {
            return [];
          }
          return response.json() as Promise<
            Array<{
              name: string;
              nativeName: string;
              numericCode: string;
              alpha2Code: string;
            }>
          >;
        })
        .then((result) => {
          const options = result.map((country) => ({
            label: `${country.name}`,
            value: country.alpha2Code,
          }));
          setCountries(options);
        });
    } catch (error) {
      // console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchCountry();
  }, [fetchCountry]);

  return (
    <Select
      {...props}
      options={countries}
      dropdownMatchSelectWidth={false}
      showSearch
      filterOption={(input, option: any) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    />
  );
}
