import { Switch, Route } from "react-router-dom";
import AuthLayout from "./layouts/AuthLayout";
import PortalLayout from "./layouts/PortalLayout";
import { Spin } from "antd";
import Spinner from "components/Spinner/Spinner";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import Cookie from "js-cookie";
import { COOKIE_CONFIG } from "configs/cookie";
import { getProfileAction } from "store/actions/auth.action";

Spin.setDefaultIndicator(<Spinner />);

function App() {
  // React redux
  const dispatch = useDispatch();

  useEffect(() => {
    const token = Cookie.get(COOKIE_CONFIG.ACCESS_NAME);
    const refreshToken = Cookie.get(COOKIE_CONFIG.REFRESH_NAME);

    if (token || refreshToken) {
      dispatch(getProfileAction());
    }
  }, [dispatch]);

  return (
    <Switch>
      <Route path="/auth">
        <AuthLayout />
      </Route>
      <Route path="/">
        <PortalLayout />
      </Route>
    </Switch>
  );
}

export default App;
