import { Row, Col } from "antd";
import { timezone } from "helpers/time";
import moment from "moment";

import { Lesson } from "types/lesson";

type HorizontalLessonThumbnailProps = {
  lesson: Lesson;
  schedule?: string;
};

export default function HorizontalLessonThumbnail({
  lesson,
  schedule,
}: HorizontalLessonThumbnailProps) {
  return (
    <div className="rounded shadow">
      <Row align="middle">
        <Col flex="0 0 150px">
          <img src={lesson.badge?.image_s3_url} alt="" width="100%" />
        </Col>
        <Col flex="1">
          <div className="px-2">
            <p>
              <strong>{moment(new Date(schedule + 'z')).tz(timezone).format("dddd DD MMMM YYYY")}</strong>
            </p>
            <p>
              {moment(new Date(schedule + 'z')).tz(timezone).format("hh:mm a")} -{" "}
              {moment(new Date(schedule + 'z')).tz(timezone)
                .add(lesson.duration, "minutes")
                .format("hh:mm a")}
            </p>
            <p>
              <strong>{lesson.title.en}</strong>
            </p>
            {lesson.age_group && (
              <p>{lesson.age_group.map((ar) => ar.title).join(" • ")}</p>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
