import { PageHeader, Tabs } from "antd";
import LayoutContent from "components/LayoutContent/LayoutContent";
import queryString from "query-string";
import { useCallback, useEffect } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  getCompletedClassesAction,
  getCurrentClassesAction,
} from "store/actions/class.action";
import { getStudentsAction } from "store/actions/student.action";
import { RootState } from "store/reducer";
import { ClassState } from "store/reducers/class.reducer";
import ActiveStudents from "./tabs/ActiveStudents";
import PastStudents from "./tabs/PastStudents";

enum MyStudentsTabKey {
  ACTIVE = "active",
  PAST = "past",
}

export default function MyStudentsPage() {
  // React router
  const { pathname, search } = useLocation();
  const { push } = useHistory();

  // React redux
  const dispatch = useDispatch();
  const { currentClassData } = useSelector<RootState, ClassState>((state) => state.classState);

 
  // Variables
  const query: any = useMemo(() => queryString.parse(search), [search]);

  useEffect(() => {
      if (query.tab !== 'past' && currentClassData && currentClassData.results.length > 0) {
        push(
          pathname +
            "?" +
            queryString.stringify({
              tab: query.tab,
              classes__is_ended: query.tab === 'past',
              class_id: query.class_id || currentClassData.results[0].id,
              search: query.search || ''
            })
        );
      }
  }, [currentClassData, pathname, push, query])

  const fetchData = useCallback(() => {
    try {
      if (query.tab === MyStudentsTabKey.PAST) {
        dispatch(getCompletedClassesAction({is_ended: true, no_pagination: true }));
        if (query.class_id) {
          dispatch(getStudentsAction(query, "past"));
        }
      } else {
          dispatch(getCurrentClassesAction({ no_pagination: true }));
        if (query.class_id) {
          dispatch(getStudentsAction(query, "active"));
        }
      }
    } catch (error) {}
  }, [dispatch, query]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function handleTabChange(activeKey: string) {
    push(pathname + "?tab=" + activeKey);
  }

  function handleClassChange(classId: string) {
    push(
      pathname +
        "?" +
        queryString.stringify({
          tab: query.tab,
          classes__is_ended: query.tab === 'past',
          class_id: classId,
          search: query.search || ''
        })
    );
  }

  function onSearchClass(search: string) {
    push(
      pathname +
        "?" +
        queryString.stringify({
          tab: query.tab,
          classes__is_ended: query.tab === 'past',
          class_id: query.class_id,
          search: search
        })
    );
  }

  return (
    <div>
      <PageHeader title="My students" />

      <LayoutContent>
        <Tabs
          activeKey={query.tab || MyStudentsTabKey.ACTIVE}
          onChange={handleTabChange}
        >
          <Tabs.TabPane tab="Active students" key={MyStudentsTabKey.ACTIVE}>
            <ActiveStudents
              handleClassChange={handleClassChange}
              class={query.class_id}
              onSearchClass={onSearchClass}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Past students" key={MyStudentsTabKey.PAST}>
            <PastStudents
              handleClassChange={handleClassChange}
              class={query.class_id}
            />
          </Tabs.TabPane>
        </Tabs>
      </LayoutContent>
    </div>
  );
}
