import { Environment, environment, Staging } from "./env";

let domain = "localhost";
let access = "amm_tk";
let refresh = "amm_rf";

if (environment.env !== Environment.Deploy) {
  if (environment.staging === Staging.Stage) {
    domain = "https://teacher-stage.adamwamishmish.com/";
    access = "amm_stage_tk";
    refresh = "amm_stage_rf";
  } else if (environment.staging === Staging.Prod) {
    domain = "https://teacher.adamwamishmish.com/";
    access = "amm_prod_tk";
    refresh = "amm_prod_rf";
  }
}

export const COOKIE_CONFIG = {
  DOMAIN: domain,
  ACCESS_NAME: access,
  REFRESH_NAME: refresh,
};
