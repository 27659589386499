import { GET_EPISODES_FAILURE, GET_EPISODES_PENDING, GET_EPISODES_SUCCESS, GET_WHAT_TO_WATCH_FAILURE, GET_WHAT_TO_WATCH_PENDING, GET_WHAT_TO_WATCH_SUCCESS } from "store/actions/episode.action"
import { Action } from "types/action"
import { EpisodeResourceData, WhatToWatch } from "types/episode"

export type EpisodeState = {
  // Data
  episodeData: EpisodeResourceData;
  whatToWatchLastWeek: WhatToWatch;
  whatToWatchNextWeek: WhatToWatch;
  whatToWatchThisWeek: WhatToWatch;
  // Loading
  getEpisodesLoading: boolean;
  getWhatToWatchLoading: boolean;
  // Error
  getEpisodesError: any;
  getWhatToWhatError: any;
}

const initialState: EpisodeState = {
  // Data
  episodeData: {totalRow: 0, results: []},
  whatToWatchThisWeek: { results: [], filter: '' },
  whatToWatchLastWeek: { results: [], filter: '' },
  whatToWatchNextWeek: { results: [], filter: '' },
  // Loading
  getEpisodesLoading: false,
  getWhatToWatchLoading: false,
  // Error
  getEpisodesError: null,
  getWhatToWhatError: null
}

export default function episodeReducer(state = initialState, action: Action): EpisodeState {
  switch (action.type) {
    //  ============================================================
    //  GET EPISODES
    //  ============================================================
    case GET_EPISODES_PENDING:
      return {
        ...state,
        getEpisodesLoading: true
      }
    case GET_EPISODES_SUCCESS:
      return {
        ...state,
        getEpisodesLoading: false,
        episodeData: action.payload
      }
    case GET_EPISODES_FAILURE:
      return {
        ...state,
        getEpisodesLoading: false,
        getEpisodesError: action.payload
      }
    //  ============================================================
    //  GET WHAT TO WATCH
    //  ============================================================
    case GET_WHAT_TO_WATCH_PENDING:
      return {
        ...state,
        getWhatToWatchLoading: true
      }
    case GET_WHAT_TO_WATCH_SUCCESS:
    if (action.payload.filter === 'next_week') {
      return {
        ...state,
        getWhatToWatchLoading: false,
        whatToWatchNextWeek: action.payload,
      };
    } else if (action.payload.filter === 'last_week') {
      return {
        ...state,
        getWhatToWatchLoading: false,
        whatToWatchLastWeek: action.payload,
      };
    } else {
      return {
        ...state,
        getWhatToWatchLoading: false,
        whatToWatchThisWeek: action.payload,
      };
    }
    case GET_WHAT_TO_WATCH_FAILURE:
      return {
        ...state,
        getWhatToWatchLoading: false,
        getWhatToWhatError: action.payload
      }
    default:
      return { ...state }
  }
}