import axios, { AxiosResponse } from "axios";
import { CLASS_URL } from "constants/urls";
import { stringifyParams } from "helpers/params";
import { Action } from "types/action";
import { ClassData } from "types/class";

//  ============================================================
//  GET ALL CLASSES
//  ============================================================
export const GET_ALL_CLASSES_PENDING = "GET_ALL_CLASSES_PENDING";
export const GET_ALL_CLASSES_SUCCESS = "GET_ALL_CLASSES_SUCCESS";
export const GET_ALL_CLASSES_FAILURE = "GET_ALL_CLASSES_FAILURE";
function getAllClassesPending(): Action {
  return {
      type: GET_ALL_CLASSES_PENDING
   }
}

function getAllClassesSuccess(data: ClassData): Action {
  return {
      type: GET_ALL_CLASSES_SUCCESS,
      payload: data
   }
}

function getAllClassesFailure(error: any): Action {
  return {
      type: GET_ALL_CLASSES_FAILURE,
      payload: error
   }
}

export function getAllClassesAction(query?: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(getAllClassesPending());
      const response: AxiosResponse<ClassData> = await axios.get(`${CLASS_URL}/${stringifyParams(query)}`);
      dispatch(getAllClassesSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getAllClassesFailure(error));
      return Promise.reject(error)
    }
  }
}

//  ============================================================
//  GET CURRENT CLASSES
//  ============================================================
export const GET_CURRENT_CLASSES_PENDING = "GET_CURRENT_CLASSES_PENDING";
export const GET_CURRENT_CLASSES_SUCCESS = "GET_CURRENT_CLASSES_SUCCESS";
export const GET_CURRENT_CLASSES_FAILURE = "GET_CURRENT_CLASSES_FAILURE";
function getCurrentClassesPending(): Action {
  return {
      type: GET_CURRENT_CLASSES_PENDING
   }
}

function getCurrentClassesSuccess(classData: ClassData): Action {
  return {
      type: GET_CURRENT_CLASSES_SUCCESS,
      payload: classData
   }
}

function getCurrentClassesFailure(error: any): Action {
  return {
      type: GET_CURRENT_CLASSES_FAILURE,
      payload: error
   }
}

export function getCurrentClassesAction(query?: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(getCurrentClassesPending());
      const response: AxiosResponse<ClassData> = await axios.get(`${CLASS_URL}/${stringifyParams(query)}`);
      dispatch(getCurrentClassesSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getCurrentClassesFailure(error));
      return Promise.reject(error)
    }
  }
}


//  ============================================================
//  GET Details CLASS
//  ============================================================
export const GET_DETAIL_CLASS_PENDING = "GET_DETAIL_CLASS_PENDING";
export const GET_DETAIL_CLASS_SUCCESS = "GET_DETAIL_CLASS_SUCCESS";
export const GET_DETAIL_CLASS_FAILURE = "GET_DETAIL_CLASS_FAILURE";
function getDetailClassPending(): Action {
  return {
      type: GET_DETAIL_CLASS_PENDING
   }
}

function getDetailClassSuccess(classData: ClassData): Action {
  return {
      type: GET_DETAIL_CLASS_SUCCESS,
      payload: classData
   }
}

function getDetailClassFailure(error: any): Action {
  return {
      type: GET_DETAIL_CLASS_FAILURE,
      payload: error
   }
}

export function getDetailClassAction(classId: string) {
  return async (dispatch: Function) => {
    try {
      dispatch(getDetailClassPending());
      const response: AxiosResponse<ClassData> = await axios.get(`${CLASS_URL}/${classId}/`);
      dispatch(getDetailClassSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getDetailClassFailure(error));
      return Promise.reject(error)
    }
  }
}

//  ============================================================
//  GET COMPLETED CLASSES
//  ============================================================
export const GET_COMPLETED_CLASSES_PENDING = "GET_COMPLETED_CLASSES_PENDING";
export const GET_COMPLETED_CLASSES_SUCCESS = "GET_COMPLETED_CLASSES_SUCCESS";
export const GET_COMPLETED_CLASSES_FAILURE = "GET_COMPLETED_CLASSES_FAILURE";
function getCompletedClassesPending(): Action {
  return {
      type: GET_COMPLETED_CLASSES_PENDING
   }
}

function getCompletedClassesSuccess(classData: ClassData): Action {
  return {
      type: GET_COMPLETED_CLASSES_SUCCESS,
      payload: classData
   }
}

function getCompletedClassesFailure(error: any): Action {
  return {
      type: GET_COMPLETED_CLASSES_FAILURE,
      payload: error
   }
}

export function getCompletedClassesAction(query?: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(getCompletedClassesPending());
      query[`where[current]`] = false;
      const response: AxiosResponse<ClassData> = await axios.get(`${CLASS_URL}/${stringifyParams(query)}`);
      dispatch(getCompletedClassesSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getCompletedClassesFailure(error));
      return Promise.reject(error)
    }
  }
}

//  ============================================================
//  END CLASS
//  ============================================================
export const END_CLASS_PENDING = "END_CLASS_PENDING";
export const END_CLASS_SUCCESS = "END_CLASS_SUCCESS";
export const END_CLASS_FAILURE = "END_CLASS_FAILURE";
function endClassPending(): Action {
  return {
      type: END_CLASS_PENDING
   }
}

function endClassSuccess(): Action {
  return {
      type: END_CLASS_SUCCESS
   }
}

function endClassFailure(error: any): Action {
  return {
      type: END_CLASS_FAILURE,
      payload: error
   }
}

export function endClassAction(classId: string, body: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(endClassPending());
      await axios.put(`${CLASS_URL}/${classId}/`, body);
      dispatch(endClassSuccess());
      return Promise.resolve();
    } catch (error) {
      dispatch(endClassFailure(error));
      return Promise.reject(error)
    }
  }
}

//  ============================================================
//  ADD CLASS
//  ============================================================
export const ADD_CLASS_PENDING = "ADD_CLASS_PENDING";
export const ADD_CLASS_SUCCESS = "ADD_CLASS_SUCCESS";
export const ADD_CLASS_FAILURE = "ADD_CLASS_FAILURE";
function addClassPending(): Action {
  return {
      type: ADD_CLASS_PENDING
   }
}

function addClassSuccess(): Action {
  return {
      type: ADD_CLASS_SUCCESS
   }
}

function addClassFailure(error: any): Action {
  return {
      type: ADD_CLASS_FAILURE,
      payload: error
   }
}

export function addClassAction(body: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(addClassPending());
      const response: AxiosResponse<{ id: string }> = await axios.post(CLASS_URL+'/', body);
      dispatch(addClassSuccess());
      return Promise.resolve(response.data.id);
    } catch (error) {
      dispatch(addClassFailure(error));
      return Promise.reject(error)
    }
  }
}



//  ============================================================
//  EDIT CLASS
//  ============================================================
export const EDIT_CLASS_PENDING = "EDIT_CLASS_PENDING";
export const EDIT_CLASS_SUCCESS = "EDIT_CLASS_SUCCESS";
export const EDIT_CLASS_FAILURE = "EDIT_CLASS_FAILURE";
function editClassPending(): Action {
  return {
      type: EDIT_CLASS_PENDING
   }
}

function editClassSuccess(): Action {
  return {
      type: EDIT_CLASS_SUCCESS
   }
}

function editClassFailure(error: any): Action {
  return {
      type: EDIT_CLASS_FAILURE,
      payload: error
   }
}

export function editClassAction(id: string, body: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(editClassPending());
      const response: AxiosResponse<{ id: string }> = await axios.put(CLASS_URL+`/${id}/`, body);
      dispatch(editClassSuccess());
      return Promise.resolve(response.data.id);
    } catch (error) {
      dispatch(editClassFailure(error));
      return Promise.reject(error)
    }
  }
}

export const RESET_CLASSES_ACTION = "RESET_CLASSES_ACTION";
export function resetClassesAction(): Action {
  return {
    type: RESET_CLASSES_ACTION
  }
}