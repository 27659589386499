import { Action } from "types/action";
import { ChatClassInfo, ChatUserProfile, ConversationType } from "types/conversation";

export const WS_CONNECTED = "WS_CONNECTED";
export function socketConnectedAction(): Action {
  return {
    type: WS_CONNECTED,
  };
}

export const WS_DISCONNECTED = "WS_DISCONNECTED";
export function socketDisconnectedAction(): Action {
  return {
    type: WS_DISCONNECTED,
  };
}

export const WS_SEND_MESSAGE = "WS_SEND_MESSAGE";
export function socketSendMessageAction(data: any): Action {
  return {
    type: WS_SEND_MESSAGE,
    payload: data,
  };
}

export const WS_MESSAGE = "WS_MESSAGE";
export function socketMessageAction(data: any): Action {
  return {
    type: WS_MESSAGE,
    payload: data,
  };
}

export const SET_SELECTED_CONVERSATION = "SET_SELECTED_CONVERSATION";
export function setSelectedConversationAction(info: {
  chatId: string;
  type: ConversationType;
}): Action {
  return {
    type: SET_SELECTED_CONVERSATION,
    payload: info,
  };
}

export const SET_USER_PROFILE_LIST = "SET_USER_PROFILE_LIST";
export function setUserProfileListAction(data: Array<ChatUserProfile>): Action {
  return {
    type: SET_USER_PROFILE_LIST,
    payload: data
  }
}

export const SET_CLASS_INFO_LIST = "SET_CLASS_INFO_LIST";
export function setClassInfoListAction(data: Array<ChatClassInfo>): Action {
  return {
    type: SET_CLASS_INFO_LIST,
    payload: data
  }
}