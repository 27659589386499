import {
  SET_CLASS_INFO_LIST,
  SET_SELECTED_CONVERSATION,
  SET_USER_PROFILE_LIST,
  WS_CONNECTED,
  WS_DISCONNECTED,
  WS_MESSAGE,
  WS_SEND_MESSAGE,
} from "store/actions/chat.action";
import { Action } from "types/action";
import {
  ChatAction,
  ChatClassInfo,
  ChatUserProfile,
  ConversationType,
  GroupConversation,
  GroupConversationData,
  MessageData,
  SingleConversation,
  SingleConversationData,
} from "types/conversation";

export type ChatState = {
  // Data
  socketConnected: boolean;
  singleConversationData: SingleConversationData;
  groupConversationData: GroupConversationData;
  selectedConversation: SingleConversation | GroupConversation | null;
  messageData: MessageData;
  userProfileList: {
    [id: string]: ChatUserProfile;
  };
  classInfoList: {
    [id: string]: ChatClassInfo;
  };
  // Loading
  getSingleConversationsLoading: boolean;
  getGroupConversationsLoading: boolean;
  getMessageDataLoading: boolean;
  // Error
};

const initialState: ChatState = {
  // Data
  socketConnected: false,
  singleConversationData: { Count: 0, Items: [] },
  groupConversationData: { Count: 0, Items: [] },
  selectedConversation: null,
  messageData: { Count: 0, Items: [], ScannedCount: 0 },
  userProfileList: {},
  classInfoList: {},
  // Loading
  getSingleConversationsLoading: false,
  getGroupConversationsLoading: false,
  getMessageDataLoading: false,
  // Error
};

export default function chatReducer(
  state = initialState,
  action: Action
): ChatState {
  switch (action.type) {
    case WS_CONNECTED:
      return {
        ...state,
        socketConnected: true,
      };
    case WS_DISCONNECTED:
      return {
        ...state,
        socketConnected: false,
      };
    case WS_SEND_MESSAGE:
      switch (action.payload.action) {
        case ChatAction.CONVERSATION_LIST_BY_TEACHER:
          return {
            ...state,
            getSingleConversationsLoading: true,
          };
        case ChatAction.GROUP_CONVERSATION_LIST_BY_TEACHER:
          return {
            ...state,
            getGroupConversationsLoading: true,
          };
        case ChatAction.MESSAGE_LIST:
          return {
            ...state,
            messageData: { Count: 0, Items: [], ScannedCount: 0 },
            getMessageDataLoading: true,
          };
        default: {
          return { ...state };
        }
      }
    case WS_MESSAGE:
      switch (action.payload.action) {
        case ChatAction.CONVERSATION_LIST_BY_TEACHER:
          return {
            ...state,
            getSingleConversationsLoading: false,
            singleConversationData: action.payload.data,
          };
        case ChatAction.GROUP_CONVERSATION_LIST_BY_TEACHER:
          return {
            ...state,
            getGroupConversationsLoading: false,
            groupConversationData: action.payload.data,
          };
        case ChatAction.MESSAGE_LIST:
          return {
            ...state,
            getMessageDataLoading: false,
            messageData: action.payload.data,
          };
        case ChatAction.MESSAGE_SEND:
          const newMessage = action.payload.data;
          if (
            state.selectedConversation &&
            state.selectedConversation.chatId === newMessage.chatId
          ) {
            return {
              ...state,
              messageData: {
                ...state.messageData,
                Items: [...state.messageData.Items, newMessage],
              },
            };
          }
          return {
            ...state,
          };
        default: {
          return { ...state };
        }
      }
    case SET_SELECTED_CONVERSATION:
      const { chatId, type } = action.payload as {
        chatId: string;
        type: ConversationType;
      };

      let selectedConversation: SingleConversation | GroupConversation | null =
        null;

      if (type === ConversationType.SINGLE) {
        const conversation = state.singleConversationData.Items.find(
          (c) => c.chatId === chatId
        );
        if (conversation) {
          selectedConversation = conversation;
        }
      } else if (type === ConversationType.GROUP) {
        const conversation = state.groupConversationData.Items.find(
          (c) => c.chatId === chatId
        );
        if (conversation) {
          selectedConversation = conversation;
        }
      }

      return {
        ...state,
        selectedConversation,
      };
    case SET_USER_PROFILE_LIST:
      const profileList = action.payload as Array<ChatUserProfile>;
      const newProfileObject: { [id: string]: ChatUserProfile } = {};
      profileList.forEach((user) => {
        newProfileObject[user.id] = user;
      });

      return {
        ...state,
        userProfileList: {
          ...state.userProfileList,
          ...newProfileObject,
        },
      };
    case SET_CLASS_INFO_LIST:
      const classList = action.payload as Array<ChatClassInfo>;
      const newClassObject: { [id: string]: ChatClassInfo } = {};
      classList.forEach((cls) => {
        newClassObject[cls.id] = cls;
      });

      return {
        ...state,
        classInfoList: {
          ...state.classInfoList,
          ...newClassObject,
        },
      };
    default:
      return { ...state };
  }
}
