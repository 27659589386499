import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { REFRESH_URL } from "constants/urls";
import { COGNITO_CONFIG } from "configs/cognito";
import { COOKIE_CONFIG } from "configs/cookie";
import Cookie from "js-cookie";
import jwtDecode from "jwt-decode";
import { notification } from "antd";

export function refreshTokenAPI(refreshToken: string) {
  return axios.post(
    REFRESH_URL,
    {
      ClientId: COGNITO_CONFIG.userPoolWebClientId,
      AuthFlow: "REFRESH_TOKEN_AUTH",
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
      },
    },
    {
      headers: {
        "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
        "Content-Type": "application/x-amz-json-1.1",
      },
    }
  );
}

export function setupInterceptor() {
  axios.interceptors.request.use(
    function (config) {
      try {
        // Remove automatic add bearer token for external requests
        if (!config.url?.includes("xero.com")) {
          const token = Cookie.get(COOKIE_CONFIG.ACCESS_NAME);

          if (token && jwtDecode(token)) {
            config.headers["Authorization"] = `Bearer ${token}`;
          }
        }
      } catch (error) {
        console.log({ error });
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (res: AxiosResponse) {
      return res;
    },
    async function (error: AxiosError) {
      notification.destroy();

      const originalRequest = error.config;

      // Add _retry to custom request to prevent infinite loop
      const customRequest: AxiosRequestConfig & { _retry?: boolean } = {
        ...originalRequest,
      };

      if (
        error.response?.status === 401 &&
        !customRequest._retry &&
        originalRequest.url !== REFRESH_URL
      ) {
        Cookie.remove(COOKIE_CONFIG.ACCESS_NAME);

        customRequest._retry = true;

        let refreshToken = Cookie.get(COOKIE_CONFIG.REFRESH_NAME);

        if (refreshToken && refreshToken.length > 0) {
          try {
            const refreshResponse = await refreshTokenAPI(refreshToken);
            const {
              AuthenticationResult: { IdToken, ExpiresIn },
            } = refreshResponse.data;
            // const newToken = refreshResponse.data.AuthenticationResult.IdToken;
            Cookie.set(COOKIE_CONFIG.ACCESS_NAME, IdToken, {
              expires: new Date(new Date().getTime() + ExpiresIn * 1000),
            });

            const config = error.config;
            config.headers["Authorization"] = `Bearer ${IdToken}`;

            return new Promise((resolve, reject) => {
              return axios
                .request(config)
                .then((res) => resolve(res))
                .catch((error) => reject(error));
            });
          } catch (error) {
            Cookie.remove(COOKIE_CONFIG.ACCESS_NAME);
            return Promise.reject(error);
          }
        }
      } else {
        let title = "Error";
        let message = "Unknown error occurred, please try again later.";
        if (error?.response) {
          if (error.response.data) {
            const {data} = error.response;
            if (data.message) {
              if (typeof data.message === "string") {
                message = data.message;
              } else if (typeof data.message === "object") {
                console.log(data);
                if (data.message.error) {
                   message = data.message.error;
                } else {
                  message = data.message[0];
                }
              } else {
                console.log(message);
                message = data.message[0];
              }
            }
            if (data.detail) {
              message = data.detail;
            }
          }
        }

        notification.error({
          message: title,
          description: message,
        });
      }

      return Promise.reject(error);
    }
  );
}
