import { PageHeader, Divider } from "antd";
import LayoutContent from "components/LayoutContent/LayoutContent";
import VocabularyCard from "components/VocabularyCard/VocabularyCard";
import { useEffect } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { getVocabularyLearnedAction } from "store/actions/student.action";
import { RootState } from "store/reducer";
import { StudentState } from "store/reducers/student.reducer";

export default function VocabularyLearned() {
  // React router
  const { goBack } = useHistory();
  const { params } = useRouteMatch<{ studentId: string }>()

  // React redux
  const dispatch = useDispatch();
  const { vocabularyLearnedData } = useSelector<RootState, StudentState>(state => state.studentState);

  const fetchData = useCallback(() => {
    try {
      if (params.studentId) {
        dispatch(getVocabularyLearnedAction(params.studentId));
      }
    } catch (error) {
      
    }
  }, [dispatch, params.studentId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <PageHeader title="Vocabulary learned" onBack={goBack} />

      <LayoutContent>
        {vocabularyLearnedData.results.map((lesson) => (
          <div key={lesson.id} className="mb-6">
            <div className="text-lg">{lesson.title}</div>
            <Divider />
            <VocabularyCard key={lesson.id} image={lesson.thumbnail.image_s3_url} />
            {/* {lesson.flashcards.map((card) => (
              <VocabularyCard key={card.id} image={card.thumbnail} />
            ))} */}
          </div>
        ))}
      </LayoutContent>
    </div>
  );
}
