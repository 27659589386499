import axios, { AxiosResponse } from "axios";
import { TEACHER_URL } from "constants/urls";
import { stringifyParams } from "helpers/params";
import { Action } from "types/action";
import { PrintableResourceData } from "types/printable";

//  ============================================================
//  GET crafts RESOURCES
//  ============================================================
export const GET_CRAFT_PENDING = "GET_CRAFT_PENDING";
export const GET_CRAFT_SUCCESS = "GET_CRAFT_SUCCESS";
export const GET_CRAFT_FAILURE = "GET_CRAFT_FAILURE";

function getCraftsPending(): Action {
  return {
      type: GET_CRAFT_PENDING
   }
}

function getCraftsSuccess(craft: PrintableResourceData): Action {
  return {
      type: GET_CRAFT_SUCCESS,
      payload: craft
   }
}

function getCraftsFailure(error: any): Action {
  return {
      type: GET_CRAFT_FAILURE,
      payload: error
   }
}

export function getCraftsAction(query: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(getCraftsPending());
      const response: AxiosResponse<PrintableResourceData> = await axios.get(`${TEACHER_URL}/crafts/${stringifyParams(query)}`);
      dispatch(getCraftsSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getCraftsFailure(error));
      return Promise.reject(error)
    }
  }
}


//  ============================================================
//  GET Flash cards RESOURCES
//  ============================================================
export const GET_FLASHCARDS_PENDING = "GET_FLASHCARDS_PENDING";
export const GET_FLASHCARDS_SUCCESS = "GET_FLASHCARDS_SUCCESS";
export const GET_FLASHCARDS_FAILURE = "GET_FLASHCARDS_FAILURE";

function getFlashcardsPending(): Action {
  return {
      type: GET_FLASHCARDS_PENDING
   }
}

function getFlashcardsSuccess(flashcard: PrintableResourceData): Action {
  return {
      type: GET_FLASHCARDS_SUCCESS,
      payload: flashcard
   }
}

function getFlashcardsFailure(error: any): Action {
  return {
      type: GET_FLASHCARDS_FAILURE,
      payload: error
   }
}

export function getFlashCardsAction(query: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(getFlashcardsPending());
      const response: AxiosResponse<PrintableResourceData> = await axios.get(`${TEACHER_URL}/flash-cards/types/${stringifyParams(query)}`);
      dispatch(getFlashcardsSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getFlashcardsFailure(error));
      return Promise.reject(error)
    }
  }
}



//  ============================================================
//  DOWNLOAD FLASHCARD
//  ============================================================
export const DOWNLOAD_FLASHCARDS_PENDING = "DOWNLOAD_FLASHCARDS_PENDING";
export const DOWNLOAD_FLASHCARDS_SUCCESS = "DOWNLOAD_FLASHCARDS_SUCCESS";
export const DOWNLOAD_FLASHCARDS_FAILURE = "DOWNLOAD_FLASHCARDS_FAILURE";

function downloadFlashCardsPending(): Action {
  return {
      type: DOWNLOAD_FLASHCARDS_PENDING
   }
}

function dowloadlashcardsSuccess(file: any): Action {
  return {
      type: DOWNLOAD_FLASHCARDS_SUCCESS,
      payload: file
   }
}

function downloadFlashcardsFailure(error: any): Action {
  return {
      type: DOWNLOAD_FLASHCARDS_FAILURE,
      payload: error
   }
}

export function downloadFlashCardsAction(body: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(downloadFlashCardsPending());
      const response = await axios.post(`${TEACHER_URL}/downloads/printable-resources/`, body, { responseType: 'blob'});
      dispatch(dowloadlashcardsSuccess(new Blob([response.data])));
      return Promise.resolve();
    } catch (error) {
      dispatch(downloadFlashcardsFailure(error));
      return Promise.reject(error)
    }
  }
}