import { PageHeader, Space, Table } from "antd";
import LayoutContent from "components/LayoutContent/LayoutContent";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { ReviewState } from "store/reducers/review.reducer";
import { useMemo } from "react";
import usePagination from "hooks/usePagination";
import useTableAndQueryChange from "hooks/useTableAndQueryChange";
import { useCallback } from "react";
import { getRatingAction, getReviewsAction } from "store/actions/review.action";
import { useEffect } from "react";
import { ColumnsType } from "antd/lib/table";
import { Review } from "types/review";
import { AuthState } from "store/reducers/auth.reducer";
import { RatingView } from "react-simple-star-rating";
import moment from "moment";
import { timezone } from "helpers/time";

export default function ReviewPage() {
  // React router
  const { search } = useLocation();

  // React redux
  const dispatch = useDispatch();
  const { user, getProfileLoading } = useSelector<RootState, AuthState>(
    (state) => state.authState
  );
  const { reviewData, totalReview, getReviewsLoading } = useSelector<RootState, ReviewState>((state) => state.reviewState);

  const { page, limit } = usePagination();
  const { onTableChange } = useTableAndQueryChange();

  const query: any = useMemo(() => queryString.parse(search), [search]);

  const columns: ColumnsType<Review> = [
    {
      title: "Rating",
      key: "rating",
      dataIndex: "rating",
      render: (rating: string) => (
        <RatingView ratingValue={parseFloat(rating)} />
      ),
    },
    {
      title: "Class time",
      key: "classTime",
      dataIndex: ['classes'],
      render: (classes: {start_datetime: string | null}) => moment(new Date(classes.start_datetime + 'z')).tz(timezone).format("DD/MM/YYYY")
    },
    {
      title: "Review",
      key: "review",
      dataIndex: "review",
    },
    {
      title: "Date",
      key: "date",
      dataIndex: "created_at",
      render: (created_at: string) => moment(created_at).format("DD/MM/YYYY"),
    },
  ];

  const fetchData = useCallback(() => {
    try {
      dispatch(getReviewsAction(query));
      dispatch(getRatingAction())
    } catch (error) {
      //
    }
  }, [dispatch, query]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <PageHeader title="My reviews" />

      <LayoutContent loading={getProfileLoading || getReviewsLoading}>
        <Table
          title={() =>
            user && (
              <Space size={30} align="start">
                <div>
                  <span className="text-xl">My overall rating</span>
                </div>
                <div className="text-xl">
                  <p>{Math.floor(totalReview.rating)}/5</p>
                  <p>
                    <RatingView ratingValue={Math.floor(user.rating)} />
                  </p>
                </div>
              </Space>
            )
          }
          onChange={onTableChange}
          rowKey="id"
          dataSource={reviewData.results}
          columns={columns}
          pagination={{
            total: reviewData.count,
            current: page,
            pageSize: limit,
            showSizeChanger: true,
            hideOnSinglePage: true,
          }}
        />
      </LayoutContent>
    </div>
  );
}
