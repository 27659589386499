import { AutoComplete } from "antd";
import {
  getLocationFromGeoCoderResult,
  LocationResult,
} from "helpers/location";
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";

type PlaceAutocompleteProps = {
  onSelect?: (location: LocationResult) => void;
  onChange?: (value: string) => void;
  value?: string
};

export default function PlaceAutocomplete(props: PlaceAutocompleteProps) {
  const {
    clearSuggestions,
    suggestions: { status, data },
    setValue,
    value,
  } = usePlacesAutocomplete({
    requestOptions: {
      // componentRestrictions: {
      //   country: ["au"],
      // }
    }
  });

  function handleSearch(search: string) {
    setValue(search);
  }

  function handleChange(value: string) {
    setValue(value, false);
    if (props.onChange) {
      props.onChange(value);
    }
  }

  async function handleSelect(
    prediction: google.maps.places.AutocompletePrediction
  ) {
    if (props.onSelect) {
      try {
        const response = await getGeocode({ placeId: prediction.place_id });
        const geocodeResult = response[0];
        const location = getLocationFromGeoCoderResult(geocodeResult);
        props.onSelect(location);
      } catch (error) {
        console.log({ error });
      }
    }
  }

  return (
    <AutoComplete
      onSearch={handleSearch}
      value={props.value || value}
      onChange={handleChange}
      allowClear
      onClear={clearSuggestions}
    >
      {status === "OK" &&
        data.map((prediction) => (
          <AutoComplete.Option
            key={prediction.place_id}
            value={prediction.description}
          >
            <div onClick={() => handleSelect(prediction)}>
              <strong>{prediction.structured_formatting.main_text}</strong>{" "}
              {prediction.structured_formatting.secondary_text}
            </div>
          </AutoComplete.Option>
        ))}
    </AutoComplete>
  );
}
