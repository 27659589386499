import {
  PageHeader,
  Divider,
  Form,
  Select,
  Row,
  Col,
  Input,
  DatePicker,
  Button,
  Checkbox,
  Space,
  Modal,
  Spin,
} from "antd";
import LayoutContent from "components/LayoutContent/LayoutContent";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useState } from "react";
import ClassFilter from "components/ClassFilter/ClassFilter";
import LessonSelect from "components/LessonSelect/LessonSelect";
import AgeRangeSelect from "components/AgeRangeSelect/AgeRangeSelect";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { RootState } from "store/reducer";
import { useForm } from "antd/lib/form/Form";
import { useCallback } from "react";
import queryString from "query-string";
import { getLessonsAction } from "store/actions/lesson.action";
import { useMemo } from "react";
import { LessonState } from "store/reducers/lesson.reducer";
import { getLessonOutlineAction } from "store/actions/lesson-outline.action";
import { LessonOutlineState } from "store/reducers/lesson-outline.reducer";
import { ERROR_MESSAGES } from "constants/content";
import moment, { Moment } from "moment";
import { addClassAction, editClassAction, getDetailClassAction } from "store/actions/class.action";
import { ClassState } from "store/reducers/class.reducer";
import { AuthState } from "store/reducers/auth.reducer";
import { getProfileProgressAction } from "store/actions/auth.action";
import { getClassSubjectsAction } from "store/actions/class-subject.action";
import { ClassSubjectState } from "store/reducers/class-subject.reducer";
import { AgeRangeState } from "store/reducers/age-range.reducer";
import { socketSendMessageAction } from "store/actions/chat.action";
import { ChatAction, ChatRoute } from "types/conversation";
import { checkCompletedProfileProgress } from "helpers/profileProgress";
import { AgeRange } from "types/age-range";
import { timezone } from "helpers/time";

const dayOfWeek: Array<{ value: string; label: string }> = [
  { label: "Sundays", value: "Sundays" },
  { label: "Mondays", value: "Mondays" },
  { label: "Tuesdays", value: "Tuesdays" },
  { label: "Wednesdays", value: "Wednesdays" },
  { label: "Thursdays", value: "Thursdays" },
  { label: "Fridays", value: "Fridays" },
  { label: "Saturdays", value: "Saturdays" }
];

enum AddClassStep {
  SET_DETAILS,
  SELECT_LESSONS,
}


export default function AddNewClassPage() {
  const [step, setStep] = useState<AddClassStep>(AddClassStep.SET_DETAILS);
  // React router
  const { search, pathname } = useLocation();
  const { goBack, push } = useHistory();

  // React redux
  const dispatch = useDispatch();
  const { user } = useSelector<RootState, AuthState>((state) => state.authState);
  const { ageRangeData } = useSelector<RootState, AgeRangeState>((state) => state.ageRangeState);
  const { lessonData, getLessonsLoading } = useSelector<RootState, LessonState>((state) => state.lessonState);
  const { lessonOutline, getLessonOutlineLoading } = useSelector<RootState, LessonOutlineState>((state) => state.lessonOutlineState);
  const { addClassLoading, classDetailData } = useSelector<RootState, ClassState>((state) => state.classState);
  const { classSubjectData, getClassSubjectsLoading } = useSelector<RootState, ClassSubjectState>((state) => state.classSubjectState);

  // Variables
  const [classDetailsForm] = useForm();
  const [ageRange, setAgeRange] = useState<AgeRange>()
  const [day, setDay] = useState<string>('')
  const [upComingLesson, setUpcomingLessons] = useState<Array<string>>([])
  const query: any = useMemo(() => queryString.parse(search), [search]);
  const { params } = useRouteMatch<{ classId: string }>();

  const fetchData = useCallback(() => {
    try {
      Promise.all([
        dispatch(getLessonsAction(query)),
        dispatch(getLessonOutlineAction()),
        dispatch(getClassSubjectsAction({ no_pagination: true })),
        params.classId && dispatch(getDetailClassAction(params.classId)),
      ]);
    } catch (error) {
      //
    }
  }, [dispatch, query, params.classId]);

  useEffect(() => {
    if (classDetailData.id) {
      const dateTime = moment(new Date(classDetailData.start_datetime + 'z')).tz(timezone, false)
      const upcoming_lessons = classDetailData.upcoming_lessons?.map((item) => (item.lesson.id))
      setUpcomingLessons(upcoming_lessons || [])
      const values = {
        age_range: classDetailData.age_range?.id,
        duration: classDetailData.duration,
        class_type: classDetailData.class_type?.id,
        class_subject: classDetailData.class_subject?.id,
        days_of_week: classDetailData.days_of_week,
        max_students: classDetailData.max_students,
        start_datetime: dateTime,
        time: dateTime,
        upcoming_lessons: upcoming_lessons
      }
      setDay(classDetailData.days_of_week || '')
      setAgeRange(classDetailData.age_range)
      classDetailsForm.setFieldsValue(values);
    }
  }, [classDetailData, classDetailsForm])

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function handleGoBackForm() {
    setStep(AddClassStep.SET_DETAILS);
    push("/my-classes/add-new");
  }

  function convertDate(start_datetime: any, time: any) {
    const startDate = moment(start_datetime)
    const startTime = moment(time)
    const newDate = new Date(startDate.years(), startDate.month(), startDate.date(), startTime.hours(), startTime.minute())
    return moment(newDate).utc().format()
  }

  async function handleAddClassLessons(values: any) {
    const classDetails = classDetailsForm.getFieldsValue();
    const startDate = classDetails.start_datetime as Moment;
    classDetails.start_datetime = convertDate(classDetails.start_datetime, classDetails.time)
    classDetails.max_students = parseFloat(classDetails.max_students);
    const formBody = {
      ...classDetails,
      ...values,
    };
    try {
      if (params.classId) {
        await dispatch(editClassAction(params.classId, {upcoming_lessons: values.upcoming_lessons}))
      } else {
        const newClassId = await dispatch(addClassAction(formBody));
        dispatch(socketSendMessageAction({
          route: ChatRoute.CHAT,
          action: ChatAction.GROUP_CONVERSATION_CREATE,
          data: {
            classId: newClassId
          }
        }))
      }
      if (user && checkCompletedProfileProgress(user) < 100) {
        await dispatch(getProfileProgressAction());
      }
      Modal.success({
        title: `${params.classId ? 'Class updated' : 'Class added'}`,
        content: (
          <div>
            <p>
              Your {params.classId && 'new'} class has been successfully {params.classId ? 'updated' : 'added'} to your schedule! Go to
              your Lesson Calendar to prepare your next upcoming lesson.
            </p>
            <br />
            <p>
              Class start date:{" "}
              <strong>{moment(startDate).format("DD/MM/YYYY")}</strong>
            </p>
          </div>
        ),
        onOk: () => {
          push("/my-classes");
        },
      });
    } catch (error) {
      //
    }
  }

  function onValuesChange(changedValue: any) {
    if ("age_range" in changedValue) {
      const range = ageRangeData.results.find(x => x.id === changedValue.age_range)
      setAgeRange(range)
      classDetailsForm.setFieldsValue({
        duration: range?.duration.times
      });
    } else if ("days_of_week" in changedValue) {
      setDay(changedValue.days_of_week)
    }
  }

  function checkLocalTime(date: any) {
    const dateTime = moment(date).days()
    const number = dayOfWeek.findIndex(x => x.value === day)
    return dateTime === number
  }

  async function handleSetDetails(values: any) {
    values.start_datetime = convertDate(values.start_datetime, values.time)
    values.max_students = parseFloat(values.max_students);
    if (params.classId) {
      await dispatch(editClassAction(params.classId, values))
      Modal.success({
        title: `Class updated`,
        content: (
          <div>
            <p>
              Your class has been successfully added to your schedule! Go to
              your Lesson Calendar to prepare your next upcoming lesson.
            </p>
            <br />
            <p>
              Class start date:{" "}
              <strong>{moment(values.start_datetime).format("DD/MM/YYYY")}</strong>
            </p>
          </div>
        ),
        onOk: () => {
          push("/my-classes");
        },
      });
    } else {
      if (step === AddClassStep.SET_DETAILS) {
        push(`${pathname}?class_subs=${values.class_subject}&age_group=${values.age_range}`);
        setStep(AddClassStep.SELECT_LESSONS);
      }
    }
  }

  function handleGoToLesson() {
    if (step === AddClassStep.SET_DETAILS) {
      setStep(AddClassStep.SELECT_LESSONS);
    }
  }

  return (
    <div>
      <PageHeader title={params.classId ? 'Edit class' : 'Add new class'} onBack={goBack} />

      <LayoutContent>
        <div className={step === AddClassStep.SET_DETAILS ? "" : "hidden"}>
          <Divider orientation="left">1. Set your class details</Divider>

          <div style={{ maxWidth: 768 }}>
            <Form
              layout="vertical"
              onFinish={handleSetDetails}
              onValuesChange={onValuesChange}
              form={classDetailsForm}
            >
              <Row gutter={24}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label="Age range"
                    name="age_range"
                    rules={[
                      {
                        required: true,
                        message: ERROR_MESSAGES.ADD_CLASS_AGE_RANGE_REQUIRED,
                      },
                    ]}
                  >
                    <AgeRangeSelect />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label="Class duration (mins)"
                    name="duration"
                    rules={[
                      {
                        required: true,
                        message:
                          ERROR_MESSAGES.ADD_CLASS_CLASS_DURATION_REQUIRED,
                      },
                    ]}
                  >
                    <Input
                      disabled
                      readOnly
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label="Class type"
                    name="class_type"
                    rules={[
                      {
                        required: true,
                        message: ERROR_MESSAGES.ADD_CLASS_CLASS_TYPE_REQUIRED,
                      },
                    ]}
                  >
                    <Select
                      disabled={getLessonOutlineLoading}
                      loading={getLessonOutlineLoading}
                    >
                      {lessonOutline.results.map((outline) => (
                        <Select.Option key={outline.id} value={outline.id}>
                          {outline.parent.en.title} /{" "}
                          {outline.title}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label="Class subject"
                    name="class_subject"
                    rules={[
                      {
                        required: true,
                        message:
                          ERROR_MESSAGES.ADD_CLASS_CLASS_SUBJECT_REQUIRED,
                      },
                    ]}
                  >
                    <Select
                      disabled={getClassSubjectsLoading}
                      loading={getClassSubjectsLoading}
                    >
                      {classSubjectData.results.map((classSubject) => (
                        <Select.Option
                          key={classSubject.id}
                          value={classSubject.id}
                        >
                          {classSubject.title}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label="Day of week"
                    name="days_of_week"
                    rules={[
                      {
                        required: true,
                        message: ERROR_MESSAGES.ADD_CLASS_DAY_OF_WEEK_REQUIRED,
                      },
                    ]}
                  >
                    <Select options={dayOfWeek} />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label="Time"
                    name="time"
                    rules={[
                      {
                        required: true,
                        message: ERROR_MESSAGES.ADD_CLASS_TIME_REQUIRED,
                      },
                    ]}
                  >
                    <DatePicker.TimePicker
                      format="HH:mm"
                      style={{ width: "100%" }}
                      showSecond={false}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={24}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label="Start date"
                    name="start_datetime"
                    rules={[
                      {
                        required: true,
                        message: ERROR_MESSAGES.ADD_CLASS_DATE_REQUIRED,
                      },
                      {
                        validator: (_, value: string) => {
                          if (checkLocalTime(value)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              `Start date must be on ${day}`
                            );
                          }
                        }
                      }
                    ]}
                  >
                    <DatePicker
                      format="ddd DD/MM/YYYY"
                      style={{ width: "100%" }}
                      disabled={!day}
                      disabledDate={date => date.isBefore(moment())}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={
                      "Max amount of students" +
                      (ageRange
                        ? ` (${ageRange.class_size.min_students} - ${ageRange.class_size.max_students || '∞'})`
                        : "")
                    }
                    name="max_students"
                    tooltip="Max. amount of students is set by Admin. You can choose a max. amount of students for your class within that range."
                    rules={[
                      {
                        required: true,
                        message: ERROR_MESSAGES.ADD_CLASS_MAX_STUDENT_REQUIRED,
                      },
                      {
                        validator: (_, value: string) => {
                          const student = parseFloat(value);
                          if (
                            !value ||
                            (ageRange &&
                              student >= Number(ageRange.class_size.min_students) &&
                              student <= (ageRange.class_size.max_students ? Number(ageRange.class_size.max_students) : 10000000) )
                          ) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              `Number of student must between ${ageRange && ageRange.class_size.min_students} and ${ageRange && ageRange.class_size.max_students}`
                            );
                          }
                        },
                      },
                    ]}
                  >
                    <Input
                      type="number"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <i className="font-serif">
                  Next, select your first 4 lessons for the month. After your
                  first month, you will be prompted to pick the next 4 lessons
                  for the next month.
                </i>
              </Form.Item>

              <Form.Item shouldUpdate>
                {({ getFieldsError, isFieldsTouched }) => (
                  <Space>
                    <Button
                      disabled={
                        !isFieldsTouched(true) ||
                        getFieldsError().filter(({ errors }) => errors.length > 0)
                          .length > 0
                      }
                      type="primary"
                      loading={addClassLoading}
                      htmlType="submit"
                    >  {params.classId ? 'Update class' : 'Next'}
                      {!params.classId && <ArrowRightOutlined />}
                    </Button>
                    {
                      params.classId &&
                      <Button
                        onClick={handleGoToLesson}
                      >
                        Add Next Upcoming Lessons
                        <ArrowRightOutlined />
                      </Button>
                    }
                  </Space>
                )}
              </Form.Item>
            </Form>
          </div>
        </div>

        <div className={step === AddClassStep.SELECT_LESSONS ? "" : "hidden"}>
          <Divider orientation="left">2. Select lesssons</Divider>
          <p className="font-serif">
            <i>
              Select your first 4 lessons for the month. After your first month,
              you will be prompted to pick the next 4 lessons for the next
              month.
            </i>
          </p>

          {
            step === AddClassStep.SELECT_LESSONS ?
              <>
                <ClassFilter />
                <Form onFinish={handleAddClassLessons}>
                  <Spin spinning={getLessonsLoading}>
                    <Form.Item name="upcoming_lessons" initialValue={upComingLesson}>
                      <Checkbox.Group>
                        {lessonData.results.map((lesson, index) => (
                          <LessonSelect key={lesson.id} lesson={lesson} />
                        ))}
                      </Checkbox.Group>
                    </Form.Item>
                  </Spin>

                  <Form.Item shouldUpdate>
                    {({ getFieldValue }) => (
                      <Space>
                        <Button
                          icon={<ArrowLeftOutlined />}
                          onClick={handleGoBackForm}
                        >
                          Back
                        </Button>
                        <Button
                          loading={addClassLoading}
                          disabled={
                            (getFieldValue("upcoming_lessons") || []).length !== 4
                          }
                          type="primary"
                          htmlType="submit"
                        >
                          {params.classId ? 'Update' : 'Add class'}
                        </Button>
                      </Space>
                    )}
                  </Form.Item>
                </Form>
              </>
              : ''}

        </div>
      </LayoutContent>
    </div>
  );
}
