import { PageHeader } from "antd";
import { ReactNode } from "react";

type AuthContainerProps = {
  children: ReactNode;
  title: string
};

export default function AuthContainer(props: AuthContainerProps) {
  return (
    <div
      style={{ width: "100%", maxWidth: 480 }}
      className="bg-white rounded shadow-lg overflow-hidden"
    >
      <PageHeader title={props.title} className="border-0 bg-gray-50 border-b border-solid border-gray-200" />
      <div className="px-10 py-6">
        {props.children}
      </div>
      
    </div>
  );
}
