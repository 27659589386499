import { Action } from "types/action";
import { Auth } from "aws-amplify";
import Cookie from "js-cookie";
import { COOKIE_CONFIG } from "configs/cookie";
import axios, { AxiosResponse } from "axios";
import {
  AUTH_URL,
  TEACHER_URL,
} from "constants/urls";
import { Profile, ProfileProgress } from "types/profile";
import { VerifyTeacherResponse } from "types/auth";
import { PaymentMethod } from "types/payment";

//  ============================================================
//  LOGIN
//  ============================================================
export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
function loginPending(): Action {
  return {
    type: LOGIN_PENDING,
  };
}

function loginSuccess(): Action {
  return {
    type: LOGIN_SUCCESS,
  };
}

function loginFailure(error: any): Action {
  return {
    type: LOGIN_FAILURE,
    payload: error,
  };
}

export function loginAction(body: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(loginPending());
      const response = await Auth.signIn({
        username: body.email,
        password: body.password,
      });
      const {
        signInUserSession: {
          refreshToken: { token },
          idToken: {
            jwtToken,
            payload: { exp, iat },
          },
        },
      } = response;

      const iatDate = new Date(iat * 1000);
      const expDate = new Date(exp * 1000);

      const refreshTokenExp = iatDate.setDate(iatDate.getDate() + 30); // 30 days, return number
      const refreshTokenExpDate = new Date(refreshTokenExp); // Convert to Date object

      Cookie.set(COOKIE_CONFIG.ACCESS_NAME, jwtToken, { expires: expDate });
      Cookie.set(COOKIE_CONFIG.REFRESH_NAME, token, {
        expires: refreshTokenExpDate,
      });
      dispatch(getProfileAction());
      dispatch(loginSuccess());
      return Promise.resolve();
    } catch (error) {
      dispatch(loginFailure(error));
      return Promise.reject(error);
    }
  };
}

//  ============================================================
//  GET PROFILE
//  ============================================================
export const GET_PROFILE_PENDING = "GET_PROFILE_PENDING";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";
function getProfilePending(): Action {
  return {
    type: GET_PROFILE_PENDING,
  };
}

function getProfileSuccess(user: Profile): Action {
  return {
    type: GET_PROFILE_SUCCESS,
    payload: user,
  };
}

function getProfileFailure(error: any): Action {
  return {
    type: GET_PROFILE_FAILURE,
    payload: error,
  };
}

export function getProfileAction() {
  return async (dispatch: Function) => {
    try {
      dispatch(getProfilePending());
      const response: AxiosResponse<Profile> = await axios.get(
        `${TEACHER_URL}/profile/`
      );
      dispatch(getProfileSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getProfileFailure(error));
      // Cookie.remove(COOKIE_CONFIG.ACCESS_NAME)
      // Cookie.remove(COOKIE_CONFIG.REFRESH_NAME)
      return Promise.reject(error);
    }
  };
}

//  ============================================================
//  FORGOT PASSWORD
//  ============================================================
export const FORGOT_PASSWORD_PENDING = "FORGOT_PASSWORD_PENDING";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";
function forgotPasswordPending(): Action {
  return {
    type: FORGOT_PASSWORD_PENDING,
  };
}

function forgotPasswordSuccess(username: string): Action {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload: username,
  };
}

function forgotPasswordFailure(error: any): Action {
  return {
    type: FORGOT_PASSWORD_FAILURE,
    payload: error,
  };
}

export function forgotPasswordAction(email: string) {
  return async (dispatch: Function) => {
    try {
      dispatch(forgotPasswordPending());
      await Auth.forgotPassword(email);
      dispatch(forgotPasswordSuccess(email));
      return Promise.resolve();
    } catch (error) {
      dispatch(forgotPasswordFailure(error));
      return Promise.reject(error);
    }
  };
}

//  ============================================================
//  RESET PASSWORD
//  ============================================================
export const RESET_PASSWORD_PENDING = "RESET_PASSWORD_PENDING";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
function resetPasswordPending(): Action {
  return {
    type: RESET_PASSWORD_PENDING,
  };
}

function resetPasswordSuccess(): Action {
  return {
    type: RESET_PASSWORD_SUCCESS,
  };
}

function resetPasswordFailure(error: any): Action {
  return {
    type: RESET_PASSWORD_FAILURE,
    payload: error,
  };
}

export function resetPasswordAction(
  username: string,
  code: string,
  password: string
) {
  return async (dispatch: Function) => {
    try {
      dispatch(resetPasswordPending());
      await Auth.forgotPasswordSubmit(username, code, password);
      dispatch(resetPasswordSuccess());
      return Promise.resolve();
    } catch (error) {
      dispatch(resetPasswordFailure(error));
      return Promise.reject(error);
    }
  };
}

//  ============================================================
//  VERIFY INVITATION
//  ============================================================
export const VERIFY_INVITATION_PENDING = "VERIFY_INVITATION_PENDING";
export const VERIFY_INVITATION_SUCCESS = "VERIFY_INVITATION_SUCCESS";
export const VERIFY_INVITATION_FAILURE = "VERIFY_INVITATION_FAILURE";
function verifyInvitationPending(): Action {
  return {
    type: VERIFY_INVITATION_PENDING,
  };
}

function verifyInvitationSuccess(response: VerifyTeacherResponse): Action {
  return {
    type: VERIFY_INVITATION_SUCCESS,
    payload: response,
  };
}

function verifyInvitationFailure(error: any): Action {
  return {
    type: VERIFY_INVITATION_FAILURE,
    payload: error,
  };
}

export function verifyInvitationAction(email: string, code: string) {
  return async (dispatch: Function) => {
    try {
      dispatch(verifyInvitationPending());
      const response: AxiosResponse<VerifyTeacherResponse> = await axios.put(
        `${AUTH_URL}/invitation-verify/`,
        {
          email,
          code,
        }
      );
      dispatch(verifyInvitationSuccess({ ...response.data, code }));
      return Promise.resolve();
    } catch (error) {
      dispatch(verifyInvitationFailure(error));
      return Promise.reject(error);
    }
  };
}

//  ============================================================
//  SIGN UP
//  ============================================================
export const SIGN_UP_PENDING = "SIGN_UP_PENDING";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAILURE = "SIGN_UP_FAILURE";
function signUpPending(): Action {
  return {
    type: SIGN_UP_PENDING,
  };
}

function signUpSuccess(): Action {
  return {
    type: SIGN_UP_SUCCESS,
  };
}

function signUpFailure(error: any): Action {
  return {
    type: SIGN_UP_FAILURE,
    payload: error,
  };
}

export function signUpAction(
  info: {
    id: string;
    email: string;
    phone: string;
    first_name: string;
    last_name: string;
    date_of_birth: string;
    language: 'en'
  },
  password: string
) {
  return async (dispatch: Function) => {
    try {
      dispatch(signUpPending());
      const { id, email, first_name, last_name  } = info;
      const name = `${first_name} ${last_name}`
      await Auth.signUp({
        username: id,
        password: password,
        attributes: {
          email,
          name
        },
      });
      await axios.post(`${AUTH_URL}/signup/`, info);
      dispatch(signUpSuccess());
      return Promise.resolve();
    } catch (error) {
      dispatch(signUpFailure(error));
      return Promise.reject(error);
    }
  };
}

//  ============================================================
//  COMPLETE SIGN UP
//  ============================================================
export const COMPLETE_SIGN_UP_PENDING = "COMPLETE_SIGN_UP_PENDING";
export const COMPLETE_SIGN_UP_SUCCESS = "COMPLETE_SIGN_UP_SUCCESS";
export const COMPLETE_SIGN_UP_FAILURE = "COMPLETE_SIGN_UP_FAILURE";
function completeSignUpPending(): Action {
  return {
    type: COMPLETE_SIGN_UP_PENDING,
  };
}

function completeSignUpSuccess(): Action {
  return {
    type: COMPLETE_SIGN_UP_SUCCESS,
  };
}

function completeSignUpFailure(error: any): Action {
  return {
    type: COMPLETE_SIGN_UP_FAILURE,
    payload: error,
  };
}

export function completeSignUpAction(
  info: {
    id: string;
    email: string;
    password: string;
  },
  verifyCode: string
) {
  return async (dispatch: Function) => {
    try {
      dispatch(completeSignUpPending());
      const { id } = info;
      await Auth.confirmSignUp(id, verifyCode);
      await axios.put(`${AUTH_URL}/email-verify/`, {user_id: id});
      dispatch(completeSignUpSuccess());
      return Promise.resolve();
    } catch (error) {
      dispatch(completeSignUpFailure(error));
      return Promise.reject(error);
    }
  };
}

//  ============================================================
//  Resend verify email
//  ============================================================
export const RESEND_EMAIL_CODE = "RESEND_CODE_PENDING";
export const RESEND_EMAIL_SUCCESS = "RESEND_CODE_SUCCESS";
export const RESEND_EMAIL_FAILURE = "RESEND_CODE_FAILURE";
function resendEmailCode(): Action {
  return {
    type: RESEND_EMAIL_CODE,
  };
}

function resentCodeSuccess(): Action {
  return {
    type: RESEND_EMAIL_SUCCESS,
    payload: 'success',
  };
}

function resentCodeFailure(error: any): Action {
  return {
    type: RESEND_EMAIL_FAILURE,
    payload: error,
  };
}

export function resentEmailCodeAction(email: string) {
  return async (dispatch: Function) => {
    try {
      dispatch(resendEmailCode());
      await Auth.resendSignUp(email)
      dispatch(resentCodeSuccess());
      return Promise.resolve();
    } catch (error) {
      dispatch(resentCodeFailure(error));
      return Promise.reject(error);
    }
  };
}



//  ============================================================
//  CHANGE AVATAR
//  ============================================================
export const CHANGE_AVATAR_PENDING = "CHANGE_AVATAR_PENDING";
export const CHANGE_AVATAR_SUCCESS = "CHANGE_AVATAR_SUCCESS";
export const CHANGE_AVATAR_FAILURE = "CHANGE_AVATAR_FAILURE";
function changeAvatarPending(): Action {
  return {
    type: CHANGE_AVATAR_PENDING,
  };
}

function changeAvatarSuccess(imageUrl: string): Action {
  return {
    type: CHANGE_AVATAR_SUCCESS,
    payload: imageUrl,
  };
}

function changeAvatarFailure(error: any): Action {
  return {
    type: CHANGE_AVATAR_FAILURE,
    payload: error,
  };
}

export function changeAvatarAction(imageUrl: string) {
  return async (dispatch: Function) => {
    try {
      dispatch(changeAvatarPending());
      await axios.put(TEACHER_URL + "/profile/profile-picture", {
        avatar: imageUrl,
      });
      dispatch(changeAvatarSuccess(imageUrl));
      return Promise.resolve();
    } catch (error) {
      dispatch(changeAvatarFailure(error));
      return Promise.reject(error);
    }
  };
}

//  ============================================================
//  UPDATE PROFILE
//  ============================================================
export const UPDATE_PROFILE_PENDING = "UPDATE_PROFILE_PENDING";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";
function updateProfilePending(): Action {
  return {
    type: UPDATE_PROFILE_PENDING,
  };
}

function updateProfileSuccess(profile: any): Action {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    payload: profile,
  };
}

function updateProfileFailure(error: any): Action {
  return {
    type: UPDATE_PROFILE_FAILURE,
    payload: error,
  };
}

export function updateProfileAction(body: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(updateProfilePending());
      await axios.put(TEACHER_URL + "/profile/", body);
      dispatch(updateProfileSuccess(body));
      return Promise.resolve();
    } catch (error) {
      dispatch(updateProfileFailure(error));
      return Promise.reject(error);
    }
  };
}

//  ============================================================
//  GET PAYMENT METHOD
//  ============================================================
export const GET_PAYMENT_PENDING = "GET_PAYMENT_PENDING";
export const GET_PAYMENT_SUCCESS = "GET_PAYMENT_SUCCESS";
export const GET_PAYMENT_FAILURE = "GET_PAYMENT_FAILURE";
function getPaymentPending(): Action {
  return {
    type: GET_PAYMENT_PENDING,
  };
}

function getPaymentSuccess(payment: PaymentMethod): Action {
  return {
    type: GET_PAYMENT_SUCCESS,
    payload: payment,
  };
}

function getPaymentFailure(error: any): Action {
  return {
    type: GET_PAYMENT_FAILURE,
    payload: error,
  };
}

export function getPaymentAction() {
  return async (dispatch: Function) => {
    try {
      dispatch(getPaymentPending());
      const response: AxiosResponse<PaymentMethod> = await axios.get(
        TEACHER_URL + "/payment-details/"
      );
      dispatch(getPaymentSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getPaymentFailure(error));
      return Promise.reject(error);
    }
  };
}

//  ============================================================
//  CREATE PAYMENT METHOD
//  ============================================================
export const CREATE_PAYMENT_PENDING = "CREATE_PAYMENT_PENDING";
export const CREATE_PAYMENT_SUCCESS = "CREATE_PAYMENT_SUCCESS";
export const CREATE_PAYMENT_FAILURE = "CREATE_PAYMENT_FAILURE";
function createPaymentPending(): Action {
  return {
    type: CREATE_PAYMENT_PENDING,
  };
}

function createPaymentSuccess(): Action {
  return {
    type: CREATE_PAYMENT_SUCCESS,
  };
}

function createPaymentFailure(error: any): Action {
  return {
    type: CREATE_PAYMENT_FAILURE,
    payload: error,
  };
}

export function createPaymentAction(body: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(createPaymentPending());
      await axios.put(TEACHER_URL + "/payment-details/", body);
      dispatch(createPaymentSuccess());
      return Promise.resolve();
    } catch (error) {
      dispatch(createPaymentFailure(error));
      return Promise.reject(error);
    }
  };
}


//  ============================================================
//  Upload stripe document
//  ============================================================
export const STRIPE_DOCUMENT_PENDING = "STRIPE_DOCUMENT_PENDING";
export const STRIPE_DOCUMENT_SUCCESS = "STRIPE_DOCUMENT_SUCCESS";
export const STRIPE_DOCUMENT_FAILURE = "STRIPE_DOCUMENT_FAILURE";
function stripeDocumentPending(): Action {
  return {
    type: STRIPE_DOCUMENT_PENDING,
  };
}

function stripeDocumentSuccess(): Action {
  return {
    type: STRIPE_DOCUMENT_SUCCESS,
  };
}

function stripeDocumentFailure(error: any): Action {
  return {
    type: STRIPE_DOCUMENT_FAILURE,
    payload: error,
  };
}

export function uploadStripeDocumentAction(body: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(stripeDocumentPending());
      const response = await axios.post(TEACHER_URL + "/payment-details/stripe/documents/", body);
      await axios.put(TEACHER_URL + "/payment-details/stripe/account/verify/", response.data);
      dispatch(stripeDocumentSuccess());
      return Promise.resolve();
    } catch (error) {
      dispatch(stripeDocumentFailure(error));
      return Promise.reject(error);
    }
  };
}


//  ============================================================
//  UPDATE PASSWORD
//  ============================================================
export const UPDATE_PASSWORD_PENDING = "UPDATE_PASSWORD_PENDING";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE";
function updatePasswordPending(): Action {
  return {
    type: UPDATE_PASSWORD_PENDING,
  };
}

function updatePasswordSuccess(): Action {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
  };
}

function updatePasswordFailure(error: any): Action {
  return {
    type: UPDATE_PASSWORD_FAILURE,
    payload: error,
  };
}

export function updatePasswordAction(oldPassword: string, newPassword: string) {
  return async (dispatch: Function) => {
    try {
      dispatch(updatePasswordPending());
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);
      dispatch(updatePasswordSuccess());
      return Promise.resolve();
    } catch (error) {
      dispatch(updatePasswordFailure(error));
      return Promise.reject(error);
    }
  };
}

//  ============================================================
//  SIGN OUT
//  ============================================================
export const SIGN_OUT_PENDING = "SIGN_OUT_PENDING";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const SIGN_OUT_FAILURE = "SIGN_OUT_FAILURE";
function signOutPending(): Action {
  return {
    type: SIGN_OUT_PENDING,
  };
}

function signOutSuccess(): Action {
  return {
    type: SIGN_OUT_SUCCESS,
  };
}

function signOutFailure(error: any): Action {
  return {
    type: SIGN_OUT_FAILURE,
    payload: error,
  };
}

export function signOutAction() {
  return async (dispatch: Function) => {
    try {
      dispatch(signOutPending());
      localStorage.clear();
      Cookie.remove(COOKIE_CONFIG.ACCESS_NAME);
      Cookie.remove(COOKIE_CONFIG.REFRESH_NAME);
      window.location.href = "/auth/login";
      await Auth.signOut();
      dispatch(signOutSuccess());
      return Promise.resolve();
    } catch (error) {
      dispatch(signOutFailure(error));
      return Promise.reject(error);
    }
  };
}

//  ============================================================
//  GET PROFILE PROGRESS
//  ============================================================
export const GET_PROFILE_PROGRESS_PENDING = "GET_PROFILE_PROGRESS_PENDING";
export const GET_PROFILE_PROGRESS_SUCCESS = "GET_PROFILE_PROGRESS_SUCCESS";
export const GET_PROFILE_PROGRESS_FAILURE = "GET_PROFILE_PROGRESS_FAILURE";
function getProfileProgressPending(): Action {
  return {
    type: GET_PROFILE_PROGRESS_PENDING
  }
}

function getProfileProgressSuccess(progress: ProfileProgress): Action {
  return {
    type: GET_PROFILE_PROGRESS_SUCCESS,
    payload: progress
  }
}

function getProfileProgressFailure(error: any): Action {
  return {
    type: GET_PROFILE_PROGRESS_FAILURE,
    payload: error
  }
}

export function getProfileProgressAction() {
  return async (dispatch: Function) => {
    try {
      dispatch(getProfileProgressPending());
      const response = await axios.get(TEACHER_URL + "/profile/progress");
      dispatch(getProfileProgressSuccess(response.data.results.profile_completed_steps));
      return Promise.resolve();
    } catch (error) {
      dispatch(getProfileProgressFailure(error));
      return Promise.reject(error)
    }
  }
}