import { DOWNLOAD_FLASHCARDS_FAILURE, DOWNLOAD_FLASHCARDS_PENDING, DOWNLOAD_FLASHCARDS_SUCCESS, GET_CRAFT_FAILURE, GET_CRAFT_PENDING, GET_CRAFT_SUCCESS, GET_FLASHCARDS_FAILURE, GET_FLASHCARDS_PENDING, GET_FLASHCARDS_SUCCESS } from "store/actions/printable.action"
import { Action } from "types/action"
import { PrintableResourceData } from "types/printable"
import {saveAs} from 'file-saver'
export type PrintableState = {
  // Data
  craftData: PrintableResourceData;
  flashCardData: PrintableResourceData;
  // Loading
  getPrintableLoading: boolean;
  downloadFlashCardsLoading: boolean;
  // Error
  getPrintableError: any;
}

const initialState: PrintableState = {
  // Data
  craftData: { results: [], totalRow: 0 },
  flashCardData: { results: [], totalRow: 0 },
  // Loading
  getPrintableLoading: false,
  downloadFlashCardsLoading: false,
  // Error
  getPrintableError: null
}

export default function printableReducer(state = initialState, action: Action): PrintableState {
  switch (action.type) {
    //  ============================================================
    //  GET PRINTABLE RESOURCES
    //  ============================================================
    case GET_CRAFT_PENDING:
      return {
        ...state,
        getPrintableLoading: true
      }
    case GET_CRAFT_SUCCESS:
      return {
        ...state,
        getPrintableLoading: false,
        craftData: action.payload
      }
    case GET_CRAFT_FAILURE:
      return {
        ...state,
        getPrintableLoading: false,
        getPrintableError: action.payload
      }
    //  ============================================================
    //  GET PRINTABLE RESOURCES
    //  ============================================================
    case GET_FLASHCARDS_PENDING:
      return {
        ...state,
        getPrintableLoading: true
      }
    case GET_FLASHCARDS_SUCCESS:
      return {
        ...state,
        getPrintableLoading: false,
        flashCardData: action.payload
      }
    case GET_FLASHCARDS_FAILURE:
      return {
        ...state,
        getPrintableLoading: false,
        getPrintableError: action.payload
      }
    //  ============================================================
    //  Download flashcards
    //  ============================================================
    case DOWNLOAD_FLASHCARDS_PENDING:
      return {
        ...state,
        downloadFlashCardsLoading: true
      }
    case DOWNLOAD_FLASHCARDS_SUCCESS:
      saveAs(action.payload, `download_file_${new Date().toString()}.zip`);
      return {
        ...state,
        downloadFlashCardsLoading: false,
      }
    case DOWNLOAD_FLASHCARDS_FAILURE:
      return {
        ...state,
        downloadFlashCardsLoading: false,
      }
    default:
      return { ...state }
  }
}