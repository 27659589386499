import { Menu, Layout, Modal } from "antd";
import { POPUP_CONTENT } from "constants/content";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { routes } from "../../constants/routes";
import { useDispatch } from "react-redux";
import { signOutAction } from "store/actions/auth.action";

const { Sider } = Layout;

export default function PortalSider() {
  // React router dom
  const { pathname } = useLocation();

  // React redux
  const dispatch = useDispatch();

  function renderRoutes() {
    return routes.map((route) =>
      route.children ? (
        <Menu.SubMenu title={route.text} key={route.href}>
          {route.children.map((childRoute) => (
            <Menu.Item
              key={childRoute.href}
              className={
                (childRoute.href === "/" && pathname === "/") ||
                (childRoute.href !== "/" && pathname.endsWith(childRoute.href))
                  ? "ant-menu-item-selected"
                  : ""
              }
            >
              <Link to={route.href + childRoute.href}>{childRoute.text}</Link>
            </Menu.Item>
          ))}
        </Menu.SubMenu>
      ) : (
        <Menu.Item
          key={route.href}
          className={
            (route.href === "/" && pathname === "/") ||
            (route.href !== "/" && pathname.includes(route.href))
              ? "ant-menu-item-selected"
              : ""
          }
        >
          <Link to={route.href}>{route.text}</Link>
        </Menu.Item>
      )
    );
  }

  function handleLogout() {
    Modal.confirm({
      ...POPUP_CONTENT.LOG_OUT,
      onOk: async () => {
        try {
          await dispatch(signOutAction());
        } catch (error) {
          //
        }
      },
    });
  }

  return (
    <Sider className="min-h-screen" width={240} theme="dark">
      <h1 className="text-xl text-white m-0 p-3">A&MM Teacher</h1>
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[pathname]}
        defaultOpenKeys={
          pathname.includes("/resources") ? ["/resources"] : []
        }
      >
        {renderRoutes()}
        <Menu.Divider />
        <Menu.Item key="logout" onClick={handleLogout}>
          Logout
        </Menu.Item>
      </Menu>
    </Sider>
  );
}
