import { PageHeader, Steps, Divider, Button, Space, Result } from "antd";
import LayoutContent from "components/LayoutContent/LayoutContent";
import { useState } from "react";
import { Link } from "react-router-dom";
import ProfileTab from "pages/profile/tabs/ProfileTab";
import { useForm } from "antd/lib/form/Form";
import SubscriptionFees from "pages/financials/tabs/SubscriptionFees";
import PaymentTab from "pages/profile/tabs/PaymentTab";
import { RootState } from "store/reducer";
import { AuthState } from "store/reducers/auth.reducer";
import { useSelector } from "react-redux";
import { SubscriptionState } from "store/reducers/subscription.reducer";
import { useEffect } from "react";
import { useMemo } from "react";
import { checkCompletedProfileProgress } from "helpers/profileProgress";

enum Step {
  PROFILE = 0,
  CLASS_FEES = 1,
  BANK_DETAILS = 2,
  FIRST_CLASS = 3,
}

export default function CompleteProfilePage() {
  // State
  const [step, setStep] = useState<Step>(-1);
  const [completeProfileProgress, setCompleteProfileProgress] = useState(1);
  // React redux
  const {
    user,
    updateProfileLoading,
    createPaymentLoading,
    getProfileProgressLoading,
    getPaymentLoading
  } = useSelector<RootState, AuthState>((state) => state.authState);
  const { updateSubscriptionFeesLoading } = useSelector<
    RootState,
    SubscriptionState
  >((state) => state.subscriptionState);

  const [profileForm] = useForm();
  const [feeForm] = useForm();
  const [paymentForm] = useForm();

  const firstNotCompletedStep = useMemo(() => {
    let result: Step = Step.PROFILE;
    if (user) {
      if (!user.profile_completed_steps.PROFILE) {
        result = Step.PROFILE
      } else if (!user.profile_completed_steps.CLASS_FEE) {
        result = Step.CLASS_FEES
      } else if (!user.profile_completed_steps.PAYMENT_DETAILS) {
        result = Step.BANK_DETAILS
      } else {
        result = Step.FIRST_CLASS
      }
    }
    return result;
  }, [user]);

  useEffect(() => {
    if (user) {
      if (Number(step) === -1) {
        setStep(firstNotCompletedStep);
      }
      setCompleteProfileProgress(checkCompletedProfileProgress(user))
    }
  }, [user, step, firstNotCompletedStep]);

  function handleBack() {
    setStep((currentStep) => currentStep - 1);
  }

  async function handleNext() {
    try {
      if (step === Step.PROFILE) {
        profileForm.submit();
      } else if (step === Step.CLASS_FEES) {
        feeForm.submit();
      } else if (step === Step.BANK_DETAILS) {
        paymentForm.submit();
      }
    } catch (error) {
      //
    }
  }

  function onUpdateProfileComplete() {
    setStep((currentStep) => currentStep + 1);
  }

  function onUpdateFeesComplete() {
    setStep((currentStep) => currentStep + 1);

  }

  function onUpdatePaymentComplete() {
    setStep((currentStep) => currentStep + 1);
  }

  return (
    <div>
      <PageHeader title="Complete your profile" />

      <LayoutContent>
        {user && completeProfileProgress < 100 && (
          <Steps current={step}>
            <Steps.Step title="Profile photo" />
            <Steps.Step title="Class fees" />
            <Steps.Step title="Add bank details" />
            <Steps.Step title="Add your first class" />
          </Steps>
        )}

        <div className="pt-10 pb-6">
          {step === Step.PROFILE && (
            <ProfileTab
              form={profileForm}
              onUpdateSuccess={onUpdateProfileComplete}
            />
          )}

          {step === Step.CLASS_FEES && (
            <div style={{ maxWidth: 1024 }}>
              <SubscriptionFees
                form={feeForm}
                onUpdateSuccess={onUpdateFeesComplete}
              />
            </div>
          )}

          {step === Step.BANK_DETAILS && (
            <div style={{ maxWidth: 320 }}>
              <PaymentTab
                form={paymentForm}
                onUpdateSuccess={onUpdatePaymentComplete}
              />
            </div>
          )}

          {step === Step.FIRST_CLASS && (
            <div className="text-center">
              <Link to="/my-classes/add-new">
                <Button type="primary">Add your first class</Button>
              </Link>
            </div>
          )}
        </div>

        {user && completeProfileProgress < 100 && (
          <div>
            <Divider />
            <Space>
              {step > Step.PROFILE && (
                <Button onClick={handleBack}>Back</Button>
              )}

              {step < Step.FIRST_CLASS && (
                <Button
                  onClick={handleNext}
                  type="primary"
                  loading={
                    updateProfileLoading ||
                    updateSubscriptionFeesLoading ||
                    createPaymentLoading ||
                    getProfileProgressLoading ||
                    getPaymentLoading
                  }
                >
                  Next
                </Button>
              )}
            </Space>
          </div>
        )}

        {user && completeProfileProgress === 100 && (
          <Result
            status="success"
            title="Successfully"
            subTitle="Your profile has been completed!"
            extra={
              <Link to="/dashboard">
                <Button type="primary">Go to dashboard</Button>
              </Link>
            }
          />
        )}
      </LayoutContent>
    </div>
  );
}
