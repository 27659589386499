import { PageHeader, Tabs } from "antd";
import LayoutContent from "components/LayoutContent/LayoutContent";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useMemo } from "react";
import SubscriptionFees from "./tabs/SubscriptionFees";
import UpcomingTransactions from "./tabs/UpcomingTransactions";
import PastTransactions from "./tabs/PastTransactions";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { getSubscriptionFeesAction } from "store/actions/subscription.action";
import { useEffect } from "react";
import {
  getPastTransactionsAction,
  getTotalTransactionsAction,
  getUpcomingTransactionsAction,
} from "store/actions/transaction.action";
import { getAllClassesAction, getCurrentClassesAction } from "store/actions/class.action";

enum FinancialTabKey {
  SUBSCRIPTION_FEE = "my-subscription-fees",
  UPCOMING_TRANSACTION = "upcoming-transactions",
  PAST_TRANSCTION = "past-transactions",
}

export default function FinancialsPage() {
  // React router
  const { pathname, search } = useLocation();
  const { push } = useHistory();

  // React redux
  const dispatch = useDispatch();

  // Variables
  const query: any = useMemo(() => queryString.parse(search), [search]);

  const fetchData = useCallback(() => {
    try {
      if (query.tab === FinancialTabKey.UPCOMING_TRANSACTION) {
        if (query.class) {
          Promise.all([
            dispatch(getCurrentClassesAction({ no_pagination: true })),
            dispatch(getUpcomingTransactionsAction({status: 3, class_id: query.class})),
            dispatch(getTotalTransactionsAction({class_id: query.class}))
          ]);
        } else {
          dispatch(getCurrentClassesAction({ no_pagination: true }));
        }
      } else if (query.tab === FinancialTabKey.PAST_TRANSCTION) {
        if (query.class) {
          Promise.all([
            dispatch(getAllClassesAction({ no_pagination: true })),
            dispatch(getPastTransactionsAction({class_id: query.class})),
            dispatch(getTotalTransactionsAction({class_id: query.class}))
          ]);
        } else {
          dispatch(getAllClassesAction({ no_pagination: true }));
        }
      } else {
        dispatch(getSubscriptionFeesAction());
      }
    } catch (error) {
      //
    }
  }, [query, dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function handleTabChange(activeKey: string) {
    push(pathname + "?tab=" + activeKey);
  }

  return (
    <div>
      <PageHeader title="Financials" />

      <LayoutContent>
        <Tabs
          activeKey={query.tab || FinancialTabKey.SUBSCRIPTION_FEE}
          onChange={handleTabChange}
        >
          <Tabs.TabPane
            tab="My subscription fees"
            key={FinancialTabKey.SUBSCRIPTION_FEE}
          >
            <SubscriptionFees />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab="Upcoming transactions"
            key={FinancialTabKey.UPCOMING_TRANSACTION}
          >
            <UpcomingTransactions currentClass={query.class} />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab="Past transactions"
            key={FinancialTabKey.PAST_TRANSCTION}
          >
            <PastTransactions currentClass={query.class} />
          </Tabs.TabPane>
        </Tabs>
      </LayoutContent>
    </div>
  );
}
