import { Button, Upload, UploadProps } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { customUploadRequest } from "helpers/upload";
import { UploadChangeParam } from "antd/lib/upload";
import { useState } from "react";
import { UploadFile } from "antd/lib/upload/interface";
import UploadButton from "components/UploadButton/UploadButton";
import { useEffect } from "react";

type FileInputProps = {
  value?: string;
  isMulti?: boolean;
  onChange?: (url?: string) => void;
};

export default function FileInput({
  onChange,
  value,
  isMulti,
  ...props
}: FileInputProps & UploadProps) {
  const [fileList, setFileList] = useState<Array<UploadFile>>([]);

  useEffect(() => {
    console.log(value);
    if (value) {
      setFileList([{
        url: value,
        uid: value,
        name: value,
        response: {
          file: value
        }
      }])
    }
  }, [value])

  function onUploadChange(info: UploadChangeParam) {
    if (onChange) {
      onChange(info.file.response?.file);
    }

    setFileList(info.fileList);
  }

  return (
    <Upload
      {...props}
      onChange={onUploadChange}
      fileList={fileList}
      customRequest={customUploadRequest}
    >
      {fileList.length === 0 && !isMulti ? (
        props.listType !== "picture-card" ? (
          <Button icon={<UploadOutlined />}>Upload</Button>
        ) : (
          <UploadButton />
        )
      ) : isMulti ? ( <UploadButton />) : null}
    </Upload>
  );
}