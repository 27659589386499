import { Modal } from "antd";
import Plyr, { HTMLPlyrVideoElement, PlyrProps } from "plyr-react";
import "plyr-react/dist/plyr.css";
import { useState } from "react";
import "./VideoPlayer.scss";
import playRed from "assets/images/play-red.png";
import { useRef } from "react";

type VideoPlayerProps = {
  showName?: boolean;
  name?: string;
  src?: string;
  thumbnail?: string;
};

export default function VideoPlayer({
  showName = false,
  name,
  thumbnail,
  ...props
}: VideoPlayerProps & PlyrProps) {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const plyrRef = useRef<HTMLPlyrVideoElement>(null);

  function onThumbnailClick() {
    setModalVisible(true);
  }

  function onCancel() {
    setModalVisible(false);

    if (plyrRef.current && plyrRef.current.plyr) {
      plyrRef.current.plyr.stop();
    }
  }

  return (
    <div className="video-player-wrapper">
      <div
        className="video-player__thumbnail-wrapper"
        onClick={onThumbnailClick}
      >
        <img
          className="video-player__thumbnail"
          src={thumbnail}
          alt="thumbnail"
        />

        <img className="video-player__play" src={playRed} width="40" height="40" alt="play-red" />
      </div>

      {showName && name && <p className="py-2">{name}</p>}

      <Modal visible={modalVisible} onCancel={onCancel} title={name || "Video"} footer={false}>
        <Plyr
          {...props}
          ref={plyrRef}
          options={{
            autoplay: true,
            ratio: "16:9",
          }}
        />
      </Modal>
    </div>
  );
}
