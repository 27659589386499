import { Layout, notification } from "antd";
import AuthGuard from "components/AuthGuard/AuthGuard";
import { Redirect, Route, Switch } from "react-router-dom";
import PortalNavbar from "../components/PortalNavbar/ProtalNavbar";
import PortalSider from "../components/PortalSider/PortalSider";
import { routes } from "../constants/routes";
import LessonCalendar from "pages/my-classes/LessonCalendar";
import AddNewClassPage from "pages/my-classes/AddNewClassPage";
import LessonDetails from "pages/my-classes/class-lesson-details/ClassLessonDetails";
import ClassDetails from "pages/curriculum/class-details/ClassDetails";
import StudentDetails from "pages/my-students/student-details/StudentDetails";
import LessonsCompleted from "pages/my-students/student-details/LessonsCompleted";
import VocabularyLearned from "pages/my-students/student-details/VocabularyLearned";
import BooksRead from "pages/my-students/student-details/BooksRead";
import ProfilePage from "pages/profile/ProfilePage";
import CompleteProfilePage from "pages/complete-profile/CompleteProfile";
import BookDetails from "pages/resources/books/BookDetails";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PK } from "configs/stripe";
import ReviewPage from "pages/review/ReviewPage";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { ChatState } from "store/reducers/chat.reducer";
import { socketSendMessageAction } from "store/actions/chat.action";
import { useRef } from "react";
import { useCallback } from "react";
import { getNotificationsAction, getNotificationsUnreadAction, getUpdateFcmTokenAction } from "store/actions/notification.action";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { FIREBASE_API_KEY } from "configs/firebase";
import { NotifyState } from "store/reducers/notification.reducer";


const stripePromise = loadStripe(STRIPE_PK);

const { Content } = Layout;

export default function PortalLayout() {
  const messaging = getMessaging();
  const dispatch = useDispatch();
  const { socketConnected } = useSelector<RootState, ChatState>(state => state.chatState);
  const { readAll } = useSelector<RootState, NotifyState>(state => state.notifyStage);
  const interval = useRef<number>();

  const ping = useCallback(() => {
    dispatch(socketSendMessageAction({ route: "ping" }));
  }, [dispatch]);

  useEffect(() => {
    if (socketConnected) {
      // Ping to the Websocket server every 5 minutes
      interval.current = window.setInterval(ping, 1000 * 60 * 5);
    } else {
      clearInterval(interval.current);
    }
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      notification['info']({
        message: 'New Notification',
        description:
          'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      });
      // ...
    });

  }, [socketConnected, ping, messaging]);

  const fetchData = useCallback(() => {
    try {
      dispatch(getNotificationsAction());
      dispatch(getNotificationsUnreadAction())
    } catch (error) {
      //
    }
  }, [dispatch]);


  const getFCMToken = useCallback(() => {
    getToken(messaging, { vapidKey: FIREBASE_API_KEY }).then((currentToken) => {
      if (currentToken) {
        dispatch(getUpdateFcmTokenAction({ firebase_registration_id: currentToken }))
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
        // ...
      }
    }).catch((err) => { });
  }, [dispatch, messaging]);


  useEffect(() => {
    fetchData();
  }, [fetchData, readAll])

  useEffect(() => {
    getFCMToken();
  }, [getFCMToken]);

  return (
    <AuthGuard>
      <Elements stripe={stripePromise}>
        <Layout>
          <PortalSider />
          <Layout>
            <PortalNavbar />
            <Content>
              <Switch>
                {routes.map((route) =>
                  route.children ? (
                    route.children.map((childRoute) => (
                      <Route
                        key={route.href + childRoute.href}
                        path={route.href + childRoute.href}
                        component={childRoute.component}
                        exact
                      />
                    ))
                  ) : (
                    <Route
                      key={route.href}
                      path={route.href}
                      component={route.component}
                      exact
                    />
                  )
                )}

                {/* Custom routes */}
                <Route path="/my-classes/lesson-calendar" exact>
                  <LessonCalendar />
                </Route>

                <Route path="/my-classes/add-new" exact>
                  <AddNewClassPage />
                </Route>

                <Route path="/my-classes/details/:classId" exact>
                  <AddNewClassPage />
                </Route>

                <Route path="/my-classes/:classId" exact>
                  <LessonDetails />
                </Route>

                <Route path="/curriculum/:lessonId" exact>
                  <ClassDetails />
                </Route>

                <Route path="/my-students/:studentId" exact>
                  <StudentDetails />
                </Route>

                <Route path="/my-students/:studentId/lessons-completed" exact>
                  <LessonsCompleted />
                </Route>

                <Route path="/my-students/:studentId/vocabulary-learned" exact>
                  <VocabularyLearned />
                </Route>

                <Route path="/my-students/:studentId/books-read" exact>
                  <BooksRead />
                </Route>

                <Route path="/profile" exact>
                  <ProfilePage />
                </Route>

                <Route path="/complete-profile" exact>
                  <CompleteProfilePage />
                </Route>

                <Route path="/resources/books/:bookId" exact>
                  <BookDetails />
                </Route>

                <Route path="/my-reviews" exact>
                  <ReviewPage />
                </Route>

                <Redirect to="/dashboard" />
              </Switch>
            </Content>
          </Layout>
        </Layout>
      </Elements>
    </AuthGuard>
  );
}
