import { PageHeader, Tabs } from "antd";
import LayoutContent from "components/LayoutContent/LayoutContent";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useMemo } from "react";
import ProfileTab from "./tabs/ProfileTab";
import PaymentTab from "./tabs/PaymentTab";

enum ProfileTabKey {
  PROFILE = "profile",
  PAYMENT = "payment-details",
}

export default function ProfilePage() {
  // React router
  const { search, pathname } = useLocation();
  const { push } = useHistory();

  // Variables
  const query: any = useMemo(() => queryString.parse(search), [search]);

  function handleTabChange(activeKey: string) {
    push(pathname + "?tab=" + activeKey);
  }

  return (
    <div>
      <PageHeader title="Account details" />

      <LayoutContent>
        <Tabs
          onChange={handleTabChange}
          activeKey={query.tab || ProfileTabKey.PROFILE}
        >
          <Tabs.TabPane tab="Profile details" key={ProfileTabKey.PROFILE}>
            <ProfileTab />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Payment details" key={ProfileTabKey.PAYMENT}>
            <PaymentTab />
          </Tabs.TabPane>
        </Tabs>
      </LayoutContent>
    </div>
  );
}
