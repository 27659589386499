import classNames from "classnames";

import "./Message.scss";

type MessageProps = {
  role: "teacher" | "parent";
  content: string;
  name?: string;
};

export default function Message(props: MessageProps) {
  const messageClasses = classNames("message", `message-${props.role}`);

  const messageContentClasses = classNames("message-content", {
    "bg-primary": props.role === "teacher",
  });

  return (
    <div className={messageClasses}>
      <div className="message-wrapper">
        {props.role !== "teacher" && props.name && (
          <div className="message__name">{props.name}:</div>
        )}
        <div className={messageContentClasses}>{props.content}</div>
      </div>
    </div>
  );
}
