import { environment, Staging } from "./env";

let firebaseConfig = {  
    apiKey: "AIzaSyBMxyauiPRMqP9RKVzUP0u-C9M_sACrZww",
  authDomain: "trinull-tmxgnp.firebaseapp.com",
  databaseURL: "https://trinull-tmxgnp.firebaseio.com",
  projectId: "trinull-tmxgnp",
  storageBucket: "trinull-tmxgnp.appspot.com",
  appId: "1:578193233190:web:fae2768b053a3f95a6dde7",
  messagingSenderId: "578193233190",
  measurementId: ''
};
let vapidKey = 'BB0iz4Z81t4A-_R6EKt2QndO2MO7bb-BLGtdW2FjuEUOSrZwRCwRgRhfoWgRoEhFDOaG05_auaUjBUVZWs0Q_C0'
if (environment.staging === Staging.Stage) {
    firebaseConfig =  {
        apiKey: "AIzaSyAaiM0JFbNK_6zVydvCMPpCvtmYV9tgt_I",
        authDomain: "adam-stage.firebaseapp.com",
        databaseURL: '',
        projectId: "adam-stage",
        storageBucket: "adam-stage.appspot.com",
        messagingSenderId: "916658660894",
        appId: "1:916658660894:web:4c094edca63d45d05bf00f",
        measurementId: "G-Z08VKYLTKT"
    };
    vapidKey = 'BJJrXQXpFokZkFuYd9iJU5-mKd2PiTM-xQA3yf-t_qxCyiSOmhyJKEGcOeAe0hGz503Z83RARKTbDQdCdR4iZkU'
} else if (environment.prod === Staging.Prod) {
    firebaseConfig =  {  
        apiKey: "AIzaSyC8jI3vDcvRY5Q6izXiQvj5WjgYV8KhjAw",
        authDomain: "adam-prod-64923.firebaseapp.com",
        databaseURL: '',
        projectId: "adam-prod-64923",
        storageBucket: "adam-prod-64923.appspot.com",
        messagingSenderId: "98576052251",
        appId: "1:98576052251:web:d8cd8a717e71828a2b08be",
        measurementId: "G-H0DPHECTZW"
    };
    vapidKey = 'BO5GzkLikQ_ghHjveCj6rdhWqvzeLZo-assbtDCBjU8yqlxD-C5s7FVtnVRGtHvz-JTYI6GQPDSvTUtKwhWKXWw'
}

export const FIREBASE_CONFIG = firebaseConfig;
export const FIREBASE_API_KEY = vapidKey