import {
  Button,
  PageHeader,
  Progress,
  Row,
  Col,
  Card,
  Statistic,
  Spin,
} from "antd";
import LayoutContent from "components/LayoutContent/LayoutContent";
import { Link } from "react-router-dom";
// import HorizontalLessonThumbnail from "components/HorizontalLessonThumbnail/HorizontalLessonThumbnail";

import "./DashboardPage.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { AuthState } from "store/reducers/auth.reducer";
import { useCallback } from "react";
import {
  getDashboardAction,
} from "store/actions/dashboard.action";
import { useEffect } from "react";
import { DashboardState } from "store/reducers/dashboard.reducer";
import moment from "moment";
import HorizontalLessonThumbnail from "components/HorizontalLessonThumbnail/HorizontalLessonThumbnail";
import { checkCompletedProfileProgress } from "helpers/profileProgress";

export default function DashboardPage() {
  // React redux
  const dispatch = useDispatch();
  const { user } = useSelector<RootState, AuthState>(
    (state) => state.authState
  );
  const { dashboardData, getDashboardLoading } = useSelector<
    RootState,
    DashboardState
  >((state) => state.dashboardState);

  const fetchData = useCallback(() => {
    try {
      Promise.all([
        dispatch(getDashboardAction())
      ]);
    } catch (error) {
      //
    }
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function renderCompleteProfile() {
    // const itemComplete = Object.values(true).length;
    if (user && checkCompletedProfileProgress(user) < 100) {
      const completeProfileProgress = checkCompletedProfileProgress(user)
      if (completeProfileProgress < 100) {
        return [
          <div className="dashboard-progress-wrapper" key="percent">
            <Progress
              format={(percent) => `Profile progress: ${percent}%`}
              percent={completeProfileProgress}
              strokeColor="#DB2522"
              trailColor="#FEC02D"
            />
          </div>,
          <Link to="/complete-profile" key="complete-profile">
            <Button type="primary">Complete profile</Button>
          </Link>,
        ];
      } else {
        return null;
      }
    }
    return null
  }

  return (
    <div className="dashboard">
      <PageHeader title="Dashboard" extra={renderCompleteProfile()} />
      <LayoutContent>
        <Row gutter={48}>
          <Col className="mb-4" xs={24} xl={8}>
            <Spin spinning={getDashboardLoading}>
              <Card type="inner" title="Completed lessons">
                <div className="flex justify-between">
                  <Statistic
                    title="This month"
                    value={
                      dashboardData?.class_comleted.total_lesson_completed_in_month
                    }
                  />
                  <Statistic
                    title={`Since joining ${moment(user?.created_at).format(
                      "DD MMM YYYY"
                    )}`}
                    value={
                      dashboardData?.class_comleted.total_lesson_completed
                    }
                  />
                </div>
              </Card>
            </Spin>
          </Col>
          <Col className="mb-4" xs={24} xl={8}>
            <Spin spinning={getDashboardLoading}>
              <Card type="inner" title="Total earnings">
                <div className="flex justify-between">
                  <Statistic
                    title="This month"
                    value={(dashboardData?.total_eatings.earning_in_month || 0).toFixed(2)}
                    prefix="$"
                  />
                  <Statistic
                    title={`Since joining ${moment(user?.created_at).format(
                      "DD MMM YYYY"
                    )}`}
                    value={parseFloat(
                      dashboardData?.total_eatings.total_earning || "0"
                    ).toFixed(2)}
                    prefix="$"
                  />
                </div>
              </Card>
            </Spin>
          </Col>
          <Col className="mb-4" xs={24} xl={8}>
            <Spin spinning={getDashboardLoading}>
              <Card type="inner" title="Number of students">
                <div className="flex justify-between">
                  <Statistic
                    title="Students"
                    value={dashboardData?.number_of_students.total_students}
                  />
                  <Statistic
                    title={`Since joining ${moment(user?.created_at).format(
                      "DD MMM YYYY"
                    )}`}
                    value={dashboardData?.number_of_students.total_students_complete}
                  />
                </div>
              </Card>
            </Spin>
          </Col>
        </Row>

        <p className="text-xl mb-6">Next Week's Lessons</p>

        <div style={{ maxWidth: 1024 }}>
          <Row gutter={48}>
            {dashboardData?.next_week_lesson.map((nw) => (
              <Col key={nw.id} className="mb-6" xs={24} xl={12}>
                <HorizontalLessonThumbnail
                  lesson={nw.lesson}
                  schedule={nw.scheduled_at}
                />
              </Col>
            ))}
          </Row>
        </div>
      </LayoutContent>
    </div>
  );
}
