import { Card, List } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { toggleOutlineAction } from "store/actions/class-lesson.action";
import { RootState } from "store/reducer";
import { ClassLessonState } from "store/reducers/class-lesson.reducer";

function getOutlineTime(time: number) {
  const minutes = (time % 60).toString().padStart(2, "0");
  const seconds = Math.floor(time / 60)
    .toString()
    .padStart(2, "0");
  return minutes + ":" + seconds;
}

type ClassLessonOutlineProps = {
  classId: string;
  lessonId: string;
  isLessonStarted: boolean;
  isLessonEnded: boolean;
};

export default function ClassLessonOutline(props: ClassLessonOutlineProps) {
  // React redux
  const dispatch = useDispatch();
  const { classLesson } = useSelector<RootState, ClassLessonState>(
    (state) => state.classLessonState
  );

  function toggleOutline(planSectionLessonDetailReferenceId: string) {
    const body = {prepared_lesson_outlines: [planSectionLessonDetailReferenceId]}
    dispatch(toggleOutlineAction(props.lessonId, body));
  }

  return (
    <Card title="LESSON OUTLINE" className="lesson-outline">
      {classLesson.lesson?.lesson_outlines.map((section) => (
        <List
          key={section.id}
          header={
            <div className="text-lg">
              <strong>{section?.title}</strong>
            </div>
          }
          split={false}
        >
          {section.activities.map((item) => (
            <List.Item key={item.id}>
              <Checkbox
                defaultChecked={false}
                onChange={() => toggleOutline(item.id)}
                disabled={props.isLessonEnded}
                className="flex items-center"
              >
                <div className="flex justify-between items-center flex-1">
                  <div className="flex-1 pr-1 flex items-center">
                    <img src={item.thumbnail.image_s3_url} alt={item.title} style={{width: 20}} className="block mr-2" />
                    <span>{item.title}</span>
                  </div>
                  <div>{getOutlineTime(item.time)}</div>
                </div>
              </Checkbox>
            </List.Item>
          ))}
        </List>
      ))}
    </Card>
  );
}
