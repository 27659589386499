import { GET_CLASS_SUBJECTS_FAILURE, GET_CLASS_SUBJECTS_PENDING, GET_CLASS_SUBJECTS_SUCCESS } from "store/actions/class-subject.action"
import { Action } from "types/action"
import { ClassSubjectData } from "types/class-subject"

export type ClassSubjectState = {
  // Data
  classSubjectData: ClassSubjectData,
  // Loading
  getClassSubjectsLoading: boolean;
  // Error
  getClassSubjectsError: any;
}

const initialState: ClassSubjectState = {
  // Data
  classSubjectData: {totalRow: 0, results: []},
  // Loading
  getClassSubjectsLoading: false,
  // Error
  getClassSubjectsError: null
}

export default function classSubjectReducer(state = initialState, action: Action): ClassSubjectState {
  switch (action.type) {
    //  ============================================================
    //  GET CLASS SUBJECTS
    //  ============================================================
    case GET_CLASS_SUBJECTS_PENDING:
      return {
        ...state,
        getClassSubjectsLoading: true
      }
    case GET_CLASS_SUBJECTS_SUCCESS:
      return {
        ...state,
        getClassSubjectsLoading: false,
        classSubjectData: action.payload
      }
    case GET_CLASS_SUBJECTS_FAILURE:
      return {
        ...state,
        getClassSubjectsLoading: false,
        getClassSubjectsError: action.payload
      }
    default:
      return { ...state }
  }
}