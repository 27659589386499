import AuthContainer from "./AuthContainer";
import { Alert, Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { AuthState } from "store/reducers/auth.reducer";
import { loginAction } from "store/actions/auth.action";
import { Link, useLocation } from "react-router-dom";
import { ERROR_MESSAGES } from "constants/content";
import { PASSWORD_PATTERN } from "constants/patterns";
import queryString from "query-string";
import { useMemo } from "react";

export default function LoginPage() {
  const { search } = useLocation();
  // React redux hooks
  const dispatch = useDispatch();
  const { loginLoading, getProfileLoading, loginError } = useSelector<
    RootState,
    AuthState
  >((state) => state.authState);

  const query: any = useMemo(() => queryString.parse(search), [search]);

  async function handleLogin(values: any) {
    try {
      dispatch(loginAction(values));
      // replace("/dashboard");
    } catch (error) {
      //
    }
  }

  return (
    <AuthContainer title="LOGIN">
      <Form layout="vertical" onFinish={handleLogin}>
        {loginError && loginError.message && (
          <Alert
            className="mb-3"
            showIcon
            closable
            type="error"
            message={loginError.message}
          />
        )}
        <Form.Item
          label="Email"
          name="email"
          initialValue={query.email}
          rules={[
            {
              required: true,
              message: ERROR_MESSAGES.LOGIN_EMAIL_REQUIRED,
            },
          ]}
        >
          <Input type="email" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: ERROR_MESSAGES.LOGIN_PASSWORD_REQUIRED,
            },
            {
              pattern: PASSWORD_PATTERN,
              message: ERROR_MESSAGES.INVALID_PASSWORD,
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={loginLoading || getProfileLoading}
            block
          >
            Login
          </Button>
        </Form.Item>
        <p>
          <Link to="/auth/forgot-password">Forgot your password?</Link>
        </p>
      </Form>
    </AuthContainer>
  );
}
