import axios, { AxiosResponse } from "axios";
import { CLASS_URL } from "constants/urls";
import { stringifyParams } from "helpers/params";
import { Action } from "types/action";
import { ClassLesson, ListAttendees } from "types/class-lesson";

//  ============================================================
//  GET CLASS LESSON DETAILS
//  ============================================================
export const GET_CLASS_LESSON_PENDING = "GET_CLASS_LESSON_PENDING";
export const GET_CLASS_LESSON_SUCCESS = "GET_CLASS_LESSON_SUCCESS";
export const GET_CLASS_LESSON_FAILURE = "GET_CLASS_LESSON_FAILURE";
function getClassLessonPending(): Action {
  return {
    type: GET_CLASS_LESSON_PENDING,
  };
}

function getClassLessonSuccess(classLesson: ClassLesson): Action {
  return {
    type: GET_CLASS_LESSON_SUCCESS,
    payload: classLesson,
  };
}

function getClassLessonFailure(error: any): Action {
  return {
    type: GET_CLASS_LESSON_FAILURE,
    payload: error,
  };
}

export function getClassLessonAction(lessonId: string) {
  return async (dispatch: Function) => {
    try {
      dispatch(getClassLessonPending());
      const response: AxiosResponse<ClassLesson> = await axios.get(
        CLASS_URL + `/lessons/${lessonId}/full/` 
      );
      dispatch(getClassLessonSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getClassLessonFailure(error));
      return Promise.reject(error);
    }
  };
}

//  ============================================================
//  GET CLASS LESSON DETAILS
//  ============================================================
export const GET_CLASS_LESSON_ATTENDEES_PENDING = "GET_CLASS_LESSON_ATTENDEES_PENDING";
export const GET_CLASS_LESSON_ATTENDEES_SUCCESS = "GET_CLASS_LESSON_ATTENDEES_SUCCESS";
export const GET_CLASS_LESSON_ATTENDEES_FAILURE = "GET_CLASS_LESSON_ATTENDEES_FAILURE";

function getClassLessonAttendeesPending(): Action {
  return {
    type: GET_CLASS_LESSON_ATTENDEES_PENDING,
  };
}

function getClassLessonAttendeesSuccess(classLesson: ListAttendees): Action {
  return {
    type: GET_CLASS_LESSON_ATTENDEES_SUCCESS,
    payload: classLesson,
  };
}

function getClassLessonAttendeesFailure(error: any): Action {
  return {
    type: GET_CLASS_LESSON_ATTENDEES_FAILURE,
    payload: error,
  };
}

export function getClassLessonAttendeesAction(lessonId: string, query: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(getClassLessonAttendeesPending());
      const response: AxiosResponse<ListAttendees> = await axios.get(CLASS_URL + `/lessons/${lessonId}/attendees/${stringifyParams(query)}`);
      dispatch(getClassLessonAttendeesSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getClassLessonAttendeesFailure(error));
      return Promise.reject(error);
    }
  };
}

//  ============================================================
//  MARK AS PREPARED
//  ============================================================
export const MARK_AS_REPARED_PENDING = "MARK_AS_REPARED_PENDING";
export const MARK_AS_REPARED_SUCCESS = "MARK_AS_REPARED_SUCCESS";
export const MARK_AS_REPARED_FAILURE = "MARK_AS_REPARED_FAILURE";
function markAsReparedPending(): Action {
  return {
    type: MARK_AS_REPARED_PENDING,
  };
}

function markAsReparedSuccess(): Action {
  return {
    type: MARK_AS_REPARED_SUCCESS,
  };
}

function markAsReparedFailure(error: any): Action {
  return {
    type: MARK_AS_REPARED_FAILURE,
    payload: error,
  };
}

export function markAsReparedAction(lessonId: string, body: Object) {
  return async (dispatch: Function) => {
    try {
      dispatch(markAsReparedPending());
      await axios.put(`${CLASS_URL}/lessons/${lessonId}/`, body);
      dispatch(markAsReparedSuccess());
      return Promise.resolve();
    } catch (error) {
      dispatch(markAsReparedFailure(error));
      return Promise.reject(error);
    }
  };
}

//  ============================================================
//  CHANGE LESSON STATUS
//  ============================================================
export const CHANGE_LESSON_STATUS_PENDING = "CHANGE_LESSON_STATUS_PENDING";
export const CHANGE_LESSON_STATUS_SUCCESS = "CHANGE_LESSON_STATUS_SUCCESS";
export const CHANGE_LESSON_STATUS_FAILURE = "CHANGE_LESSON_STATUS_FAILURE";
function changeLessonStatusPending(): Action {
  return {
    type: CHANGE_LESSON_STATUS_PENDING,
  };
}

function changeLessonStatusSuccess(status: string): Action {
  return {
    type: CHANGE_LESSON_STATUS_SUCCESS,
    payload: status,
  };
}

function changeLessonStatusFailure(error: any): Action {
  return {
    type: CHANGE_LESSON_STATUS_FAILURE,
    payload: error,
  };
}

export function changeLessonStatusAction(
  lessonId: string,
  action: "started" | "pause" | "reschedule" | "ended",
  time: string
) {
  return async (dispatch: Function) => {
    try {
      let body = {}
      if (action === "started") {
        body = {started_at: time}
      } else if (action === "ended") {
        body = {ended_at: time}
      }
      dispatch(changeLessonStatusPending());
      await axios.put(`${CLASS_URL}/lessons/${lessonId}/`, body);
      dispatch(changeLessonStatusSuccess(action));
      return Promise.resolve();
    } catch (error) {
      dispatch(changeLessonStatusFailure(error));
      return Promise.reject(error);
    }
  };
}

//  ============================================================
//  RESCHEDULE LESSON
//  ============================================================
export const RESCHEDULE_LESSON_PENDING = "RESCHEDULE_LESSON_PENDING";
export const RESCHEDULE_LESSON_SUCCESS = "RESCHEDULE_LESSON_SUCCESS";
export const RESCHEDULE_LESSON_FAILURE = "RESCHEDULE_LESSON_FAILURE";
function reScheduleLessonPending(): Action {
  return {
      type: RESCHEDULE_LESSON_PENDING
   }
}

function reScheduleLessonSuccess(classLesson: ClassLesson): Action {
  return {
      type: RESCHEDULE_LESSON_SUCCESS,
      payload: classLesson
   }
}

function reScheduleLessonFailure(error: any): Action {
  return {
      type: RESCHEDULE_LESSON_FAILURE,
      payload: error
   }
}

export function reScheduleLessonAction(lessonId: string, body: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(reScheduleLessonPending());
      const response = await axios.put(`${CLASS_URL}/lessons/${lessonId}/full/`, body);
      dispatch(reScheduleLessonSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(reScheduleLessonFailure(error));
      return Promise.reject(error)
    }
  }
} 

//  ============================================================
//  TOGGLE ATTENDANCE
//  ============================================================
export const TOGGLE_ATTENDANCE_PENDING = "TOGGLE_ATTENDANCE_PENDING";
export const TOGGLE_ATTENDANCE_SUCCESS = "TOGGLE_ATTENDANCE_SUCCESS";
export const TOGGLE_ATTENDANCE_FAILURE = "TOGGLE_ATTENDANCE_FAILURE";
function toggleAttendancePending(): Action {
  return {
      type: TOGGLE_ATTENDANCE_PENDING
   }
}

function toggleAttendanceSuccess(): Action {
  return {
      type: TOGGLE_ATTENDANCE_SUCCESS
   }
}

function toggleAttendanceFailure(error: any): Action {
  return {
      type: TOGGLE_ATTENDANCE_FAILURE,
      payload: error
   }
}

export function toggleAttendanceAction(classId: string, studentId: string, body: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(toggleAttendancePending());
      await axios.put(`${CLASS_URL}/lessons/${classId}/attendees/${studentId}/`, body);
      dispatch(toggleAttendanceSuccess());
      return Promise.resolve();
    } catch (error) {
      dispatch(toggleAttendanceFailure(error));
      return Promise.reject(error)
    }
  }
}

//  ============================================================
//  TOGGLE PREPARE LIST
//  ============================================================
export const TOGGLE_REPARE_LIST_PENDING = "TOGGLE_REPARE_LIST_PENDING";
export const TOGGLE_REPARE_LIST_SUCCESS = "TOGGLE_REPARE_LIST_SUCCESS";
export const TOGGLE_REPARE_LIST_FAILURE = "TOGGLE_REPARE_LIST_FAILURE";
function toggleRepareListPending(): Action {
  return {
    type: TOGGLE_REPARE_LIST_PENDING,
  };
}

function toggleRepareListSuccess(): Action {
  return {
    type: TOGGLE_REPARE_LIST_SUCCESS,
  };
}

function toggleRepareListFailure(error: any): Action {
  return {
    type: TOGGLE_REPARE_LIST_FAILURE,
    payload: error,
  };
}

export function toggleRepareListAction(
  classId: string,
  body: any
) {
  return async (dispatch: Function) => {
    try {
      dispatch(toggleRepareListPending());
      await axios.put(`${CLASS_URL}/lessons/${classId}/`, body);
      dispatch(toggleRepareListSuccess());
      return Promise.resolve();
    } catch (error) {
      dispatch(toggleRepareListFailure(error));
      return Promise.reject(error);
    }
  };
}

//  ============================================================
//  TOGGLE OUTLINE
//  ============================================================
export const TOGGLE_OUTLINE_PENDING = "TOGGLE_OUTLINE_PENDING";
export const TOGGLE_OUTLINE_SUCCESS = "TOGGLE_OUTLINE_SUCCESS";
export const TOGGLE_OUTLINE_FAILURE = "TOGGLE_OUTLINE_FAILURE";
function toggleOutlinePending(): Action {
  return {
    type: TOGGLE_OUTLINE_PENDING,
  };
}

function toggleOutlineSuccess(): Action {
  return {
    type: TOGGLE_OUTLINE_SUCCESS,
  };
}

function toggleOutlineFailure(error: any): Action {
  return {
    type: TOGGLE_OUTLINE_FAILURE,
    payload: error,
  };
}

export function toggleOutlineAction(lessonReferenceId: string, body: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(toggleOutlinePending());
      await axios.put(`${CLASS_URL}/lessons/${lessonReferenceId}/`, body);
      dispatch(toggleOutlineSuccess());
      return Promise.resolve();
    } catch (error) {
      dispatch(toggleOutlineFailure(error));
      return Promise.reject(error);
    }
  };
}


//  ============================================================
//  TOGGLE OTHER RESOURCE
//  ============================================================
export const TOGGLE_OTHER_RESOURCE_PENDING = "TOGGLE_OTHER_RESOURCE_PENDING";
export const TOGGLE_OTHER_RESOURCE_SUCCESS = "TOGGLE_OTHER_RESOURCE_SUCCESS";
export const TOGGLE_OTHER_RESOURCE_FAILURE = "TOGGLE_OTHER_RESOURCE_FAILURE";
function toggleOtherResourcePending(): Action {
  return {
      type: TOGGLE_OTHER_RESOURCE_PENDING
   }
}

function toggleOtherResourceSuccess(): Action {
  return {
      type: TOGGLE_OTHER_RESOURCE_SUCCESS
   }
}

function toggleOtherResourceFailure(error: any): Action {
  return {
      type: TOGGLE_OTHER_RESOURCE_FAILURE,
      payload: error
   }
}

export function toggleOtherResourceAction(lessonReferenceId: string ,lessonResourceReferenceId: string) {
  return async (dispatch: Function) => {
    try {
      dispatch(toggleOtherResourcePending());
      await axios.put(`${CLASS_URL}/lessons/${lessonReferenceId}/resources/${lessonResourceReferenceId}/status/`);
      dispatch(toggleOtherResourceSuccess());
      return Promise.resolve();
    } catch (error) {
      dispatch(toggleOtherResourceFailure(error));
      return Promise.reject(error)
    }
  }
}

//  ============================================================
//  TOGGLE FLASHCARD
//  ============================================================
export const TOGGLE_FLASHCARD_PENDING = "TOGGLE_FLASHCARD_PENDING";
export const TOGGLE_FLASHCARD_SUCCESS = "TOGGLE_FLASHCARD_SUCCESS";
export const TOGGLE_FLASHCARD_FAILURE = "TOGGLE_FLASHCARD_FAILURE";
function toggleFlashCardPending(): Action {
  return {
      type: TOGGLE_FLASHCARD_PENDING
   }
}

function toggleFlashCardSuccess(): Action {
  return {
      type: TOGGLE_FLASHCARD_SUCCESS
   }
}

function toggleFlashCardFailure(error: any): Action {
  return {
      type: TOGGLE_FLASHCARD_FAILURE,
      payload: error
   }
}

export function toggleFlashCardAction(lessonId: string, flashCardReferenceId: string) {
  return async (dispatch: Function) => {
    try {
      dispatch(toggleFlashCardPending());
      await axios.put(`${CLASS_URL}/lessons/${lessonId}/flashcards/${flashCardReferenceId}/status/`);
      dispatch(toggleFlashCardSuccess());
      return Promise.resolve();
    } catch (error) {
      dispatch(toggleFlashCardFailure(error));
      return Promise.reject(error)
    }
  }
}

//  ============================================================
//  GET LESSON SUMMARY
//  ============================================================
export const GET_SUMMARY_PENDING = "GET_SUMMARY_PENDING";
export const GET_SUMMARY_SUCCESS = "GET_SUMMARY_SUCCESS";
export const GET_SUMMARY_FAILURE = "SEND_SUMMARY_FAILURE";
function getSummaryPending(): Action {
  return {
      type: GET_SUMMARY_PENDING
   }
}

function getSummarySuccess(summary: any): Action {
  return {
      type: GET_SUMMARY_SUCCESS,
      payload: summary,
   }
}

function getSummaryFailure(error: any): Action {
  return {
      type: SEND_SUMMARY_FAILURE,
      payload: error
   }
}

export function getSummaryAction(lesson_id: string) {
  return async (dispatch: Function) => {
    try {
      dispatch(getSummaryPending());
      const response = await axios.get(`${CLASS_URL}/lessons/${lesson_id}/summary/?no_pagination=true`);
      dispatch(getSummarySuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getSummaryFailure(error));
      return Promise.reject(error)
    }
  }
}


//  ============================================================
//  SEND LESSON SUMMARY
//  ============================================================
export const SEND_SUMMARY_PENDING = "SEND_SUMMARY_PENDING";
export const SEND_SUMMARY_SUCCESS = "SEND_SUMMARY_SUCCESS";
export const SEND_SUMMARY_FAILURE = "SEND_SUMMARY_FAILURE";
function sendSummaryPending(): Action {
  return {
      type: SEND_SUMMARY_PENDING
   }
}

function sendSummarySuccess(): Action {
  return {
      type: SEND_SUMMARY_SUCCESS
   }
}

function sendSummaryFailure(error: any): Action {
  return {
      type: SEND_SUMMARY_FAILURE,
      payload: error
   }
}

export function sendSummaryAction(lesson_id: string, body: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(sendSummaryPending());
      await axios.post(`${CLASS_URL}/lessons/${lesson_id}/summary/`, body);
      dispatch(sendSummarySuccess());
      return Promise.resolve();
    } catch (error) {
      dispatch(sendSummaryFailure(error));
      return Promise.reject(error)
    }
  }
}