import { UploadRequestOption } from "rc-upload/es/interface";
import { Modal } from "antd";
import axios, { AxiosResponse } from "axios";
import { NewUploadResponse } from "types/upload";
import { UPLOAD_URL } from "constants/urls";

export async function customUploadRequest({
  file,
  onSuccess,
  onError,
  onProgress,
}: UploadRequestOption) {
  let uploadType: "image" | "video" | "" = "";
  const uploadFile = file as File;

  if (uploadFile.type.startsWith("video/")) {
    uploadType = "video";
  } else if (uploadFile.type.startsWith("image/")) {
    uploadType = "image";
  }

  if (uploadType.length === 0) {
    Modal.error({
      title: "Error",
      content: "File is not supported",
    });
    return;
  }

  try {
    const formData = new FormData();
    formData.append("file", uploadFile);
    formData.append("type", uploadType);
    const response: AxiosResponse<NewUploadResponse> = await axios.post(
      `${UPLOAD_URL}`,
      formData,
      {
        onUploadProgress: (event: ProgressEvent) => {
          const percent = Math.floor((event.loaded / event.total) * 100);
          if (onProgress) {
            onProgress({
              ...event,
              percent,
            });
          }
        },
      }
    );

    if (onSuccess) {
      onSuccess(
        {
          file: response.data.result,
          type: uploadType,
          fileName: uploadFile.name,
          fileType: uploadFile.name.slice(uploadFile.name.lastIndexOf(".")),
        },
        response.request
      );
    }
  } catch (error) {
    if (onError) {
      onError(error);
    }
  }
}
