import { Collapse, PageHeader, Space, List, Row, Col, Divider, Empty } from "antd";
import songLibthumbnail from "assets/images/song-thumbnail.png";
import LayoutContent from "components/LayoutContent/LayoutContent";
import AudioPlayer from "components/AudioPlayer/AudioPlayer";
import HorizontalLessonThumbnail from "components/HorizontalLessonThumbnail/HorizontalLessonThumbnail";

import "./MusicPage.scss";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import {
  getMusicAction, getWhatListenAction,
} from "store/actions/music.action";
import { useEffect } from "react";
import { RootState } from "store/reducer";
import { MusicState } from "store/reducers/music.reducer";
import { ThemeState } from "store/reducers/theme.reducer";
import { getThemesAction } from "store/actions/theme.action";
import { getLessonsAction } from "store/actions/lesson.action";
import { LessonState } from "store/reducers/lesson.reducer";

export default function MusicPage() {
  // React redux
  const dispatch = useDispatch();
  const { musicData, whatToListenLastWeek, whatToListenThisWeek, whatToListenNextWeek, getMusicLoading, getWhatToListenLoading } = useSelector<RootState, MusicState>(
    (state) => state.musicState
  );
  const { themeData } = useSelector<RootState, ThemeState>((state) => state.themeState)
  const { lessonData, getLessonsLoading } = useSelector<RootState, LessonState>((state) => state.lessonState)
  const fetchData = useCallback(() => {
    try {
      dispatch(getThemesAction({ show_total_musics: true }));
      dispatch(getWhatListenAction({ since: 'last_week' }));
      dispatch(getWhatListenAction({ since: 'next_week' }));
      dispatch(getWhatListenAction({ since: 'this_week' }));
    } catch (error) {
      //
    }
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // get lession by theme
  function themesActive(e: string | any) {
    dispatch(getLessonsAction({ no_pagination: true, theme: e }))
  }

  // get music by lessions
  function lessonsActive(e: string | any) {
    dispatch(getMusicAction({ no_pagination: true, lesson_id: e }))
  }

  return (
    <div className="music-page">
      <PageHeader title="Music" />
      <LayoutContent loading={getMusicLoading || getWhatToListenLoading || getLessonsLoading}>
        <h2 className="text-xl mb-2">Song library</h2>
        <Collapse accordion expandIconPosition="right" className="song-collapse" onChange={themesActive} >
          {themeData.results.map((theme) => (
            <Collapse.Panel
              key={theme.id}
              header={
                <Space>
                  <img src={theme.cover_image?.image_s3_url || songLibthumbnail} alt="" />
                  <span>
                    <strong>
                      {theme.title}
                    </strong>
                    <p className="flex items-center"><svg xmlns="http://www.w3.org/2000/svg" width="20.068" height="12.096" viewBox="0 0 20.068 12.096">
                      <g id="Group_2" data-name="Group 2" transform="translate(-234.907 -51.769)">
                        <path id="Path_11" data-name="Path 11" d="M295.484,57.189c.078-.346.148-.587.186-.832.193-1.238.359-2.48.57-3.715.14-.815.28-.9,1.124-.865a34.612,34.612,0,0,1,6.523,1.063.6.6,0,0,1,.519.625c.005.278.016.557,0,.835-.084,2.165-.166,4.33-.263,6.495-.047,1.038-.456,1.328-1.33,1.406a5.062,5.062,0,0,1-2.178-.16,2,2,0,0,1-1.548-2.127A2.229,2.229,0,0,1,301.153,58a3.693,3.693,0,0,1,1.008.038,7.079,7.079,0,0,1,1.011.361,2.9,2.9,0,0,0,.1-.45c.035-.536.024-1.076.091-1.607.055-.44-.107-.63-.523-.676-1.165-.128-2.328-.272-3.494-.393-.652-.067-1.309-.091-1.962-.15-.429-.039-.606.15-.655.567-.166,1.442-.359,2.881-.531,4.323-.1.84-.3,1.055-1.127,1.2a4.988,4.988,0,0,1-2.415-.169,2.188,2.188,0,0,1-1.614-2.181,2.315,2.315,0,0,1,1.76-2.217,3.009,3.009,0,0,1,2.035.217C295.019,56.946,295.191,57.04,295.484,57.189Z" transform="translate(-49.442)" fill="#f1b24d" />
                        <path id="Path_12" data-name="Path 12" d="M238.578,66.379c-.072-.332-.114-.54-.163-.747-.283-1.2-.587-2.394-.846-3.6-.179-.832-.046-1.123.748-1.354,1.1-.321,2.228-.57,3.355-.794a.838.838,0,0,1,1.081.685,9.738,9.738,0,0,1,.278,1.1c.127.642-.025.93-.658,1.13-.89.281-1.792.522-2.691.775-.758.213-.766.212-.559.976q.561,2.072,1.137,4.139c.229.823.135,1.075-.581,1.543a4.709,4.709,0,0,1-2.417.754,2.106,2.106,0,0,1-2.2-1.4,2.326,2.326,0,0,1,.737-2.683,2.95,2.95,0,0,1,2.072-.593C238.064,66.326,238.256,66.348,238.578,66.379Z" transform="translate(0 -7.122)" fill="#f1b24d" />
                      </g>
                    </svg>
                      <span className="mx-1">{theme.total_musics}</span> Music</p>
                  </span>
                </Space>
              }
            >
              <Collapse accordion expandIconPosition="right" ghost onChange={lessonsActive}>
                {
                  lessonData.results.length === 0 && (
                    <Collapse.Panel
                      key={theme.id + 'lesson'}
                      showArrow={false}
                      header={
                        <Empty description="No lessons found" />
                      }
                    >
                    </Collapse.Panel>
                  )
                }
                {lessonData.results.length > 0 && lessonData.results.map((lesson) => (
                  <Collapse.Panel
                    key={lesson.id}
                    header={
                      <strong>
                        {lesson.title}
                      </strong>
                    }
                  >
                    <List
                      dataSource={musicData.results}
                      renderItem={(music) => (
                        <List.Item>
                          <AudioPlayer
                            src={music.file}
                            name={music.title}
                          />
                        </List.Item>
                      )}
                    />
                  </Collapse.Panel>
                ))}
              </Collapse>
            </Collapse.Panel>
          ))
          }

        </Collapse>

        <Divider />

        <h2 className="text-xl">What to listen to this week</h2>
        <Row gutter={48}>
            <Col xs={24} xl={24}>
              <div className="p-4">
                <p className="text-lg mb-3">This week's lesson</p>
                {whatToListenThisWeek.results.length > 0 ? whatToListenThisWeek.results.map((item) => (
                <div key={item.id}>
                  <HorizontalLessonThumbnail lesson={item.lesson} schedule={item.scheduled_at} />
                  <List
                    dataSource={item.lesson?.musics || []}
                    renderItem={(music) => (
                      <List.Item>
                        <AudioPlayer src={music.file} name={music.title} />
                      </List.Item>
                    )}
                  />

                  <Divider />
                </div>
              )) : <Empty description="No songs found" />}
                </div>
            </Col>
            </Row>
        <Row gutter={48}>
          <Col xs={24} xl={12}>
            <div className="p-4">
            <p className="text-lg mb-3">Last week's lesson</p>

              {whatToListenLastWeek.results.length > 0 ? whatToListenLastWeek.results.map((item) => (
                <div key={item.id}>
                  <HorizontalLessonThumbnail lesson={item.lesson} schedule={item.scheduled_at} />
                  <List
                    dataSource={item.lesson?.musics || []}
                    renderItem={(music) => (
                      <List.Item>
                        <AudioPlayer src={music.file} name={music.title} />
                      </List.Item>
                    )}
                  />

                  <Divider />
                </div>
              )) : <Empty description="No songs found" />}
            </div>
          </Col>

          <Col xs={24} xl={12}>
            <div className="p-4">
              <p className="text-lg mb-3">Next week's lesson</p>

              {whatToListenNextWeek.results.length > 0 ? whatToListenNextWeek.results.map((item) => (
                <div key={item.id}>
                  <HorizontalLessonThumbnail lesson={item.lesson} schedule={item.scheduled_at} />

                  <List
                    dataSource={item.lesson?.musics || []}
                    renderItem={(music) => (
                      <List.Item>
                        <AudioPlayer src={music.file} name={music.title} />
                      </List.Item>
                    )}
                  />

                  <Divider />
                </div>
              )) : <Empty description="No songs found" />}
            </div>
          </Col>
        </Row>
      </LayoutContent>
    </div>
  );
}
