import "./VocabularyCard.scss";

type VocabularyCardProps = {
  image: string;
};

export default function VocabularyCard(props: VocabularyCardProps) {
  return (
    <div className="vocabulary-card">
      <div className="vocabulary-card__image-wrapper">
        <img className="vocabulary-card__image" src={props.image} alt="" />
      </div>
    </div>
  );
}
