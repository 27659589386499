import { Card, Row, Col, Divider, List, Button, Form, Checkbox } from "antd";
import VideoPlayer from "components/VideoPlayer/VideoPlayer";
import AudioPlayer from "components/AudioPlayer/AudioPlayer";
import { PrinterOutlined, DownloadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducer";
import { ClassLessonState } from "store/reducers/class-lesson.reducer";
import { toggleFlashCardAction, toggleOtherResourceAction } from "store/actions/class-lesson.action";
import bookIcon from "assets/images/book-icon.png";
import songIcon from "assets/images/song-icon.png";
import videoIcon from "assets/images/video-icon.png";
import craftIcon from "assets/images/craft-icon.png";
import flashcardIcon from "assets/images/flashcard-icon.png";
import Print from "print-js";
import PrintCard from "components/PrintCard/PrintCard";
import { useEffect, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { downloadFlashCardsAction } from "store/actions/printable.action";
import { PrintableState } from "store/reducers/printable.reducer";

type ClassLessonResourcesProps = {
  classId: string,
  lessonId: string
}
export default function ClassLessonResources(props: ClassLessonResourcesProps) {
  const [formFlashcard] = useForm();
  const [submitType, setSubmitType] = useState<string>('print')

  // React redux
  const { downloadFlashCardsLoading } = useSelector<RootState, PrintableState>((state) => state.printableState)
  const dispatch = useDispatch();
  const { classLesson } = useSelector<RootState, ClassLessonState>(
    (state) => state.classLessonState
  );
  function toggleOtherResource(lessonResourceReferenceId: string) {
    dispatch(toggleOtherResourceAction(props.lessonId, lessonResourceReferenceId));
  }

  function toggleFlashCard(flashCardReferenceId: string) {
    dispatch(toggleFlashCardAction(props.lessonId, flashCardReferenceId));
  }

  useEffect(() => {
    const ids :Array<string> = []
    classLesson.lesson?.flashcards.map((item) => (
      item.flashcards.filter(x => x.is_prepared).map((card) => (
        ids.push(card.thumbnail?.image_s3_url)
      ))
    ))
    formFlashcard.setFieldsValue({resources: ids})
  }, [classLesson, formFlashcard])

  function handlerPrint(images: any) {
    Print({
      printable: images,
      type: "image",
      imageStyle: "max-width: 100%"
    })
  }

  function printResources(values: any) {
    if (submitType === 'print') {
      handlerPrint(values.resources)
    } else {
      const array = classLesson.lesson?.flashcards.map((item) => item.flashcards.filter(x => values.resources.includes(x.thumbnail?.image_s3_url)).map((card) => card.id));
      const crafts = classLesson.lesson?.crafts.filter(x => values.resources.includes(x.thumbnail?.image_s3_url)).map((item) => item.id);;
      var flashcards = array?.flat(1)
      dispatch(downloadFlashCardsAction({ flashcards, crafts }))

    }
  }

  return (
    <Card title="RESOURCES">
      <p>
        <strong>Source yourself:</strong>
      </p>
      <p className="font-serif mb-6">
        <i>
          These are resources you may have already around your house, or may
          need to purchase prior to the lesson.
        </i>
      </p>

      <Row>
        {classLesson.lesson?.resources?.map((resource) => (
          <Col xs={24} lg={12} xxl={12} key={resource.id}>
            <p>
              <strong>{resource.title}</strong>
            </p>
            {resource.resources.map((item) => (
              <p key={item.id}>
                <Checkbox defaultChecked={item.is_prepared} onChange={() => toggleOtherResource(item.id)}>
                  <img src={item.thumbnail?.image_s3_url} width="30" height="30" style={{height: '30px'}} alt="" />
                  <span className="ml-2">{item.title}</span>
                </Checkbox>
              </p>
            ))}
          </Col>
        ))}
      </Row>

      <Divider />

      <p>
        <strong>Adam & MishMish Resources</strong>
      </p>
      <p className="font-serif mb-6">
        <i>
          These are resources you have at your disposal as part of the Adam &
          MishMish curriculum.
        </i>
      </p>

      <List
        header={
          <h3 className="flex items-center">
            <img src={bookIcon} alt="book" width="20" className="block mr-2" />
            <strong>BOOKS</strong>
          </h3>
        }
        dataSource={classLesson.lesson?.books}
        renderItem={(book) => (
          <List.Item
            actions={[
              <a
                href={book.file}
                key="read-book"
                target="_blank"
                rel="noopener noreferrer"
              >
                Read
              </a>,
            ]}
          >
            <img src={book.thumbnail?.image_s3_url} alt={book.title} width="100" />
            <div className="flex-1 px-3">{book.title}</div>
          </List.Item>
        )}
      />

      <List
        header={
          <h3 className="flex items-center">
            <img src={songIcon} alt="song" className="block mr-2" width="20" />
            <strong>MUSIC</strong>
          </h3>
        }
        dataSource={classLesson.lesson?.musics}
        renderItem={(music) => (
          <List.Item>
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <div className="flex-1">
                  <AudioPlayer src={music.file} name={music.title} />
                </div>
              </div>
            </div>
          </List.Item>
        )}
      />

      <Divider />

      <h3 className="flex items-center">
        <img src={videoIcon} alt="video" className="block mr-2" width="20" />
        <strong>VIDEOS</strong>
      </h3>
      <Divider />

      <Row gutter={24}>
        {classLesson.lesson?.episodes.map((episode) => (
          <Col key={episode.id} className="mb-3" xs={24} lg={12} xl={8} xxl={6}>
            <VideoPlayer
              thumbnail={episode.thumbnail?.image_s3_url}
              source={{
                type: "video",
                sources: [
                  {
                    src: episode.file,
                  },
                ],
              }}
            />
          </Col>
        ))}
      </Row>

      <Divider />

      <List
        header={
          <h3 className="flex items-center">
            <img src={craftIcon} alt="craft" width="20" className="block mr-2" />
            <strong>ARTS & CRAFTS</strong>
          </h3>
        }
        dataSource={classLesson.lesson?.crafts}
        renderItem={(artCraft) => (
          <List.Item
            actions={[
              <Button key="print" onClick={() => handlerPrint(artCraft.thumbnail?.image_s3_url)} icon={<PrinterOutlined />}>
                Print
              </Button>,
                <Button icon={<DownloadOutlined />} onClick={() => dispatch(downloadFlashCardsAction({flashcards: [], crafts: [artCraft.id]}))}  loading={downloadFlashCardsLoading}>Download</Button>
            ]}
          >
            <img src={artCraft.thumbnail?.image_s3_url} alt="" width="100" />
            <div className="flex-1 px-3">{artCraft.title}</div>
          </List.Item>
        )}
      />
      <Form onFinish={printResources} form={formFlashcard}>
        <Form.Item noStyle name="resources" initialValue={['879fecf9-f543-41b0-940a-fefc3ef90f93']}>
          <Checkbox.Group className="w-full">
            <List
              header={
                <div className="flex items-center w-full justify-between">
                  <h3 className="flex items-center">
                    <img src={flashcardIcon} alt="flashcard" width="20" className="block mr-2" />
                    <strong>FLASH CARD</strong>
                  </h3>
                  <div >
                    <Button key="print" htmlType="submit" onClick={() => setSubmitType('print')} icon={<PrinterOutlined />}>
                      Print
                    </Button>
                    <Button key="download" className="ml-5"  loading={downloadFlashCardsLoading} icon={<DownloadOutlined />}>
                      Download
                    </Button>
                  </div>
                </div>

              }
              dataSource={classLesson.lesson?.flashcards}
              split={false}
              renderItem={(card) => (
                <List.Item
                  key={card.id}>
                  <Row gutter={24}>
                    <Col
                      className="mb-3"
                      key={card.id}>
                      <p>
                        <strong>{card.title}</strong>
                      </p>
                      <div className="w-full flex flex-wrap">
                        {card.flashcards.map((item) => (
                          <PrintCard
                            key={item.id}
                            name={item.title}
                            checked={true}
                            value={item.id}
                            image={item.thumbnail?.image_s3_url}
                            onChange={() => toggleFlashCard(item.id)}
                          />
                        ))}
                      </div>
                    </Col>
                  </Row>
                </List.Item>
              )}
            />
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Card>
  );
}
