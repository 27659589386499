import { PageHeader, Row, Col, Spin } from "antd";
import { useHistory, useRouteMatch } from "react-router-dom";
import ClassOverview from "./ClassOverview";
import ClassOutline from "./ClassOutline";
import ClassResources from "./ClassResources";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { getLessonDetailsAction } from "store/actions/lesson.action";
import { useEffect } from "react";
import { RootState } from "store/reducer";
import { LessonState } from "store/reducers/lesson.reducer";

export default function ClassDetails() {
  // React router
  const { goBack } = useHistory();
  const { params } = useRouteMatch<{ lessonId: string }>();

  // React redux
  const dispatch = useDispatch();
  const { getLessonDetailsLoading } = useSelector<RootState, LessonState>(
    (state) => state.lessonState
  );

  const fetchData = useCallback(() => {
    try {
      if (params.lessonId) {
        dispatch(getLessonDetailsAction(params.lessonId));
      }
    } catch (error) {
      //
    }
  }, [dispatch, params.lessonId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="class-details">
      <PageHeader title="Class details" onBack={goBack} />

      <Spin spinning={getLessonDetailsLoading}>
        <div className="px-6">
          <div className="mb-6">
            <ClassOverview />
          </div>

          <Row gutter={30}>
            <Col flex="0 0 400px">
              <div className="mb-6">
                <ClassOutline />
              </div>
            </Col>
            <Col flex="1">
              <ClassResources />
            </Col>
          </Row>
        </div>
      </Spin>
    </div>
  );
}
