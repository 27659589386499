import { Spin } from "antd";
import { ReactNode } from "react";

type LayoutContentProps = {
  children: ReactNode;
  loading?: boolean;
};

export default function LayoutContent({
  loading = false,
  ...props
}: LayoutContentProps) {
  return (
    <div className="admin-layout-content p-6 pt-3" id="layout-content">
      <Spin spinning={loading}>
        <div
          className="bg-white rounded shadow py-6 px-12 overflow-hidden"
          id="layout-content__inner"
        >
          {props.children}
        </div>
      </Spin>
    </div>
  );
}
