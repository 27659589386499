import axios, { AxiosResponse } from "axios";
import { TEACHER_URL } from "constants/urls";
import { stringifyParams } from "helpers/params";
import { Action } from "types/action";
import { PastTransactionData, TotalEarning, UpcomingTransactionData } from "types/transaction";

//  ============================================================
//  GET UPCOMMING TRANSACTIONS
//  ============================================================
export const GET_UPCOMING_TRANSACTIONS_PENDING = "GET_UPCOMING_TRANSACTIONS_PENDING";
export const GET_UPCOMING_TRANSACTIONS_SUCCESS = "GET_UPCOMING_TRANSACTIONS_SUCCESS";
export const GET_UPCOMING_TRANSACTIONS_FAILURE = "GET_UPCOMING_TRANSACTIONS_FAILURE";
function getUpcomingTransactionsPending(): Action {
  return {
      type: GET_UPCOMING_TRANSACTIONS_PENDING
   }
}

function getUpcomingTransactionsSuccess(data: UpcomingTransactionData): Action {
  return {
      type: GET_UPCOMING_TRANSACTIONS_SUCCESS,
      payload: data
   }
}

function getUpcomingTransactionsFailure(error: any): Action {
  return {
      type: GET_UPCOMING_TRANSACTIONS_FAILURE,
      payload: error
   }
}

export function getUpcomingTransactionsAction(query?: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(getUpcomingTransactionsPending());
      const response: AxiosResponse<UpcomingTransactionData> = await axios.get(`${TEACHER_URL}/transactions/${stringifyParams(query)}`);
      dispatch(getUpcomingTransactionsSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getUpcomingTransactionsFailure(error));
      return Promise.reject(error)
    }
  }
}

//  ============================================================
//  GET PAST TRANSACTIONS
//  ============================================================
export const GET_PAST_TRANSACTIONS_PENDING = "GET_PAST_TRANSACTIONS_PENDING";
export const GET_PAST_TRANSACTIONS_SUCCESS = "GET_PAST_TRANSACTIONS_SUCCESS";
export const GET_PAST_TRANSACTIONS_FAILURE = "GET_PAST_TRANSACTIONS_FAILURE";
function getPastTransactionsPending(): Action {
  return {
      type: GET_PAST_TRANSACTIONS_PENDING
   }
}

function getPastTransactionsSuccess(data: PastTransactionData): Action {
  return {
      type: GET_PAST_TRANSACTIONS_SUCCESS,
      payload: data
   }
}

function getPastTransactionsFailure(error: any): Action {
  return {
      type: GET_PAST_TRANSACTIONS_FAILURE,
      payload: error
   }
}

export function getPastTransactionsAction(query?: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(getPastTransactionsPending());
      const response: AxiosResponse<PastTransactionData> = await axios.get(`${TEACHER_URL}/transactions/${stringifyParams(query)}`);
      dispatch(getPastTransactionsSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getPastTransactionsFailure(error));
      return Promise.reject(error)
    }
  }
}


//  ============================================================
//  TOTAL TRANSACTIONS
//  ============================================================
export const GET_TOTAL_TRANSACTIONS_PENDING = "GET_TOTAL_TRANSACTIONS_PENDING";
export const GET_TOTAL_TRANSACTIONS_SUCCESS = "GET_TOTAL_TRANSACTIONS_SUCCESS";
export const GET_TOTAL_TRANSACTIONS_FAILURE = "GET_TOTAL_TRANSACTIONS_FAILURE";

function getTotalTransactionsPending(): Action {
  return {
      type: GET_TOTAL_TRANSACTIONS_PENDING
   }
}

function getTotalTransactionsSuccess(data: TotalEarning): Action {
  return {
      type: GET_TOTAL_TRANSACTIONS_SUCCESS,
      payload: data
   }
}

function getTotalTransactionsFailure(error: any): Action {
  return {
      type: GET_TOTAL_TRANSACTIONS_FAILURE,
      payload: error
   }
}

export function getTotalTransactionsAction(query?: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(getTotalTransactionsPending());
      const response: AxiosResponse<TotalEarning> = await axios.get(`${TEACHER_URL}/transactions/total-earning/${stringifyParams(query)}`);
      dispatch(getTotalTransactionsSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getTotalTransactionsFailure(error));
      return Promise.reject(error)
    }
  }
}
