import {
  GET_BOOKS_FAILURE,
  GET_BOOKS_PENDING,
  GET_BOOKS_SUCCESS,
  GET_BOOK_DETAILS_FAILURE,
  GET_BOOK_DETAILS_PENDING,
  GET_BOOK_DETAILS_SUCCESS,
  GET_FEATURED_BOOKS_FAILURE,
  GET_FEATURED_BOOKS_PENDING,
  GET_FEATURED_BOOKS_SUCCESS,
  GET_WHAT_TO_READ_FAILURE,
  GET_WHAT_TO_READ_PENDING,
  GET_WHAT_TO_READ_SUCCESS,
} from "store/actions/book.action";
import { Action } from "types/action";
import { BookDetails, BookResourceData, FeaturedBookData, WhatToRead } from "types/book";

export type BookState = {
  // Data
  bookData: BookResourceData;
  whatToReadThisWeek: WhatToRead;
  whatToReadLastWeek: WhatToRead;
  whatToReadNextWeek: WhatToRead;
  featuredBookData: FeaturedBookData;
  book: BookDetails | null;
  // Loading
  getBooksLoading: boolean;
  getWhatToReadLoading: boolean;
  getFeaturedBooksLoading: boolean;
  getBookDetailsLoading: boolean;
  // Error
  getBooksError: any;
  getWhatToReadError: any;
  getFeaturedBooksError: any;
  getBookDetailsError: any;
};

const initialState: BookState = {
  // Data
  bookData: { totalRow: 0, results: [] },
  whatToReadThisWeek: { results: [], filter: '' },
  whatToReadLastWeek: { results: [], filter: '' },
  whatToReadNextWeek: { results: [], filter: '' },
  featuredBookData: { totalRow: 0, data: [] },
  book: null,
  // Loading
  getBooksLoading: false,
  getWhatToReadLoading: false,
  getFeaturedBooksLoading: false,
  getBookDetailsLoading: false,
  // Error
  getBooksError: null,
  getWhatToReadError: null,
  getFeaturedBooksError: null,
  getBookDetailsError: null
};

export default function bookReducer(
  state = initialState,
  action: Action
): BookState {
  switch (action.type) {
    //  ============================================================
    //  GET BOOKS
    //  ============================================================
    case GET_BOOKS_PENDING:
      return {
        ...state,
        getBooksLoading: true,
      };
    case GET_BOOKS_SUCCESS:
      return {
        ...state,
        getBooksLoading: false,
        bookData: action.payload,
      };
    case GET_BOOKS_FAILURE:
      return {
        ...state,
        getBooksLoading: false,
        getBooksError: action.payload,
      };
    //  ============================================================
    //  GET WHAT TO READ
    //  ============================================================
    case GET_WHAT_TO_READ_PENDING:
      return {
        ...state,
        getWhatToReadLoading: true,
      };
    case GET_WHAT_TO_READ_SUCCESS:
      if (action.payload.filter === 'next_week') {
        return {
          ...state,
          getWhatToReadLoading: false,
          whatToReadNextWeek: action.payload,
        };
      } else if (action.payload.filter === 'last_week'){
        return {
          ...state,
          getWhatToReadLoading: false,
          whatToReadLastWeek: action.payload,
        };
      } else {
        return {
          ...state,
          getWhatToReadLoading: false,
          whatToReadThisWeek: action.payload,
        };
      }

    case GET_WHAT_TO_READ_FAILURE:
      return {
        ...state,
        getWhatToReadLoading: false,
        getWhatToReadError: action.payload,
      };
    //  ============================================================
    //  GET FEATURED BOOKS
    //  ============================================================
    case GET_FEATURED_BOOKS_PENDING:
      return {
        ...state,
        getFeaturedBooksLoading: true,
      };
    case GET_FEATURED_BOOKS_SUCCESS:
      return {
        ...state,
        getFeaturedBooksLoading: false,
        featuredBookData: action.payload,
      };
    case GET_FEATURED_BOOKS_FAILURE:
      return {
        ...state,
        getFeaturedBooksLoading: false,
        getFeaturedBooksError: action.payload,
      };
    //  ============================================================
    //  GET BOOK DETAILS
    //  ============================================================
    case GET_BOOK_DETAILS_PENDING:
      return {
        ...state,
        getBookDetailsLoading: true
      }
    case GET_BOOK_DETAILS_SUCCESS:
      return {
        ...state,
        getBookDetailsLoading: false,
        book: action.payload
      }
    case GET_BOOK_DETAILS_FAILURE:
      return {
        ...state,
        getBookDetailsLoading: false,
        getBookDetailsError: action.payload
      }
    default:
      return { ...state };
  }
}
