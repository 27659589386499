import Cookie from "js-cookie";
import { COOKIE_CONFIG } from "configs/cookie";
import { Redirect, useLocation } from "react-router";

type AuthGuardProps = {
  children: JSX.Element;
};

export default function AuthGuard(props: AuthGuardProps) {
  // React router hooks
  const { pathname, search } = useLocation();

  function isAuthenticated(): boolean {
    const accessToken = Cookie.get(COOKIE_CONFIG.ACCESS_NAME);
    const refreshToken = Cookie.get(COOKIE_CONFIG.REFRESH_NAME);

    return (!!accessToken || !!refreshToken);
  }

  function getRedirectUrl(): string {
    if (pathname === "/" && search === "") {
      return "/auth/login";
    }

    return "/auth/login?continue=" + encodeURIComponent(pathname + search);
  }

  return isAuthenticated() ? (
    props.children
  ) : (
    <Redirect to={getRedirectUrl()} />
  );
}
