import { GeocodeResult } from "use-places-autocomplete";

export type LocationResult = {
  address: string;
  city?: string;
  country?: string;
  state?: string;
  postcode?: string;
  latitude: number,
  longitude: number
};

export function getLocationFromGeoCoderResult(
  result: GeocodeResult
): LocationResult {
  const address = result.formatted_address;
  const city = result.address_components.find((add) =>
    add.types.includes("locality")
  )?.long_name;
  const country = result.address_components.find((add) =>
    add.types.includes("country")
  )?.short_name;
  const state = result.address_components.find((add) =>
    add.types.includes("administrative_area_level_1")
  )?.long_name;
  const postcode = result.address_components.find((add) =>
    add.types.includes("postal_code")
  )?.long_name;
  let lat: number = 0,
    lng: number = 0;

  if (typeof result.geometry.location.lat === "function") {
    lat = result.geometry.location.lat();
    lng = result.geometry.location.lng();
  } else if (typeof result.geometry.location.lat === "number") {
    lat = Number(result.geometry.location.lat as unknown);
    lng = Number(result.geometry.location.lng as unknown);
  }
  
  return {
    address: address,
    city: city,
    country: country,
    longitude: lng,
    latitude: lat,
    state: state,
    postcode: postcode,
  };
}
