import { GET_DIALECTS_FAILURE, GET_DIALECTS_PENDING, GET_DIALECTS_SUCCESS } from "store/actions/dialect.action"
import { Action } from "types/action"
import { DialectData } from "types/dialect"

export type DialectState = {
  // Data
  dialectData: DialectData;
  // Loading
  getDialectsLoading: boolean;
  // Error
  getDialectsError: any;
}

const initialState: DialectState = {
  // Data
  dialectData: {totalRow: 0, data: []},
  // Loading
  getDialectsLoading: false,
  // Error
  getDialectsError: null
}

export default function dialectReducer(state = initialState, action: Action): DialectState {
  switch (action.type) {
    //  ============================================================
    //  GET DIALECTS
    //  ============================================================
    case GET_DIALECTS_PENDING:
      return {
        ...state,
        getDialectsLoading: true
      }
    case GET_DIALECTS_SUCCESS:
      return {
        ...state,
        getDialectsLoading: false,
        dialectData: action.payload
      }
    case GET_DIALECTS_FAILURE:
      return {
        ...state,
        getDialectsLoading: false,
        getDialectsError: action.payload
      }
    default:
      return { ...state }
  }
}