import axios, { AxiosResponse } from "axios";
import { CLASS_SUBJECT_URL } from "constants/urls";
import { stringifyParams } from "helpers/params";
import { Action } from "types/action";
import { ClassSubjectData } from "types/class-subject";

//  ============================================================
//  GET CLASS SUBJECTS
//  ============================================================
export const GET_CLASS_SUBJECTS_PENDING = "GET_CLASS_SUBJECTS_PENDING";
export const GET_CLASS_SUBJECTS_SUCCESS = "GET_CLASS_SUBJECTS_SUCCESS";
export const GET_CLASS_SUBJECTS_FAILURE = "GET_CLASS_SUBJECTS_FAILURE";
function getClassSubjectsPending(): Action {
  return {
      type: GET_CLASS_SUBJECTS_PENDING
   }
}

function getClassSubjectsSuccess(classSubjects: ClassSubjectData): Action {
  return {
      type: GET_CLASS_SUBJECTS_SUCCESS,
      payload: classSubjects
   }
}

function getClassSubjectsFailure(error: any): Action {
  return {
      type: GET_CLASS_SUBJECTS_FAILURE,
      payload: error
   }
}

export function getClassSubjectsAction(query?: any) {
  return async (dispatch: Function) => {
    try {
      dispatch(getClassSubjectsPending());
      const response: AxiosResponse<any> = await axios.get(CLASS_SUBJECT_URL + stringifyParams(query));
      dispatch(getClassSubjectsSuccess(response.data));
      return Promise.resolve();
    } catch (error) {
      dispatch(getClassSubjectsFailure(error));
      return Promise.reject(error)
    }
  }
}